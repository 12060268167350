<template>
  <div>
    <dashboard-panel no-collapse title="Killswitch">
      <broker-kill-switch />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import BrokerKillSwitch from '@/modules/broker-admin/components/BrokerKillSwitch.vue';

@Component({
  components: {
    DashboardPanel,
    BrokerKillSwitch,
  },
})
export default class BrokerKillSwitchHome extends Vue {}
</script>

<style lang="scss"></style>
