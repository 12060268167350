import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { QueryMarketplaceConfig, serviceMarketplace } from '@/connect/services/new-marketplace';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { QueryMarketplaceFilter } from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { OmsOrderType } from '@/connect/gen/consts/omsconsts_pb';
import { useStoreExplorer } from './store-explorer';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreCompanies } from '@/store/store-companies';

export function createDataSource(): IServerSideDatasource {
  return {
    async getRows(params) {
      const store = useStoreExplorer();

      const queryParams = {
        page: 1,
        pageSize: 200,
        tradeIntention: store.direction === 'borrow' ? Side.BORROWER : Side.LENDER,
        borrowFilter: getFilter('borrow', params),
        lendFilter: getFilter('lend', params),
      };

      if (params.request.groupKeys.length === 0) {
        await fetchGroups(params, queryParams);
      } else {
        await fetchItems(params, queryParams);
      }
    },
  };
}

async function fetchGroups(params: IServerSideGetRowsParams, queryParams: QueryMarketplaceConfig) {
  const response = await serviceMarketplace.querySecurities(queryParams);
  const rowData = Object.values(response).sort((a, b) => a.ticker.localeCompare(b.ticker));

  params.success({
    rowData,
    rowCount: rowData.length,
  });
}

async function fetchItems(params: IServerSideGetRowsParams, queryParams: QueryMarketplaceConfig) {
  const cusip = params.request.groupKeys[0];
  queryParams.lendFilter.instruments = [cusip];
  queryParams.borrowFilter.instruments = [cusip];

  const response = await serviceMarketplace.queryMarketplace(queryParams);

  if (!response.success) return;

  const storeSecurities = useStoreSecurities();
  const storeCompanies = useStoreCompanies();

  storeSecurities.addSecurities(response.data.instruments);
  storeCompanies.addCompanies(response.data.companies);

  params.success({
    rowData: response.data.items,
    rowCount: response.data.items.length,
  });
}

function getFilter(direction: 'borrow' | 'lend', params: IServerSideGetRowsParams) {
  const store = useStoreExplorer();
  return {
    orderType: getOrderTypeFilter(params, direction),
    instruments: store.selectedSecurity ? [store.selectedSecurity.cusip] : undefined,
    ...getQuantityFilter(params, direction),
    ...getRateFilter(params, direction),
  } as QueryMarketplaceFilter;
}

function getOrderTypeFilter(
  params: IServerSideGetRowsParams,
  direction: 'borrow' | 'lend'
): OmsOrderType | undefined {
  const values = params.request.filterModel?.[`${direction}Order.orderType`]?.values;
  if (!values) return undefined;
  if (values.includes('Limit')) return OmsOrderType.LIMIT;
  if (values.includes('IOI')) return OmsOrderType.IOI;
  return undefined;
}

function getQuantityFilter(params: IServerSideGetRowsParams, direction: 'borrow' | 'lend') {
  const filter = params.request.filterModel?.[`${direction}Order.quantity`];
  if (!filter) return undefined;
  switch (filter.type) {
    case 'equals':
      return {
        minimumQuantity: BigInt(filter.filter),
        maximumQuantity: BigInt(filter.filter),
      };
    case 'lessThan':
      return { maximumQuantity: BigInt(filter.filter) };
    case 'greaterThan':
      return { minimumQuantity: BigInt(filter.filter) };
  }
  return undefined;
}

function getRateFilter(params: IServerSideGetRowsParams, direction: 'borrow' | 'lend') {
  const filter = params.request.filterModel?.[`${direction}Order.rate`];
  if (!filter) return undefined;
  switch (filter.type) {
    case 'equals':
      return {
        minimumRate: filter.filter,
        maximumRate: filter.filter,
      };
    case 'lessThan':
      return { maximumRate: filter.filter };
    case 'greaterThan':
      return { minimumRate: filter.filter };
  }
  return undefined;
}
