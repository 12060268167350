<template>
  <div class="wrapper">
    <div
      v-if="fetchStatus === 'fetching'"
      class="d-flex justify-center pt-16 flex-column align-center"
    >
      <fulfilling-bouncing-circle-spinner :animation-duration="1500" color="#4caf50" :size="50" />
      <h3 class="mt-2">Fetching corporate actions...</h3>
    </div>

    <div v-else-if="fetchStatus === 'failed'" class="justify-center pt-16 flex-column align-center">
      <v-alert dense type="error">
        {{ fetchError }}
      </v-alert>
    </div>

    <template v-else-if="fetchStatus === 'completed'">
      <div class="pt-4 white--text">
        <div v-for="action in actions" :key="action.actionId" class="action mb-8 pl-4 py-2">
          <h3>{{ action.eventType }} ({{ action.eventLabel }})</h3>

          <div class="mt-4">
            <v-row dense>
              <v-col class="py-0" cols="4">Effective date </v-col>
              <v-col class="py-0" cols="6">
                {{ formatDate(action.dateInAction, 'MM/dd/yy') }}
              </v-col>
            </v-row>

            <v-row v-for="field in action.fields" :key="`${action.actionId}_${field.name}`" dense>
              <v-col class="py-0" cols="4">{{ field.label }} </v-col>
              <v-col class="py-0" cols="6">
                <template v-if="field.dataType === 'date'">
                  {{ formatDate(field.value, 'MM/dd/yy') }}
                </template>
                <!--  v-else when no special formatting needed
                      if there are new dataTypes, v-else-if's should be added above
                      (and type definitions should also be updated) -->
                <template v-else>
                  {{ field.value }}
                </template>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Api } from '@/modules/common/types/api';
import Vue from 'vue';
import Component from 'vue-class-component';
import { formatDate } from '@/utils/helpers/dates';

@Component({
  props: {
    cusip: {
      type: String,
      required: true,
    },
  },
})
export default class CorpActionsCard extends Vue {
  protected readonly cusip!: string;

  protected actions: Api.CorpActions.Action[] = [];
  protected fetchStatus: 'fetching' | 'completed' | 'failed' = 'fetching';
  protected fetchError = '';
  protected formatDate = formatDate;

  protected async mounted(): Promise<void> {
    try {
      this.actions = await this.$api.corpActions.fetchActions(this.cusip);
      this.fetchStatus = 'completed';
      this.$emit('fetch-corp-actions', this.actions);
    } catch (e) {
      this.fetchStatus = 'failed';
      this.fetchError = e as string;
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}

.action {
  border-left: 2px solid #2c8d3a;
}
</style>
