import { Raw } from '@/modules/common/helpers/api';
import { ParseOmsBasketResponse } from '@/modules/marketplace/models';
import { OmsBasketItem, OrderBasketParams, RawOmsBasketItem } from '@/modules/marketplace/models';

export type RawOmsCreateBasketRequest = Raw<
  OmsCreateBasketRequest,
  {
    // always specify existing raw entry types explititly
    entries: RawOmsBasketItem[];
  }
>;

export class OmsCreateBasketRequest extends OrderBasketParams {
  protected constructor(data: RawOmsCreateBasketRequest) {
    super(data);
  }

  public static fromData(data: RawOmsCreateBasketRequest): OmsCreateBasketRequest {
    return new OmsCreateBasketRequest(data);
  }

  public static fromParseOmsBasketResponse(data: ParseOmsBasketResponse): OmsCreateBasketRequest {
    return new OmsCreateBasketRequest({
      companyId: data.companyId,
      userId: data.userId,
      side: data.side,
      entries: data.entries.map(OmsBasketItem.toData),
    });
  }

  public static toData(model: OmsCreateBasketRequest): RawOmsCreateBasketRequest {
    return OrderBasketParams.toData(model);
  }
}
