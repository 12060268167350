import { Raw } from '@/modules/common/helpers/api';

export type RawLogin2FAResponse = Raw<Login2FAResponse>;

export class Login2FAResponse {
  public status: string;

  protected constructor(data: RawLogin2FAResponse) {
    this.status = data.status;
  }

  public static fromData(data: RawLogin2FAResponse): Login2FAResponse {
    return new Login2FAResponse(data);
  }
}
