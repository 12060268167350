<template>
  <span class="is-date">{{ prettyFormat() }}</span>
</template>

<script lang="ts">
/**
 * Display datetime nicely (including timezone)
 */
import { Component, Vue } from 'vue-property-decorator';
import { isSameDay } from 'date-fns';
import { formatDate } from '@/utils/helpers/dates';

@Component({
  props: {
    when: Date,
    short: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: Boolean,
      default: true,
    },
  },
})
export default class PrettyDateTime extends Vue {
  private when!: Date;
  private short!: boolean;
  private timezone!: boolean;

  private baseFormat = 'MMM dd, HH:mm';
  private zoneFormat = !this.short && this.timezone ? ' O' : '';

  protected prettyFormat(): string {
    if (this.short) {
      if (isSameDay(this.when, new Date())) {
        this.baseFormat = 'HH:mm';
      } else {
        this.baseFormat = 'MMM dd';
      }
    }
    return formatDate(this.when, `${this.baseFormat}${this.zoneFormat}`);
  }
}
</script>
<style lang="scss" scoped>
.is-date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
