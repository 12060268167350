<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <form class="form" novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(title) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="svErrors">
            <v-alert dense type="error">{{ svErrors }}</v-alert>
          </v-container>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="profile.label"
                  autofocus
                  :error-messages="clErrors.label"
                  :label="$t('investorProfile')"
                  placeholder="e.g. Pension Fund"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-16">
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeModalDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="120" type="submit" @click="onSubmit">
            {{ $t('saveInvestorProfile') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import { InvestorProfile, RestOperation } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { FormValidator } from '@/utils/helpers/input-form';

@Component({
  components: {},
  props: {
    crudAction: Number,
    title: String,
    profile: Object,
  },
  data: () => ({
    clErrors: {},
    svErrors: '',
    RestOperation,
  }),
  methods: {
    ...mapActions(['createInvestorProfile', 'updateInvestorProfile']),
  },
})
export default class InvestorProfileDialog extends Vue {
  public $refs!: {
    autofocus: HTMLFormElement;
  };

  private crudAction!: RestOperation;
  private profile!: InvestorProfile;

  private createInvestorProfile!: (profile: InvestorProfile) => void;
  private updateInvestorProfile!: (profile: InvestorProfile) => void;

  private processing = false;

  private clErrors: { [index: string]: string } = {};
  private svErrors = '';

  private mounted() {
    this.$refs.autofocus.focus();
  }

  // @click ...
  private async onSubmit() {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      this.svErrors = '';
      this.clErrors = new FormValidator(this.profile, ['label']).check();

      if (isEmpty(this.clErrors)) {
        await this.doSubmit(this.profile);
      }
    } finally {
      this.processing = false;
    }
  }

  private async doSubmit(profileRecord: InvestorProfile) {
    try {
      if (this.crudAction === RestOperation.Create) {
        await this.createInvestorProfile(profileRecord);
      } else {
        await this.updateInvestorProfile(profileRecord);
      }

      this.closeModalDialog();
    } catch (e) {
      this.svErrors = errorString(e as Error);
    }
  }

  private closeModalDialog() {
    // allow parent to cleanup
    this.$emit('close-modal');
  }
}
</script>
