<template>
  <v-container>
    <template v-if="state === State.Input || state === State.DisplayResult">
      <v-form novalidate @submit.prevent>
        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              data-test-id="var-calculator-file-input"
              :error-messages="calcError"
              :label="$t('analytics.VaR.uploadLabel')"
              truncate-length="50"
              @change="calculate()"
            ></v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn class="mt-2" @click="calculate()">{{ $t('analytics.VaR.calculate') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <p>
              {{ $t('analytics.VaR.explanation.0') }} <br />
              {{ $t('analytics.VaR.explanation.1') }} <br />
              {{ $t('analytics.VaR.explanation.2') }}
            </p>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-if="state === State.Submitted">
      <v-row>
        <v-col align="center">
          <fulfilling-bouncing-circle-spinner
            :animation-duration="1500"
            color="#4caf50"
            :size="50"
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="state === State.DisplayResult">
      <v-row v-if="varResult">
        <v-col>
          <p>
            Total Notional: ${{ formatPrice(totalNotional) }} <br />
            {{ $t('analytics.VaR.totalValueAtRisk') }}: ${{
              formatPrice(varResult.valueAtRisk)
            }}
            (<pretty-date always-long :when="varResult.date" />) <br />
            {{ $t('analytics.VaR.haircut') }}:
            {{ getPercentageAsString(varResult.valueAtRisk.div(totalNotional).mul(100.0)) }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            dense
            disable-pagination
            fixed-header
            :headers="tableColumns"
            :items="varResult?.positions"
            no-hide-default-footer
            sort-by="notional"
            sort-desc
          >
            <template #[`item.closePrice`]="{ item }">
              ${{ formatPrice(item.closePrice) }}
            </template>
            <template #[`item.notional`]="{ item }"> ${{ formatPrice(item.notional) }} </template>
            <template #[`item.valueAtRisk`]="{ item }">
              ${{ formatPrice(item.valueAtRisk) }}
            </template>
            <template #[`item.haircut`]="{ item }">
              {{ getPercentageAsString(item.valueAtRisk.div(item.notional).mul(100.0)) }}
            </template>
            <template #[`item.utilization`]="{ item }">
              {{ getPercentageAsString(item.utilization * 100.0) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import SystemBar from '@/modules/common/components/SystemBar.vue';
import PrettyDate from '@/modules/common/components/format-date/PrettyDate.vue';
import PriceOutput from '@/modules/common/components/format-price/PriceOutput.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { ValueAtRiskResponse } from '@/utils/api/analytics';
import { getPercentageAsString, getPriceAsString } from '@/utils/helpers/auction-numbers';
import { errorString } from '@/utils/helpers/rest-response';
import Decimal from 'decimal.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions } from 'vuex';

// we use >= State.X in some places so the order matters!
enum State {
  Input,
  Submitted,
  DisplayResult,
}

const tableColumns = [
  {
    text: i18n.t('CUSIP'),
    value: 'cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.t('Ticker'),
    value: 'ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: 100,
  },
  {
    text: i18n.t('Quantity'),
    value: 'quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate is-numeric',
    align: 'end',
  },
  {
    text: i18n.t('Close Price'),
    value: 'closePrice',
    class: 'text-truncate',
    cellClass: 'text-truncate is-numeric',
    align: 'end',
  },
  {
    text: i18n.t('Notional Value'),
    value: 'notional',
    class: 'text-truncate',
    cellClass: 'text-truncate is-numeric',
    align: 'end',
  },
  {
    text: i18n.t('VaR'),
    value: 'valueAtRisk',
    class: 'text-truncate',
    cellClass: 'text-truncate is-numeric',
    align: 'end',
  },
  {
    text: i18n.t('analytics.VaR.haircut'),
    value: 'haircut',
    class: 'text-truncate',
    cellClass: 'text-truncate is-numeric',
    align: 'end',
  },
  {
    text: i18n.t('% of total VaR'),
    value: 'utilization',
    class: 'text-truncate',
    cellClass: 'text-truncate utilization',
    align: 'end',
  },
];

@Component({
  components: {
    SystemBar,
    PriceOutput,
    PrettyDate,
  },
  data: () => ({
    State,
    PRICE_PRECISION,
  }),
  methods: {
    ...mapActions(['getValueAtRiskCalculation']),
  },
})
export default class ValueAtRiskCalculator extends Vue {
  // store methods
  protected getValueAtRiskCalculation!: (file: File) => Promise<ValueAtRiskResponse>;

  protected state: State = State.Input;
  protected calcError: string | null = null;
  protected file: File | null = null;
  protected varResult?: ValueAtRiskResponse;

  // and tableColumns
  protected tableColumns = tableColumns;

  protected get totalNotional(): Decimal {
    const zero = new Decimal(0);
    return this.varResult
      ? this.varResult.positions.reduce((s, p) => s.add(p.notional), zero)
      : zero;
  }

  protected async calculate(): Promise<void> {
    if (this.file === null) {
      this.calcError = this.$t('analytics.VaR.needFile') as string;
      return;
    }

    // grab file, reset err state and go into submitted state
    const file = this.file;
    this.file = null;
    this.state = State.Submitted;
    this.calcError = null;
    const minimumWait = this.$wait(500);

    try {
      // fetch result
      this.varResult = await this.getValueAtRiskCalculation(file);
      // and wait a bit if we got it really fast, for the UI animations
      await minimumWait;
      this.state = State.DisplayResult;
    } catch (e) {
      this.state = State.Input;
      this.calcError = errorString(e as Error);
    }
  }
  protected getPercentageAsString(num: number | Decimal): string {
    return getPercentageAsString(num);
  }
  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }
}
</script>

<style lang="scss" scoped></style>
