import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors/api-error';

/**
 * Permission Denied Error (403)
 *
 * The request was denied due to not having the necessary permissions
 */
export class PermissionDeniedError<T = Api.ErrorResponse> extends ApiError<T> {
  public constructor(
    public message: string,
    public responseData?: T
  ) {
    super(message);
    this.name = 'PermissionDeniedError';
  }
}
