class Wait {
  private readonly p: Promise<void>;
  private readonly id: ReturnType<typeof setTimeout>;
  private resolve!: (value?: void | PromiseLike<void>) => void;
  private reject!: (reason?: unknown) => void;
  private done = false;

  public constructor(timeout: number) {
    this.p = new Promise<void>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });

    this.id = setTimeout(() => {
      if (this.done) {
        return;
      }
      this.done = true;

      this.resolve();
    }, timeout);
  }

  public get promise(): Promise<void> {
    return this.p;
  }

  public static waitFor(timeout: number, callback?: () => void): Wait {
    const w = new Wait(timeout);

    if (callback != null) {
      // add callback and ignore err
      w.promise.then(callback, () => {
        // ignored
      });
    }

    return w;
  }

  public cancel(): void {
    if (this.done) {
      return;
    }
    this.done = true;

    clearTimeout(this.id);
    this.reject();
  }

  public trigger(): void {
    if (this.done) {
      return;
    }
    this.done = true;

    clearTimeout(this.id);
    this.resolve();
  }
}

export async function wait(waitts: number, callback?: () => void): Promise<void> {
  return Wait.waitFor(waitts, callback).promise;
}

export default wait;
