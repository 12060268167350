<template>
  <v-system-bar class="justify-space-between" window @dblclick="toggle">
    <span class="text-capitalize">{{ title }}</span>
    <div>
      <v-icon
        v-if="!noCollapse"
        :title="collapseMsg"
        @click="$emit('update:isCollapsed', !isCollapsed)"
      >
        {{ collapseIcon }}
      </v-icon>
      <v-icon
        v-if="showCloseIcon"
        data-test="system-bar-close-icon"
        title="close"
        @click="$emit('close', true)"
      >
        mdi-close
      </v-icon>
    </div>
  </v-system-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    title: String,
    /**
     * Disable the collapse feature and hide the icon
     */
    'no-collapse': Boolean,
    /**
     * Indicates if component is collapsed
     */
    isCollapsed: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Show a close icon which will emit a "close" event
     */
    showCloseIcon: Boolean,
  },
})
export default class SystemBar extends Vue {
  protected readonly title!: string;
  protected readonly noCollapse!: boolean;
  protected readonly isCollapsed!: boolean;
  protected readonly showCloseIcon!: boolean;

  protected get collapseIcon(): string {
    return this.isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up';
  }

  protected get collapseMsg(): string {
    return this.isCollapsed ? 'expand panel' : 'collapse panel';
  }

  protected toggle(): void {
    if (!this.noCollapse) {
      this.$emit('update:isCollapsed', !this.isCollapsed);
    }
  }
}
</script>
