<!-- This component implements <ConfirmDialogMarkup> in declarative way.

     It is used to wrap a component that needs to confirm an action.
     Because we have a parent::child relationship, it offers more flexibility
     when composing related components and dealing with $emit events. -->
<template>
  <span>
    <slot v-bind="{ confirm }" />
    <confirm-dialog-markup v-model="value" :options="options" @accept="accept" @reject="reject" />
  </span>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { DialogOptions } from '@/plugins/dialog-manager';
import ConfirmDialogMarkup from '@/modules/common/components/popups/ConfirmDialogMarkup.vue';

@Component({
  props: {
    options: Object as PropType<DialogOptions>,
  },
  components: {
    ConfirmDialogMarkup,
  },
})
export default class ConfirmDialogWrapper extends Vue {
  // props
  protected options!: DialogOptions;

  protected value = false;
  protected onAccept!: () => void;
  protected onReject?: () => void;

  public closeDialog(): void {
    this.$emit('is-confirming', false);
    this.value = false;
  }

  protected confirm(onAccept: () => void, onReject?: () => void): void {
    this.onAccept = onAccept;
    this.onReject = onReject;
    this.$emit('is-confirming', true);
    this.value = true;
  }

  protected accept(): void {
    this.onAccept();
    this.closeDialog();
  }

  protected reject(): void {
    if (this.onReject) {
      this.onReject();
    }
    this.closeDialog();
  }
}
</script>
