import { ActionHandler, ExtendedStore, Mutation } from '@/types/vuex.custom';
import { ManualLoanListItem, Socket } from '@/modules/manual-loan/types/manual-loans';
import { AppState } from '@/store/store';
import { SocketMessage } from '@/utils/helpers/socket';
import { Module } from 'vuex';
import { debounce, each, extend } from 'lodash';
import { socketDebouncConfig, socketDebounceTime } from '@/store/store.const';
import { Api } from '@/modules/common/types/api';
import { ManualLoanStatus } from '@/modules/manual-loan/constants/manual-loans.const';

/**
 * Vuex Store state for the Manual Loans feature
 */
export interface ManualLoansState {
  filterPreEstablished: boolean;
  filterStatuses: ManualLoanStatus[];
  manualLoanRequests: ManualLoanListItem[];
}

/**
 * Manual Loans: Store State
 *   store filters in the store so that socket `refresh` events know how to refresh
 *   (the filters are applied server-side)
 */
export function initialStoreState(): ManualLoansState {
  return {
    filterPreEstablished: false,
    filterStatuses: [],
    manualLoanRequests: [],
  };
}

/**
 * Manual Loans: Store Getters
 */
export const getters = {};

/**
 * Manual Loans: Store Mutations
 */
export const mutations = {
  resetSessionState: function (state) {
    // reset session related state to initial values
    extend(state, initialStoreState());
  } as Mutation<ManualLoansState, void>,

  filterPreEstablished: function (state, isPreEstablished) {
    state.filterPreEstablished = isPreEstablished;
  } as Mutation<ManualLoansState, boolean>,

  filterStatuses: function (state, statuses) {
    state.filterStatuses = statuses;
  } as Mutation<ManualLoansState, ManualLoanStatus[]>,

  manualLoanRequests: function (state, manualLoans) {
    state.manualLoanRequests = manualLoans;
  } as Mutation<ManualLoansState, Api.ManualLoans.ManualLoanListResponseItem[]>,
};

/**
 * Manual Loans: Store Actions
 */
type ManualLoanAction = ActionHandler<ManualLoansState, AppState, typeof mutations>;
export const actions = {
  setFilterPreEstablished: function (
    this: ExtendedStore<AppState>,
    { commit },
    isPreEstablished: boolean
  ) {
    commit('filterPreEstablished', isPreEstablished);
  } as ManualLoanAction,

  setFilterStatuses: function (this: ExtendedStore<AppState>, { commit }, statuses: boolean) {
    commit('filterStatuses', statuses);
  } as ManualLoanAction,

  fetchManualLoanRequests: async function (this: ExtendedStore<AppState>, { state, commit }) {
    // @TODO need a better way to get access to Vue app instance. This feels hacky.
    // maybe better to use Vue.prototype? until we move to vue 3 at least
    const vue = this._vm;

    // @TODO use store for filter/limit/offset/etc
    const { data } = await vue.$api.manualLoans.getManualLoansList(
      state.filterPreEstablished,
      state.filterStatuses
    );
    commit('manualLoanRequests', data);
  } as ManualLoanAction,
};

/**
 * Manual Loans: Store actions for socket events
 */
export const socketActions = {
  cancelPendingSocketHandles: function () {
    // find just the debounced socket actions and cancel them if they are pending
    each(socketActions, (action) => {
      if ('cancel' in action) {
        action.cancel();
      }
    });
  } as ManualLoanAction,

  refreshManualLoanRequests: debounce(
    function ({ dispatch }, _sm: SocketMessage<Socket.ManualLoans.StatusChangePayload>) {
      void dispatch('fetchManualLoanRequests');
    } as ManualLoanAction,
    socketDebounceTime,
    socketDebouncConfig
  ),
};

/**
 * Manual Loans: Store module
 */
export const manualLoansStore: Module<ManualLoansState, AppState> = {
  state: initialStoreState,
  getters,
  mutations,
  actions: { ...socketActions, ...actions },
};
