import { BespokeAuction, RawBespokeAuction } from '@/modules/auction/models';
import { Raw } from '@/modules/common/helpers/api';

export type RawAuctionsListResponse = Raw<
  AuctionsListResponse,
  {
    // always specify existing raw entry types explititly
    auctions: RawBespokeAuction[];
  }
>;

export class AuctionsListResponse {
  public status: string;
  public auctions: BespokeAuction[];

  protected constructor(data: RawAuctionsListResponse) {
    this.status = data.status;
    this.auctions = data.auctions.map(BespokeAuction.fromData);
  }

  public static fromData(data: RawAuctionsListResponse): AuctionsListResponse {
    return new AuctionsListResponse(data);
  }
}
