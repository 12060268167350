import colors from 'vuetify/lib/util/colors';

export const darkTheme = {
  primary: {
    base: colors.green.base,
    darken1: colors.green.darken1,
    darken2: colors.green.darken2,
  },
  secondary: colors.blueGrey,
  tertiary: colors.grey,
  'list-label': colors.deepPurple.accent1,
  'form-field': colors.blueGrey,
  'form-tooltip': colors.blueGrey,
};

export const lightTheme = {
  primary: {
    base: colors.blue.base,
    darken1: colors.blue.darken1,
    darken2: colors.blue.darken2,
  },
  secondary: colors.indigo,
  tertiary: colors.grey,
  'list-label': colors.deepOrange,
  'form-field': colors.indigo,
  'form-tooltip': colors.indigo,
};
