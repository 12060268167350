import { AuroraError } from './aurora-error';

/**
 * Generic Error
 *
 * A generic error thrown when something goes wrong in our app
 */
export class GenericError extends AuroraError {
  public constructor(public message: string) {
    super(message);
    this.name = 'GenericError';

    // use built in stack trace so the trace starts here
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
