import Vue from 'vue';

export interface SnackbarOptions {
  top: boolean;
  message: string;
  color: string;
  timeout: number;
  actionText: string;
  onAction: () => void;
}

export class SnackbarManager extends Vue {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    v.prototype.$snackbar = singleton;
  }

  /**
   * Display a confirmation message
   */
  public confirm(message: string): void {
    this.$root.$emit('showMessage', { message: message, color: 'primary', timeout: 10000 });
  }

  /**
   * Display an error message
   */
  public error(message: string): void {
    this.$root.$emit('showMessage', { message: message, color: 'error', timeout: -1 });
  }

  /**
   * Display a generic message
   */
  public show(options: Partial<SnackbarOptions>): void {
    this.$root.$emit('showMessage', options);
  }
}

export function useSnackbarManager(): SnackbarManager {
  return Vue.prototype.$snackbar;
}
