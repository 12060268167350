<template>
  <uptime-statistics />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import UptimeStatistics from '@/modules/uptime/views/UptimeStatistics.vue';

@Component({
  components: {
    UptimeStatistics,
  },
})
export default class BrokerUptimeStatistics extends Vue {}
</script>

<style lang="scss"></style>
