<template>
  <div>
    <dashboard-panel no-collapse title="active auctions">
      <auctions />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import Auctions from '@/modules/auction/views/Auctions.vue';

@Component({
  components: {
    DashboardPanel,
    Auctions,
  },
})
export default class SecuritiesLendingActive extends Vue {}
</script>

<style lang="scss"></style>
