import { Instrument } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { PlainMessage } from '@/connect';
import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { PbInstrument } from '@/connect/gen/common/protos/types_pb';

export const useStoreSecurities = defineStore('securities', () => {
  // Once we migrate to Vue3 we should use shallowReactive
  const securities = shallowRef(new Map<string, PlainMessage<Instrument>>());

  function addSecurities(items: Record<string, PlainMessage<Instrument | PbInstrument>>) {
    const entries = Object.entries(items).map(([idOrCusip, item]) => {
      // @TODO: we need a consistent approach in the BE so we don't have to do this
      const cusip = 'cusip' in item ? item.cusip : idOrCusip;
      return [cusip, { ...item }];
    }) as Array<[string, PlainMessage<Instrument>]>;

    securities.value = new Map([...securities.value, ...entries]);
  }

  function getSecurity(cusip: string) {
    return securities.value.get(cusip);
  }

  return {
    addSecurities,
    getSecurity,
  };
});

export type StoreSecurities = ReturnType<typeof useStoreSecurities>;
