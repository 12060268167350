import { getCurrentScope, onScopeDispose } from 'vue';
import { Mediator } from '@/utils/helpers/mediator';
import SleepWorker from './worker?sharedworker';

/**
 * A Mediator instance for handling 'sleep' and 'wake' events.
 */
const mediatorSleep = new Mediator<{ wake: void }>();

const sleepWorker = new SleepWorker();
sleepWorker.port.onmessage = function () {
  mediatorSleep.dispatch('wake', undefined);
};

/**
 * Registers a callback function to be called when the 'wake' event is triggered.
 * @param {() => void} callback - The function to call when the 'wake' event occurs.
 */
export function useWake(callback: () => void): void {
  const effectScope = getCurrentScope();

  if (effectScope === undefined) {
    throw new Error('`useWake` composable must be called from within setup context');
  }

  effectScope.run(() => {
    mediatorSleep.on('wake', callback);
    onScopeDispose(() => mediatorSleep.off('wake', callback));
  });
}
