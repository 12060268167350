// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/commonconsts.proto (package aurora.core.consts.common, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum aurora.core.consts.common.Side
 */
export enum Side {
  /**
   * @generated from enum value: BORROWER = 0;
   */
  BORROWER = 0,

  /**
   * @generated from enum value: LENDER = 1;
   */
  LENDER = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Side)
proto3.util.setEnumType(Side, "aurora.core.consts.common.Side", [
  { no: 0, name: "BORROWER" },
  { no: 1, name: "LENDER" },
]);

