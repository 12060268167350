/**
 * An abstract base error class, which all our custom error classes should extend.
 * This class allows us to easily distinguish between our own custom errors and 3rd party errors.
 */
export abstract class AuroraError extends Error {
  public constructor(public message: string) {
    super(message);
    // Object.setPrototypeOf(this, AuroraError.prototype);    // should not be needed anymore as of Babel 7
  }
}
