import assert from 'assert';
import { CryptoSubtle } from '@/modules/common/services/crypto/crypto-subtle';
import { Pbkdf2Service } from '@/modules/common/services/crypto/pbkdf2-service';
import { Pbkdf2WebCrypto } from '@/modules/common/services/crypto/pbkdf2-web-crypto';
import { Pbkdf2AsmCrypto } from '@/modules/common/services/crypto/pbkdf2-asm-crypto';

// KeyDerivation is only a wrapper around 2 pbkdf services to provide some sanity checks and choosing the right one
// No other stretching implementations are supported at this point.
export class KeyDerivation {
  public static readonly defaultIterations = 35000;

  private static readonly keySizeBits = 256;
  private static readonly keySizeBytes = KeyDerivation.keySizeBits / 8;
  private pbkdf2Service!: Pbkdf2Service;

  // Use a 'Factory' method to create an instance. We must be sure the async crypto has been initialized
  // before returning the instance.
  public static async create(subtle: CryptoSubtle): Promise<KeyDerivation> {
    const self = new KeyDerivation();
    self.pbkdf2Service = await this.choosePbkdf2Implementation(subtle);

    return self;
  }

  private static async choosePbkdf2Implementation(subtle: CryptoSubtle): Promise<Pbkdf2Service> {
    // try webcrypto
    let pbkdf2Service: Pbkdf2Service = new Pbkdf2WebCrypto(subtle);
    if (await pbkdf2Service.isSupported()) {
      return pbkdf2Service;
    }
    // fallback on asmcrypto
    pbkdf2Service = new Pbkdf2AsmCrypto(subtle);
    if (await pbkdf2Service.isSupported()) {
      return pbkdf2Service;
    }

    throw new Error('pbkdf2 not available');
  }

  public async compute(pw: Buffer, salt: Buffer, iterations: number): Promise<Buffer> {
    iterations = iterations || KeyDerivation.defaultIterations;

    // Sanity checks
    assert(pw instanceof Buffer, 'Password must be provided as a Buffer');
    assert(salt instanceof Buffer, 'Salt must be provided as a Buffer');
    assert(salt.length > 0, 'Salt must not be empty');
    assert(typeof iterations === 'number', 'Iterations must be a number');
    assert(iterations > 0, 'Iteration count should be at least 1');
    if (salt.length > 0x80) {
      throw new Error('Sanity check: Invalid salt, length can never be greater than 128');
    }

    return this.pbkdf2Service.pbkdf2(pw, salt, iterations, KeyDerivation.keySizeBytes);
  }

  public isPbkdf2Supported(): Promise<boolean> {
    if (this.pbkdf2Service === undefined) {
      return Promise.resolve(false);
    }

    return this.pbkdf2Service.isSupported();
  }
}
