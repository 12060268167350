/* eslint-disable vue/one-component-per-file */

import { ColDef } from 'ag-grid-enterprise';
import { VChip } from 'vuetify/lib';
import { alignCenter, alignLeft, alignRight, component } from './utils';
import { h } from 'vue';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { formatSideAdapter, prettyPrice, rateAdapter } from './common';
import { loanEvents, newLoanEvents } from '@/utils/api/loans';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import FormatEventInitiator from '@/modules/common/components/FormatEventInitiator.vue';
import { aggSelector } from './open-loans';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { proto3 } from '@bufbuild/protobuf';
import { Benchmark } from '@/connect/gen/consts/benchmarkconsts_pb';
import { Benchmark as OldBenchmark } from '@/utils/api/loans';
import { SettlementType } from '@/connect/gen/consts/settlementconsts_pb';
import { LoanHistoryEvent } from '@/connect/gen/modules/apiengine/services/loans/loans_pb';
import LoanHistoryTableActions from '@/modules/open-loans/components/LoanHistoryTableActions.vue';
import { LoanHistoryEventInitiatorKind } from '@/connect/gen/consts/loanhistoryconsts_pb';
import { LoanEventType } from '@/modules/common/models';
export { checkbox } from './common';

const eventTypeAdapter = component<{
  eventType: LoanEventType;
}>(
  (props) => () =>
    h(
      VChip,
      {
        props: { color: loanEvents[props.eventType].color, xSmall: true },
        style: { minWidth: '8rem', justifyContent: 'center' },
      },
      loanEvents[props.eventType].label.toLocaleUpperCase()
    )
);

export function eventType(): ColDef {
  return {
    field: 'eventType',
    colId: 'eventType',
    headerName: 'Event Type',
    valueFormatter: (params) =>
      params.node?.rowPinned && params.data && 'nounOrQuantity' in params.data
        ? params.data.nounOrQuantity('eventTypes')
        : undefined,
    cellRendererSelector: (params) => {
      if (
        params.node?.rowPinned &&
        params.data &&
        'noun' in params.data &&
        params.data.noun?.eventTypes.length === 1
      ) {
        return eventTypeAdapter({
          eventType: newLoanEvents[params.data.noun.eventTypes[0]],
        });
      }
      if (!params.node?.rowPinned) {
        return eventTypeAdapter({
          eventType: newLoanEvents[params.value],
        });
      }
    },
    resizable: false,
    // pinned: 'left',
    width: 120,
    ...alignCenter(),
  };
}

const eventInitiadorAdapter = component<{
  initiator: {
    kind: string;
    email: string;
    externalIdentifier: string;
  };
}>(
  (props) => () =>
    h(FormatEventInitiator, {
      props: {
        initiator: props.initiator,
      },
    })
);

export function eventInitiator(): ColDef {
  return {
    field: 'initiator',
    colId: 'initiator',
    headerName: 'Initiator',
    cellRendererSelector: (params) => {
      const kind = proto3
        .getEnumType(LoanHistoryEventInitiatorKind)
        .values.find((v) => v.no === params.value?.kind)?.name as string;
      return params.node.rowPinned
        ? undefined
        : eventInitiadorAdapter({
            initiator: {
              kind,
              email: params.value.email,
              externalIdentifier: params.value.externalIdentifier,
            },
          });
    },
    ...alignLeft(),
  };
}

export function side(): ColDef {
  return {
    ...cols.side(),
    field: 'loanState.side',
    sortable: false,
    valueFormatter: (params) =>
      params.node?.rowPinned && params.data && 'nounOrQuantity' in params.data
        ? params.data.nounOrQuantity('sides')
        : undefined,
    cellRendererSelector: (params) => {
      if (
        params.node?.rowPinned &&
        params.data &&
        'noun' in params.data &&
        params.data.noun?.sides.length === 1
      ) {
        return formatSideAdapter({
          side: params.data.noun.sides[0],
        });
      }
      if (!params.node?.rowPinned) {
        return formatSideAdapter({
          side: params.value === Side.BORROWER ? 'BORROWER' : 'LENDER',
        });
      }
    },
  };
}

export const eventTime = (): ColDef =>
  cols.timestamp({
    field: 'eventTime',
    headerName: 'Event Time',
  });

export function openQuantity(): ColDef {
  return {
    field: 'loanState.openQuantity',
    colId: 'loanState.openQuantity',
    headerName: 'Open',
    valueFormatter: (params) =>
      formatPrettyNumber(
        params.node?.rowPinned && params.data
          ? params.data[params.data.selected].openQuantity
          : params.value
      ),
    ...alignRight(),
  };
}

export function openQuantityChange(): ColDef {
  return {
    field: 'loanState.openQuantityChange',
    colId: 'loanState.openQuantityChange',
    headerName: 'Quantity Change',
    valueFormatter: (params) =>
      formatPrettyNumber(
        params.node?.rowPinned && params.data
          ? params.data[params.data.selected].openQuantity
          : params.value
      ),
    ...alignRight(),
  };
}

export function unitPrice(): ColDef {
  return {
    field: 'loanState.unitPrice',
    colId: 'loanState.unitPrice',
    headerName: 'Unit Price',
    sortable: false,
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return `$${prettyPrice(params.data[params.data.selected].unitPrice)}`;
      }
      return `$${prettyPrice(params.value)}`;
    },
    ...alignRight(),
  };
}

export function rate(): ColDef {
  return {
    ...cols.rate(),
    field: 'loanState.rateDetails',
    colId: 'loanState.rateDetails',
    cellRendererSelector: (params) => {
      if (params.node?.rowPinned && params.data && params.data?.avg.rateModifiers.length === 1) {
        const rateModifier = proto3
          .getEnumType(Benchmark)
          .values.find((v) => v.no === params.data.avg.rateModifiers[0]);
        return rateAdapter({
          rate: params.data.avg.rate,
          rateModifier: (rateModifier?.name as OldBenchmark) || undefined,
          precision: undefined,
        });
      }
      if (!params.node?.rowPinned) {
        const rateModifier = proto3
          .getEnumType(Benchmark)
          .values.find((v) => v.no === params.value.benchmark);
        return rateAdapter({
          rate: params.value.rate,
          rateModifier: (rateModifier?.name as OldBenchmark) || undefined,
          precision: undefined,
        });
      }
    },
  };
}

export function settlementAmount(): ColDef {
  return {
    field: 'loanState.settlementAmount',
    colId: 'loanState.settlementAmount',
    headerName: 'Settlement Amount',
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return `$${prettyPrice(params.data[params.data.selected].settlementAmount)}`;
      }
      return `$${prettyPrice(params.value)}`;
    },
    ...alignRight(),
  };
}

export function settlementAmountChange(): ColDef {
  return {
    field: 'loanState.settlementAmountChange',
    colId: 'loanState.settlementAmountChange',
    headerName: 'Sett Amount Change',
    valueFormatter: (params) =>
      `$${prettyPrice(
        params.node?.rowPinned && params.data
          ? params.data[params.data.selected].settlementAmount
          : params.value
      )}`,
    ...alignRight(),
  };
}

export function rateAmount(): ColDef {
  return {
    field: 'loanState.interestDueAmount',
    colId: 'loanState.interestDueAmount',
    headerName: 'Rate Amount',
    valueFormatter: (params) => {
      if (params.node?.rowPinned && params.data) {
        return `$${prettyPrice(params.data[params.data.selected].interestDueAmount)}`;
      }
      return `$${prettyPrice(params.value)}`;
    },
    ...alignRight(),
  };
}

export function counterparty(): ColDef {
  return {
    field: 'loanState.counterparty',
    colId: 'loanState.counterparty',
    headerName: 'Counterparty',
    valueFormatter: (params) =>
      params.node?.rowPinned
        ? params.data.nounOrQuantity('parties')
        : `${params.value.companyName} (${params.value.companyDisplayId})`,
    ...alignLeft(),
  };
}

export function settlementType(): ColDef {
  return {
    headerName: 'Sett Type',
    field: 'loanState.settlementType',
    colId: 'loanState.settlementType',
    valueFormatter: (params) => {
      if (params.node?.rowPinned) return '';
      const value = proto3
        .getEnumType(SettlementType)
        .values.find((v) => v.no === params.value)!.name;
      return value;
    },
    // @TODO: fix agg
    // valueFormatter: (params) => {
    //   const value = proto3
    //     .getEnumType(SettlementType)
    //     .values.find((v) => v.no === params.value)!.name;
    //   return params.node?.rowPinned ? params.data.nounOrQuantity('settlementType') : value;
    // },
    ...alignLeft(),
  };
}

export function ticker(): ColDef {
  return {
    ...cols.ticker(),
    field: 'loanState.instrument.ticker',
    colId: 'loanState.instrument.ticker',
    valueFormatter: (params) =>
      params.node?.rowPinned ? params.data.nounOrQuantity('ticker') : params.value,
  };
}

export function cusip(): ColDef {
  return {
    field: 'loanState.instrument.cusip',
    colId: 'loanState.instrument.cusip',
    headerName: 'CUSIP',
    valueFormatter: (params) =>
      params.node?.rowPinned ? params.data.nounOrQuantity('cusip') : params.value,
    ...alignLeft(),
  };
}

export function displayId(): ColDef {
  return {
    field: 'loanState.loanDisplayId',
    colId: 'loanState.loanDisplayId',
    headerName: 'Loan ID',
    sortable: false,
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  event: LoanHistoryEvent;
  viewLoan: (loanId: string) => void;
}>(
  (props) => () =>
    h(LoanHistoryTableActions, {
      on: { 'view-loan': () => props.viewLoan(props.event.loanState!.loanId!) },
    })
);

export function actions({
  changeSumOrAvg,
  viewLoan,
}: {
  changeSumOrAvg: (sumOrAvg: 'sum' | 'avg') => void;
  viewLoan: (loanId: string) => void;
}): ColDef {
  return {
    field: 'actions',
    colId: 'actions',
    headerName: 'Actions',
    sortable: false,
    cellRendererSelector: (params) =>
      params.node?.rowPinned
        ? aggSelector({
            sumOrAvg: params.data.selected,
            // @TODO: this should probably be an optional prop?
            showDropdownActions: undefined,
            changeSumOrAvg,
          })
        : actionsAdapter({ event: params.data, viewLoan }),
    pinned: 'right',
    lockVisible: true,
    width: 100,
    maxWidth: 100,
    ...alignCenter(),
  };
}
