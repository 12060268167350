import { darkTheme as dark, lightTheme as light } from '@/plugins/theme';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/styles/main.sass';

export default new Vuetify({
  theme: {
    themes: {
      dark,
      light,
    },
    dark: true,
  },
});
