<template>
  <div>
    <v-form novalidate @submit.prevent>
      <v-card-title>
        <span class="headline">{{ $t('makeWish.title') }}</span>
      </v-card-title>
      <v-card-text class="my-4">
        <v-container>
          <v-alert v-if="svErrors" dense type="error">{{ svErrors }}</v-alert>
          <v-alert v-else dense type="info">{{ $t('makeWish.info') }}</v-alert>
        </v-container>
        <v-container>
          <v-textarea
            v-model="rfcText"
            auto-grow
            autofocus
            color="secondary"
            counter
            dense
            filled
            :hint="$t('makeWish.label')"
            outlined
            persistent-hint
          ></v-textarea>
        </v-container>
      </v-card-text>

      <!-- card-actions slot -->
      <v-card-actions>
        <slot name="cardActions" v-bind="{ onSubmit, closeDialog }">
          <v-spacer></v-spacer>
          <v-btn
            class="mx-6 px-6"
            color="primary"
            :disabled="isProcessing"
            :loading="isProcessing"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('submitWish.button') }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { isEmpty } from 'lodash';
import i18n from '@/localisation/i18n';
import { RfcRequest } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { mapActions } from 'vuex';

@Component({
  methods: {
    ...mapActions(['submitWish']),
  },
})
export default class FeedbackForm extends Vue {
  protected rfcText = '';
  protected svErrors = '';

  private submitWish!: (req: RfcRequest) => void;

  private isProcessing = false;

  // @click ...
  protected async onSubmit(): Promise<void> {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;
    this.svErrors = '';

    try {
      const rfcText = this.rfcText.trim();
      if (isEmpty(rfcText)) {
        this.svErrors = i18n.tc('makeWish.error');
        return;
      }

      // any input is fine!
      await this.doSubmit(rfcText);
    } finally {
      this.rfcText = '';
      this.isProcessing = false;
    }
  }

  private async doSubmit(rfcText: string) {
    try {
      const req: RfcRequest = {
        rfcText: rfcText,
        rfcPath: this.$route.fullPath,
        rfcPage: this.$route.name ? this.$route.name : '',
      };
      await this.submitWish(req);

      this.$snackbar.confirm(i18n.tc('makeWish.thanks'));
      this.closeDialog();
    } catch (e) {
      this.svErrors = errorString(e);
    }
  }

  private closeDialog() {
    // allow parent to close when used in a dialog box
    this.$emit('close-dialog');
  }
}
</script>

<style lang="scss" scoped>
.is-formfield-group {
  margin: 20px 0 0 0;
}
</style>
