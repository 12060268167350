import i18n from '@/localisation/i18n';
import { merge } from 'lodash';
import { getDecimalIntegralLength, getPriceFractionalLength } from './price-alignment';
import {
  PRICE_TICKSIZE,
  RATE_PRECISION,
  RATE_TICKSIZE,
} from '@/modules/common/constants/precision';
import Decimal from 'decimal.js';

const reIsFloat = /e[0-9-]+$/gi;
const reFloat = /[^0-9.-]/gi;
const reMoney = /[^0-9.,-]/gi;
const reInteger = /[^0-9-]/gi;
const reNumber = /[^+\-0-9.-]/gi;

export function getCurrencySymbol(currency: string): string {
  return currency;
}

export function getPriceAsString(price: number | Decimal, precision: number): string {
  if (price instanceof Decimal) {
    price = price.toDecimalPlaces(precision).toNumber();
  }

  // Format without! currency symbol.
  // Since currency is NOT displayed we can give the format export function a bogus (existing!) currency
  return formatPriceAsString('USD', price, precision).replace(reMoney, '');
}

export function formatPriceAsString(currency: string, price: number, precision: number): string {
  // Format according the current 'locale'
  const nf = i18n.getNumberFormat(i18n.locale);

  const options = merge({}, nf.currency, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision, // enforce fixed number of digits by setting min- and max-
    currency: currency,
    currencyDisplay: 'code',
  });

  return i18n.n(price, options);
}

export function formatDecimalAsCurrencyString(
  currency: string,
  d: Decimal,
  precision: number
): string {
  // Format according the current 'locale'
  const nf = i18n.getNumberFormat(i18n.locale);

  const options = merge({}, nf.currency, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision, // enforce fixed number of digits by setting min- and max-
    currency: currency,
    currencyDisplay: 'symbol',
  });

  // i18n.n pretends it needs a number but really just works fine with a string
  return i18n.n(d.toString() as unknown as number, options);
}

export function formatDecimalAsString(d: Decimal, precision: number): string {
  // Format according the current 'locale'
  const nf = i18n.getNumberFormat(i18n.locale);

  const options = merge(
    {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision, // enforce fixed number of digits by setting min- and max-
    },
    nf.decimal
  );

  // i18n.n pretends it needs a number but really just works fine with a string
  return i18n.n(d.toString() as unknown as number, options);
}

export function formatAlignablePrice(
  val: Decimal,
  currencyCode: string,
  minIntegralDigits: number,
  minFractionDigits: number
): string {
  if (val === null) {
    return '-';
  }

  // Database field, divide by precision before formatting!
  // Format according the current 'locale'
  const paddingChar = ' '; // !space! do not trim
  const currency = currencyCode;
  const integralDigits = getDecimalIntegralLength(val);
  const fractionalDigits = getPriceFractionalLength(PRICE_TICKSIZE);
  const priceStr = formatDecimalAsString(val, fractionalDigits);

  // insert spaces between currency-symbol and price so that currency-symbols are aligned
  const paddingLeft = integralDigits < minIntegralDigits ? minIntegralDigits - integralDigits : 0;

  // pad spaces after the price to make the this price as long as the most accurate price in the list
  let paddingRight = 0;
  if (fractionalDigits < minFractionDigits) {
    paddingRight = minFractionDigits - fractionalDigits;
    if (fractionalDigits === 0) {
      paddingRight++; // extra space to make up for the decimal dot
    }
  }

  // print price with the currency code we received from Reuters!
  return `${currency} ${paddingChar.repeat(paddingLeft)}${
    priceStr + paddingChar.repeat(paddingRight)
  }`;
}

export function formatAlignableRate(val: Decimal | null, minIntegralDigits: number): string {
  if (val === null) {
    return '-';
  }

  // Database field, divide by precision before formatting!
  // Format according the current 'locale'
  const paddingChar = ' '; // !space! do not trim
  const integralDigits = getDecimalIntegralLength(val);
  const fractionalDigits = RATE_PRECISION;
  const strVal = val.toFixed(fractionalDigits);

  // insert spaces between currency-symbol and price so that currency-symbols are aligned
  const paddingLeft = integralDigits < minIntegralDigits ? minIntegralDigits - integralDigits : 0;

  // print price with the currency code we received from Reuters!
  return paddingChar.repeat(paddingLeft) + strVal;
}

export function parsePrice(price: string): number | undefined {
  // when a float is larger than what can be represented in an integer it will be in the form of 1.23463262437e20
  //  but our following checks strips off the e20 so we can't rely on the `Number.isSafeInteger` down the line
  if (price.match(reIsFloat)) {
    return undefined;
  }

  const sPrice: string = price.replace(reFloat, '');

  if (sPrice === '') {
    return undefined;
  }

  return parseFloat(sPrice);
}

/**
 * attempts to parse a string to a number (signed float or integer)
 * NB: not safe with localised number strings
 */
export function parseNumber(number: string): number | null {
  // when a float is larger than what can be represented in an integer it will be in the form of 1.23463262437e20
  //  but our following checks strips off the e20 so we can't rely on the `Number.isSafeInteger` down the line
  if (number.match(reIsFloat)) {
    return null;
  }

  // string non-numeric chars from the string, leaving decimal and signage
  const numberStr = number.replace(reNumber, '');
  const parsedNumber = parseFloat(numberStr);

  if (numberStr === '' || isNaN(parsedNumber)) {
    return null;
  } else {
    return parsedNumber;
  }
}

export function roundSecurityPriceToTickSize(price: number | null, tickSize: number): number {
  if (price === null) {
    return 0;
  }
  return Math.round(price / tickSize) * tickSize;
}

//
// Volume conversions
//
export function getVolumeAsInteger(volume: number): number {
  return parseInt((volume * 1).toFixed(0), 10);
}

export function getVolumeAsString(volume: number): string {
  return i18n.n(getVolumeAsInteger(volume));
}

export function parseVolume(volume: string): number | undefined {
  const sVolume: string = volume.replace(reInteger, '');

  if (sVolume === '') {
    return undefined;
  }

  const iVolume = parseInt(sVolume, 10);
  if (isNaN(iVolume)) {
    return undefined;
  }

  return iVolume;
}

export function getPercentageAsString(pct: number | Decimal, decimals = 2): string {
  if (pct instanceof Decimal) {
    pct = pct.toDecimalPlaces(decimals).toNumber();
  }

  return `${pct.toFixed(decimals)}%`;
}

/**
 * Calculate step increment for a Rate input element
 */
export function calculateRateStepIncrement(input: string | number | Decimal): number {
  const value = new Decimal(input);

  if (value.equals(0)) {
    return RATE_TICKSIZE;
  }

  // generate the step to allow incrementing by the last decimal place
  const decimalPlaces = Math.max(value.decimalPlaces(), 1);
  return parseFloat(`.${new Array(decimalPlaces - 1).fill(0).join('')}1`);
}

/**
 * Calculates the notional value given a quantity and a security's last price
 * Note: use `calculateNotional()` in `contract-details.ts` if IndependentAmount and RoundingRules need to be applied.
 */
export function calculateOrderNotional(
  quantity: number | null,
  lastClosePrice: Decimal | null
): Decimal {
  if (!quantity || !lastClosePrice || lastClosePrice.isZero()) {
    return new Decimal(0);
  }

  return lastClosePrice.mul(quantity).toDecimalPlaces(Decimal.ROUND_CEIL);
}
