import Decimal from 'decimal.js';
import { LoanHistoryEventType } from '@/connect/gen/consts/loanhistoryconsts_pb';
import {
  BorrowerOpenLoan,
  CompanyInfo,
  LenderOpenLoan,
  LoanEventType,
} from '@/modules/common/models';

export type Side = 'LENDER' | 'BORROWER' | 'all';

// eslint-disable-next-line @typescript-eslint/naming-convention
const LoanStatus = [
  'NEW',
  'PENDING',
  'REJECTED',
  'DROPPED',
  'CANCELED',
  'OPEN',
  'CLOSED',
  'TERMINATED',
  'UNDER_CORP_ACTION',
  'ROLL_PENDING',
  'CANCEL_ROLL_PENDING',
  'CANCEL_RETURN_PENDING',
  'CANCEL_NEW_LOAN_PENDING',
  'ERROR',
] as const;
export type LoanStatus = (typeof LoanStatus)[number];

export function getOpenLoanStatuses(): readonly LoanStatus[] {
  return LoanStatus.map((key) => key as LoanStatus).filter((s) => !isTerminalLoanStatus(s));
}

type StatusColorString =
  | 'error'
  | 'info'
  | 'green'
  | 'purple'
  | 'warning'
  | 'grey'
  | 'green lighten-2';

export const loanStatuses: Record<LoanStatus, { color: StatusColorString; label: string }> = {
  NEW: { color: 'info', label: 'New' },
  PENDING: { color: 'info', label: 'Pending' },
  REJECTED: { color: 'error', label: 'Rejected' },
  DROPPED: { color: 'error', label: 'Dropped' },
  CANCELED: { color: 'grey', label: 'Canceled' },
  OPEN: { color: 'green', label: 'Open' },
  CLOSED: { color: 'grey', label: 'Closed' },
  TERMINATED: { color: 'error', label: 'Terminated' },
  UNDER_CORP_ACTION: { color: 'warning', label: 'Undergoing Corp Action' },
  ROLL_PENDING: { color: 'green lighten-2', label: 'Roll Pending' },
  CANCEL_ROLL_PENDING: { color: 'warning', label: 'Cancel Roll Pending' },
  CANCEL_RETURN_PENDING: { color: 'warning', label: 'Cancel Return Pending' },
  CANCEL_NEW_LOAN_PENDING: { color: 'warning', label: 'Cancel Pending' },
  ERROR: { color: 'error', label: 'Error' },
};

export function isTerminalLoanStatus(s: LoanStatus): boolean {
  switch (s) {
    case 'ERROR':
    case 'REJECTED':
    case 'DROPPED':
    case 'CANCELED':
    case 'CLOSED':
    case 'TERMINATED':
      return true;
    default:
      return false;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Benchmark = {
  NoBenchmark: '',
  OBFR: 'OBFR',
  EFFR: 'EFFR',
  SOFR: 'SOFR',
  BGCR: 'BGCR',
  TGCR: 'TGCR',
  IORB: 'IORB',
} as const;
export type Benchmark = (typeof Benchmark)[keyof typeof Benchmark];

export function getAllBenchmarks(): string[] {
  return Object.keys(Benchmark).reduce<string[]>((acc, next) => {
    acc.push(next);
    return acc;
  }, []);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RenegotiateSide = {
  Borrower: 'BORROWER',
  Lender: 'LENDER',
} as const;
export type RenegotiateSide = (typeof RenegotiateSide)[keyof typeof RenegotiateSide];

export interface RenegotiateRequest {
  side: RenegotiateSide;
  loanId: string;
  rate: Decimal;
  rateModifier: Benchmark;
}

export interface OpenLoansRequest {
  filters: {
    showAll: boolean;
    isRecalled: boolean;
    isRenegotiating: boolean;
    hasLateReturns: boolean;
    side: 'LENDER' | 'BORROWER' | null;
    counterpartyCompanyId: string | null;
    cusip: string | null;
    isSponsored?: boolean; // when undefined returns both regular and sponsored loans
    termContractDisplayId?: string;
  };

  // sorting:
  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface OpenLoansParams {
  filters?: Partial<OpenLoansRequest['filters']>;
  sort?: Partial<OpenLoansRequest['sort']>;
  pagination?: Partial<OpenLoansRequest['pagination']>;
}

export interface BrokerOpenLoansRequest {
  filters: {
    cusip: string | null;
    lender: string | null;
    borrower: string | null;
    statuses: string | null;
    displayId: string | null;
  };

  // sorting:
  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface BrokerOpenLoansParams {
  filters?: Partial<BrokerOpenLoansRequest['filters']>;
  sort?: Partial<BrokerOpenLoansRequest['sort']>;
  pagination?: Partial<BrokerOpenLoansRequest['pagination']>;
}

export type RecallStatus = 'new' | 'approved' | 'made' | 'canceled' | 'pendingcancel';

export interface AggregatedLoansRequest {
  group: string | null;

  filters: {
    side: 'LENDER' | 'BORROWER' | null;
    counterpartyCompanyId: string | null;
    cusip: string | null;
  };

  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface AggregatedOpenLoansParams {
  group: Partial<AggregatedLoansRequest['group']>;
  filters?: Partial<AggregatedLoansRequest['filters']>;
  sort?: Partial<AggregatedLoansRequest['sort']>;
  pagination?: Partial<AggregatedLoansRequest['pagination']>;
}

export interface AggregatedLoans {
  items: AggregatedLoanBySecurityItem[] | AggregatedLoanByCounterpartyItem[];
  total: number;
}

interface AggregatedLoanItem {
  side: 'LENDER' | 'BORROWER';
  loansCount: number;
  totalValue: Decimal | string;
  totalOpenQty: number;
  totalRecallQty: number;
  totalPendingReturnQty: number;
  totalOpenQtyForReturn: number;
  avgRate: Decimal | string;
}

export interface AggregatedLoanBySecurityItem extends AggregatedLoanItem {
  ticker: string;
  cusip: string;
  counterpartyCount: number;
}

export interface AggregatedLoanByCounterpartyItem extends AggregatedLoanItem {
  counterpartyDisplay: string; // @TODO: remove me once it's removed from backend
  counterparty: CompanyInfo;
  securityCount: number;
}

export function normalizeAggregatedLoan(item: AggregatedLoanItem): void {
  const itemWithStrAvgRate: { avgRate: string | Decimal } = item;
  if (typeof itemWithStrAvgRate.avgRate === 'string') {
    item.avgRate = new Decimal(itemWithStrAvgRate.avgRate);
  }

  const itemWithStrTotalValue: { totalValue: string | Decimal } = item;
  if (typeof itemWithStrTotalValue.totalValue === 'string') {
    item.totalValue = new Decimal(itemWithStrTotalValue.totalValue);
  }
}

const colorNotImportant = 'grey';
const colorClosedNormal = 'red darken-4';
const colorClosedNotNormal = 'red darken-1';
const colorRoll = 'blue lighten-2';
const colorPO = `blue light-2`;
const colorPoFailed = `red light-2`;
const colorCancel = 'secondary';
const colorRequiresAttention = 'orange darken-3';
const colorRate = 'purple';
const colorReturn = 'purple';

export const newLoanEvents: Partial<Record<LoanHistoryEventType, LoanEventType>> = {
  [LoanHistoryEventType.CREATED]: 'CREATED',
  [LoanHistoryEventType.PENDING]: 'PENDING',
  [LoanHistoryEventType.DO_PENDING]: 'DO_PENDING',
  [LoanHistoryEventType.MADE]: 'MADE',
  [LoanHistoryEventType.DROPPED]: 'DROPPED',
  [LoanHistoryEventType.REJECTED]: 'REJECTED',
  [LoanHistoryEventType.CANCEL_PENDING]: 'CANCEL_PENDING',
  [LoanHistoryEventType.CANCELLED]: 'CANCELED',
  [LoanHistoryEventType.CANCEL_REJECTED]: 'CANCEL_REJECTED',
  [LoanHistoryEventType.ROLL_PENDING]: 'ROLL_PENDING',
  [LoanHistoryEventType.ROLL_PD_PENDING]: 'ROLL_PD_PENDING',
  [LoanHistoryEventType.ROLL_PD_MADE]: 'ROLL_PD_MADE',
  [LoanHistoryEventType.ROLLED]: 'ROLLED',
  [LoanHistoryEventType.BUY_IN_PO_FAILED]: 'BUY_IN_PO_FAILED',
  [LoanHistoryEventType.BUY_IN_PO_MADE]: 'BUY_IN_PO_MADE',
  [LoanHistoryEventType.MARK_TO_MARKET_PO_FAILED]: 'MARK_TO_MARKET_PO_FAILED',
  [LoanHistoryEventType.MARK_TO_MARKET_PO_MADE]: 'MARK_TO_MARKET_PO_MADE',
  [LoanHistoryEventType.MARKED_TO_MARKET]: 'MARKED_TO_MARKET',
  [LoanHistoryEventType.DAILY_INTEREST_AMOUNT]: 'DAILY_INTEREST_AMOUNT',
  [LoanHistoryEventType.DAILY_INTEREST_PO_FAILED]: 'DAILY_INTEREST_PO_FAILED',
  [LoanHistoryEventType.DAILY_INTEREST_PO_MADE]: 'DAILY_INTEREST_PO_MADE',
  [LoanHistoryEventType.RENEGOTIATED]: 'RENEGOTIATED',
  [LoanHistoryEventType.RENEGOTIATION_CANCELED]: 'RENEGOTIATION_CANCELED',
  [LoanHistoryEventType.RENEGOTIATION_REJECTED]: 'RENEGOTIATION_REJECTED',
  [LoanHistoryEventType.RENEGOTIATION_EXPIRED]: 'RENEGOTIATION_EXPIRED',
  [LoanHistoryEventType.TERMINATED]: 'TERMINATED',
  [LoanHistoryEventType.PARTIALLY_RETURNED]: 'PARTIALLY_RETURNED',
  [LoanHistoryEventType.RETURNED]: 'RETURNED',
  [LoanHistoryEventType.RETURN_PENDING]: 'RETURN_PENDING',
  // [LoanHistoryEventType.CANCEL_RETURN_PENDING]: 'CANCEL_RETURN_PENDING',
  [LoanHistoryEventType.RETURN_CANCELED]: 'RETURN_CANCELED',
  [LoanHistoryEventType.RETURN_FAILED]: 'RETURN_FAILED',
  [LoanHistoryEventType.RETURN_REJECTED]: 'RETURN_REJECTED',
  [LoanHistoryEventType.RECALLED]: 'RECALLED',
  [LoanHistoryEventType.RECALL_REJECTED]: 'RECALL_REJECTED',
  [LoanHistoryEventType.RECALL_CANCELED]: 'RECALL_CANCELED',
  [LoanHistoryEventType.BUY_IN]: 'BUY-IN',
  [LoanHistoryEventType.BUY_IN_PENDING]: 'BUY-IN_PENDING',
  [LoanHistoryEventType.BUY_IN_CANCELLED]: 'BUY-IN_CANCELED',
  [LoanHistoryEventType.BUY_IN_REJECTED]: 'BUY-IN_REJECTED',
  // [LoanHistoryEventType.BUY_IN_FAILED]: 'BUY-IN_FAILED',
  [LoanHistoryEventType.CORPORATE_ACTION]: 'CORP-ACTION',
  [LoanHistoryEventType.FLOATING_RATE_RECALCULATED]: 'FLOATING_RATE_RECALCULATED',
  [LoanHistoryEventType.RETURN_PROPOSED]: 'RETURN_PROPOSED',
  [LoanHistoryEventType.RETURN_PROPOSAL_ACCEPTED]: 'RETURN_PROPOSAL_ACCEPTED',
  [LoanHistoryEventType.RETURN_PROPOSAL_REJECTED]: 'RETURN_PROPOSAL_REJECTED',
  [LoanHistoryEventType.RETURN_PROPOSAL_CANCELED]: 'RETURN_PROPOSAL_CANCELED',
  [LoanHistoryEventType.RETURN_PROPOSAL_DROPPED]: 'RETURN_PROPOSAL_DROPPED',
};

export const loanEvents: Record<LoanEventType, { color: string; label: string }> = {
  CREATED: { color: 'green', label: 'Created' },
  PENDING: { color: 'blue', label: 'Pending' },
  DO_PENDING: { color: colorNotImportant, label: 'DO Pending' },
  MADE: { color: colorNotImportant, label: 'Made' },
  DROPPED: { color: colorClosedNotNormal, label: 'Dropped' },
  REJECTED: { color: colorNotImportant, label: 'Rejected' },
  CANCEL_PENDING: { color: colorNotImportant, label: 'Cancel Pending' },
  CANCELED: { color: colorNotImportant, label: 'Canceled' },
  CANCEL_REJECTED: { color: colorPoFailed, label: 'Cancel Rejected' },
  ROLL_PENDING: { color: colorRoll, label: 'Roll Pending' },
  ROLL_PD_PENDING: { color: colorRoll, label: 'PD Pending' },
  ROLL_PD_MADE: { color: colorRoll, label: 'PD Made' },
  ROLLED: { color: 'blue', label: 'Rolled' },
  BUY_IN_PO_FAILED: { color: colorPoFailed, label: 'Buy-in PO Failed' },
  BUY_IN_PO_MADE: { color: colorPO, label: 'Buy-in PO Made' },
  MARK_TO_MARKET_PO_FAILED: { color: colorPoFailed, label: 'M-T-M PO Failed' },
  MARK_TO_MARKET_PO_MADE: { color: colorPO, label: 'M-T-M PO Made' },
  MARKED_TO_MARKET: { color: colorNotImportant, label: 'Marked' },
  DAILY_INTEREST_AMOUNT: { color: colorNotImportant, label: 'Daily Interest' },
  DAILY_INTEREST_PO_FAILED: { color: colorPoFailed, label: 'Daily Interest PO Failed' },
  DAILY_INTEREST_PO_MADE: { color: colorPO, label: 'Daily Interest PO Made' },
  RENEGOTIATED: { color: colorRate, label: 'Renegotiated' },
  RENEGOTIATION_CANCELED: { color: colorCancel, label: 'Renegotiation Canceled' },
  RENEGOTIATION_REJECTED: { color: colorRequiresAttention, label: 'Renegotiation Rejected' },
  RENEGOTIATION_EXPIRED: { color: colorNotImportant, label: 'Renegotiation Expired' },
  TERMINATED: { color: colorClosedNotNormal, label: 'Terminated' },
  PARTIALLY_RETURNED: { color: colorClosedNormal, label: 'Partially Returned' },
  RETURNED: { color: colorClosedNormal, label: 'Returned' },
  RETURN_PENDING: { color: colorRoll, label: 'Return Pending' },
  CANCEL_RETURN_PENDING: { color: colorRequiresAttention, label: 'Cancel Return Pending' },
  RETURN_CANCELED: { color: colorCancel, label: 'Return Canceled' },
  RETURN_FAILED: { color: colorNotImportant, label: 'Return Failed' },
  RETURN_REJECTED: { color: colorRequiresAttention, label: 'Return Rejected' },
  RECALLED: { color: colorClosedNotNormal, label: 'Recalled' },
  RECALL_REJECTED: { color: colorCancel, label: 'Recall Rejected' },
  RECALL_CANCELED: { color: colorCancel, label: 'Recall Canceled' },
  'BUY-IN': { color: colorClosedNotNormal, label: 'Buy-In' },
  'BUY-IN_PENDING': { color: colorNotImportant, label: 'Buy-In Pending' },
  'BUY-IN_CANCELED': { color: colorCancel, label: 'Buy-In Canceled' },
  'BUY-IN_REJECTED': { color: colorRequiresAttention, label: 'Buy-In Rejected' },
  'BUY-IN_FAILED': { color: colorPoFailed, label: 'Buy-In Failed' },
  'CORP-ACTION': { color: colorClosedNotNormal, label: 'Corp. Action' },
  FLOATING_RATE_RECALCULATED: { color: colorRate, label: 'Rate recalculated' },
  RETURN_PROPOSED: { color: colorReturn, label: 'Return Proposed' },
  RETURN_PROPOSAL_ACCEPTED: { color: colorReturn, label: 'Return Proposal Accepted' },
  RETURN_PROPOSAL_REJECTED: { color: colorReturn, label: 'Return Proposal Rejected' },
  RETURN_PROPOSAL_CANCELED: { color: colorReturn, label: 'Return Proposal Canceled' },
  RETURN_PROPOSAL_DROPPED: { color: colorReturn, label: 'Return Proposal Dropped' },
};

/**
 * Injects "availableActions" into the object based on the current loan state
 */
export function normalizeAvailableLoanActions(loan: LenderOpenLoan | BorrowerOpenLoan): void {
  loan.availableActions = {};

  if (loan.status === 'NEW') {
    return;
  }

  // canceling bilateral loans not supported yet.
  if (loan.status === 'PENDING' && loan.settlementType != 'BILATERAL') {
    loan.availableActions.cancelPending = true;
    return;
  }

  // statuses different than OPEN have already been handled, we can return early
  if (loan.status !== 'OPEN') {
    return;
  }

  // only allow renegotiation if the loan was not generated by a term loan contract
  if (loan.termContractDisplayId === null) {
    // Renegotiate actions (both lender and borrower)
    if (loan.renegotiation === null) {
      if (loan.rateModifier === Benchmark.NoBenchmark) {
        loan.availableActions.renegotiateFixed = true;
      } else {
        loan.availableActions.renegotiateFloating = true;
      }
    } else if (
      (loan.side === 'LENDER' && loan.renegotiation.side === RenegotiateSide.Lender) ||
      (loan.side === 'BORROWER' && loan.renegotiation.side === RenegotiateSide.Borrower)
    ) {
      loan.availableActions.cancelRenegotiate = true;
    } else {
      loan.availableActions.acceptRenegotiate = true;
      loan.availableActions.rejectRenegotiate = true;
    }
  }

  // Lender actions
  if (loan.side === 'LENDER' || loan.sponsorshipSide === 'sponsor') {
    if (loan.recalledQuantity > 0 && loan.pendingBuyInQuantity < loan.recalledQuantity) {
      // If there's a buy-in in progress, only enable if it's less than the recalledQuantity
      loan.availableActions.updateRecall = true;
    } else if (loan.recalledQuantity === 0) {
      loan.availableActions.recall = true;
    }

    if (loan.openQuantityToBuyIn - loan.pendingBuyInQuantity - loan.pendingReturnQuantity > 0) {
      loan.availableActions.buyIn = true;
    }
  }

  // Borrower actions
  if (loan.side === 'BORROWER' || loan.sponsorshipSide === 'sponsor') {
    if (loan.pendingReturnQuantity > 0) {
      loan.availableActions.cancelReturn = true;
    }
    if (loan.openQuantityToReturn > 0) {
      if (loan.termContractDisplayId === null || loan.recalledQuantity > 0) {
        loan.availableActions.return = true;
      }
    }
  }

  // Returns (late returns, maybe others in the future)
  // decision of who can accept/reject/cancel are made on the components themselves
  if (loan.loanReturns.length > 0) {
    loan.availableActions.updateLateReturn = true;
  }
}
