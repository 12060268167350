// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/oms/oms.proto (package aurora.core.apiengine.omsv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CanceledOrderEvent, CancelOrderRequest, CancelOrderResponse, CancelOrdersRequest, CancelOrdersResponse, ClosedOrderEvent, CreatedOrderEvent, CreateOrderRequest, CreateOrderResponse, CreateOrdersRequest, CreateOrdersResponse, ExecutedOrderEvent, ExpiredOrderEvent, GetOrdersRequest, GetOrdersResponse, ModifiedOrderEvent, ModifyOrderRequest, ModifyOrderResponse, ModifyOrdersRequest, ModifyOrdersResponse, QueryExecutionsRequest, QueryExecutionsResponse, QueryOrdersRequest, QueryOrdersResponse } from "./oms_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service aurora.core.apiengine.omsv1.OmsService
 */
export const OmsService = {
  typeName: "aurora.core.apiengine.omsv1.OmsService",
  methods: {
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.CreateOrder
     */
    createOrder: {
      name: "CreateOrder",
      I: CreateOrderRequest,
      O: CreateOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.CreateOrders
     */
    createOrders: {
      name: "CreateOrders",
      I: CreateOrdersRequest,
      O: CreateOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.CancelOrder
     */
    cancelOrder: {
      name: "CancelOrder",
      I: CancelOrderRequest,
      O: CancelOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.CancelOrders
     */
    cancelOrders: {
      name: "CancelOrders",
      I: CancelOrdersRequest,
      O: CancelOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.ModifyOrder
     */
    modifyOrder: {
      name: "ModifyOrder",
      I: ModifyOrderRequest,
      O: ModifyOrderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.ModifyOrders
     */
    modifyOrders: {
      name: "ModifyOrders",
      I: ModifyOrdersRequest,
      O: ModifyOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.GetOrders
     */
    getOrders: {
      name: "GetOrders",
      I: GetOrdersRequest,
      O: GetOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.QueryOrders
     */
    queryOrders: {
      name: "QueryOrders",
      I: QueryOrdersRequest,
      O: QueryOrdersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsService.QueryExecutions
     */
    queryExecutions: {
      name: "QueryExecutions",
      I: QueryExecutionsRequest,
      O: QueryExecutionsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service aurora.core.apiengine.omsv1.OmsServiceEvents
 */
export const OmsServiceEvents = {
  typeName: "aurora.core.apiengine.omsv1.OmsServiceEvents",
  methods: {
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsServiceEvents.OrderCreated
     */
    orderCreated: {
      name: "OrderCreated",
      I: CreatedOrderEvent,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsServiceEvents.OrderModified
     */
    orderModified: {
      name: "OrderModified",
      I: ModifiedOrderEvent,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsServiceEvents.OrderExecuted
     */
    orderExecuted: {
      name: "OrderExecuted",
      I: ExecutedOrderEvent,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsServiceEvents.OrderCanceled
     */
    orderCanceled: {
      name: "OrderCanceled",
      I: CanceledOrderEvent,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsServiceEvents.OrderExpired
     */
    orderExpired: {
      name: "OrderExpired",
      I: ExpiredOrderEvent,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.omsv1.OmsServiceEvents.OrderClosed
     */
    orderClosed: {
      name: "OrderClosed",
      I: ClosedOrderEvent,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

