<template>
  <div class="actions d-flex justify-end">
    <v-btn
      class="icon-action"
      data-test="view-order"
      icon
      style="margin-top: 4px"
      x-small
      @click="$emit('view-loan')"
    >
      <v-icon size="24">mdi-eye</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class LoanHistoryTableActions extends Vue {
  // props
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
  width: 100%;
}
</style>
