<template>
  <div>
    <!-- add company account -->
    <v-btn class="ma-6" color="primary" @click="createProfile">
      <v-icon left> mdi-plus</v-icon>
      {{ $t('addInvestorProfile') }}
    </v-btn>

    <!-- search bar -->
    <v-text-field
      v-model="tableSearch"
      class="mx-6"
      clearable
      :label="$t('searchInvestorProfile')"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>

    <v-data-table
      class="elevation-0 pb-8"
      dense
      disable-pagination
      fixed-header
      :headers="tableColumns"
      hide-default-footer
      :items="investorProfiles"
      :no-data-text="
        $tc('no profiles', investorProfiles.length, { count: investorProfiles.length })
      "
      :search="tableSearch"
    >
      <!-- profile icon -->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template #item.icon>
        <v-icon disabled small> mdi-currency-usd</v-icon>
      </template>

      <!-- action buttons -->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template #item.actions="{ item }">
        <v-tooltip color="primary" top>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="ma-1" color="primary" icon x-small @click="editProfile(item)">
                <v-icon> mdi-database-edit </v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ $t('editInvestorProfile') }}</span>
        </v-tooltip>

        <v-tooltip color="primary" top>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="ma-1" color="primary" icon x-small @click="deleteProfile(item)">
                <v-icon> mdi-database-remove </v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ $t('deleteInvestorProfile') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <investor-profile-dialog
      v-if="dialogOptions.isActive"
      :crud-action="dialogOptions.crudAction"
      :profile="dialogBuffer"
      :title="dialogOptions.title"
      @close-modal="closeModal"
    ></investor-profile-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import InvestorProfileDialog from '@/modules/investor-profiles/components/InvestorProfileDialog.vue';
import { InvestorProfile, RestOperation } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import i18n from '@/localisation/i18n';
import { DataTableHeader } from 'vuetify';
import { AppState } from '@/store/store';

const defaultProfile = {
  id: '',
  label: '',
};
const tableColumns: DataTableHeader[] = [
  { text: '', value: 'icon', sortable: false, width: 16, class: 'row-icon', cellClass: 'row-icon' },
  { text: i18n.tc('investorProfile'), value: 'label', class: 'text-no-wrap text-truncate' },
  { text: '', value: 'actions', sortable: false },
];

@Component({
  components: {
    InvestorProfileDialog,
  },
  methods: {
    ...mapActions(['fetchAdminInvestorProfiles', 'deleteInvestorProfile']),
  },
  computed: {
    ...mapState(['investorProfiles']),
  },
})
export default class InvestorProfiles extends Vue {
  // store state
  protected readonly investorProfiles!: AppState['investorProfiles'];
  // store actions
  protected readonly fetchAdminInvestorProfiles!: () => void;
  protected readonly deleteInvestorProfile!: (profile: InvestorProfile) => void;

  protected tableSearch = '';
  protected tableColumns = tableColumns;

  protected addBuffer: Partial<NullableAll<InvestorProfile>> | null = null;
  protected dialogBuffer: Partial<NullableAll<InvestorProfile>> | null = null;
  protected dialogOptions = {
    isActive: false,
    crudAction: RestOperation.Noop,
    title: 'Profile',
    cancelOptions: ['escape', 'outside'], // no 'X' for real FORMs
  };

  protected async mounted(): Promise<void> {
    try {
      await this.fetchAdminInvestorProfiles();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected createProfile(): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Create;
    this.dialogOptions.title = 'Add Profile';

    // addBuffer is a 2nd buffer behind the dialog so that the admin
    // can escape from `Create New Profile` and return with partially filled record
    // browsing the profile list will not destroy the partially filled record
    if (this.addBuffer == null) {
      this.addBuffer = cloneDeep(defaultProfile);
    }
    this.dialogBuffer = this.addBuffer;
  }

  protected editProfile(rowProfile: InvestorProfile): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Update;
    this.dialogOptions.title = 'Change Profile';
    // do not modify the row while typing
    this.dialogBuffer = cloneDeep(rowProfile);
  }

  protected closeModal(): void {
    // wipe user data
    this.dialogOptions.isActive = false;
    this.dialogOptions.crudAction = RestOperation.Noop;
    this.dialogOptions.title = '';
    this.addBuffer = null;
    this.dialogBuffer = null;
  }

  protected deleteProfile(rowProfile: InvestorProfile): void {
    this.$dialog.ask({
      title: i18n.t('deleteInvestorProfile'),
      color: 'error',
      icon: 'mdi-alert',
      message: i18n.t('deleteInvestorProfileMessage', { profileLabel: rowProfile.label }),
      acceptText: i18n.t('deleteInvestorProfile'),
      onAccept: () => this.doDeleteProfile(rowProfile),
      rejectText: i18n.t('cancelButton'),
    });
  }

  private async doDeleteProfile(rowProfile: InvestorProfile) {
    try {
      await this.deleteInvestorProfile(rowProfile);

      this.deleteConfirmation();
    } catch (e) {
      this.deleteError(errorString(e as Error));
    }
  }

  private deleteConfirmation() {
    this.$snackbar.confirm(i18n.tc('deletedInvestorProfile'));
  }

  private deleteError(message: string) {
    this.$snackbar.error(message);
  }
}
</script>

<style lang="scss" scoped></style>
