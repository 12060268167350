import Decimal from 'decimal.js';
import { flatten, uniq } from 'lodash';
import { formatDate } from '@/utils/helpers/dates';
import { defaultFilter } from 'vuetify/lib/util/helpers';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';

export function searchableTableFilter<T>(value: unknown, search: string | null, item: T): boolean {
  // use the "searchable" formatters to convert the value before passing on to the default filter fn
  let searchableValue: string | null | undefined;
  switch (typeof value) {
    case 'number': {
      // @TODO what about precision?
      // should we assume numbers are ints and Decimals will have precision?
      // or should we make all numbers Decimal, and use max rate for formatting?
      // can Decimals have precision included in some way?
      searchableValue = searchableNumber(value);
      break;
    }
    case 'undefined': {
      searchableValue = value;
      break;
    }
    case 'object': {
      if (value instanceof Date) {
        searchableValue = searchableDate(value);
      } else if (value instanceof Decimal) {
        // @TODO precision?
        // maybe best to default to the greates precision needed
        // it should be fine to include an extra couple zeros on currencies
        searchableValue = searchableNumber(value, RATE_PRECISION);
      } else if (value === null) {
        searchableValue = value;
      } else {
        searchableValue = value.toString();
      }
      break;
    }
    default: {
      searchableValue = value as never;
    }
  }

  return defaultFilter(searchableValue, search, item);
}

export function searchableNumber(num: number | Decimal | string, precision?: number): string {
  if (typeof num === 'number' || num instanceof Decimal) {
    const variations = [
      num.toString(),
      num.toFixed(precision),
      formatPrettyNumber(num instanceof Decimal ? num.toNumber() : num, {
        minimumFractionDigits: precision || 2,
        maximumFractionDigits: precision || 2,
      }),
    ];
    return variations.join(';');
  }

  // fallback to returning as string (for anything that wasn't a Decimal or number)
  return num === null || num === undefined ? '' : `${num}`;
}

export function searchableDate(d: Date | string): string {
  if (d instanceof Date) {
    return dateVariations(d).join(';');
  }

  // fallback to returning as string (for anything that wasn't a Date)
  return d === null || d === undefined ? '' : `${d}`;
}

export function searchableDateWithTime(d: Date | string): string {
  if (d instanceof Date) {
    // combine the time variations and date variation strings
    return uniq(
      flatten(
        timeVariations(d).map((ts) => {
          return dateVariations(d).map((ds) => {
            return `${ds} ${ts}`;
          });
        })
      )
    ).join(';');
  }

  // fallback to returning as string (for anything that wasn't a Date)
  return d === null || d === undefined ? '' : `${d}`;
}

function timeVariations(d: Date): string[] {
  return [
    formatDate(d, 'H:mm'),
    formatDate(d, 'HH:mm'),
    formatDate(d, 'h:mm bbb'),
    formatDate(d, 'hh:mm bbb'),
  ];
}

function dateVariations(d: Date): string[] {
  return [
    formatDate(d, 'eeee dd-MM-yyyy'),
    formatDate(d, 'eeee MM/dd/yyyy'),
    formatDate(d, 'yyyy-MM-dd'),
  ];
}
