<script setup lang="ts">
import { ConnectResult, type SSOSettings, serviceSecurity } from '@/connect';
import i18n from '@/localisation/i18n';
import { useSnackbarManager } from '@/modules/snackbar/services/snackbar-manager';
import { useDialogManager } from '@/plugins/dialog-manager';
import { computed } from 'vue';
import { shallowRef } from 'vue';
import { ref } from 'vue';
import { VFadeTransition } from 'vuetify/lib';

const dialogManager = useDialogManager();
const snackbarManager = useSnackbarManager();

const ssoSettingsCurrent = ref<SSOSettings>({ type: 'none' });

const ssoTypeOld = computed(() => ssoSettingsCurrent.value.type);
const ssoTypeNew = shallowRef<SSOSettings['type']>('none');

const ssoFileOld = computed(() =>
  ssoSettingsCurrent.value.type === 'file'
    ? {
        name: ssoSettingsCurrent.value.name,
      }
    : null
);
const ssoFileNew = shallowRef<null | { name: string } | File>(null);

const ssoURLOld = computed(() =>
  ssoSettingsCurrent.value.type === 'url' ? ssoSettingsCurrent.value.url : ''
);
const ssoURLNew = shallowRef('');

async function loadSSOSettings() {
  const result = await serviceSecurity.getSSOSettings();
  ssoSettingsCurrent.value = result.success
    ? result.data
    : {
        type: 'none',
      };

  resetNewSSOSettings();
}

function resetNewSSOSettings() {
  ssoTypeNew.value = ssoSettingsCurrent.value.type;
  ssoFileNew.value = ssoFileOld.value;
  ssoURLNew.value = ssoURLOld.value;
}

const isSubmitting = shallowRef(false);
async function saveSSOSettings() {
  isSubmitting.value = true;
  try {
    if (ssoTypeNew.value === 'file' && ssoFileNew.value instanceof File) {
      await serviceSecurity.setSSOSettingsFile(ssoFileNew.value);
    }

    let result: ConnectResult<unknown>;
    switch (ssoTypeNew.value) {
      case 'none': {
        result = await serviceSecurity.removeSSOSettings();
        break;
      }
      case 'file': {
        if (!(ssoFileNew.value instanceof File)) return; // file not updated, impossible unhappy path
        result = await serviceSecurity.setSSOSettingsFile(ssoFileNew.value);
        break;
      }
      case 'url': {
        result = await serviceSecurity.setSSOSettingsURL(ssoURLNew.value);
        break;
      }
      default:
        return; // no type selected, impossible unhappy path
    }
    if (!result.success) {
      snackbarManager.error(i18n.t(result.error, result.details) as string);
      return;
    }

    await loadSSOSettings();

    snackbarManager.confirm('SSO preferences successfully updated');
  } finally {
    isSubmitting.value = false;
  }
}

function isFile(value: unknown): value is File {
  return value instanceof File;
}

function requireProtocol(value = '') {
  return !value || /^[a-z][a-z0-9]+:\/\//i.test(value) || 'Please specify protocol';
}

function removeSSOSettings() {
  dialogManager.alert({
    title: 'Are you sure?',
    message: 'Current SSO preferences will be removed',
    isRejectable: true,
    rejectText: 'Cancel',
    acceptText: 'Remove SSO',
    onAccept: saveSSOSettings,
  });
}

void loadSSOSettings();
</script>

<template>
  <v-card class="flex d-flex flex-column">
    <v-form class="col-xl-8 pa-0" novalidate @submit.prevent>
      <v-card-title>
        <span class="headline">SSO Configuration</span>
      </v-card-title>

      <v-card-text class="text--primary">
        <p class="d-block text--secondary label-note">
          Set up your Single Sign-On (SSO) by uploading the SSO metadata file or providing the URL
          where the metadata file resides. This metadata file should contain the SSO certificate,
          EntityDescriptor, and EntityId.
        </p>

        <v-radio-group v-model="ssoTypeNew" class="mb-4" dense hide-details row>
          <v-radio color="form-field" label="None" value="none"></v-radio>
          <v-radio color="form-field" label="File" value="file"></v-radio>
          <v-radio color="form-field" label="URL" value="url"></v-radio>
        </v-radio-group>

        <v-fade-transition v-if="ssoTypeNew === 'none'" mode="out-in">
          <span v-if="ssoTypeOld === 'none'">SSO is not set up</span>
          <v-btn v-else color="error" :disabled="isSubmitting" @click="removeSSOSettings">
            Remove SSO
          </v-btn>
        </v-fade-transition>

        <v-file-input
          v-if="ssoTypeNew === 'file'"
          v-model="ssoFileNew"
          accept="application/xml, text/xml"
          :disabled="isSubmitting"
          hint="Upload the XML file containing your SSO configuration."
          label="SSO Metadata File"
          :loading="isSubmitting"
          :persistent-hint="!ssoFileNew"
        >
          <template v-if="isFile(ssoFileNew)" #append-outer>
            <v-btn color="success" :disabled="isSubmitting" @click="saveSSOSettings">
              Upload
            </v-btn>
          </template>
        </v-file-input>

        <v-text-field
          v-if="ssoTypeNew === 'url'"
          v-model="ssoURLNew"
          :disabled="isSubmitting"
          hint="Enter the URL where your SSO metadata file can be accessed."
          label="SSO Metadata URL"
          :loading="isSubmitting"
          :persistent-hint="!ssoURLNew"
          placeholder="https://example.com/metadata.xml"
          :rules="[requireProtocol]"
        >
          <template v-if="ssoURLNew && ssoURLNew !== ssoURLOld" #append-outer>
            <v-btn
              class="hide-on-error"
              color="success"
              :disabled="isSubmitting"
              @click="saveSSOSettings"
            >
              Load
            </v-btn>
          </template>
        </v-text-field>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<style lang="scss" scoped>
.error--text .hide-on-error {
  display: none;
}
</style>
