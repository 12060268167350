<template>
  <div class="d-flex">
    <span>{{ data.security.ticker }}</span>
    <v-tooltip v-if="clientConfig.corpActionsEnabled" data-test="corp-actions-button" top>
      <template #activator="{ on, attrs }">
        <button
          v-if="data.security.hasCorporateActions"
          class="corp-actions-button"
          v-bind="attrs"
          small
          v-on="on"
          @click="$emit('view-loan', data, 'corporateActions')"
        >
          <v-icon color="purple darken-1" small> mdi-flash</v-icon>
        </button>
      </template>
      <span>Upcoming events</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { OpenLoanItem } from '@/modules/common/models';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';

@Component({
  computed: {
    ...mapState(['clientConfig']),
  },
  props: {
    data: { type: Object as PropType<OpenLoanItem>, required: true },
  },
})
export default class OpenLoansTableTicker extends Vue {
  // props
  protected readonly data!: OpenLoanItem;

  // store
  protected readonly clientConfig!: ClientConfig;
}
</script>

<style lang="scss" scoped>
.corp-actions-button {
  width: 24px;
  height: 24px;
}

.corp-actions-button i {
  margin-top: -4px;
}
</style>
