<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    not-persistent
    overlay-color="secondary"
    overlay-opacity="0.80"
    :value="true"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-form novalidate @submit.prevent>
      <v-card v-shortkey="['enter']" class="form-summary" @shortkey="submitForm()">
        <v-card-title class="mb-0">
          <div class="d-flex align-center">
            <span class="headline">Rerate</span>
          </div>
          <v-spacer />
        </v-card-title>

        <v-card-text>
          <v-alert v-if="failedIds.length" dense type="error">
            {{ items.length - failedIds.length }}
            {{ items.length - failedIds.length > 1 ? 'actions' : 'action' }} succeeded /
            {{ failedIds.length }} failed (marked in red below)
          </v-alert>

          <div v-else class="d-flex pl-4 flex-column mt-n2">
            <span>Suggest new rate for {{ items.length }} loans</span>
            <v-select
              v-model="rateType"
              :items="['Fixed', 'Floating']"
              @change="onChangeRateType($event)"
            />
            <benchmark-select
              v-if="rateType === 'Floating'"
              v-model="newRateModifier"
              :error-messages="errorMsgs.newRateModifier"
              @blur="$v.newRateModifier.$touch()"
              @change="onChangeRateModifier($event)"
              @input="$v.newRateModifier.$touch()"
            />
            <numeric-input
              v-model="newRate"
              autofocus
              class="mt-4 pt-0"
              :error-messages="errorMsgs['newRate']"
              :label="`new ${rateType === 'Fixed' ? 'rate' : 'offset'}`"
              placeholder="0.0000"
              :precision="{ min: 4 }"
              :step="0.25"
              suffix="%"
              type="decimal"
              @blur="$v.newRate.$touch()"
              @change="onChangeRate()"
              @input="$v.newRate.$touch()"
            />
          </div>

          <v-divider class="mt-6" />

          <div class="table-container mt-4">
            <div>
              <v-data-table
                disable-filtering
                disable-pagination
                disable-sort
                fixed-header
                :headers="[
                  { text: 'Counterparty' },
                  { text: 'Ticker' },
                  { text: 'CUSIP' },
                  { text: 'Open' },
                  { text: 'Rebate', align: 'end' },
                ]"
                hide-default-footer
                item-key="key"
                :items="items"
                width="100%"
              >
                <template #item="{ item }">
                  <tr :class="{ 'error--text': failedIds.includes(item.id) }">
                    <td>
                      {{ item.counterpartyDisplay }}
                    </td>
                    <td>
                      {{ item.security.ticker }}
                    </td>
                    <td>
                      {{ item.security.cusip }}
                    </td>
                    <td>
                      <pretty-number :value="item.openQuantity" />
                    </td>
                    <td class="text-end">
                      <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row v-if="showError || showSuccess">
            <v-col class="pa-0 px-1 col-6 offset-3">
              <div
                class="v-alert v-alert--dense text--primary text-body-2 text-center"
                :class="{ error: showError, success: showSuccess }"
              >
                <div v-if="showError">
                  {{ errorMsgs.apiErrors.join('\n') }}
                </div>
                <div v-if="showSuccess">Your rate changes have have been sent.</div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions class="d-flex mt-4">
          <div v-if="failedIds.length" class="d-flex flex-grow-1 justify-end align-end">
            <v-btn color="primary" data-test="close-btn" @click="closeDialog()"> Close</v-btn>
          </div>

          <div v-else class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">Cancel</v-btn>
            <v-btn
              color="primary"
              :disabled="formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              type="submit"
              @click="submitForm()"
            >
              Confirm
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import wait from '@/modules/common/services/wait';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import BenchmarkSelect from '@/modules/marketdata/components/BenchmarkSelect.vue';
import { RateType } from '@/modules/marketdata/types/marketdata';
import { BorrowerOpenLoan, LenderOpenLoan } from '@/modules/common/models';
import { Benchmark } from '@/utils/api/loans';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import Decimal from 'decimal.js';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { decimal, required, requiredIf } from 'vuelidate/lib/validators';

interface FormErrors {
  apiErrors: string[];
  newRate: string[];
  newRateModifier: string[];
}

@Component({
  components: {
    BenchmarkSelect,
  },
  props: {
    items: Array as PropType<LenderOpenLoan[] | BorrowerOpenLoan[]>,
    shouldCounterOffer: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [validationMixin],
  validations: {
    newRateModifier: {
      required: requiredIf((vm: BatchRenegotiateDialog) => vm.rateType === 'Floating'),
    },
    newRate: {
      decimal,
      required,
      notGreaterThanPrecision: notGreaterThanPrecision(RATE_PRECISION),
    },
  },
})
export default class BatchRenegotiateDialog extends mixins(validationMixin) {
  // props
  protected readonly items!: LenderOpenLoan[] | BorrowerOpenLoan[];
  protected readonly shouldCounterOffer!: boolean;

  protected newRate: Decimal | null = null;
  protected newRateModifier: Benchmark = Benchmark.NoBenchmark;
  protected rateType: RateType = 'Fixed';
  protected showSuccess = false;
  protected apiErrors: string[] = [];
  protected formStatus: DialogFormStatus = 'idle';
  protected failedIds: string[] = [];

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      newRate: [],
      newRateModifier: [],
    };

    // rate errors
    if (this.$v.newRate.$dirty) {
      if (!this.$v.newRate.required)
        errors.newRate.push(`${this.rateType === 'Fixed' ? 'Rate' : 'Offset'} is required.`);
      if (!this.$v.newRate.notGreaterThanPrecision) {
        errors.newRate.push(`Rate must not be more than ${RATE_PRECISION} decimal places.`);
      }
    }

    // rate modifier errors
    if (this.$v.newRateModifier.$dirty) {
      if (!this.$v.newRateModifier.required) errors.newRateModifier.push('Benchmark is required.');
    }

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected mounted(): void {
    if (this.items.length > 0) {
      this.newRateModifier = this.items[0].rateModifier;
    }
    if (this.newRateModifier === Benchmark.NoBenchmark) {
      this.rateType = 'Fixed';
    } else {
      this.rateType = 'Floating';
    }
  }

  protected onChangeRate(): void {
    // clear any previous API errors
    this.apiErrors = [];
  }

  protected onChangeRateType(rateType: RateType): void {
    this.apiErrors = [];
    this.rateType = rateType;
    if (this.rateType === 'Fixed') {
      this.newRateModifier = Benchmark.NoBenchmark;
    }
  }

  protected onChangeRateModifier(rateModifier: Benchmark): void {
    this.apiErrors = [];
    this.newRateModifier = rateModifier;
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      const res = await this.postRenegotiates();

      if (res.failedIds?.length) {
        this.failedIds = res.failedIds;
      } else {
        this.showSuccess = true;
        this.formStatus = 'closing';
        await wait(1200);
        this.$emit('success');
        await this.closeDialog();
      }
    } catch (err) {
      this.apiErrors = [`${err}`];
    } finally {
      this.formStatus = 'idle';
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  private async postRenegotiates(): Promise<{ failedIds?: string[] }> {
    const side = this.items[0].side;
    const ids = this.items.map((i: BorrowerOpenLoan | LenderOpenLoan) => i.id);
    const method = this.shouldCounterOffer
      ? 'batchRejectRenegotiateAndCounterLoans'
      : 'batchRenegotiateLoans';

    const result = await this.$api.secLending[method](
      side,
      ids,
      this.newRate as Decimal,
      this.newRateModifier
    );

    const failedIds = result.failedItems.map((i) => i.requestId);

    // failing ids only, we are losing the error messages here
    return { failedIds };
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 30vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
