import Decimal from 'decimal.js';
import { CorporateActions, RawSecurity } from './security';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { OpenLoan, RawLoanBuyIn, RawLoanRecall, RawLoanReturn } from './open-loans';
import { RenegotiateSide } from '@/utils/api/loans';
import { RawCompanyInfo, RawLoanRenegotiation } from '@/modules/common/models';

export type RawBrokerOpenLoans = Raw<
  BrokerOpenLoans,
  {
    // always specify existing raw entry types explititly
    items: RawBrokerOpenLoan[];
  }
>;

export class BrokerOpenLoans {
  public items: BrokerOpenLoan[];
  public total: number;
  public corporateActions: CorporateActions;

  protected constructor(data: RawBrokerOpenLoans) {
    this.items = data.items.map((item) => BrokerOpenLoan.fromData(item, data.corporateActions));
    this.total = data.total;
    this.corporateActions = data.corporateActions;
  }

  public static fromData(data: RawBrokerOpenLoans): BrokerOpenLoans {
    return new BrokerOpenLoans(data);
  }
}

export type RawBrokerOpenLoan = Raw<
  BrokerOpenLoan,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
    counterparty: RawCompanyInfo;
    renegotiation: RawLoanRenegotiation | null;
    openRecalls: RawLoanRecall[];
    openBuyIns?: RawLoanBuyIn[];
    loanReturns: RawLoanReturn[];
  },
  'security' | 'availableActions' | 'renegotiateSideDisplay' | 'side'
>;

export class BrokerOpenLoan extends OpenLoan {
  public declare side: undefined;
  public lenderDisplay: string;
  public borrowerDisplay: string;
  public renegotiateSideDisplay: string | null;
  public dtccRefId: string;
  public independentAmount: Decimal;
  public yesterdayIndependentAmount: Decimal;

  protected constructor(data: RawBrokerOpenLoan, corporateActions?: CorporateActions) {
    super(data, corporateActions);
    this.availableActions = {};

    this.lenderDisplay = data.lenderDisplay;
    this.borrowerDisplay = data.borrowerDisplay;
    this.renegotiateSideDisplay = this.renegotiation
      ? this.renegotiation.side === RenegotiateSide.Lender
        ? this.lenderDisplay
        : this.borrowerDisplay
      : null;
    this.dtccRefId = data.dtccRefId;
    this.independentAmount = new Decimal(data.independentAmount);
    this.yesterdayIndependentAmount = new Decimal(data.yesterdayIndependentAmount);
  }

  public static fromData(
    data: RawBrokerOpenLoan,
    corporateActions?: CorporateActions
  ): BrokerOpenLoan {
    return new BrokerOpenLoan(data, corporateActions);
  }

  public static mock(data?: null | DeepPartial<RawBrokerOpenLoan>): BrokerOpenLoan {
    return BrokerOpenLoan.fromData(BrokerOpenLoan.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawBrokerOpenLoan>): RawBrokerOpenLoan {
    return {
      ...OpenLoan.mockData(data),

      lenderDisplay: data?.lenderDisplay ?? 'Lender Company',
      borrowerDisplay: data?.borrowerDisplay ?? 'Borrower Company',
      dtccRefId: data?.dtccRefId ?? 'DTCC123',
      independentAmount: data?.independentAmount ?? '0',
      yesterdayIndependentAmount: data?.yesterdayIndependentAmount ?? '0',
    };
  }
}
