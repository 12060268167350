// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/settlementconsts.proto (package aurora.core.consts.settlement, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum aurora.core.consts.settlement.SettlementType
 */
export enum SettlementType {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: NSCC = 1;
   */
  NSCC = 1,

  /**
   * @generated from enum value: BILATERAL = 2;
   */
  BILATERAL = 2,

  /**
   * @generated from enum value: OCC = 3;
   */
  OCC = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SettlementType)
proto3.util.setEnumType(SettlementType, "aurora.core.consts.settlement.SettlementType", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "NSCC" },
  { no: 2, name: "BILATERAL" },
  { no: 3, name: "OCC" },
]);

