import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, RawCompanyInfo, RawSecurity, Security } from '@/modules/common/models';
import Decimal from 'decimal.js';

export type RawVenueTopOfBookResponse = Raw<
  VenueTopOfBookResponse,
  {
    // always specify existing raw entry types explititly
    data: RawVenueTopOfBookItem[];
  }
>;

export class VenueTopOfBookResponse {
  public data: VenueTopOfBookItem[];

  protected constructor(data: RawVenueTopOfBookResponse) {
    this.data = data.data.map<VenueTopOfBookItem>(VenueTopOfBookItem.fromData);
  }

  public static fromData(data: RawVenueTopOfBookResponse): VenueTopOfBookResponse {
    return new VenueTopOfBookResponse(data);
  }

  public static mock(data?: DeepPartial<RawVenueTopOfBookResponse>): VenueTopOfBookResponse {
    return VenueTopOfBookResponse.fromData(VenueTopOfBookResponse.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawVenueTopOfBookResponse>): RawVenueTopOfBookResponse {
    return {
      data: data?.data?.map(VenueTopOfBookItem.mockData) ?? [],
    };
  }
}

export type RawVenueTopOfBookItem = Raw<
  VenueTopOfBookItem,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
    borrow: RawVenueTopOfBookSideItem | null;
    lend: RawVenueTopOfBookSideItem | null;
  },
  'security'
>;

export class VenueTopOfBookItem {
  public security: Security;
  public borrow: VenueTopOfBookSideItem | null;
  public lend: VenueTopOfBookSideItem | null;

  protected constructor(data: RawVenueTopOfBookItem) {
    this.security = Security.fromData(data.equity);
    this.borrow = VenueTopOfBookSideItem.fromData(data.borrow);
    this.lend = VenueTopOfBookSideItem.fromData(data.lend);
  }

  public static fromData(data: RawVenueTopOfBookItem): VenueTopOfBookItem {
    return new VenueTopOfBookItem(data);
  }

  public static mock(data?: DeepPartial<RawVenueTopOfBookItem>): VenueTopOfBookItem {
    return VenueTopOfBookItem.fromData(VenueTopOfBookItem.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawVenueTopOfBookItem>): RawVenueTopOfBookItem {
    return {
      equity: Security.mockData(data?.equity),
      borrow: VenueTopOfBookSideItem.mockData(data?.borrow),
      lend: VenueTopOfBookSideItem.mockData(data?.lend),
    };
  }
}

export type RawVenueTopOfBookSideItem = Raw<
  VenueTopOfBookSideItem,
  {
    // always specify existing raw entry types explititly
    counterparties: RawCompanyInfo[];
  }
>;

export class VenueTopOfBookSideItem {
  public quantity: number;
  public rate: Decimal;
  public totalQuantity: number;
  public counterparties: CompanyInfo[];
  public eligibleTotalQuantity: number;

  protected constructor(data: RawVenueTopOfBookSideItem) {
    this.quantity = data.quantity;
    this.rate = new Decimal(data.rate);
    this.totalQuantity = data.totalQuantity;
    this.counterparties = data.counterparties.map<CompanyInfo>(CompanyInfo.fromData);
    this.eligibleTotalQuantity = data.eligibleTotalQuantity;
  }

  public static fromData(data: RawVenueTopOfBookSideItem): VenueTopOfBookSideItem;
  public static fromData(data: RawVenueTopOfBookSideItem | null): VenueTopOfBookSideItem | null;
  public static fromData(
    data: RawVenueTopOfBookSideItem | undefined
  ): VenueTopOfBookSideItem | undefined;
  public static fromData(
    data: RawVenueTopOfBookSideItem
  ): null | undefined | VenueTopOfBookSideItem {
    if (data === null) return null;
    if (data === undefined) return undefined;
    return new VenueTopOfBookSideItem(data);
  }

  public static mockData(
    data?: DeepPartial<RawVenueTopOfBookSideItem> | null
  ): RawVenueTopOfBookSideItem | null {
    if (data === null) return null;

    const {
      counterparties = [{ companyId: '0', companyName: 'Test Company', displayBoxId: null }],
      ...rest
    } = data ?? {};

    return {
      counterparties: counterparties.map(CompanyInfo.mockData),
      eligibleTotalQuantity: 4,
      quantity: 7,
      rate: '17',
      totalQuantity: 347,

      ...rest,
    };
  }
}
