<template>
  <span style="display: contents">
    <div class="d-flex justify-space-between">
      <marketplace-top-of-book-watchlist-tabs
        v-if="withWatchlists"
        :selected-display-id="watchlistDisplayId"
        @change="onTabChange($event)"
      >
        <template #additional-buttons>
          <aurora-btn-dropdown color="secondary" main-text="Export" @click="exportAsCsv">
            <v-list dense>
              <v-list-item @click="exportAsCsv">
                <v-list-item-content>
                  <v-list-item-title>CSV</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon dense small>mdi-file-download</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="exportAsExcel">
                <v-list-item-content>
                  <v-list-item-title>Excel</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon dense small>mdi-file-excel</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </aurora-btn-dropdown>
        </template>
      </marketplace-top-of-book-watchlist-tabs>
    </div>
    <marketplace-top-of-book-table :as-broker="asBroker" :items="topOfBook" />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import MarketplaceTopOfBookWatchlistTabs from '@/modules/marketplace/components/MarketplaceTopOfBookWatchlistTabs.vue';
import MarketplaceTopOfBookTable from '@/modules/marketplace/components/MarketplaceTopOfBookTable.vue';
import { VenueTopOfBookResponse } from '@/modules/marketplace/models';
import axios from 'axios';
import { fileNameFromHeaders } from '@/utils/api/helpers';
import { errorString } from '@/utils/helpers/rest-response';

const POLLING_INTERVAL = 1;

@Component({
  props: {
    withWatchlists: {
      type: Boolean,
      default: false,
    },
    asBroker: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MarketplaceTopOfBookWatchlistTabs,
    MarketplaceTopOfBookTable,
  },
})
export default class MarketplaceTopOfBook extends Vue {
  protected readonly withWatchlists!: boolean;
  protected readonly asBroker!: boolean;
  protected topOfBook: VenueTopOfBookResponse['data'] = [];
  protected watchlistDisplayId: string | null = null;
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  protected async mounted(): Promise<void> {
    await this.fetchContents();
    // @TODO: replace with socket events
    this.pollInterval = setInterval(this.fetchContents, POLLING_INTERVAL * 1000);
  }

  protected async fetchContents(): Promise<void> {
    if (this.asBroker) {
      this.topOfBook = await this.$api.marketplace.fetchAdminTopOfBook();
    } else {
      this.topOfBook = this.watchlistDisplayId
        ? await this.$api.marketplace.fetchWatchlistContent(this.watchlistDisplayId)
        : await this.$api.marketplace.fetchTopOfBook();
    }
  }

  protected onTabChange(watchlistDisplayId: string | null): void {
    this.watchlistDisplayId = watchlistDisplayId;
    void this.fetchContents();
  }

  protected async exportAsExcel(): Promise<void> {
    return this.exportAsCsv(true);
  }

  protected async exportAsCsv(excel = false): Promise<void> {
    try {
      const response = await axios.get<BlobPart>('/api/1/orderbook/top-of-book/export/csv', {
        params: {
          excel: excel,
        },
        responseType: 'blob',
      });

      const fileName =
        fileNameFromHeaders(response.headers as Record<string, string>) || 'export.csv';
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  // vue-class-component implements destroyed, but not unmounted
  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
</script>

<style lang="scss" scoped></style>
