<template>
  <div class="d-flex flex-column flex fill-height">
    <dashboard-panel no-collapse title="Orderbook">
      <marketplace-top-of-book as-broker />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import MarketplaceTopOfBook from '@/modules/marketplace/components/MarketplaceTopOfBook.vue';

@Component({
  components: {
    DashboardPanel,
    MarketplaceTopOfBook,
  },
})
export default class BrokerUserOrderbook extends Vue {}
</script>

<style lang="scss"></style>
