<template>
  <v-dialog
    v-model="dialog"
    :content-class="contentClass + ' au-popup-dialog'"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    scrollable
    :width="width"
  >
    <v-card>
      <v-card-text class="text-center">
        <div class="title text-capitalize text--primary mb-6">{{ title }}</div>

        <v-progress-linear v-if="isUploading" color="primary" indeterminate />

        <v-icon v-if="showSuccess" class="ml-1 success--text" x-large>mdi-check</v-icon>
        <v-icon v-if="showWarning" class="ml-1 warning--text" x-large>mdi-alert</v-icon>
        <v-icon v-if="showError" class="ml-1 error--text" x-large>mdi-close</v-icon>

        <!-- Success message display -->
        <slot v-if="showSuccess" name="success" v-bind="{ uploadResult, closeDialog }" />

        <!-- Error message display -->
        <slot
          v-if="showError || showWarning"
          name="error"
          v-bind="{ error, errorMsg, uploadMsgs, showError, showWarning, closeDialog }"
        >
          <div class="v-alert v-alert-dense error--text text-center">{{ errorMsg }}</div>
        </slot>
      </v-card-text>

      <!-- Dialog box actions slot -->
      <v-card-actions class="d-flex mt-4">
        <slot name="cardActions" v-bind="{ closeDialog }">
          <div
            v-if="showError || (!closeOnSuccess && showSuccess)"
            class="d-flex flex-grow-1 justify-center"
          >
            <v-btn color="secondary" @click="closeDialog()">{{ $t('dialogs.closeButton') }}</v-btn>
          </div>
          <v-container v-if="showWarning">
            <v-row>
              <v-col class="d-flex flex-grow-1 justify-center text--primary"
                ><b>Please confirm you would like to submit.</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex flex-grow-1 justify-center">
                <v-spacer></v-spacer>
                <v-btn class="px-5" @click="closeDialog()">go back</v-btn>
                <v-btn class="ml-5 px-5" color="warning" @click="uploadAgain()">submit</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import wait from '@/modules/common/services/wait';
import { ApiError, BadRequestError } from '@/utils/errors';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { i18nServerMessage } from '@/utils/helpers/rest-response';

@Component({
  components: {},
  props: {
    /**
     * binds current display status when used with .sync
     */
    displayStatus: Boolean,
    /**
     * Override the popup default title
     */
    uploadStatusMsg: String,
    /**
     * Close the popup automatically after successful upload
     */
    closeOnSuccess: {
      type: Boolean,
      default: true,
    },
    progressPromise: {
      required: true,
    },
    /**
     * CSS Class to apply to the dialog content
     */
    contentClass: {
      type: String,
      default: '',
    },
    /**
     * Width of the dialog
     */
    width: {
      type: [String, Number],
      default: '300',
    },
  },
})
export default class UploadProgressPopup<T = unknown> extends Vue {
  protected readonly progressPromise!: Promise<T> | null;
  protected readonly uploadStatusMsg!: string;
  protected readonly closeOnSuccess!: boolean;
  protected readonly width!: string | number;

  protected isUploading = false;
  protected showSuccess = false;
  protected showWarning = false;
  protected showError = false;
  protected error: ApiError | null = null;
  protected errorMsg = '';
  protected dialog = false;
  protected uploadResult: T | null = null;
  protected uploadMsgs: T[] | null = null;

  protected get title(): string {
    if (this.uploadStatusMsg) {
      return this.uploadStatusMsg;
    } else {
      if (this.isUploading) {
        return 'uploading...';
      } else if (this.showSuccess) {
        return 'upload successful!';
      } else if (this.showWarning) {
        return 'upload warning!';
      } else if (this.showError) {
        return 'upload failed!';
      } else {
        return '';
      }
    }
  }

  @Watch('progressPromise')
  protected onProgressPromise(): void {
    this.reset();

    if (this.progressPromise) {
      // show the dialog box while the promise being unresolved
      this.showDialog();

      void this.progressPromise
        .then(async (result) => {
          this.uploadResult = result;

          // show success message, then close popup
          this.showSuccess = true;
          this.isUploading = false;
          if (this.closeOnSuccess) {
            await wait(600);
            this.closeDialog();
          }
        })
        .catch((err) => {
          // show error/warning messages, let user close popup
          this.showError = true;
          this.showWarning = false;
          this.isUploading = false;
          this.error = err;

          if (err.responseData && err.responseData.errors) {
            // in case of errors, only show the errors
            this.uploadMsgs = err.responseData?.errors.filter((e) => e.level === 'ERROR') as T[];

            // no errors? show warnings
            if (this.uploadMsgs.length > 0) {
              this.showError = true;
            } else {
              // no errors; we must have warnings
              this.showError = false;
              this.showWarning = true;
              this.uploadMsgs = err.responseData?.errors.filter(
                (e) => e.level === 'WARNING'
              ) as T[];
            }
          }

          if (err instanceof BadRequestError) {
            if (err.responseData && err.responseData?.msgkey) {
              this.errorMsg = i18nServerMessage(err);
            } else {
              this.errorMsg = this.showError
                ? `Something went wrong`
                : `Please double-check your orders`;
            }
          } else {
            this.errorMsg = `${err}`;
          }
        });
    } else {
      // hide the dialog box if the promise is cleared upstream
      this.closeDialog();
    }
  }

  protected mounted(): void {
    // trigger the popup if promise prop is already set when mounting
    this.onProgressPromise();
  }

  protected showDialog(): void {
    this.dialog = true;
    this.onDisplayChange();
  }

  protected closeDialog(): void {
    this.dialog = false;
    this.onDisplayChange();
  }

  protected async uploadAgain(): Promise<void> {
    this.reset();
    await wait(250);
    this.$emit('uploadAgain');
  }

  protected reset(): void {
    this.uploadResult = null;
    this.isUploading = true;
    this.showSuccess = false;
    this.showError = false;
    this.showWarning = false;
    this.errorMsg = '';
    this.uploadMsgs = null;
    this.error = null;
  }

  protected onDisplayChange(): void {
    // emit a change event upstream to update the display status
    this.$emit('update:displayStatus', this.dialog);
  }
}
</script>
