import { UUID_EMPTY } from '@/constants';
import { AuctionSecurity, RawAuctionSecurity } from '@/modules/auction/models';
import { Raw } from '@/modules/common/helpers/api';
import { parseISO } from 'date-fns';

export type RawBorrowerLocatesResponse = Raw<
  BorrowerLocatesResponse,
  {
    // always specify existing raw entry types explititly
    locates: RawBorrowerLocate[];
  }
>;

export class BorrowerLocatesResponse {
  public status: string;
  public locates: BorrowerLocate[];

  protected constructor(data: RawBorrowerLocatesResponse) {
    this.status = data.status;
    this.locates = data.locates.map(BorrowerLocate.fromData);
  }

  public static fromData(data: RawBorrowerLocatesResponse): BorrowerLocatesResponse {
    return new BorrowerLocatesResponse(data);
  }
}

export type RawBorrowerLocate = Raw<
  BorrowerLocate,
  {
    // always specify existing raw entry types explititly
    equity: RawAuctionSecurity | null;
  },
  'security'
>;

export class BorrowerLocate {
  public id: string;
  public security: AuctionSecurity | null;
  public quantity: number;
  public approvedQuantity: number;
  public createdAt: Date;

  protected constructor(data: RawBorrowerLocate) {
    this.id = data.id;
    this.security = AuctionSecurity.fromData(data.equity);
    this.quantity = data.quantity;
    this.approvedQuantity = data.approvedQuantity;
    this.createdAt = parseISO(data.createdAt);
  }

  public static create(): BorrowerLocate {
    return BorrowerLocate.fromData({
      id: UUID_EMPTY,
      equity: null,
      quantity: 0,
      approvedQuantity: 0,
      createdAt: new Date().toString(),
    });
  }

  public static fromData(data: RawBorrowerLocate): BorrowerLocate {
    return new BorrowerLocate(data);
  }
}
