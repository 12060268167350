// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/login/security.proto (package aurora.core.apiengine.loginv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetSSOSettingsRequest, GetSSOSettingsResponse, LogoutRequest, LogoutResponse, RemoveSSOSettingRequest, RemoveSSOSettingResponse, SetSSOSettingsRequest, SetSSOSettingsResponse } from "./security_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service aurora.core.apiengine.loginv1.SecurityService
 */
export const SecurityService = {
  typeName: "aurora.core.apiengine.loginv1.SecurityService",
  methods: {
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.SecurityService.Logout
     */
    logout: {
      name: "Logout",
      I: LogoutRequest,
      O: LogoutResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.SecurityService.GetSSOSettings
     */
    getSSOSettings: {
      name: "GetSSOSettings",
      I: GetSSOSettingsRequest,
      O: GetSSOSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.SecurityService.SetSSOSettings
     */
    setSSOSettings: {
      name: "SetSSOSettings",
      I: SetSSOSettingsRequest,
      O: SetSSOSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.SecurityService.RemoveSSOSettings
     */
    removeSSOSettings: {
      name: "RemoveSSOSettings",
      I: RemoveSSOSettingRequest,
      O: RemoveSSOSettingResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

