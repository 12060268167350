<template>
  <span data-test="open-loans-action-dialog-manager">
    <recall-dialog v-if="dialog === 'recall'" :loan="loan" @close-modal="closeModal" />
    <buy-in-dialog v-if="dialog === 'buyIn'" :loan="loan" @close-modal="closeModal" />
    <return-dialog v-if="dialog === 'return'" :loan="loan" @close-modal="closeModal" />
    <renegotiate-dialog
      v-if="dialog === 'renegotiate' || dialog === 'rejectRenegotiateAndCounterOffer'"
      :loan="loan"
      :should-counter-offer="dialog === 'rejectRenegotiateAndCounterOffer'"
      @close-modal="closeModal"
    />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import RecallDialog from '@/modules/lender/components/RecallDialog.vue';
import BuyInDialog from '@/modules/lender/components/BuyInDialog.vue';
import ReturnDialog from '@/modules/borrower/components/ReturnDialog.vue';
import RenegotiateDialog from '@/modules/sec-lending/components/RenegotiateDialog.vue';
import { AxiosError } from 'axios';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { BorrowerOpenLoan, LenderOpenLoan } from '@/modules/common/models';

type DialogName =
  | 'recall'
  | 'buyIn'
  | 'return'
  | 'renegotiate'
  | 'rejectRenegotiateAndCounterOffer';

interface LoanAction {
  name:
    | DialogName
    | 'acceptRenegotiate'
    | 'rejectRenegotiate'
    | 'cancelRenegotiate'
    | 'cancelPendingLoan'
    | 'cancelPendingReturns'
    | null;
  loan: LenderOpenLoan | BorrowerOpenLoan | null;
}

@Component({
  components: {
    RecallDialog,
    BuyInDialog,
    ReturnDialog,
    RenegotiateDialog,
  },
})
export default class OpenLoansActionManager extends Vue {
  protected dialog: DialogName | null = null;
  protected loan: LenderOpenLoan | BorrowerOpenLoan | null = null;

  // this method is called by the parent component via a "ref"
  public trigger(action: LoanAction): void {
    if (action.loan === null) {
      return;
    }
    switch (action.name) {
      // some actions trigger a dialog workflow
      case 'recall':
      case 'buyIn':
      case 'return':
      case 'renegotiate':
      case 'rejectRenegotiateAndCounterOffer':
        this.dialog = action.name;
        this.loan = action.loan;
        break;
      // other actions are handled directly here
      case 'acceptRenegotiate':
        void this.respondToRenegotiate(action.loan, true);
        break;
      case 'rejectRenegotiate':
        void this.respondToRenegotiate(action.loan, false);
        break;
      case 'cancelRenegotiate':
        void this.cancelRenegotiate(action.loan);
        break;
      case 'cancelPendingLoan':
        void this.cancelPendingLoan(action.loan);
        break;
      case 'cancelPendingReturns':
        void this.cancelPendingReturns(action.loan);
        break;
    }
  }

  protected closeModal(): void {
    this.dialog = null;
    this.loan = null;
  }

  private async respondToRenegotiate(
    loan: LenderOpenLoan | BorrowerOpenLoan,
    accepted: boolean
  ): Promise<void> {
    const keyword = accepted ? 'accepted' : 'rejected';
    try {
      await this.$api.openLoans.respondToRenegotiate(loan, accepted);
      this.$snackbar.confirm(`New rate for ${loan.security.name} has been ${keyword}`);
    } catch (err) {
      this.$snackbar.error(
        i18nServerMessage(
          err as AxiosError,
          `New rate for ${loan.security.name}' cannot be ${keyword}`
        )
      );
    }
  }

  private async cancelRenegotiate(loan: LenderOpenLoan | BorrowerOpenLoan): Promise<void> {
    try {
      await this.$api.openLoans.cancelRenegotiate(loan);
      this.$snackbar.confirm('Renegotiate canceled');
    } catch (err) {
      this.$snackbar.error(
        i18nServerMessage(
          err as AxiosError,
          `Renegotiate for ${loan.security.name}' cannot be canceled`
        )
      );
    }
  }

  private async cancelPendingLoan(loan: LenderOpenLoan | BorrowerOpenLoan): Promise<void> {
    try {
      await this.$api.openLoans.cancelPendingLoan(loan);
      this.$snackbar.confirm('Loan cancel submitted');
    } catch (err) {
      this.$snackbar.error(
        i18nServerMessage(err as AxiosError, `Loan for ${loan.security.name}' cannot be canceled`)
      );
    }
  }

  private async cancelPendingReturns(loan: LenderOpenLoan | BorrowerOpenLoan): Promise<void> {
    try {
      await this.$api.openLoans.cancelPendingReturns(loan);
      this.$snackbar.confirm('All pending returns are being canceled');
    } catch (err) {
      this.$snackbar.error(
        i18nServerMessage(
          err as AxiosError,
          `Returns for ${loan.security.name}' cannot be canceled anymore`
        )
      );
    }
  }
}
</script>
