export const RATE_PRECISION = 4;
export const RATE_TICKSIZE = 0.0001;
export const RATE_DIGITS = 5;
export const MAX_RATE = 99999.9999;

export const IA_PRECISION = 2;
export const MAX_IA = 10.0;

export const PRICE_PRECISION = 2;
export const PRICE_TICKSIZE = 0.01;

export const VALUE_PRECISION = 2;
export const VALUE_MAX = 9999999999.99;
