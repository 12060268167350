import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, RawCompanyInfo, RawSecurity, Security } from '@/modules/common/models';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { Benchmark } from '@/utils/api/loans';
import { format as dateFormatter, parseISO } from 'date-fns';
import Decimal from 'decimal.js';

type Side = 'LENDER' | 'BORROWER';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TermLoanStatus = {
  TermLoanContractStatusDraft: 'draft',
  TermLoanContractStatusProposed: 'proposed',
  TermLoanContractStatusAccepted: 'accepted',
  TermLoanContractStatusActive: 'active',
  TermLoanContractStatusCanceled: 'canceled',
  TermLoanContractStatusExpired: 'expired',
  TermLoanContractStatusRejected: 'rejected',
} as const;
export type TermLoanStatus = (typeof TermLoanStatus)[keyof typeof TermLoanStatus];

export type RawContract = Raw<
  Contract,
  {
    maxEquityPercent: string;
    counterparty: RawCompanyInfo;
    equities?: RawSecurity[];
  },
  'maxSecurityPercent' | 'securities' | 'availableActions'
>;

export class Contract {
  public displayId: string;
  public notionalValue: Decimal;
  public currentValue: Decimal;
  public threshold: Decimal;
  public rate: Decimal;
  public rateModifier: Benchmark;
  public maxSecurityPercent: Decimal;
  public independentAmountRate: Decimal;
  public roundingRule: RoundingRule;
  public startDate: Date;
  public endDate: Date;
  public createdAt: Date;
  public side: Side;
  public counterparty: CompanyInfo;
  public status: TermLoanStatus;
  public securities: Security[];
  public isCreator: boolean;
  public hasPendingLoans: boolean;

  // client bloat
  /**
   * all actions that can be performed, based on "side", "status" and "isCreator"
   */
  public availableActions: {
    [key in
      | 'view'
      | 'edit'
      | 'manageLoans'
      | 'cancelDraft'
      | 'cancelProposed'
      | 'accept'
      | 'reject']?: true;
  };

  protected constructor(data: RawContract) {
    this.displayId = data.displayId;
    this.notionalValue = new Decimal(data.notionalValue);
    this.currentValue = new Decimal(data.currentValue);
    this.threshold = new Decimal(data.threshold);
    this.rate = new Decimal(data.rate);
    this.rateModifier = data.rateModifier;
    this.maxSecurityPercent = new Decimal(data.maxEquityPercent);
    this.independentAmountRate = new Decimal(data.independentAmountRate);
    this.roundingRule = data.roundingRule;
    this.startDate = parseISO(data.startDate);
    this.endDate = parseISO(data.endDate);
    this.createdAt = parseISO(data.createdAt);
    this.side = data.side;
    this.counterparty = CompanyInfo.fromData(data.counterparty);
    this.status = data.status;
    this.securities = data.equities?.map<Security>(Security.fromData) ?? [];
    this.isCreator = data.isCreator;
    this.hasPendingLoans = data.hasPendingLoans;
    this.availableActions = {};

    this.injectAvailableActions();
  }

  public static toData(model: null | Contract): null | RawContract {
    return model === null
      ? null
      : {
          displayId: model.displayId,
          notionalValue: model.notionalValue.toString(),
          currentValue: model.currentValue.toString(),
          threshold: model.threshold.toString(),
          rate: model.rate.toString(),
          rateModifier: model.rateModifier,
          maxEquityPercent: model.maxSecurityPercent.toString(),
          independentAmountRate: model.independentAmountRate.toString(),
          roundingRule: model.roundingRule,
          startDate: model.startDate.toString(),
          endDate: model.endDate.toString(),
          createdAt: model.createdAt.toString(),
          side: model.side,
          counterparty: CompanyInfo.toData(model.counterparty),
          status: model.status,
          equities: model.securities.map(Security.toData),
          isCreator: model.isCreator,
          hasPendingLoans: model.hasPendingLoans,
        };
  }

  public static fromData(data: RawContract): Contract {
    return new Contract(data);
  }

  public static mock(data?: DeepPartial<RawContract>): Contract {
    return Contract.fromData(Contract.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawContract>): RawContract {
    const { counterparty, equities, ...rest } = data ?? {};

    return {
      counterparty: CompanyInfo.mockData(counterparty),
      equities: equities?.map(Security.mockData) ?? [],
      displayId: '51192a4f-9f63-4fae-ae97-c5893153ebfe',
      status: 'active',
      isCreator: true,
      side: 'BORROWER',
      rate: '0.05',
      rateModifier: Benchmark.NoBenchmark,
      notionalValue: '1000000',
      currentValue: '0',
      startDate: '2021-01-01',
      endDate: '2022-01-01',
      threshold: '10',
      maxEquityPercent: '80',
      independentAmountRate: '0.03',
      roundingRule: RoundingRule.NoRounding,
      createdAt: '2021-01-01',
      hasPendingLoans: false,

      ...rest,
    };
  }

  private injectAvailableActions() {
    if (this.status === 'draft' && this.isCreator) {
      this.availableActions.edit = true;
      this.availableActions.cancelDraft = true;
    }

    if (this.status === 'proposed' && this.isCreator) {
      this.availableActions.edit = true;
      this.availableActions.cancelProposed = true;
    }

    if (this.status === 'proposed' && !this.isCreator) {
      this.availableActions.accept = true;
      this.availableActions.reject = true;
    }

    if (this.status === 'proposed' && !this.isCreator && this.side === 'BORROWER') {
      this.availableActions.accept = true;
      this.availableActions.reject = true;
      this.availableActions.manageLoans = true;
    }

    if (this.status === 'accepted' && this.side === 'BORROWER') {
      this.availableActions.manageLoans = true;
    }

    if (this.status === 'accepted' && this.side === 'LENDER') {
      this.availableActions.view = true;
    }

    if (this.status === 'active' && this.side === 'LENDER') {
      this.availableActions.view = true;
    }

    if (this.status === 'active' && this.side === 'BORROWER') {
      this.availableActions.manageLoans = true;
    }

    // @TODO: implement "completed" status
  }
}

export type RawTermLoanContractResponse = Raw<
  TermLoanContractResponse,
  {
    maxEquityPercent: string;
    counterparty: RawCompanyInfo;
    equities?: RawSecurity[];
    items: RawSecurityAggregatedLoans[];
  },
  'maxSecurityPercent' | 'securities' | 'availableActions' | 'items'
>;

export class TermLoanContractResponse extends Contract {
  public items: SecurityAggregatedLoans[];

  protected constructor(data: RawTermLoanContractResponse) {
    super(data);

    const securitiesByCusip = this.securities.reduce(
      (acc, security) => {
        acc[security.cusip] = security;
        return acc;
      },
      {} as Record<string, Security>
    );

    this.items = data.items.map((item) =>
      SecurityAggregatedLoans.fromData(item, securitiesByCusip)
    );
  }

  public static fromData(data: RawTermLoanContractResponse): TermLoanContractResponse {
    return new TermLoanContractResponse(data);
  }

  public static toData(model: TermLoanContractResponse | null): RawTermLoanContractResponse | null {
    return model === null
      ? null
      : {
          ...(Contract.toData(model) as RawContract),
          items: model.items.map(SecurityAggregatedLoans.toData),
        };
  }

  public static mock(data?: DeepPartial<RawTermLoanContractResponse>): TermLoanContractResponse {
    return TermLoanContractResponse.fromData(TermLoanContractResponse.mockData(data));
  }

  public static mockData(
    data?: DeepPartial<RawTermLoanContractResponse>
  ): RawTermLoanContractResponse {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const { counterparty: _counterparty, equities: _equities, items, ...rest } = data ?? {};

    return {
      ...Contract.mockData(data),
      items: items?.map(SecurityAggregatedLoans.mockData) ?? [],

      ...rest,
    };
  }
}

export type RawSecurityAggregatedLoans = Raw<SecurityAggregatedLoans, 'security'>;

export class SecurityAggregatedLoans {
  public ticker: string;
  public cusip: string;
  public loansCount: number;
  public totalOpenQuantity: number;
  public totalPendingQuantity: number;
  public totalReturnedQuantity: number;
  public totalPendingReturnQuantity: number;
  public totalOpenQuantityForReturn: number;
  public totalRecalledQuantity: number;
  public position: Decimal;
  public totalValue: Decimal;
  public security: Security;

  protected constructor(
    data: RawSecurityAggregatedLoans,
    securitiesByCusip: Record<string, Security>
  ) {
    this.ticker = data.ticker;
    this.cusip = data.cusip;
    this.loansCount = data.loansCount;
    this.totalOpenQuantity = data.totalOpenQuantity;
    this.totalPendingQuantity = data.totalPendingQuantity;
    this.totalReturnedQuantity = data.totalReturnedQuantity;
    this.totalPendingReturnQuantity = data.totalPendingReturnQuantity;
    this.totalOpenQuantityForReturn = data.totalOpenQuantityForReturn;
    this.totalRecalledQuantity = data.totalRecalledQuantity;
    this.position = new Decimal(data.position);
    this.totalValue = new Decimal(data.totalValue);
    this.security = securitiesByCusip[this.cusip];
  }

  public static fromData(
    data: RawSecurityAggregatedLoans,
    securitiesByCusip: Record<string, Security>
  ): SecurityAggregatedLoans {
    return new SecurityAggregatedLoans(data, securitiesByCusip);
  }

  public static toData(model: SecurityAggregatedLoans): RawSecurityAggregatedLoans {
    return {
      ticker: model.ticker,
      cusip: model.cusip,
      loansCount: model.loansCount,
      totalOpenQuantity: model.totalOpenQuantity,
      totalPendingQuantity: model.totalPendingQuantity,
      totalReturnedQuantity: model.totalReturnedQuantity,
      totalPendingReturnQuantity: model.totalPendingReturnQuantity,
      totalOpenQuantityForReturn: model.totalOpenQuantityForReturn,
      totalRecalledQuantity: model.totalRecalledQuantity,
      position: model.position.toString(),
      totalValue: model.totalValue.toString(),
    };
  }

  public static mockData(
    data?: DeepPartial<RawSecurityAggregatedLoans>
  ): RawSecurityAggregatedLoans {
    return {
      ticker: 'AAPL',
      cusip: '123',
      loansCount: 1,
      totalOpenQuantity: 2500,
      totalPendingQuantity: 125,
      totalReturnedQuantity: 0,
      totalPendingReturnQuantity: 0,
      totalOpenQuantityForReturn: 100,
      totalRecalledQuantity: 0,
      position: '50',
      totalValue: '10000000',

      ...data,
    };
  }
}

export type RawPostContractRequest = Raw<
  PostContractRequest,
  { maxEquityPercent: string },
  'maxSecurityPercent'
>;

export class PostContractRequest {
  public side: Side;
  public counterpartyId: string;
  public notionalValue: Decimal;
  public threshold: Decimal;
  public rate: Decimal;
  public rateModifier: Benchmark;
  public maxSecurityPercent: Decimal;
  public startDate: Date;
  public endDate: Date;
  public isComplete: boolean;
  public cusips: string[];

  protected constructor(model: PostContractRequest) {
    this.side = model.side;
    this.counterpartyId = model.counterpartyId;
    this.notionalValue = model.notionalValue;
    this.threshold = model.threshold;
    this.rate = model.rate;
    this.rateModifier = model.rateModifier;
    this.maxSecurityPercent = model.maxSecurityPercent;
    this.startDate = model.startDate;
    this.endDate = model.endDate;
    this.isComplete = model.isComplete;
    this.cusips = model.cusips;
  }

  public static fromModel(model: PostContractRequest): PostContractRequest {
    return new PostContractRequest(model);
  }

  public static toData(model: PostContractRequest): RawPostContractRequest {
    return {
      side: model.side,
      counterpartyId: model.counterpartyId,
      notionalValue: model.notionalValue.toString(),
      threshold: model.threshold.toString(),
      rate: model.rate.toString(),
      rateModifier: model.rateModifier,
      maxEquityPercent: model.maxSecurityPercent.toString(),
      startDate: dateFormatter(model.startDate, 'yyyy-MM-dd'),
      endDate: dateFormatter(model.endDate, 'yyyy-MM-dd'),
      isComplete: model.isComplete,
      cusips: model.cusips,
    };
  }
}

export type RawGetAllContractsRequest = Raw<GetAllContractsRequest, { items: RawContract[] }>;

export class GetAllContractsRequest {
  public items: Contract[];

  protected constructor(data: RawGetAllContractsRequest) {
    this.items = data.items.map(Contract.fromData);
  }

  public static fromData(data: RawGetAllContractsRequest): GetAllContractsRequest {
    return new GetAllContractsRequest(data);
  }
}
