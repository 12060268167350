<template>
  <items-batch-confirmation-dialog
    :description="description"
    :execute="execute"
    item-id-key="orderRef"
    :items="itemsWithTotalValue"
    success-message="Cancel all successfully initiated."
    :table-headers="[
      { text: 'Side' },
      { text: 'Ticker' },
      { text: 'CUSIP' },
      { text: 'Open Qty' },
      { text: 'Rate Limit', align: 'end' },
      { text: 'Exec Qty', align: 'end' },
      { text: 'Total Qty', align: 'end' },
      { text: 'Total Value', align: 'end' },
      { text: 'Time in force', align: 'end' },
    ]"
    title="Cancel all"
    v-on="$listeners"
  >
    <template #row="{ item }">
      <td>
        <format-side :side="item.side === Side.BORROWER ? 'BORROWER' : 'LENDER'" />
      </td>
      <td>
        {{ storeSecurities.getSecurity(item.cusip)?.ticker }}
      </td>
      <td>
        {{ item.cusip }}
      </td>
      <td class="text-end">
        <pretty-number :value="item.quantity - item.totalExecQty" />
      </td>
      <td class="text-end">
        <span v-if="item.rate === null">–</span>
        <rate-output v-else :rate="item.rate" :rate-modifier="item.rateModifier" />
      </td>
      <td class="text-end">
        <span v-if="!item.totalExecQty">–</span>
        <span v-else>
          <pretty-number :value="item.totalExecQty" />
        </span>
      </td>
      <td class="text-end">
        <pretty-number :value="item.quantity" />
      </td>
      <td class="text-end">${{ formatPrice(item.totalValue) }}</td>
      <td class="text-end">
        {{ formatTimeInForceType(item) }}
      </td>
    </template>
  </items-batch-confirmation-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { timeInForceAbbr } from '@/modules/marketplace/helpers/marketplace';
import ItemsBatchConfirmationDialog from '@/modules/common/components/ItemsBatchConfirmationDialog.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { Order } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { proto3 } from '@bufbuild/protobuf';
import { OmsTimeInForceType } from '@/connect/gen/consts/omsconsts_pb';
import { useStoreSecurities } from '@/store/store-securities';

class OrderWithTotalValue extends Order {
  public totalValue!: number;
}

@Component({
  components: {
    ItemsBatchConfirmationDialog,
  },
  props: {
    items: Array as PropType<Order[]>,
  },
})
export default class MarketplaceBatchCancelAllDialog extends Vue {
  public timeInForceAbbr = timeInForceAbbr;

  // We want to `lock in` the items list once we are in the cancel flow in case something changes it in the parent
  // component, so we disconnect it from the incoming prop. See mounted().
  protected readonly items!: Order[];
  protected itemsToBeCanceled: Order[] = [];
  protected storeSecurities = useStoreSecurities();

  protected readonly pricePrecision = PRICE_PRECISION;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Side = Side;

  public get itemsWithTotalValue(): OrderWithTotalValue[] {
    return this.items.map((item) => {
      const security = this.storeSecurities.getSecurity(item.cusip);
      const totalValue = security
        ? new Decimal(security.lastClosePrice ?? 0).mul(item.quantity.toString())
        : new Decimal(0);
      return Object.assign(new OrderWithTotalValue(), { ...item, totalValue });
    });
  }

  public get description(): string {
    const orderStr = this.itemsToBeCanceled?.length > 1 ? `orders` : 'order';
    if (this.itemsToBeCanceled?.length > 0) {
      return `You are about to fully cancel ${this.itemsToBeCanceled.length} ${orderStr}`;
    }
    return '';
  }

  public async execute(): Promise<{ failedIds?: string[] }> {
    const orderRefs = this.itemsToBeCanceled.map(({ orderRef }) => orderRef);
    const { failedOrderRefs } = await this.$api.marketplace.batchCancelOrders(orderRefs);
    return { failedIds: failedOrderRefs };
  }

  protected mounted(): void {
    this.itemsToBeCanceled = [...this.items];
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }

  protected formatTimeInForceType(item: Order): string {
    return timeInForceAbbr(
      proto3.getEnumType(OmsTimeInForceType).values.find((v) => v.no === item.timeInForceType)
        ?.name as string
    );
  }
}
</script>
