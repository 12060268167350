/**
 * The status of a manual loan request
 */
export enum ManualLoanStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
  Canceled = 'CANCELED',
  Automatched = 'AUTOMATCHED',
}

/**
 * A map of manual loan statuses to corresponding CSS classes for styling
 */
export const manualLoanStatusCssClass = {
  [ManualLoanStatus.Pending]: 'status-pending',
  [ManualLoanStatus.Accepted]: 'status-accepted',
  [ManualLoanStatus.Rejected]: 'status-rejected',
  [ManualLoanStatus.Expired]: 'status-expired',
  [ManualLoanStatus.Canceled]: 'status-canceled',
  [ManualLoanStatus.Automatched]: 'status-automatched',
};
