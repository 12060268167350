<template>
  <!-- price preformatted and padded with spaces! -->
  <pre>{{ formatPrice() }}</pre>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { formatAlignablePrice } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';

/**
 * Display a price with fixated width (by padding spaces).
 * When using a monospace font this component can be used to align prices in a table on the decimal dot
 */
@Component({
  props: {
    price: Decimal,
    currencyCode: String,
    integralLen: {
      type: Number,
      default: 6,
    },
    fractionalLen: {
      type: Number,
      default: PRICE_PRECISION,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class PriceOutput extends Vue {
  // props
  private price!: Decimal;
  private currencyCode?: string;
  private integralLen!: number;
  private fractionalLen!: number;

  // store state refs
  private clientConfig!: ClientConfig;

  protected formatPrice(): string {
    return formatAlignablePrice(
      this.price,
      this.currencyCode ?? this.clientConfig.auctionMarketBaseCurrency,
      this.integralLen,
      this.fractionalLen
    );
  }
}
</script>
