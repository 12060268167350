<template>
  <span class="d-inline-block text-truncate fix-top-position" :style="`max-width: ${maxWidth}`">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on"> {{ value }} </span>
      </template>
      <span class="tip-background">{{ value }}</span>
    </v-tooltip>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  props: {
    value: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '6rem',
    },
  },
})
export default class TruncateText extends Vue {
  protected readonly maxWidth!: string;
}
</script>
<style lang="scss" scoped>
.tip-background {
  border: solid #303030 1px;
  border-radius: 4px;
  padding: 1px 4px;
  background: #393939 !important;
  opacity: 1 !important;
}

// the combination of `d-inline-block` and `text-truncate` does strange things to the height
// of the element, but both are necessary. Fix by give it some padding
.fix-top-position {
  margin-top: 4px;
}
</style>
