import { type ConnectResult, getConnectResult } from '@/connect';
import { useStoreAuth } from '@/store/store-auth';
import { type AnyMessage, type ServiceType } from '@bufbuild/protobuf';
import {
  type Interceptor,
  type PromiseClient,
  StreamRequest,
  UnaryRequest,
  type UnaryResponse,
  createPromiseClient as createNativePromiseClient,
} from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

type ReplaceReturnType<T extends (...a: never[]) => unknown, TNewReturn> = (
  ...a: Parameters<T>
) => TNewReturn;

type InterceptedPromiseClient<Service extends ServiceType> = {
  [M in keyof PromiseClient<Service>]: ReplaceReturnType<
    PromiseClient<Service>[M],
    Promise<ConnectResult<Awaited<ReturnType<PromiseClient<Service>[M]>>>>
  >;
};

let accessToken: null | string = null;
export function setConnectAccessToken(token: null | string): void {
  accessToken = token;
}

function setAuthorizationToken(
  req: UnaryRequest<AnyMessage, AnyMessage> | StreamRequest<AnyMessage, AnyMessage>,
  token: null | string
) {
  if (token !== null) req.header.set('Authorization', `Bearer ${token}`);
}

const addAuthorizationHeader: Interceptor = (next) => async (req) => {
  setAuthorizationToken(req, accessToken);
  return next(req);
};

const transformData: Interceptor = (next) => async (req) => {
  const storeAuth = useStoreAuth();

  // will be updated
  let result = undefined as unknown as UnaryResponse;

  function getTransformedData(): Promise<AnyMessage> {
    return getConnectResult(async () => {
      result = (await next(req)) as UnaryResponse;
      return result.message;
    }) as unknown as Promise<AnyMessage>;
  }

  let transformedData = await getTransformedData();
  if (
    !transformedData.success &&
    ['rest.MissingAuthToken', 'rest.InvalidAuthToken'].includes(transformedData.error)
  ) {
    // get new token
    accessToken = await storeAuth.refreshAuthTokens();
    setAuthorizationToken(req, accessToken);
    // use new token inside
    transformedData = await getTransformedData();
  }

  return {
    ...result,
    message: transformedData,
  };
};

const transport = createConnectTransport({
  baseUrl: '',
  interceptors: [addAuthorizationHeader, transformData],
});

export function createPromiseClient<T extends ServiceType>(
  service: T
): InterceptedPromiseClient<T> {
  return createNativePromiseClient(service, transport) as unknown as InterceptedPromiseClient<T>;
}
