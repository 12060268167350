import Decimal from 'decimal.js';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';

export type RawSecurity = Omit<Raw<Security>, 'hasCorporateActions'>;

export type CorporateActions = Record<string, true>;

export class Security {
  public id: string;
  public cusip: string;
  public ticker: string;
  public name: string;
  public lastClosePrice: Decimal;
  public cannotTradeMessage: string | null;
  public hasCorporateActions?: boolean;

  protected constructor(data: RawSecurity, corporateActions: CorporateActions) {
    this.id = data.id;
    this.cusip = data.cusip;
    this.ticker = data.ticker;
    this.name = data.name;
    this.lastClosePrice = new Decimal(data.lastClosePrice);
    this.cannotTradeMessage = data.cannotTradeMessage;
    this.hasCorporateActions = Object.keys(corporateActions).includes(this.cusip);
  }

  public static fromData(data: RawSecurity): Security;
  public static fromData(data: RawSecurity | null): Security | null;
  public static fromData(data: RawSecurity | undefined): Security | undefined;
  public static fromData(data: RawSecurity): null | undefined | Security {
    if (data === null) return null;
    if (data === undefined) return undefined;

    return Security.fromDataAndCorporateActions(data, undefined);
  }

  public static fromDataAndCorporateActions(
    data: RawSecurity,
    corporateActions?: CorporateActions
  ): Security;
  public static fromDataAndCorporateActions(
    data: RawSecurity | null,
    corporateActions?: CorporateActions
  ): Security | null;
  public static fromDataAndCorporateActions(
    data: RawSecurity | undefined,
    corporateActions?: CorporateActions
  ): Security | undefined;
  public static fromDataAndCorporateActions(
    data: RawSecurity,
    corporateActions: CorporateActions = {}
  ): null | undefined | Security {
    if (data === null) return null;
    if (data === undefined) return undefined;

    return new Security(data, corporateActions);
  }

  public static toData(model: Security): RawSecurity {
    return {
      id: model.id,
      cusip: model.cusip,
      ticker: model.ticker,
      name: model.name,
      lastClosePrice: model.lastClosePrice.toString(),
      cannotTradeMessage: model.cannotTradeMessage,
    };
  }

  public static mock(data?: null | DeepPartial<RawSecurity>): Security {
    return Security.fromData(Security.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawSecurity>): RawSecurity {
    return {
      id: '1',
      cusip: '123456789',
      ticker: 'IBM',
      name: 'IBM',
      lastClosePrice: '120.0',
      cannotTradeMessage: null,
      ...data,
    };
  }
}
