<template>
  <v-card>
    <v-card-title>
      <span class="headline">Reports</span>
    </v-card-title>
    <v-card-text>
      <v-divider class="mt-n2" color="primary" />
      <v-divider class="mt-0 mb-4" color="primary" />
      <div class="reports">
        <slot />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class Reports extends Vue {}
</script>

<style lang="scss">
.reports {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 1200px) {
  .reports {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1700px) {
  .reports {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 2100px) {
  .reports {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
