import VueI18n, { Values } from 'vue-i18n';
// see https://messageformat.github.io/messageformat for details
import MessageFormat from 'messageformat';
import Vue from 'vue';

Vue.use(VueI18n);

const languages = ['en'];

// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat for options
const numberFormats: VueI18n.NumberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      // currency: undefined, // should always be set per usage and never should we rely on a default!!
      minimumFractionDigits: 2,
      currencyDisplay: 'symbol',
    },
  },
};
const dateTimeFormats: VueI18n.DateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

function loadLocaleMessages(): VueI18n.LocaleMessages {
  const locales = import.meta.glob<true, string, string>('./locales/*.json', {
    eager: true,
    query: '?raw',
    import: 'default',
  });

  const messages: VueI18n.LocaleMessages = {};
  languages.forEach((locale) => {
    messages[locale] = JSON.parse(
      locales[`./locales/${locale}.json`]
    ) as VueI18n.LocaleMessages[keyof VueI18n.LocaleMessages];
  });
  return messages;
}

class CustomFormatter implements VueI18n.Formatter {
  private readonly locale: string;
  private formatter: MessageFormat;
  private readonly caches: Record<string, (...args) => string>;

  public constructor(locale: string) {
    this.locale = locale;
    this.formatter = new MessageFormat(this.locale);
    this.caches = Object.create(null);
  }

  public interpolate(message: string, values: Values | undefined, _path: string): string[] | null {
    let fn = this.caches[message];
    if (!fn) {
      fn = this.formatter.compile(message, this.locale);
      this.caches[message] = fn;
    }
    return [fn(values)];
  }
}

export default new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en',
  formatter: new CustomFormatter('en-US'),
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  numberFormats,
  dateTimeFormats,
});
