<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <simple-security-search
      :autofocus="shouldAutoFocus"
      class="mt"
      clearable
      :error-messages="localErrorMsg || errorMessages"
      label="add security"
      :value="security"
      @change="onSecurityChange($event)"
      @input="onSecurityChange($event)"
    />

    <div class="table-container mt-4">
      <div>
        <v-data-table
          v-if="securities.length"
          disable-filtering
          disable-pagination
          disable-sort
          fixed-header
          hide-default-footer
          item-key="key"
          :items="securities"
          width="100%"
        >
          <template #item="{ item }">
            <tr>
              <td>
                {{ item.ticker }}
              </td>
              <td>
                {{ item.cusip }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td class="text-right">
                <v-btn class="icon-action" icon @click="removeSecurity(item)">
                  <v-icon size="24">mdi-close-circle</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SimpleSecuritySearch from '@/modules/manual-loan/components/SimpleSecuritySearch.vue';
import { Security } from '@/modules/common/models';
import { PropType } from 'vue';

@Component({
  props: {
    securities: {
      type: Array as PropType<Security[]>,
      required: true,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SimpleSecuritySearch,
  },
})
export default class MultipleSecuritySelector extends Vue {
  protected readonly securities!: Security[];
  protected readonly errorMessages!: string[];
  protected readonly autofocus!: boolean;

  protected security: Security | null = null;
  protected localErrorMsg: string | null = null;

  protected get shouldAutoFocus(): boolean {
    return this.autofocus && this.securities.length === 0;
  }

  protected onSecurityChange(security: Security | null): void {
    this.localErrorMsg = null;

    if (security === null) {
      // user cleared the search field
      return;
    }

    this.security = security;

    if (this.security.cannotTradeMessage) {
      this.localErrorMsg = this.security.cannotTradeMessage;
      return;
    }

    if (this.securities.map((i) => i.cusip).includes(security?.cusip)) {
      this.localErrorMsg = 'security already in list.';
      return;
    }

    this.$emit('update:securities', [...this.securities, security]);
    // get model and validator ready for the next security
    this.$nextTick(() => {
      this.security = null;
    });
  }

  protected removeSecurity(security: Security): void {
    this.$emit(
      'update:securities',
      this.securities.filter((e) => e.id !== security.id)
    );
  }
}
</script>

<style lang="scss" scoped>
.icon-action {
  opacity: 0.4;
  transition: opacity linear 0.15s;
}

.icon-action:hover {
  opacity: 1;
}

.securities {
  list-style: none;
  padding: 0;
  margin: 0;
}

.securities li {
  padding: 5px;
  border-bottom: 1px solid #444;
}

.securities li + li:last-child {
  border-bottom: none;
}

.securities li:hover .v-icon {
  display: inline-block;
  cursor: pointer;
}

::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 40vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
