/**
 * Type safe wrapper for EventTarget.
 * @template T - A record of event names and their corresponding payload types.
 */
export class Mediator<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any> = Record<string, any>,
> {
  /**
   * An instance of EventTarget used to handle events.
   * @private
   * @readonly
   */
  private readonly eventTarget = new EventTarget();

  /**
   * A WeakMap to store the relationship between the handler functions and their corresponding callbacks.
   * @private
   * @readonly
   */
  private readonly callbacks = new WeakMap<
    (payload: T[string & keyof T]) => void,
    (customEvent: Event) => void
  >();

  /**
   * Dispatches an event with the given name and payload.
   * @param {E} name - The name of the event to dispatch.
   * @param {P} payload - The payload associated with the event.
   * @template E - The type of the event name, constrained to be a string and a key of T.
   * @template P - The type of the payload, derived from the corresponding handler in T.
   */
  public dispatch<E extends string & keyof T, P extends T[E]>(name: E, payload: P): void {
    this.eventTarget.dispatchEvent(new CustomEvent(name, { detail: payload }));
  }

  /**
   * Registers a handler function to be called whenever the specified event occurs.
   * @param {E} event - The name of the event to listen for.
   * @param {(payload: P) => void} handler - The function to call when the event occurs.
   * @template E - The type of the event name, constrained to be a string and a key of T.
   * @template P - The type of the payload, derived from the corresponding handler in T.
   */
  public readonly on = <E extends string & keyof T, P extends T[E]>(
    event: E,
    handler: (payload: P) => void
  ): void => {
    const callback = (customEvent: Event) => handler((customEvent as CustomEvent).detail);
    this.callbacks.set(handler, callback);
    this.eventTarget.addEventListener(event, callback);
  };

  /**
   * Unregisters a handler function from the specified event.
   * @param {E} event - The name of the event to stop listening for.
   * @param {(payload: P) => void} handler - The function to remove from the event listeners.
   * @template E - The type of the event name, constrained to be a string and a key of T.
   * @template P - The type of the payload, derived from the corresponding handler in T.
   */
  public readonly off = <E extends string & keyof T, P extends T[E]>(
    event: E,
    handler: (payload: P) => void
  ): void => {
    const callback = this.callbacks.get(handler);
    if (callback === undefined) return;
    this.eventTarget.removeEventListener(event, callback);
    this.callbacks.delete(handler);
  };
}
