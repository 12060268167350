//
// PublicKeyEncrypter wraps the interface SubtleCryptoLike
// At runtime, 'window.crypto.subtle' is injected but
// the test suite injects a mocked implementation
//
import { CryptoSubtle } from '@/modules/common/services/crypto/crypto-subtle';
import { WindowBase64 } from '@/modules/common/services/crypto/window-base64';

export class PublicKeyEncrypter {
  private readonly encoder: WindowBase64;
  private readonly subtle: CryptoSubtle;

  public constructor(encoder: WindowBase64, subtle: CryptoSubtle) {
    this.encoder = encoder;
    this.subtle = subtle;
  }

  private static ab2str(buf: ArrayBuffer): string {
    return String.fromCharCode.apply(null, new Uint8Array(buf) as unknown as number[]);
  }

  public async newKeyPair(): Promise<KeyPair> {
    return this.subtle
      .generateKey(
        {
          name: 'RSA-OAEP',
          modulusLength: 2048,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: 'SHA-256',
        },
        true,
        ['encrypt', 'decrypt']
      )
      .then((ckp) => {
        if (!ckp.publicKey || !ckp.privateKey) {
          return Promise.reject('No pubkey/privkey');
        }
        return Promise.all([
          this.subtle.exportKey('spki', ckp.publicKey).then((k) => {
            const exportedAsString = PublicKeyEncrypter.ab2str(k);
            return this.encoder.btoa(exportedAsString);
          }),
          this.subtle.exportKey('pkcs8', ckp.privateKey).then((k) => {
            const exportedAsString = PublicKeyEncrypter.ab2str(k);
            return this.encoder.btoa(exportedAsString);
          }),
        ]).then((keys) => {
          return { publicKey: keys[0], privateKey: keys[1] };
        });
      });
  }

  /**
   * @param msg plaintext
   * @param key base64
   * @return base64 ciphertext
   */
  public async encrypt(msg: string, key: string): Promise<string> {
    if (key.length < 1) {
      throw new Error('key missing');
    }
    // import the key before using it to encrypt the message
    return this.subtle
      .importKey(
        'spki',
        Buffer.from(key, 'base64'),
        {
          name: 'RSA-OAEP',
          hash: 'SHA-256',
        },
        true,
        ['encrypt']
      )
      .then((cryptoKey) => {
        // encrypt utf8 string to base64 string
        return this.subtle
          .encrypt(
            {
              name: 'RSA-OAEP',
              // do NOT remove the hash parameter!
              // hash is not part of the spec but Edge and Safari fail without it!
              hash: { name: 'SHA-256' },
            } as RsaOaepParams,
            cryptoKey,
            Buffer.from(msg, 'utf8')
          )
          .then((cipherText: ArrayBuffer) => {
            return Buffer.from(cipherText).toString('base64');
          });
      });
  }

  /**
   * @param cipherText base64
   * @param key base64
   * @return base64 plaintext
   */
  public async decrypt(cipherText: string, key: string): Promise<string> {
    if (key.length < 1) {
      throw new Error('key missing');
    }
    if (cipherText.length < 1) {
      throw new Error('ciphertext missing');
    }

    // atob() will raise an exception if the cipherText is not base64 encoded.
    // do NOT remove: decrypt will generate a buffer overflow or, worse,
    // never return while eating cpu cycles!
    this.encoder.atob(cipherText);

    // import the key before using it to decrypt the message
    return this.subtle
      .importKey(
        'pkcs8',
        Buffer.from(key, 'base64'),
        {
          name: 'RSA-OAEP',
          hash: 'SHA-256',
        },
        true,
        ['decrypt']
      )
      .then((cryptoKey) => {
        // decrypt base64 string to utf8 string
        return this.subtle
          .decrypt(
            {
              name: 'RSA-OAEP',
              // do NOT remove the hash parameter!
              // hash is not part of the spec but Edge and Safari fail without it!
              hash: { name: 'SHA-256' },
            } as RsaOaepParams,
            cryptoKey,
            Buffer.from(cipherText, 'base64')
          )
          .then((msg: ArrayBuffer) => {
            return Buffer.from(msg).toString('utf8');
          });
      });
  }
}

export interface KeyPair {
  publicKey: string;
  privateKey: string;
}
