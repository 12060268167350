<template>
  <confirm-dialog-wrapper
    :options="{
      message: 'You are about to cancel the order.',
      title: 'Order cancellation',
      rejectText: 'Keep order',
      acceptText: 'Cancel order',
    }"
    v-on="$listeners"
  >
    <template #default="{ confirm }">
      <!-- icon button variant -->
      <aurora-btn
        v-if="icon"
        class="icon-action"
        :disabled="isLoading || !hasTraderUserRole"
        icon
        :loading="isLoading"
        timeframe="createLoans"
        x-small
        @click="confirm(() => cancelOrder())"
      >
        <v-icon size="24">mdi-close-circle</v-icon>
      </aurora-btn>

      <!-- regular button variant -->
      <aurora-btn
        v-else
        color="red"
        :disabled="isLoading || !hasTraderUserRole"
        :loading="isLoading"
        timeframe="createLoans"
        @click="confirm(() => cancelOrder())"
      >
        Cancel
      </aurora-btn>
    </template>
  </confirm-dialog-wrapper>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';

@Component({
  props: {
    orderRef: String,
    icon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['clientConfig']), //@TODO: remove?
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class MarketplaceCancelBtn extends Vue {
  // props
  protected orderRef!: string;
  protected icon!: boolean;

  // store state
  protected clientConfig!: ClientConfig;

  protected isLoading = false;
  protected hasTraderUserRole!: boolean;

  private async cancelOrder(): Promise<void> {
    this.isLoading = true;

    try {
      await this.$api.marketplace.cancelOrder(this.orderRef);

      this.$emit('action', {
        orderRef: this.orderRef,
        message: 'Order has been successfully canceled',
      });
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.$emit('action', {
        error: true,
        orderRef: this.orderRef,
        message: `Operation failed: ${errorMessage}`,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped></style>
