import { UUID_EMPTY } from '@/constants';
import { Raw } from '@/modules/common/helpers/api';
import { RawUser, User } from '@/modules/user-accounts/models';
import { AccountStatus } from '@/modules/user-accounts/types/user-accounts';
import { TradingPermission } from '@/utils/helpers/trading-permissions';
import Decimal from 'decimal.js';

export type RawUserRequest = Raw<
  UserRequest,
  {
    // always specify existing raw entry types explititly
    id?: string;
    companyName?: string;
    companyTradingPermissions?: TradingPermission;
    accountStatus?: AccountStatus;
    rolesPendingApproval?: string[];
    defaultOrderSoftLimit?: string | null;
    defaultOrderHardLimit?: string | null;
  }
>;

export class UserRequest {
  public name: string;
  public emailAddress: string;
  public roles: string[];
  public tradingPermissions: TradingPermission | null;
  public orderSoftLimit: Decimal | null;
  public orderHardLimit: Decimal | null;
  public companyID: string;
  public resetPassword: boolean;

  // client bloat
  public id: string;
  public companyName: string;
  public companyTradingPermissions: TradingPermission;
  public accountStatus: AccountStatus;
  public rolesPendingApproval: string[];
  public defaultOrderSoftLimit: Decimal | null;
  public defaultOrderHardLimit: Decimal | null;

  protected constructor(data: RawUserRequest) {
    this.name = data.name;
    this.emailAddress = data.emailAddress;
    this.roles = data.roles;
    this.tradingPermissions = data.tradingPermissions;
    this.orderSoftLimit = data.orderSoftLimit === null ? null : new Decimal(data.orderSoftLimit);
    this.orderHardLimit = data.orderHardLimit === null ? null : new Decimal(data.orderHardLimit);
    this.companyID = data.companyID;
    this.resetPassword = data.resetPassword;

    this.id = data.id ?? UUID_EMPTY;
    this.companyName = data.companyName ?? '';
    this.companyTradingPermissions =
      data.companyTradingPermissions ?? TradingPermission.DisabledPermissions;
    this.accountStatus = data.accountStatus ?? 'active';
    this.rolesPendingApproval = data.rolesPendingApproval ?? [];
    this.defaultOrderSoftLimit =
      (data.defaultOrderSoftLimit ?? null) === null
        ? null
        : new Decimal(data.defaultOrderSoftLimit as string);
    this.defaultOrderHardLimit =
      (data.defaultOrderHardLimit ?? null) === null
        ? null
        : new Decimal(data.defaultOrderHardLimit as string);
  }

  public static create(): UserRequest {
    return UserRequest.fromData(UserRequest.createData());
  }

  public static createData(): RawUserRequest {
    return {
      name: '',
      emailAddress: '',
      roles: [] as string[],
      tradingPermissions: null, // inherit from company
      orderSoftLimit: null,
      orderHardLimit: null,
      companyID: UUID_EMPTY,
      resetPassword: false,
    };
  }

  public static clone(model: UserRequest): UserRequest {
    return UserRequest.fromData(UserRequest.toData(model));
  }

  public static fromData(data: RawUserRequest): UserRequest {
    return new UserRequest(data);
  }

  public static fromUser(user: User): UserRequest {
    return UserRequest.fromData({
      ...User.toData(user),
      resetPassword: false,
    });
  }

  public static toData(model: UserRequest): RawUserRequest {
    return {
      name: model.name,
      emailAddress: model.emailAddress,
      roles: model.roles,
      tradingPermissions: model.tradingPermissions,
      orderSoftLimit: model.orderSoftLimit?.toString() ?? null,
      orderHardLimit: model.orderHardLimit?.toString() ?? null,
      companyID: model.companyID,
      resetPassword: model.resetPassword,

      id: model.id,
      companyName: model.companyName,
      companyTradingPermissions: model.companyTradingPermissions,
      accountStatus: model.accountStatus,
      rolesPendingApproval: model.rolesPendingApproval,
      defaultOrderSoftLimit: model.defaultOrderSoftLimit?.toString() ?? null,
      defaultOrderHardLimit: model.defaultOrderHardLimit?.toString() ?? null,
    };
  }
}

export type RawUserResponse = Raw<
  UserResponse,
  {
    // always specify existing raw entry types explititly
    user: RawUser;
  }
>;

export class UserResponse {
  public status: string;
  public user: User;
  public approvalRequired: boolean;

  protected constructor(data: RawUserResponse) {
    this.status = data.status;
    this.user = User.fromData(data.user);
    this.approvalRequired = data.approvalRequired;
  }

  public static fromData(data: RawUserResponse): UserResponse {
    return new UserResponse(data);
  }
}
