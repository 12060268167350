<template>
  <trading-analytics />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import TradingAnalytics from '@/modules/analytics/components/TradingAnalytics.vue';

@Component({
  components: {
    TradingAnalytics,
  },
})
export default class TradingAnalyticsView extends Vue {}
</script>

<style lang="scss" scoped></style>
