<template>
  <ag-table-client
    :column-defs="columnDefs"
    data-test="mass-modify-loans-edit-table"
    :get-row-id="(item) => item.id"
    no-menu
    no-move
    no-sort
    :row-data="items"
    :sort="sort"
    @ready="
      $emit('update:options');
      $emit('ready');
    "
    @sort-changed="$emit('update:options')"
  />
</template>

<script lang="ts">
import { NewQuantities } from '@/modules/open-loans/helpers/multipleUpdates';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { OpenLoanItem } from '@/modules/common/models';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { ColDef, SortModelItem } from 'ag-grid-enterprise';
import { cols } from '@/modules/common/components/ag-table/columns/mass-modify-loans';
import { AgTableClient } from '@/modules/common/components/ag-table';

@Component({
  components: { AgTableClient },
  props: {
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array as PropType<OpenLoanItem[]>,
      required: true,
    },
    action: {
      type: String as PropType<MultipleLoanAction>,
      required: true,
    },
    groupingType: {
      type: String as PropType<'counterparty' | 'security' | null>,
      default: null,
    },
    newQuantities: {
      type: Object as PropType<NewQuantities>,
      required: true,
      default: () => ({}),
    },
    sortBy: {
      type: String,
      default: 'rate',
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
  },
})
export default class MassModifyLoansEditTable extends mixins(validationMixin) {
  protected readonly isReadOnly!: boolean;
  protected readonly items!: OpenLoanItem[];
  protected readonly action!: MultipleLoanAction;
  protected readonly groupingType!: 'counterparty' | 'security';
  protected readonly newQuantities!: NewQuantities;
  protected readonly sortBy!: string;
  protected readonly sortDesc!: boolean;

  protected get sort(): SortModelItem {
    return {
      colId: this.sortBy,
      sort: this.sortDesc ? 'desc' : 'asc',
    };
  }

  protected get columns(): ReturnType<typeof cols> {
    return cols({ getIsReadOnly: () => this.isReadOnly });
  }

  protected get columnDefs(): Array<ColDef<OpenLoanItem>> {
    const columns = this.columns;
    return [
      ...(this.groupingType === 'security' ? [] : [columns.ticker(), columns.cusip()]),
      ...(this.groupingType === 'counterparty' ? [] : [columns.counterparty()]),
      columns.rate(),
      columns.toUpdate({
        action: this.action,
        getNewQuantities: () => this.newQuantities,
        clickSingleQuantity: () => this.$emit('click-single-quantity'),
        updateSingleQuantity: (loan, quantity) =>
          this.$emit('update-single-quantity', { loan, quantity }),
      }),
      ...(this.action === 'recall' ? [columns.openQuantityToRecall()] : []),
      ...(this.action === 'return' ? [columns.openQuantityToReturn()] : []),
      columns.openQuantity(),
      columns.settlementValue(),
    ];
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  div:has(> .single-loan-input) {
    .single-loan-input {
      max-width: 150px;
      scale: 0.7;
      margin: 4px -20px 0;
      .v-input__slot {
        min-height: unset !important;
      }

      &:focus-visible {
        outline: none;

        .v-input__slot {
          outline: 1px solid var(--ag-range-selection-border-color);
        }
      }
    }

    button {
      margin-right: 2px;

      &:focus-visible {
        opacity: 1;
      }
    }
  }

  // so the "click" event propagates to the parent when <numeric-input> is disabled
  input[disabled] {
    pointer-events: none;
  }

  .max-btn {
    margin-top: 10px;
    margin-right: -16px;
  }

  .fade-max-icon {
    opacity: 0.2;
    transition: 0.0125s;
  }

  .fade-max-icon:hover {
    opacity: 1;
  }

  .ag-row,
  .summary {
    /* Tone down grey to increase contrast between bold and non-bold text */
    color: #888;

    strong,
    &:has(.single-loan-input > [data-has-quantity]) {
      color: white;
    }
  }

  .theme--light .ag-row,
  .theme--light .summary {
    color: #777;

    strong,
    &:has(.single-loan-input > [data-has-quantity]) {
      color: black;
    }
  }

  .v-text-field__details {
    display: none;
  }
}
</style>
