// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/oms/oms.proto (package aurora.core.apiengine.omsv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Side } from "../../../../consts/commonconsts_pb.js";
import { OmsOrderStatus, OmsOrderType, OmsTimeInForceType, QuerySortableField, RoutingStatus } from "../../../../consts/omsconsts_pb.js";
import { SettlementType } from "../../../../consts/settlementconsts_pb.js";
import { LoanStatus, RoundingRule } from "../../../../consts/loanconsts_pb.js";
import { PbCompanyInfo, PbInstrument } from "../../../../common/protos/types_pb.js";
import { SortOrder } from "../../../../consts/queryconsts_pb.js";

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrderRequest
 */
export class CreateOrderRequest extends Message<CreateOrderRequest> {
  /**
   * Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 1;
   */
  clientOrderRef = "";

  /**
   * Cusip or Ticker of instrument
   *
   * @generated from field: string instrument = 2;
   */
  instrument = "";

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 3;
   */
  side = Side.BORROWER;

  /**
   * Type of order (limit or market)
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderType order_type = 4;
   */
  orderType = OmsOrderType.UNSPECIFIED_ORDER_TYPE;

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 5;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution - optional, defaults to 100
   *
   * @generated from field: optional int64 min_quantity = 6;
   */
  minQuantity?: bigint;

  /**
   * Type of settlement this order is related to
   *
   * @generated from field: optional aurora.core.consts.settlement.SettlementType settlement_type = 7;
   */
  settlementType?: SettlementType;

  /**
   * Time In Force type (e.g. day, gtc, fok, ioc) - optional
   *
   * @generated from field: optional aurora.core.consts.oms.OmsTimeInForceType time_in_force_type = 8;
   */
  timeInForceType?: OmsTimeInForceType;

  /**
   * Loan rate - optional
   *
   * @generated from field: optional string rate = 9;
   */
  rate?: string;

  /**
   * Independent Amount rate - optional
   *
   * @generated from field: optional string independent_amount_rate = 10;
   */
  independentAmountRate?: string;

  /**
   * Rounding rule - optional
   *
   * @generated from field: optional aurora.core.consts.RoundingRule rounding_rule = 11;
   */
  roundingRule?: RoundingRule;

  /**
   * Counterparty NSCC Account ID - optional
   *
   * @generated from field: repeated string counterparty_nscc_ids = 12;
   */
  counterpartyNsccIds: string[] = [];

  /**
   * Staged order (default: false) - optional
   *
   * @generated from field: optional bool is_staged = 13;
   */
  isStaged?: boolean;

  /**
   * Anonymous order (default: false) - optional
   *
   * @generated from field: optional bool anonymous = 14;
   */
  anonymous?: boolean;

  constructor(data?: PartialMessage<CreateOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 4, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType) },
    { no: 5, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType), opt: true },
    { no: 8, name: "time_in_force_type", kind: "enum", T: proto3.getEnumType(OmsTimeInForceType), opt: true },
    { no: 9, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule), opt: true },
    { no: 12, name: "counterparty_nscc_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "is_staged", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 14, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderRequest {
    return new CreateOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined, b: CreateOrderRequest | PlainMessage<CreateOrderRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrderRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrdersRequest
 */
export class CreateOrdersRequest extends Message<CreateOrdersRequest> {
  /**
   * List of order details to create in batch
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CreateOrderRequest requests = 1;
   */
  requests: CreateOrderRequest[] = [];

  constructor(data?: PartialMessage<CreateOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: CreateOrderRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrdersRequest {
    return new CreateOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrdersRequest {
    return new CreateOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrdersRequest {
    return new CreateOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrdersRequest | PlainMessage<CreateOrdersRequest> | undefined, b: CreateOrdersRequest | PlainMessage<CreateOrdersRequest> | undefined): boolean {
    return proto3.util.equals(CreateOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrderResponse
 */
export class CreateOrderResponse extends Message<CreateOrderResponse> {
  /**
   * Unique identifier of the created order
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  constructor(data?: PartialMessage<CreateOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderResponse {
    return new CreateOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderResponse | PlainMessage<CreateOrderResponse> | undefined, b: CreateOrderResponse | PlainMessage<CreateOrderResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrderResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrdersResponse
 */
export class CreateOrdersResponse extends Message<CreateOrdersResponse> {
  /**
   * List of unique identifiers for the created orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CreateOrderResponse responses = 1;
   */
  responses: CreateOrderResponse[] = [];

  /**
   * @generated from field: repeated aurora.core.apiengine.omsv1.CreateOrderError errors = 2;
   */
  errors: CreateOrderError[] = [];

  constructor(data?: PartialMessage<CreateOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: CreateOrderResponse, repeated: true },
    { no: 2, name: "errors", kind: "message", T: CreateOrderError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrdersResponse {
    return new CreateOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrdersResponse {
    return new CreateOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrdersResponse {
    return new CreateOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrdersResponse | PlainMessage<CreateOrdersResponse> | undefined, b: CreateOrdersResponse | PlainMessage<CreateOrdersResponse> | undefined): boolean {
    return proto3.util.equals(CreateOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreateOrderError
 */
export class CreateOrderError extends Message<CreateOrderError> {
  /**
   * Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 1;
   */
  clientOrderRef = "";

  /**
   * Error when attempting to create the order
   *
   * @generated from field: string error_message = 2;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<CreateOrderError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreateOrderError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrderError {
    return new CreateOrderError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrderError {
    return new CreateOrderError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOrderError {
    return new CreateOrderError().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOrderError | PlainMessage<CreateOrderError> | undefined, b: CreateOrderError | PlainMessage<CreateOrderError> | undefined): boolean {
    return proto3.util.equals(CreateOrderError, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrderRequest
 */
export class ModifyOrderRequest extends Message<ModifyOrderRequest> {
  /**
   * Unique identifier of the order to be modified
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string old_client_order_ref = 2;
   */
  oldClientOrderRef = "";

  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 4;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution - optional, defaults to 100
   *
   * @generated from field: optional int64 min_quantity = 5;
   */
  minQuantity?: bigint;

  /**
   * Loan rate - optional for 'market' orders
   *
   * @generated from field: optional string rate = 6;
   */
  rate?: string;

  /**
   * Counterparty NSCC Account ID - optional
   *
   * @generated from field: repeated string counterparty_nscc_ids = 7;
   */
  counterpartyNsccIds: string[] = [];

  /**
   * Staged order (default: false) - optional
   *
   * @generated from field: optional bool is_staged = 8;
   */
  isStaged?: boolean;

  constructor(data?: PartialMessage<ModifyOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "old_client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "counterparty_nscc_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "is_staged", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrderRequest {
    return new ModifyOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrderRequest {
    return new ModifyOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrderRequest {
    return new ModifyOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrderRequest | PlainMessage<ModifyOrderRequest> | undefined, b: ModifyOrderRequest | PlainMessage<ModifyOrderRequest> | undefined): boolean {
    return proto3.util.equals(ModifyOrderRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrdersRequest
 */
export class ModifyOrdersRequest extends Message<ModifyOrdersRequest> {
  /**
   * List of orders to modify
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.ModifyOrderRequest requests = 1;
   */
  requests: ModifyOrderRequest[] = [];

  constructor(data?: PartialMessage<ModifyOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: ModifyOrderRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrdersRequest {
    return new ModifyOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrdersRequest {
    return new ModifyOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrdersRequest {
    return new ModifyOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrdersRequest | PlainMessage<ModifyOrdersRequest> | undefined, b: ModifyOrdersRequest | PlainMessage<ModifyOrdersRequest> | undefined): boolean {
    return proto3.util.equals(ModifyOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrderResponse
 */
export class ModifyOrderResponse extends Message<ModifyOrderResponse> {
  /**
   * Unique identifier of the modified order
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  constructor(data?: PartialMessage<ModifyOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrderResponse {
    return new ModifyOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrderResponse {
    return new ModifyOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrderResponse {
    return new ModifyOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrderResponse | PlainMessage<ModifyOrderResponse> | undefined, b: ModifyOrderResponse | PlainMessage<ModifyOrderResponse> | undefined): boolean {
    return proto3.util.equals(ModifyOrderResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrdersResponse
 */
export class ModifyOrdersResponse extends Message<ModifyOrdersResponse> {
  /**
   * List of unique identifiers for the created orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.ModifyOrderResponse responses = 1;
   */
  responses: ModifyOrderResponse[] = [];

  /**
   * @generated from field: repeated aurora.core.apiengine.omsv1.ModifyOrderError errors = 2;
   */
  errors: ModifyOrderError[] = [];

  constructor(data?: PartialMessage<ModifyOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: ModifyOrderResponse, repeated: true },
    { no: 2, name: "errors", kind: "message", T: ModifyOrderError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrdersResponse {
    return new ModifyOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrdersResponse {
    return new ModifyOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrdersResponse {
    return new ModifyOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrdersResponse | PlainMessage<ModifyOrdersResponse> | undefined, b: ModifyOrdersResponse | PlainMessage<ModifyOrdersResponse> | undefined): boolean {
    return proto3.util.equals(ModifyOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifyOrderError
 */
export class ModifyOrderError extends Message<ModifyOrderError> {
  /**
   * Unique identifier of the order to be modified
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Unique identifier of the modified order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  /**
   * Error when attempting to modify the order
   *
   * @generated from field: string error_message = 3;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<ModifyOrderError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifyOrderError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyOrderError {
    return new ModifyOrderError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyOrderError {
    return new ModifyOrderError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyOrderError {
    return new ModifyOrderError().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyOrderError | PlainMessage<ModifyOrderError> | undefined, b: ModifyOrderError | PlainMessage<ModifyOrderError> | undefined): boolean {
    return proto3.util.equals(ModifyOrderError, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrderRequest
 */
export class CancelOrderRequest extends Message<CancelOrderRequest> {
  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the order to be canceled - optional
   *
   * @generated from field: optional string order_ref = 2;
   */
  orderRef?: string;

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Cusip or Ticker of instrument
   *
   * @generated from field: string instrument = 4;
   */
  instrument = "";

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  constructor(data?: PartialMessage<CancelOrderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrderRequest {
    return new CancelOrderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrderRequest | PlainMessage<CancelOrderRequest> | undefined, b: CancelOrderRequest | PlainMessage<CancelOrderRequest> | undefined): boolean {
    return proto3.util.equals(CancelOrderRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrdersRequest
 */
export class CancelOrdersRequest extends Message<CancelOrdersRequest> {
  /**
   * List of orders to cancel
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CancelOrderRequest requests = 1;
   */
  requests: CancelOrderRequest[] = [];

  constructor(data?: PartialMessage<CancelOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: CancelOrderRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrdersRequest {
    return new CancelOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrdersRequest {
    return new CancelOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrdersRequest {
    return new CancelOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrdersRequest | PlainMessage<CancelOrdersRequest> | undefined, b: CancelOrdersRequest | PlainMessage<CancelOrdersRequest> | undefined): boolean {
    return proto3.util.equals(CancelOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrderResponse
 */
export class CancelOrderResponse extends Message<CancelOrderResponse> {
  /**
   * Unique identifier of the canceled order
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  /**
   * Status of the order in Aurora
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderStatus order_status = 3;
   */
  orderStatus = OmsOrderStatus.UNSPECIFIED_ORDER_STATUS;

  constructor(data?: PartialMessage<CancelOrderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrderResponse {
    return new CancelOrderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrderResponse {
    return new CancelOrderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrderResponse {
    return new CancelOrderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrderResponse | PlainMessage<CancelOrderResponse> | undefined, b: CancelOrderResponse | PlainMessage<CancelOrderResponse> | undefined): boolean {
    return proto3.util.equals(CancelOrderResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrdersResponse
 */
export class CancelOrdersResponse extends Message<CancelOrdersResponse> {
  /**
   * Response to each of the canceled orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.CancelOrderResponse responses = 1;
   */
  responses: CancelOrderResponse[] = [];

  /**
   * @generated from field: repeated aurora.core.apiengine.omsv1.CancelOrderError errors = 2;
   */
  errors: CancelOrderError[] = [];

  constructor(data?: PartialMessage<CancelOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: CancelOrderResponse, repeated: true },
    { no: 2, name: "errors", kind: "message", T: CancelOrderError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrdersResponse {
    return new CancelOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrdersResponse {
    return new CancelOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrdersResponse {
    return new CancelOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrdersResponse | PlainMessage<CancelOrdersResponse> | undefined, b: CancelOrdersResponse | PlainMessage<CancelOrdersResponse> | undefined): boolean {
    return proto3.util.equals(CancelOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CancelOrderError
 */
export class CancelOrderError extends Message<CancelOrderError> {
  /**
   * Unique identifier of the order to be canceled - optional
   *
   * @generated from field: string order_ref = 1;
   */
  orderRef = "";

  /**
   * Unique identifier of the canceled order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 2;
   */
  clientOrderRef = "";

  /**
   * Error when attempting to cancel the order
   *
   * @generated from field: string error_message = 3;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<CancelOrderError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CancelOrderError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelOrderError {
    return new CancelOrderError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelOrderError {
    return new CancelOrderError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelOrderError {
    return new CancelOrderError().fromJsonString(jsonString, options);
  }

  static equals(a: CancelOrderError | PlainMessage<CancelOrderError> | undefined, b: CancelOrderError | PlainMessage<CancelOrderError> | undefined): boolean {
    return proto3.util.equals(CancelOrderError, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.OrderDetails
 */
export class OrderDetails extends Message<OrderDetails> {
  /**
   * Unique identifier of the order to get
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Cusip or Ticker of instrument
   *
   * @generated from field: aurora.core.common.protos.PbInstrument instrument = 4;
   */
  instrument?: PbInstrument;

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  /**
   * Status of the order in Aurora
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderStatus order_status = 6;
   */
  orderStatus = OmsOrderStatus.UNSPECIFIED_ORDER_STATUS;

  /**
   * Loan settlement type
   *
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 7;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * Type of order (limit, market or IOI)
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderType order_type = 8;
   */
  orderType = OmsOrderType.UNSPECIFIED_ORDER_TYPE;

  /**
   * Time In Force type (e.g. day, gtc, fok, ioc)
   *
   * @generated from field: aurora.core.consts.oms.OmsTimeInForceType time_in_force_type = 9;
   */
  timeInForceType = OmsTimeInForceType.UNSPECIFIED_TIME_IN_FORCE;

  /**
   * Staged order
   *
   * @generated from field: bool is_staged = 10;
   */
  isStaged = false;

  /**
   * Routing of the order
   *
   * @generated from field: aurora.core.consts.oms.RoutingStatus routing_status = 11;
   */
  routingStatus = RoutingStatus.UNSPECIFIED_ROUTING_STATUS;

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 12;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution
   *
   * @generated from field: int64 min_quantity = 13;
   */
  minQuantity = protoInt64.zero;

  /**
   * Independent Amount rate
   *
   * @generated from field: string independent_amount_rate = 14;
   */
  independentAmountRate = "";

  /**
   * Rounding rule
   *
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 15;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * Anonymous order
   *
   * @generated from field: bool anonymous = 16;
   */
  anonymous = false;

  /**
   * Requested rate
   *
   * @generated from field: string rate = 17;
   */
  rate = "";

  /**
   * Eligible Counterparties
   *
   * @generated from field: repeated string counterparty_nscc_ids = 18;
   */
  counterpartyNsccIds: string[] = [];

  /**
   * @generated from field: repeated aurora.core.common.protos.PbCompanyInfo counterparties = 19;
   */
  counterparties: PbCompanyInfo[] = [];

  /**
   * Total quantity still available for matching
   *
   * @generated from field: int64 open_quantity = 20;
   */
  openQuantity = protoInt64.zero;

  /**
   * Total executed quantity so far
   *
   * @generated from field: int64 total_exec_qty = 21;
   */
  totalExecQty = protoInt64.zero;

  /**
   * Average rate of executed quantity so far
   *
   * @generated from field: string avg_exec_rate = 22;
   */
  avgExecRate = "";

  /**
   * Order created at
   *
   * @generated from field: google.protobuf.Timestamp created_at = 23;
   */
  createdAt?: Timestamp;

  /**
   * Order was last changed at
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 24;
   */
  updatedAt?: Timestamp;

  /**
   * Owner of the order
   *
   * @generated from field: aurora.core.common.protos.PbCompanyInfo company_info = 25;
   */
  companyInfo?: PbCompanyInfo;

  constructor(data?: PartialMessage<OrderDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.OrderDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "instrument", kind: "message", T: PbInstrument },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 6, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus) },
    { no: 7, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 8, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType) },
    { no: 9, name: "time_in_force_type", kind: "enum", T: proto3.getEnumType(OmsTimeInForceType) },
    { no: 10, name: "is_staged", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "routing_status", kind: "enum", T: proto3.getEnumType(RoutingStatus) },
    { no: 12, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 16, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "counterparty_nscc_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "counterparties", kind: "message", T: PbCompanyInfo, repeated: true },
    { no: 20, name: "open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 21, name: "total_exec_qty", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 22, name: "avg_exec_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "created_at", kind: "message", T: Timestamp },
    { no: 24, name: "updated_at", kind: "message", T: Timestamp },
    { no: 25, name: "company_info", kind: "message", T: PbCompanyInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrderDetails {
    return new OrderDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrderDetails {
    return new OrderDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrderDetails {
    return new OrderDetails().fromJsonString(jsonString, options);
  }

  static equals(a: OrderDetails | PlainMessage<OrderDetails> | undefined, b: OrderDetails | PlainMessage<OrderDetails> | undefined): boolean {
    return proto3.util.equals(OrderDetails, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.GetOrdersRequest
 */
export class GetOrdersRequest extends Message<GetOrdersRequest> {
  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the order to get - optional
   *
   * @generated from field: optional string order_id = 2;
   */
  orderId?: string;

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user - optional
   *
   * @generated from field: optional string client_order_id = 3;
   */
  clientOrderId?: string;

  /**
   * Cusip or Ticker of instrument - optional
   *
   * @generated from field: optional string instrument = 4;
   */
  instrument?: string;

  /**
   * Side of order (lender or borrower) - optional
   *
   * @generated from field: optional aurora.core.consts.common.Side side = 5;
   */
  side?: Side;

  constructor(data?: PartialMessage<GetOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.GetOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "instrument", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined, b: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined): boolean {
    return proto3.util.equals(GetOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.GetOrdersResponse
 */
export class GetOrdersResponse extends Message<GetOrdersResponse> {
  /**
   * New Unique order identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Detail(s) of one or more orders returned by Aurora
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.OrderDetails orders = 2;
   */
  orders: OrderDetails[] = [];

  /**
   * Number of orders returned by Aurora
   *
   * @generated from field: int32 total_count = 3;
   */
  totalCount = 0;

  constructor(data?: PartialMessage<GetOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.GetOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "orders", kind: "message", T: OrderDetails, repeated: true },
    { no: 3, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined, b: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersRequest
 */
export class QueryOrdersRequest extends Message<QueryOrdersRequest> {
  /**
   * New Unique identifier assigned by the user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Filter
   *
   * @generated from field: optional aurora.core.apiengine.omsv1.QueryOrdersFilter filter = 2;
   */
  filter?: QueryOrdersFilter;

  /**
   * List of sorting options
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.QueryOrdersSort sorts = 3;
   */
  sorts: QueryOrdersSort[] = [];

  /**
   * Page number, 1 being the starting one
   *
   * @generated from field: optional int32 page_number = 4;
   */
  pageNumber?: number;

  /**
   * Number of orders per page
   *
   * @generated from field: optional int32 page_limit = 5;
   */
  pageLimit?: number;

  constructor(data?: PartialMessage<QueryOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filter", kind: "message", T: QueryOrdersFilter, opt: true },
    { no: 3, name: "sorts", kind: "message", T: QueryOrdersSort, repeated: true },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "page_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersRequest {
    return new QueryOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersRequest {
    return new QueryOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersRequest {
    return new QueryOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersRequest | PlainMessage<QueryOrdersRequest> | undefined, b: QueryOrdersRequest | PlainMessage<QueryOrdersRequest> | undefined): boolean {
    return proto3.util.equals(QueryOrdersRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersFilter
 */
export class QueryOrdersFilter extends Message<QueryOrdersFilter> {
  /**
   * Include expired orders?
   *
   * @generated from field: optional bool show_all = 1;
   */
  showAll?: boolean;

  /**
   * Unique identifier of the order to get - optional
   *
   * @generated from field: optional string order_id = 2;
   */
  orderId?: string;

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user - optional
   *
   * @generated from field: optional string client_order_id = 3;
   */
  clientOrderId?: string;

  /**
   * Cusip or Ticker of instrument - optional
   *
   * @generated from field: repeated string instruments = 4;
   */
  instruments: string[] = [];

  /**
   * Side of the order (lender or borrower) - optional
   *
   * @generated from field: optional aurora.core.consts.common.Side side = 5;
   */
  side?: Side;

  /**
   * Status of the order in Aurora - optional
   *
   * @generated from field: optional aurora.core.consts.oms.OmsOrderStatus order_status = 6;
   */
  orderStatus?: OmsOrderStatus;

  /**
   * Routing status of the order in Aurora - option
   *
   * @generated from field: optional aurora.core.consts.oms.RoutingStatus routing_status = 7;
   */
  routingStatus?: RoutingStatus;

  /**
   * Only return orders that are (partial) executed - optional
   *
   * @generated from field: optional bool has_executed = 8;
   */
  hasExecuted?: boolean;

  /**
   * Owner of the order - optional for admins
   *
   * @generated from field: optional string company_id = 9;
   */
  companyId?: string;

  constructor(data?: PartialMessage<QueryOrdersFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "show_all", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "instruments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side), opt: true },
    { no: 6, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus), opt: true },
    { no: 7, name: "routing_status", kind: "enum", T: proto3.getEnumType(RoutingStatus), opt: true },
    { no: 8, name: "has_executed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersFilter {
    return new QueryOrdersFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersFilter {
    return new QueryOrdersFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersFilter {
    return new QueryOrdersFilter().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersFilter | PlainMessage<QueryOrdersFilter> | undefined, b: QueryOrdersFilter | PlainMessage<QueryOrdersFilter> | undefined): boolean {
    return proto3.util.equals(QueryOrdersFilter, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersSort
 */
export class QueryOrdersSort extends Message<QueryOrdersSort> {
  /**
   * Fields to sort on
   *
   * @generated from field: aurora.core.consts.oms.QuerySortableField field = 1;
   */
  field = QuerySortableField.UNSPECIFIED_QUERY_ORDERS_SORTABLE_FIELD;

  /**
   * Sorting order
   *
   * @generated from field: aurora.core.consts.query.SortOrder order = 2;
   */
  order = SortOrder.UNSPECIFIED_SORT_ORDER;

  constructor(data?: PartialMessage<QueryOrdersSort>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersSort";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(QuerySortableField) },
    { no: 2, name: "order", kind: "enum", T: proto3.getEnumType(SortOrder) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersSort {
    return new QueryOrdersSort().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersSort {
    return new QueryOrdersSort().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersSort {
    return new QueryOrdersSort().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersSort | PlainMessage<QueryOrdersSort> | undefined, b: QueryOrdersSort | PlainMessage<QueryOrdersSort> | undefined): boolean {
    return proto3.util.equals(QueryOrdersSort, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryOrdersResponse
 */
export class QueryOrdersResponse extends Message<QueryOrdersResponse> {
  /**
   * Unique request identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * companies and instruments referred to in the OrderDetails
   *
   * @generated from field: map<string, aurora.core.apiengine.omsv1.CompanyInfo> companies = 2;
   */
  companies: { [key: string]: CompanyInfo } = {};

  /**
   * @generated from field: map<string, aurora.core.apiengine.omsv1.Instrument> instruments = 3;
   */
  instruments: { [key: string]: Instrument } = {};

  /**
   * List of OMS orders
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.Order orders = 4;
   */
  orders: Order[] = [];

  /**
   * Total number of orders
   *
   * @generated from field: int32 total_count = 5;
   */
  totalCount = 0;

  constructor(data?: PartialMessage<QueryOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "companies", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: CompanyInfo} },
    { no: 3, name: "instruments", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Instrument} },
    { no: 4, name: "orders", kind: "message", T: Order, repeated: true },
    { no: 5, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryOrdersResponse {
    return new QueryOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryOrdersResponse {
    return new QueryOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryOrdersResponse {
    return new QueryOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryOrdersResponse | PlainMessage<QueryOrdersResponse> | undefined, b: QueryOrdersResponse | PlainMessage<QueryOrdersResponse> | undefined): boolean {
    return proto3.util.equals(QueryOrdersResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.Order
 */
export class Order extends Message<Order> {
  /**
   * Unique identifier of the order to get
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Last known Unique order identifier used to create or modify the order assigned by Aurora user
   *
   * @generated from field: string client_order_ref = 3;
   */
  clientOrderRef = "";

  /**
   * Identifier of the instrument
   *
   * @generated from field: string cusip = 4;
   */
  cusip = "";

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  /**
   * Status of the order in Aurora
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderStatus order_status = 6;
   */
  orderStatus = OmsOrderStatus.UNSPECIFIED_ORDER_STATUS;

  /**
   * Loan settlement type
   *
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 7;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * Type of order (limit, market or IOI)
   *
   * @generated from field: aurora.core.consts.oms.OmsOrderType order_type = 8;
   */
  orderType = OmsOrderType.UNSPECIFIED_ORDER_TYPE;

  /**
   * Time In Force type (e.g. day, gtc, fok, ioc)
   *
   * @generated from field: aurora.core.consts.oms.OmsTimeInForceType time_in_force_type = 9;
   */
  timeInForceType = OmsTimeInForceType.UNSPECIFIED_TIME_IN_FORCE;

  /**
   * Order is routed, unrouted or pending
   *
   * @generated from field: aurora.core.consts.oms.RoutingStatus routing_status = 10;
   */
  routingStatus = RoutingStatus.UNSPECIFIED_ROUTING_STATUS;

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 11;
   */
  quantity = protoInt64.zero;

  /**
   * Minimum quantity of shares per execution
   *
   * @generated from field: int64 min_quantity = 12;
   */
  minQuantity = protoInt64.zero;

  /**
   * Independent Amount rate
   *
   * @generated from field: string independent_amount_rate = 13;
   */
  independentAmountRate = "";

  /**
   * Rounding rule
   *
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 14;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * Anonymous order
   *
   * @generated from field: bool anonymous = 15;
   */
  anonymous = false;

  /**
   * Requested rate
   *
   * @generated from field: string rate = 16;
   */
  rate = "";

  /**
   * Illegible Counterparties
   *
   * @generated from field: repeated string counterparty_ids = 17;
   */
  counterpartyIds: string[] = [];

  /**
   * Total quantity still available for matching
   *
   * @generated from field: int64 open_quantity = 18;
   */
  openQuantity = protoInt64.zero;

  /**
   * Total executed quantity so far
   *
   * @generated from field: int64 total_exec_qty = 19;
   */
  totalExecQty = protoInt64.zero;

  /**
   * Average rate of executed quantity so far
   *
   * @generated from field: string avg_exec_rate = 20;
   */
  avgExecRate = "";

  /**
   * Order created at
   *
   * @generated from field: google.protobuf.Timestamp created_at = 21;
   */
  createdAt?: Timestamp;

  /**
   * Order was last changed at
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 22;
   */
  updatedAt?: Timestamp;

  /**
   * Owner of the order
   *
   * @generated from field: string company_id = 23;
   */
  companyId = "";

  constructor(data?: PartialMessage<Order>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.Order";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 6, name: "order_status", kind: "enum", T: proto3.getEnumType(OmsOrderStatus) },
    { no: 7, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 8, name: "order_type", kind: "enum", T: proto3.getEnumType(OmsOrderType) },
    { no: 9, name: "time_in_force_type", kind: "enum", T: proto3.getEnumType(OmsTimeInForceType) },
    { no: 10, name: "routing_status", kind: "enum", T: proto3.getEnumType(RoutingStatus) },
    { no: 11, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "min_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 15, name: "anonymous", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "counterparty_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "open_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 19, name: "total_exec_qty", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "avg_exec_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "created_at", kind: "message", T: Timestamp },
    { no: 22, name: "updated_at", kind: "message", T: Timestamp },
    { no: 23, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Order {
    return new Order().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Order {
    return new Order().fromJsonString(jsonString, options);
  }

  static equals(a: Order | PlainMessage<Order> | undefined, b: Order | PlainMessage<Order> | undefined): boolean {
    return proto3.util.equals(Order, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryExecutionsRequest
 */
export class QueryExecutionsRequest extends Message<QueryExecutionsRequest> {
  /**
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  constructor(data?: PartialMessage<QueryExecutionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryExecutionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryExecutionsRequest {
    return new QueryExecutionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryExecutionsRequest {
    return new QueryExecutionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryExecutionsRequest {
    return new QueryExecutionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: QueryExecutionsRequest | PlainMessage<QueryExecutionsRequest> | undefined, b: QueryExecutionsRequest | PlainMessage<QueryExecutionsRequest> | undefined): boolean {
    return proto3.util.equals(QueryExecutionsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.QueryExecutionsResponse
 */
export class QueryExecutionsResponse extends Message<QueryExecutionsResponse> {
  /**
   * Unique request identifier assigned by Aurora user
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * companies and instruments referred to in the OrderDetails
   *
   * @generated from field: map<string, aurora.core.apiengine.omsv1.CompanyInfo> companies = 2;
   */
  companies: { [key: string]: CompanyInfo } = {};

  /**
   * @generated from field: map<string, aurora.core.apiengine.omsv1.Instrument> instruments = 3;
   */
  instruments: { [key: string]: Instrument } = {};

  /**
   * List of executions
   *
   * @generated from field: repeated aurora.core.apiengine.omsv1.Execution executions = 4;
   */
  executions: Execution[] = [];

  constructor(data?: PartialMessage<QueryExecutionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.QueryExecutionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "companies", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: CompanyInfo} },
    { no: 3, name: "instruments", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Instrument} },
    { no: 4, name: "executions", kind: "message", T: Execution, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryExecutionsResponse {
    return new QueryExecutionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryExecutionsResponse {
    return new QueryExecutionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryExecutionsResponse {
    return new QueryExecutionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: QueryExecutionsResponse | PlainMessage<QueryExecutionsResponse> | undefined, b: QueryExecutionsResponse | PlainMessage<QueryExecutionsResponse> | undefined): boolean {
    return proto3.util.equals(QueryExecutionsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.Execution
 */
export class Execution extends Message<Execution> {
  /**
   * @generated from field: string exec_id = 1;
   */
  execId = "";

  /**
   * Unique identifier of the order to get
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Identifier of the instrument
   *
   * @generated from field: string cusip = 4;
   */
  cusip = "";

  /**
   * Side of order (lender or borrower)
   *
   * @generated from field: aurora.core.consts.common.Side side = 5;
   */
  side = Side.BORROWER;

  /**
   * Loan settlement type
   *
   * @generated from field: aurora.core.consts.settlement.SettlementType settlement_type = 7;
   */
  settlementType = SettlementType.UNSPECIFIED;

  /**
   * Quantity of shares
   *
   * @generated from field: int64 quantity = 11;
   */
  quantity = protoInt64.zero;

  /**
   * Settlement amount of loan
   *
   * @generated from field: string settlement_amount = 12;
   */
  settlementAmount = "";

  /**
   * Independent Amount rate
   *
   * @generated from field: string independent_amount_rate = 13;
   */
  independentAmountRate = "";

  /**
   * Rounding rule
   *
   * @generated from field: aurora.core.consts.RoundingRule rounding_rule = 14;
   */
  roundingRule = RoundingRule.UNKNOWN;

  /**
   * @generated from field: string unit_price = 15;
   */
  unitPrice = "";

  /**
   * Requested rate
   *
   * @generated from field: string rate = 16;
   */
  rate = "";

  /**
   * Counterparty
   *
   * @generated from field: string counterparty_id = 17;
   */
  counterpartyId = "";

  /**
   * Order was matched at
   *
   * @generated from field: google.protobuf.Timestamp matched_at = 18;
   */
  matchedAt?: Timestamp;

  /**
   * @generated from field: string trade_date = 20;
   */
  tradeDate = "";

  /**
   * @generated from field: optional aurora.core.apiengine.omsv1.ExecutionResultingLoan loan = 21;
   */
  loan?: ExecutionResultingLoan;

  constructor(data?: PartialMessage<Execution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.Execution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "exec_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 7, name: "settlement_type", kind: "enum", T: proto3.getEnumType(SettlementType) },
    { no: 11, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "settlement_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "rounding_rule", kind: "enum", T: proto3.getEnumType(RoundingRule) },
    { no: 15, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "counterparty_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "matched_at", kind: "message", T: Timestamp },
    { no: 20, name: "trade_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "loan", kind: "message", T: ExecutionResultingLoan, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Execution {
    return new Execution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Execution {
    return new Execution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Execution {
    return new Execution().fromJsonString(jsonString, options);
  }

  static equals(a: Execution | PlainMessage<Execution> | undefined, b: Execution | PlainMessage<Execution> | undefined): boolean {
    return proto3.util.equals(Execution, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ExecutionResultingLoan
 */
export class ExecutionResultingLoan extends Message<ExecutionResultingLoan> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: aurora.core.consts.LoanStatus status = 2;
   */
  status = LoanStatus.NEW;

  constructor(data?: PartialMessage<ExecutionResultingLoan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ExecutionResultingLoan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(LoanStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExecutionResultingLoan {
    return new ExecutionResultingLoan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExecutionResultingLoan {
    return new ExecutionResultingLoan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExecutionResultingLoan {
    return new ExecutionResultingLoan().fromJsonString(jsonString, options);
  }

  static equals(a: ExecutionResultingLoan | PlainMessage<ExecutionResultingLoan> | undefined, b: ExecutionResultingLoan | PlainMessage<ExecutionResultingLoan> | undefined): boolean {
    return proto3.util.equals(ExecutionResultingLoan, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CompanyInfo
 */
export class CompanyInfo extends Message<CompanyInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string nscc_account_id = 3;
   */
  nsccAccountId = "";

  /**
   * @generated from field: optional string display_box_id = 4;
   */
  displayBoxId?: string;

  /**
   * @generated from field: optional string preferred_independent_amount_rate = 5;
   */
  preferredIndependentAmountRate?: string;

  /**
   * @generated from field: optional string cut_off_time = 6;
   */
  cutOffTime?: string;

  constructor(data?: PartialMessage<CompanyInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CompanyInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nscc_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_box_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "preferred_independent_amount_rate", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "cut_off_time", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyInfo {
    return new CompanyInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyInfo {
    return new CompanyInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyInfo {
    return new CompanyInfo().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyInfo | PlainMessage<CompanyInfo> | undefined, b: CompanyInfo | PlainMessage<CompanyInfo> | undefined): boolean {
    return proto3.util.equals(CompanyInfo, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.Instrument
 */
export class Instrument extends Message<Instrument> {
  /**
   * A **CUSIP** number is a unique identification number assigned to stocks and registered bonds in the United States and Canada.
   * It comprises nine characters and includes letters and numbers.
   * Example: use `02079K305` for `Alphabet Inc` (formerly known as Google)
   *
   * @generated from field: string cusip = 1;
   */
  cusip = "";

  /**
   * A stock symbol (*ticker*) is an arrangement of characters (*usually letters*) representing publicly-traded securities on an exchange.
   * When a company issues securities to the public marketplace, it selects an available symbol for its shares, often related to the company name.
   * Example: use `AAPL` for `Apple Computers Inc.`
   *
   * @generated from field: string ticker = 2;
   */
  ticker = "";

  /**
   * Company name for this instrument
   * Example: `Apple Computers Inc.` for ticker `AAPL`
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * Last known close price
   *
   * @generated from field: optional string last_close_price = 4;
   */
  lastClosePrice?: string;

  /**
   * Reason why instruments is (temporarily) disabled from trading
   *
   * @generated from field: optional string cannot_trade_message = 5;
   */
  cannotTradeMessage?: string;

  /**
   * @generated from field: optional string id = 6;
   */
  id?: string;

  constructor(data?: PartialMessage<Instrument>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.Instrument";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticker", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_close_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "cannot_trade_message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Instrument {
    return new Instrument().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Instrument {
    return new Instrument().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Instrument {
    return new Instrument().fromJsonString(jsonString, options);
  }

  static equals(a: Instrument | PlainMessage<Instrument> | undefined, b: Instrument | PlainMessage<Instrument> | undefined): boolean {
    return proto3.util.equals(Instrument, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CreatedOrderEvent
 */
export class CreatedOrderEvent extends Message<CreatedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the created order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<CreatedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CreatedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatedOrderEvent {
    return new CreatedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatedOrderEvent {
    return new CreatedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatedOrderEvent {
    return new CreatedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: CreatedOrderEvent | PlainMessage<CreatedOrderEvent> | undefined, b: CreatedOrderEvent | PlainMessage<CreatedOrderEvent> | undefined): boolean {
    return proto3.util.equals(CreatedOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ModifiedOrderEvent
 */
export class ModifiedOrderEvent extends Message<ModifiedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the modified order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<ModifiedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ModifiedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifiedOrderEvent {
    return new ModifiedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifiedOrderEvent {
    return new ModifiedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifiedOrderEvent {
    return new ModifiedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ModifiedOrderEvent | PlainMessage<ModifiedOrderEvent> | undefined, b: ModifiedOrderEvent | PlainMessage<ModifiedOrderEvent> | undefined): boolean {
    return proto3.util.equals(ModifiedOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ExecutedOrderEvent
 */
export class ExecutedOrderEvent extends Message<ExecutedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique order execution identifier assigned by Aurora system
   *
   * @generated from field: string execution_id = 2;
   */
  executionId = "";

  /**
   * Unique identifier of the executed order
   *
   * @generated from field: string order_ref = 3;
   */
  orderRef = "";

  /**
   * Executed quantity
   *
   * @generated from field: int64 exec_quantity = 4;
   */
  execQuantity = protoInt64.zero;

  /**
   * Total executed quantity so far
   *
   * @generated from field: int64 total_exec_quantity = 5;
   */
  totalExecQuantity = protoInt64.zero;

  /**
   * Rate the order was executed for
   *
   * @generated from field: string exec_rate = 6;
   */
  execRate = "";

  constructor(data?: PartialMessage<ExecutedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ExecutedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "execution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "exec_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "total_exec_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "exec_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExecutedOrderEvent {
    return new ExecutedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExecutedOrderEvent {
    return new ExecutedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExecutedOrderEvent {
    return new ExecutedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ExecutedOrderEvent | PlainMessage<ExecutedOrderEvent> | undefined, b: ExecutedOrderEvent | PlainMessage<ExecutedOrderEvent> | undefined): boolean {
    return proto3.util.equals(ExecutedOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.CanceledOrderEvent
 */
export class CanceledOrderEvent extends Message<CanceledOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the canceled order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Reason for cancellation
   *
   * @generated from field: string reason = 3;
   */
  reason = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 4;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<CanceledOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.CanceledOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CanceledOrderEvent {
    return new CanceledOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CanceledOrderEvent {
    return new CanceledOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CanceledOrderEvent {
    return new CanceledOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: CanceledOrderEvent | PlainMessage<CanceledOrderEvent> | undefined, b: CanceledOrderEvent | PlainMessage<CanceledOrderEvent> | undefined): boolean {
    return proto3.util.equals(CanceledOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ExpiredOrderEvent
 */
export class ExpiredOrderEvent extends Message<ExpiredOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the expired order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<ExpiredOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ExpiredOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExpiredOrderEvent {
    return new ExpiredOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExpiredOrderEvent {
    return new ExpiredOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExpiredOrderEvent {
    return new ExpiredOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ExpiredOrderEvent | PlainMessage<ExpiredOrderEvent> | undefined, b: ExpiredOrderEvent | PlainMessage<ExpiredOrderEvent> | undefined): boolean {
    return proto3.util.equals(ExpiredOrderEvent, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.omsv1.ClosedOrderEvent
 */
export class ClosedOrderEvent extends Message<ClosedOrderEvent> {
  /**
   * Unique request identifier assigned to the event
   *
   * @generated from field: string request_id = 1;
   */
  requestId = "";

  /**
   * Unique identifier of the closed order
   *
   * @generated from field: string order_ref = 2;
   */
  orderRef = "";

  /**
   * Details of the order
   *
   * @generated from field: aurora.core.apiengine.omsv1.OrderDetails order_details = 3;
   */
  orderDetails?: OrderDetails;

  constructor(data?: PartialMessage<ClosedOrderEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.omsv1.ClosedOrderEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_details", kind: "message", T: OrderDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClosedOrderEvent {
    return new ClosedOrderEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClosedOrderEvent {
    return new ClosedOrderEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClosedOrderEvent {
    return new ClosedOrderEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ClosedOrderEvent | PlainMessage<ClosedOrderEvent> | undefined, b: ClosedOrderEvent | PlainMessage<ClosedOrderEvent> | undefined): boolean {
    return proto3.util.equals(ClosedOrderEvent, a, b);
  }
}

