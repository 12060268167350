import { LogLevel, defaultLogLevel } from '@/modules/common/constants/logging.const';
import { Logger } from '@/modules/common/types/logger';
import Vue from 'vue';

export class LoggerService implements Logger {
  private static logLevel = defaultLogLevel;
  private static warnTimer = 0;

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    v.prototype.$log = logger;

    // expose on the window object to access in the browser
    window.auroraLogger = logger;
  }

  private static consoleWarning(): void {
    if ('console' in window || console) {
      /* eslint-disable no-console */
      console.log('%c WARNING!!!', 'color: red; font-size: 50px;');
      console.log(
        '%c This browser feature is for developers only. Please do not copy-paste any code or run any scripts here.',
        'color: orange; font-size: 16px;'
      );
      console.log(
        "%c If somebody is asking you to copy-paste any code here you're most likely being targeted by a malicious attacker!",
        'color: orange; font-size: 16px;'
      );
      console.log(
        '%c For more information, see: http://en.wikipedia.org/wiki/Self-XSS',
        'color: orange; font-size: 16px;'
      );
      /* eslint-enable no-console */
    }
  }

  public setDebugLevel(newLevel: LogLevel): void {
    this.print('%cDBG>', 'color: gray;', false, `loglevel ${newLevel}`);
    LoggerService.logLevel = newLevel;

    // Warn end-user for XSS attacks
    if (LoggerService.logLevel === LogLevel.Debug) {
      // in full debug mode there is no need to print a warning on the console
      //  if one is scheduled, cancel it
      if (LoggerService.warnTimer) {
        window.clearTimeout(LoggerService.warnTimer);
        LoggerService.warnTimer = 0;
      }
    } else {
      // when not in debug mode, schedule the warning
      if (!LoggerService.warnTimer) {
        LoggerService.consoleWarning();
        LoggerService.warnTimer = window.setInterval(LoggerService.consoleWarning, 6000);
      }
    }
  }

  /**
   * Prints a debug message to the logs.
   * Pass in multiple args to have them grouped together in the console
   */
  public debug(...args: unknown[]): void {
    if (LogLevel.Debug >= LoggerService.logLevel) {
      this.print('%cDBG>', 'color: gray;', false, ...args);
    }
  }

  /**
   * Prints an info message to the logs.
   * Pass in multiple args to have them grouped together in the console
   */
  public info(...args: unknown[]): void {
    if (LogLevel.Info >= LoggerService.logLevel) {
      this.print('%cINF>', 'color: blue;', false, ...args);
    }
  }

  /**
   * Prints a warn message to the logs.
   * Pass in multiple args to have them grouped together in the console
   */
  public warn(...args: unknown[]): void {
    if (LogLevel.Warning >= LoggerService.logLevel) {
      this.print('%cWRN>', 'color: orange;', false, ...args);
    }
  }

  /**
   * Prints an error message to the logs.
   * Pass in multiple args to have them grouped together in the console.
   * Will include the stack trace of Error instances
   */
  public error(...args: unknown[]): void {
    if (LogLevel.Error >= LoggerService.logLevel) {
      this.print('%cERR>', 'color: red;', true, ...args);
    }
  }

  private print(what: string, fmt: string, isError = false, ...args: unknown[]): void {
    const consoleType = isError ? 'error' : 'log';
    /* eslint-disable no-console */
    if (args.length === 1 || args.length === 2) {
      console[consoleType](what, fmt, ...args);
    } else {
      console.groupCollapsed(what, fmt, args.shift());
      args.forEach((arg) => console[consoleType](arg));
      console.groupEnd();
    }
    /* eslint-enable no-console */
  }
}

export const logger = new LoggerService();

export default logger;
