<template>
  <div>
    <dashboard-panel no-collapse title="ip blacklist">
      <broker-ip-blacklist />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import BrokerIpBlacklist from '@/modules/broker-admin/components/BrokerIpBlacklist.vue';

@Component({
  components: {
    DashboardPanel,
    BrokerIpBlacklist,
  },
})
export default class BrokerIpBlacklistView extends Vue {}
</script>

<style lang="scss"></style>
