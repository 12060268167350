// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/queryconsts.proto (package aurora.core.consts.query, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum aurora.core.consts.query.SortOrder
 */
export enum SortOrder {
  /**
   * @generated from enum value: UNSPECIFIED_SORT_ORDER = 0;
   */
  UNSPECIFIED_SORT_ORDER = 0,

  /**
   * @generated from enum value: ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: DESC = 2;
   */
  DESC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SortOrder)
proto3.util.setEnumType(SortOrder, "aurora.core.consts.query.SortOrder", [
  { no: 0, name: "UNSPECIFIED_SORT_ORDER" },
  { no: 1, name: "ASC" },
  { no: 2, name: "DESC" },
]);

