import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, RawCompanyInfo } from '@/modules/common/models';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { AccountStatus } from '@/modules/user-accounts/types/user-accounts';
import { TradingPermission } from '@/utils/helpers/trading-permissions';
import { parseISO } from 'date-fns';
import Decimal from 'decimal.js';

export type RawLoginResponse = Raw<
  LoginResponse,
  {
    // always specify existing raw entry types explititly
    user: RawMyUser;
  }
>;

export class LoginResponse {
  public status: string;
  public user: MyUser;

  protected constructor(data: RawLoginResponse) {
    this.status = data.status;
    this.user = MyUser.fromData(data.user);
  }

  public static fromData(data: RawLoginResponse): LoginResponse {
    return new LoginResponse(data);
  }
}

export type RawMyUser = Raw<
  MyUser,
  {
    // always specify existing raw entry types explititly
    companyDefaultOmsCounterparties?: RawCompanyInfo[];
  }
>;

export class MyUser {
  public id: string;
  public name: string;
  public emailAddress: string;
  public companyID: string;
  public companyName: string;
  public companyDisplayBoxID: string | null;
  public companyPreferredIndependentAmountRate: Decimal;
  public companyPreferredRoundingRule: RoundingRule | null;
  public companyTradingPermissions: TradingPermission;
  public companyDefaultOmsCounterparties: CompanyInfo[];
  /**
   * trading permissions as defined in the database, could be limited by company permissions!
   */
  public tradingPermissions: TradingPermission | null;
  public orderSoftLimit: Decimal | null;
  public orderHardLimit: Decimal | null;
  public defaultOrderSoftLimit: Decimal | null;
  public defaultOrderHardLimit: Decimal | null;
  public roles: string[];
  public disclaimerSignedAt: Date | null;
  public disabledAt: Date | null;
  public tfaIsEnabled: boolean;
  public accountStatus: AccountStatus;
  public sponsorshipSide: string | null;
  public reportDeliveries: string[];

  protected constructor(data: RawMyUser) {
    this.id = data.id;
    this.name = data.name;
    this.emailAddress = data.emailAddress;
    this.companyID = data.companyID;
    this.companyName = data.companyName;
    this.companyDisplayBoxID = data.companyDisplayBoxID;
    this.companyPreferredIndependentAmountRate = new Decimal(
      data.companyPreferredIndependentAmountRate
    );
    this.companyPreferredRoundingRule = data.companyPreferredRoundingRule;
    this.companyTradingPermissions = data.companyTradingPermissions;
    this.companyDefaultOmsCounterparties =
      data.companyDefaultOmsCounterparties?.map<CompanyInfo>(CompanyInfo.fromData) ?? [];
    this.tradingPermissions = data.tradingPermissions;
    this.orderSoftLimit = data.orderSoftLimit === null ? null : new Decimal(data.orderSoftLimit);
    this.orderHardLimit = data.orderHardLimit === null ? null : new Decimal(data.orderHardLimit);
    this.defaultOrderSoftLimit =
      data.defaultOrderSoftLimit === null ? null : new Decimal(data.defaultOrderSoftLimit);
    this.defaultOrderHardLimit =
      data.defaultOrderHardLimit === null ? null : new Decimal(data.defaultOrderHardLimit);
    this.roles = data.roles;
    this.disclaimerSignedAt =
      data.disclaimerSignedAt === null ? null : parseISO(data.disclaimerSignedAt);
    this.disabledAt = data.disabledAt === null ? null : parseISO(data.disabledAt);
    this.tfaIsEnabled = data.tfaIsEnabled;
    this.accountStatus = data.accountStatus;
    this.sponsorshipSide = data.sponsorshipSide;
    this.reportDeliveries = data.reportDeliveries;
  }

  public static fromData(data: RawMyUser): MyUser {
    return new MyUser(data);
  }

  public static mock(data?: DeepPartial<RawMyUser>): MyUser {
    return MyUser.fromData(MyUser.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawMyUser>): RawMyUser {
    const { companyDefaultOmsCounterparties, ...rest } = data ?? {};

    return {
      id: '0',
      name: 'HPMoR',
      emailAddress: 'harry.j.potter@hpmor.com',
      companyID: '0',
      companyName: 'Hogwarts',
      companyDisplayBoxID: null,
      companyPreferredIndependentAmountRate: '0',
      companyPreferredRoundingRule: RoundingRule.NoRounding,
      companyTradingPermissions: TradingPermission.DisabledPermissions,
      companyDefaultOmsCounterparties:
        companyDefaultOmsCounterparties?.map(CompanyInfo.mockData) ?? [],
      tradingPermissions: null,
      orderSoftLimit: null,
      orderHardLimit: null,
      defaultOrderSoftLimit: null,
      defaultOrderHardLimit: null,
      roles: [],
      disclaimerSignedAt: null,
      disabledAt: null,
      tfaIsEnabled: false,
      accountStatus: 'active',
      sponsorshipSide: null,
      reportDeliveries: [],

      ...rest,
    };
  }
}
