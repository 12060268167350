<template>
  <ag-table-client
    v-if="items.length"
    :column-defs="columnDefs"
    fit-full-width
    :get-row-id="(item) => item.id"
    :page-size="100"
    :row-data="items"
    table-id="DepthOfBook"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace-depth-of-book';
import { ColDef } from 'ag-grid-enterprise';
import { AgTableClient } from '@/modules/common/components/ag-table';
import { DepthOfBookItem, DepthOfBookResponse } from '@/modules/marketplace/models';

const POLLING_INTERVAL = 1;

@Component({
  components: {
    AgTableClient,
  },
  props: {
    cusip: String,
    asBroker: {
      type: Boolean,
      default: false,
    },
  },
})
export default class MarketplaceDepthOfBook extends Vue {
  // props
  protected readonly cusip!: string;
  protected readonly asBroker!: boolean;

  protected depthOfBook: DepthOfBookResponse | null = null;
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  protected get columnDefs(): Array<ColDef<DepthOfBookItem>> {
    return [
      cols.borrowCounterparty(),
      cols.borrowQuantity(),
      cols.borrowIndependentAmountRate(),
      cols.borrowRate(),
      cols.lendRate(),
      cols.lendIndependentAmountRate(),
      cols.lendQuantity(),
      cols.lendCounterparty(),
    ];
  }

  protected get items(): DepthOfBookItem[] {
    return this.depthOfBook ? this.depthOfBook.data : [];
  }

  @Watch('cusip')
  protected handleCusipChange(newCusip: string, oldCusip: string): void {
    if (!this.cusip) {
      // the route that triggers the parent page could still be
      // redirecting between the route without cusip and the one with cusip
      return;
    }

    if (newCusip !== oldCusip) {
      // we need to force a table re-render when cusip changes
      this.depthOfBook = null;
    }

    void this.fetch();
  }

  protected async fetch(): Promise<void> {
    this.depthOfBook = this.asBroker
      ? await this.$api.marketplace.fetchAdminDepthOfBook(this.cusip)
      : await this.$api.marketplace.fetchDepthOfBook(this.cusip);
  }

  protected async mounted(): Promise<void> {
    await this.fetch();
    // @TODO: replace with socket events
    this.pollInterval = setInterval(() => this.fetch(), POLLING_INTERVAL * 1000);
  }

  // vue-class-component implements destroyed, but not unmounted
  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .highlighted-border-right {
    border-right: 1px solid #ccc !important; /* @TODO: fix */
  }

  .ag-header {
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>
