<!--
  this component is only being used by <AuctionsHistory>
  the newer <RateOutput> component should be used instead
-->
<template>
  <!-- rate preformatted and padded with spaces! -->
  <pre>{{ formatRate() }}%</pre>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { formatAlignableRate } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import { Benchmark } from '@/utils/api/loans';

/**
 * Display a rate with fixated width (by padding spaces).
 * When using a monospace font this component can be used to align rates in a table on the decimal dot
 */
@Component({
  props: {
    rate: Decimal,
    rateModifier: {
      type: String as PropType<Benchmark>,
      default: null,
    },
    integralLen: {
      type: Number,
      default: RATE_PRECISION,
    },
  },
})
export default class AuctionsRateOutput extends Vue {
  // props
  private readonly rate!: Decimal;
  private readonly integralLen!: number;

  protected formatRate(): string {
    return formatAlignableRate(this.rate, this.integralLen);
  }
}
</script>
