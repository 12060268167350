<template>
  <v-simple-table dense>
    <template #default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('auction-information') }}
          </th>
          <th class="text-left">
            {{ $t('Visible to Participants') }}
          </th>
          <th class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="auction.security">
          <tr>
            <td>{{ $t('security') }}</td>
            <td>{{ auction.security.name }}</td>
            <td>
              <v-icon color="secondary">mdi-check-bold</v-icon>
            </td>
          </tr>
          <tr>
            <td>{{ $t('ticker') }}</td>
            <td>{{ auction.security.ticker }} [{{ auction.security.cusip }}]</td>
            <td>
              <v-icon color="secondary">mdi-check-bold</v-icon>
            </td>
          </tr>
        </template>
        <tr>
          <td>
            <v-tooltip color="form-tooltip" max-width="600px" right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $t('cut-off') }}
                  <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                </span>
              </template>
              <span>{{ endsAtTooltip }}</span>
            </v-tooltip>
          </td>
          <td>
            <pretty-date-time :when="stagedAuction.endsAt"></pretty-date-time>
          </td>
          <td>
            <v-icon color="secondary">mdi-check-bold</v-icon>
          </td>
        </tr>
        <tr>
          <td>{{ $t('direction') }}</td>
          <td>{{ $t(auction.shareableDirection) }}</td>
          <td>
            <v-checkbox
              v-model="auction.leakDirection"
              class="ma-0 mt-0 pa-0 pt-1 share-checkbox"
              color="info"
            ></v-checkbox>
          </td>
        </tr>
        <tr>
          <td>{{ $t('quantity') }}</td>
          <td><pretty-number :value="auction.shareableQuantity" /></td>
          <td>
            <v-checkbox
              v-model="auction.leakQuantity"
              class="ma-0 mt-0 pa-0 pt-1 share-checkbox"
              color="info"
            ></v-checkbox>
          </td>
        </tr>
        <tr>
          <td>{{ $t('rate') }}</td>
          <td>
            <rate-output :rate="auction.shareableRate" />
          </td>
          <td>
            <v-checkbox
              v-model="auction.leakRate"
              class="ma-0 mt-0 pa-0 pt-1 share-checkbox"
              color="info"
            ></v-checkbox>
          </td>
        </tr>
        <tr>
          <td>
            <v-tooltip color="form-tooltip" max-width="600px" right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $t('Stacked-Order') }}
                  <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                </span>
              </template>
              <span>{{ stackedOrderTooltip }}</span>
            </v-tooltip>
          </td>
          <td>{{ $t(leakedAuctionIsStackedLabel) }}</td>
          <td>
            <v-checkbox
              v-if="isAuctionStackedOrder"
              v-model="auction.leakIsStackedOrder"
              class="ma-0 mt-0 pa-0 pt-1 share-checkbox"
              color="info"
            ></v-checkbox>
            <v-icon v-else color="secondary"> mdi-checkbox-blank-off-outline </v-icon>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { setSeconds } from 'date-fns';
import { formatDate } from '@/utils/helpers/dates';
import { AuctionDialogBuffer } from '@/utils/helpers/rest';
import PrettyDate from '@/modules/common/components/format-date/PrettyDate.vue';
import i18n from '@/localisation/i18n';
import { AuctionTimeManager } from '@/utils/helpers/auction-time-manager';
import PrettyDateTime from '@/modules/common/components/format-date/PrettyDateTime.vue';
import { BespokeAuction } from '@/modules/auction/models';

@Component({
  components: { PrettyDateTime, PrettyDate },
  props: {
    auction: Object,
    stagedAuction: Object,
  },
})
export default class AuctionPublication extends Vue {
  private auction!: AuctionDialogBuffer;
  private stagedAuction!: BespokeAuction;

  private timeManager = new AuctionTimeManager(this.$store);
  private now = setSeconds(new Date(), 0);
  private endsAtTooltip = '';
  private stackedOrderTooltip = '';

  private get direction(): string {
    const order = this.stagedAuction.companyOrderTickets[0];
    return order.direction;
  }

  private get isAuctionStackedOrder(): boolean {
    return this.auction.firstOrderTicket.orders.length > 1;
  }

  private get leakedAuctionIsStackedLabel(): string {
    return this.auction.firstOrderTicket.orders.length > 1 ? 'is-stacked' : 'is-not-stacked';
  }

  private mounted(): void {
    this.endsAtTooltip = i18n.t('brokerAuctionTimeWindow.tip', {
      auctionOpens: this.timeManager.getBrokerMarketOpens(),
      auctionCloses: this.timeManager.getBrokerMarketCloses(),
      brokerTimeZone: this.timeManager.getBrokerTimeZone(),
    }) as string;
    if (this.timeManager.getBrokerOffsetMinutes(this.now) !== 0) {
      const endsAtLocal = i18n.t('localAuctionTimeWindow.tip', {
        auctionOpens: formatDate(this.timeManager.getMarketOpens(this.now), 'HH:mm'),
        auctionCloses: formatDate(this.timeManager.getMarketCloses(this.now), 'HH:mm'),
        localTimeZone: formatDate(this.now, 'O'),
      }) as string;
      this.endsAtTooltip = `${this.endsAtTooltip} ${endsAtLocal}`;
    }

    this.stackedOrderTooltip = i18n.t('stackedTicket.tip') as string;
  }
}
</script>

<style lang="scss" scoped>
.share-checkbox {
  max-height: 32px;
}
</style>
