import { UUID_EMPTY } from '@/constants';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { AccountStatus } from '@/modules/user-accounts/types/user-accounts';
import { TradingPermission } from '@/utils/helpers/trading-permissions';
import { parseISO } from 'date-fns';
import Decimal from 'decimal.js';

export type RawUserListResponse = Raw<
  UserListResponse,
  {
    // always specify existing raw entry types explititly
    users: RawUser[];
  }
>;

export class UserListResponse {
  public status: string;
  public users: User[];

  protected constructor(data: RawUserListResponse) {
    this.status = data.status;
    this.users = data.users.map(User.fromData);
  }

  public static fromData(data: RawUserListResponse): UserListResponse {
    return new UserListResponse(data);
  }
}

export type RawUser = Raw<User>;

export class User {
  public id: string;
  public name: string;
  public emailAddress: string;
  public companyID: string;
  public companyName: string;
  public companyDisplayBoxID: string | null;
  public companyTradingPermissions: TradingPermission;
  /**
   * trading permissions as defined in the database, could be limited by company permissions!
   */
  public tradingPermissions: TradingPermission | null;
  public orderSoftLimit: Decimal | null;
  public orderHardLimit: Decimal | null;
  public defaultOrderSoftLimit: Decimal | null;
  public defaultOrderHardLimit: Decimal | null;
  public roles: string[];
  public disclaimerSignedAt: Date | null;
  public disabledAt: Date | null;
  public lastLoginAt: Date | null;
  public tfaIsEnabled: boolean;
  public accountStatus: AccountStatus;
  public totalActiveMinutes: number;
  public lastActive: Date | null;
  public rolesPendingApproval: string[];

  protected constructor(data: RawUser) {
    this.id = data.id;
    this.name = data.name;
    this.emailAddress = data.emailAddress;
    this.companyID = data.companyID;
    this.companyName = data.companyName;
    this.companyDisplayBoxID = data.companyDisplayBoxID;
    this.companyTradingPermissions = data.companyTradingPermissions;
    this.tradingPermissions = data.tradingPermissions;
    this.orderSoftLimit = data.orderSoftLimit === null ? null : new Decimal(data.orderSoftLimit);
    this.orderHardLimit = data.orderHardLimit === null ? null : new Decimal(data.orderHardLimit);
    this.defaultOrderSoftLimit =
      data.defaultOrderSoftLimit === null ? null : new Decimal(data.defaultOrderSoftLimit);
    this.defaultOrderHardLimit =
      data.defaultOrderHardLimit === null ? null : new Decimal(data.defaultOrderHardLimit);
    this.roles = data.roles;
    this.disclaimerSignedAt =
      data.disclaimerSignedAt === null ? null : parseISO(data.disclaimerSignedAt);
    this.disabledAt = data.disabledAt === null ? null : parseISO(data.disabledAt);
    this.lastLoginAt = data.lastLoginAt === null ? null : parseISO(data.lastLoginAt);
    this.tfaIsEnabled = data.tfaIsEnabled;
    this.accountStatus = data.accountStatus;
    this.totalActiveMinutes = data.totalActiveMinutes;
    this.lastActive = data.lastActive === null ? null : parseISO(data.lastActive);
    this.rolesPendingApproval = data.rolesPendingApproval;
  }

  public static fromData(data: RawUser): User {
    return new User(data);
  }

  public static toData(model: User): RawUser {
    return {
      id: model.id,
      name: model.name,
      emailAddress: model.emailAddress,
      companyID: model.companyID,
      companyName: model.companyName,
      companyDisplayBoxID: model.companyDisplayBoxID,
      companyTradingPermissions: model.companyTradingPermissions,
      tradingPermissions: model.tradingPermissions,
      orderSoftLimit: model.orderSoftLimit?.toString() ?? null,
      orderHardLimit: model.orderHardLimit?.toString() ?? null,
      defaultOrderSoftLimit: model.defaultOrderSoftLimit?.toString() ?? null,
      defaultOrderHardLimit: model.defaultOrderHardLimit?.toString() ?? null,
      roles: model.roles,
      disclaimerSignedAt: model.disclaimerSignedAt?.toString() ?? null,
      disabledAt: model.disabledAt?.toString() ?? null,
      lastLoginAt: model.lastLoginAt?.toString() ?? null,
      tfaIsEnabled: model.tfaIsEnabled,
      accountStatus: model.accountStatus,
      totalActiveMinutes: model.totalActiveMinutes,
      lastActive: model.lastActive?.toString() ?? null,
      rolesPendingApproval: model.rolesPendingApproval,
    };
  }

  public static mock(data?: DeepPartial<RawUser>): User {
    return User.fromData(User.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawUser>): RawUser {
    return {
      id: UUID_EMPTY,
      name: 'HPMoR',
      emailAddress: 'harry.j.potter@hpmor.com',
      companyID: UUID_EMPTY,
      companyName: 'Hogwarts',
      companyDisplayBoxID: null,
      companyTradingPermissions: TradingPermission.DisabledPermissions,
      tradingPermissions: null,
      orderSoftLimit: null,
      orderHardLimit: null,
      defaultOrderSoftLimit: null,
      defaultOrderHardLimit: null,
      roles: [],
      disclaimerSignedAt: null,
      disabledAt: null,
      lastLoginAt: null,
      tfaIsEnabled: false,
      accountStatus: 'active',
      totalActiveMinutes: 0,
      lastActive: null,
      rolesPendingApproval: [],

      ...data,
    };
  }
}
