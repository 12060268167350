import i18n from '@/localisation/i18n';
import { UptimeOutage } from '@/utils/api/uptime';
import { formatDuration, intervalToDuration } from 'date-fns';

export function getUptimeStatusText(status: string): string {
  return i18n.t(`uptime.status.${status}`) as string;
}

export function getUptimeStatusColor(status: string): string {
  switch (status) {
    case 'OPERATIONAL':
      return 'success';
    case 'UNDER_MAINTENANCE':
      return 'warning';
    case 'DEGRADED':
      return 'warning';
    case 'PARTIAL_OUTAGE':
      return 'warning';
    case 'MAJOR_OUTAGE':
      return 'error';
    default:
      return 'error';
  }
}

export function getUptimeStatusIcon(status: string): string {
  switch (status) {
    case 'OPERATIONAL':
      return 'mdi-check';
    case 'UNDER_MAINTENANCE':
      return 'mdi-clock';
    case 'DEGRADED':
      return 'mdi-alert';
    case 'PARTIAL_OUTAGE':
      return 'mdi-alert-circle';
    case 'MAJOR_OUTAGE':
      return 'mdi-alert-octagon';
    default:
      return 'mdi-help';
  }
}

export function getOutageDuration(o: UptimeOutage): string {
  return formatDuration(intervalToDuration({ start: o.from, end: o.until }), {
    format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'],
  });
}
