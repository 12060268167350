import { Raw } from '@/modules/common/helpers/api';
import { RawSecurity, Security } from '@/modules/common/models';
import { Benchmark } from '@/utils/api/loans';
import { parseISO } from 'date-fns';
import Decimal from 'decimal.js';

export type RawFinraTransaction = Raw<
  FinraTransaction,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
  },
  'security'
>;

export class FinraTransaction {
  public id: string;
  public displayId: string;
  public security: Security;
  public transactAt: Date;
  public reportedAt: Date;
  public reportType: string;
  public issuer: string;
  public lei: string;
  public quantity: number;
  public rate: Decimal;
  public rateModifier: Benchmark;
  public collateralType: string;
  public collateralValue: Decimal;
  public collateralPct: Decimal;
  public marketValue: Decimal;
  public venue: string;
  public counterpartyId: string;
  public counterpartyName: string;
  public counterpartyType: string;
  public ftd: string;
  public createdAt: Date;
  public endsAt: Date;

  protected constructor(data: RawFinraTransaction) {
    this.id = data.id;
    this.displayId = data.displayId;
    this.security = Security.fromData(data.equity);
    this.transactAt = parseISO(data.transactAt);
    this.reportedAt = parseISO(data.reportedAt);
    this.reportType = data.reportType;
    this.issuer = data.issuer;
    this.lei = data.lei;
    this.quantity = data.quantity;
    this.rate = new Decimal(data.rate);
    this.rateModifier = data.rateModifier;
    this.collateralType = data.collateralType;
    this.collateralValue = new Decimal(data.collateralValue);
    this.collateralPct = new Decimal(data.collateralPct);
    this.marketValue = new Decimal(data.marketValue);
    this.venue = data.venue;
    this.counterpartyId = data.counterpartyId;
    this.counterpartyName = data.counterpartyName;
    this.counterpartyType = data.counterpartyType;
    this.ftd = data.ftd;
    this.createdAt = parseISO(data.createdAt);
    this.endsAt = parseISO(data.endsAt);
  }

  public static fromData(data: RawFinraTransaction): FinraTransaction;
  public static fromData(data: RawFinraTransaction | null): FinraTransaction | null;
  public static fromData(data: RawFinraTransaction | undefined): FinraTransaction | undefined;
  public static fromData(
    data: null | undefined | RawFinraTransaction
  ): null | undefined | FinraTransaction {
    if (data === null) return null;
    if (data === undefined) return undefined;
    return new FinraTransaction(data);
  }
}

export type RawFinraTransactions = Raw<
  FinraTransactions,
  {
    // always specify existing raw entry types explititly
    items: RawFinraTransaction[];
  }
>;

export class FinraTransactions {
  public items: FinraTransaction[];
  public total: number;

  protected constructor(data: RawFinraTransactions) {
    this.items = data.items.map<FinraTransaction>(FinraTransaction.fromData);
    this.total = data.total;
  }

  public static fromData(data: RawFinraTransactions): FinraTransactions;
  public static fromData(data: RawFinraTransactions | null): FinraTransactions | null;
  public static fromData(data: RawFinraTransactions | undefined): FinraTransactions | undefined;
  public static fromData(
    data: null | undefined | RawFinraTransactions
  ): null | undefined | FinraTransactions {
    if (data === null) return null;
    if (data === undefined) return undefined;
    return new FinraTransactions(data);
  }
}
