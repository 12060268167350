import { useStoreAuth } from '@/store/store-auth';
import {
  ApiError,
  BadRequestError,
  GenericError,
  InternalServerError,
  NotAuthorisedError,
  NotFoundError,
  PermissionDeniedError,
} from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Cancel,
  CanceledError,
  default as axios,
} from 'axios';

/**
 * Base API Service class
 */
export abstract class ApiService {
  protected axios: AxiosInstance;
  protected baseUrl: string;
  protected apiVersion = 1;

  public constructor(config?: AxiosRequestConfig) {
    this.axios = axios.create(config);
    this.axios.defaults.headers = axios.defaults.headers;
    this.baseUrl = `/api/${this.apiVersion}`;

    // configure axios to use our api subdomain
    // @TODO: we could enable this to always force CORS
    // axios.defaults.baseURL = window.location.protocol + "//"
    //   + (window.location.host.startsWith("localhost") ? window.location.host : "api." + window.location.host);

    // set up global interceptors
    this.axios.interceptors.response.use(
      this.successResponseInterceptor,
      this.errorResponseInterceptor.bind(this)
    );
  }

  /**
   * Intercepts success responses
   */
  private successResponseInterceptor(response: AxiosResponse) {
    // With this interceptor we can add global formatting to success response objects
    // ...
    return response;
  }

  /**
   * Intercepts error responses and wraps as an appropriate Error class to return
   */
  private async errorResponseInterceptor(error: AxiosError | Error | Cancel) {
    const storeAuth = useStoreAuth();

    let err: ApiError | GenericError;

    // bubble axios cancellations so the caller can act upon it
    if (error instanceof CanceledError) {
      return Promise.reject(error);
    }

    if (axios.isAxiosError(error)) {
      // error came from the server, wrap as an appropriate ApiError instance
      switch (error.response?.status) {
        case 400:
          // @TODO use i18n to translate msgkey to appropriate message here
          // for now just attach as responseData to the error and handle further up
          err = new BadRequestError('Bad input', error.response.data);
          break;
        case 401:
          if (storeAuth.isUserAuthenticated && error.config) {
            try {
              const authToken = await storeAuth.refreshAuthTokens();
              error.config.headers.Authorization = `Bearer ${authToken}`;
              return this.axios(error.config);
            } catch (e) {
              return this.errorResponseInterceptor(error as Error);
            }
          }

          // @TODO need to see what the API returns and parse accordingly
          err = new NotAuthorisedError('Not logged in', error.response.data);
          // Duplication warning: logout redirect is also present on rest-response.ts
          // directly manipulating the window.location.href is not ideal
          // when all API calls are moved to the api.service.ts this can be rethinked
          window.location.href = '/#/logout';
          break;
        case 403:
          /// @TODO need to see what the API returns and parse accordingly
          err = new PermissionDeniedError('Permission denied', error.response.data);
          break;
        case 404:
          // @TODO use i18n to translate msgkey to appropriate message here
          // for now just attach as responseData to the error and handle further up
          err = new NotFoundError('Not found', error.response.data);
          break;
        case 500:
          err = new InternalServerError('Unexpected error');
          break;
        default:
          err = new ApiError(i18nServerMessage(error));
          break;
      }
    } else {
      // something else went wrong. Wrap as a generic error
      err = new GenericError(error.message ?? '');
    }

    return Promise.reject(err);
  }
}
