<template>
  <v-select
    :error-messages="errorMessages"
    :items="benchmarks"
    :label="label"
    placeholder="select benchmark..."
    :value="value"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Benchmark } from '@/utils/api/loans';
import { PropType } from 'vue';

/**
 * Select benchmark from list of benchmarks loaded from the backend
 **/
@Component({
  props: {
    value: String as PropType<Benchmark>,
    withNoBenchmark: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
    },
  },
})
export default class BenchmarkSelect extends Vue {
  protected readonly value!: Benchmark;
  protected readonly label!: string;
  protected readonly withNoBenchmark!: boolean;
  protected readonly errorMessages!: string[];

  protected benchmarks: Array<{ text: string; value: Benchmark }> = [];

  protected async mounted(): Promise<void> {
    this.benchmarks = this.withNoBenchmark
      ? [{ text: 'None (fixed)', value: Benchmark.NoBenchmark }]
      : [];

    try {
      const { benchmarks } = await this.$api.marketdata.getBenchmarkPrices();
      if (benchmarks === null) {
        return;
      }

      this.benchmarks = this.benchmarks.concat(
        benchmarks.map((bp) => ({
          text: `${bp.benchmark} (${bp.price}%)`,
          value: bp.benchmark,
        }))
      );
    } catch (e) {
      this.$log.warn(e);
    }
  }
}
</script>
