<template>
  <v-col v-if="data" class="ml-2" cols="4">
    <v-card class="flex d-flex flex-column" variant="outlined">
      <v-card-title>Value at Risk</v-card-title>
      <v-card-subtitle
        >This view shows the total VaR for all open positions, retrieved from DTCC.
      </v-card-subtitle>
      <v-card-text class="white--text">
        <b>{{ formatDecimalAsCurrencyString('USD', data.total, 2) }}</b>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Api } from '@/modules/analytics/types/statistics';
import axios from 'axios';
import { formatDecimalAsCurrencyString } from '@/utils/helpers/auction-numbers';

const POLLING_INTERVAL = 1;

@Component({
  methods: { formatDecimalAsCurrencyString },
})
export default class ValueAtRiskStatistics extends Vue {
  protected data: null | Api.VarStatisticsResponse = null;
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  protected async mounted(): Promise<void> {
    await this.fetchData();
    this.pollInterval = setInterval(() => this.fetchData(), POLLING_INTERVAL * 6000);
  }

  protected async fetchData(): Promise<void> {
    try {
      const res = await axios.get<Api.VarStatisticsResponse>(`/api/1/analytics/var`);
      this.normalizeData(res.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
    }
  }

  protected normalizeData(data: Api.VarStatisticsResponse): void {
    this.data = data;
  }

  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
</script>
