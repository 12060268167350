import { BorrowerLoansApiService } from '@/modules/borrower/services/borrower-loans-api.service';
import { CommonApiService } from '@/modules/common/services/common.api.service';
import { VueNativeEncryption, createEncrypter } from '@/modules/common/services/encrypter-service';
import { LoggerService } from '@/modules/common/services/logger.service';
import wait from '@/modules/common/services/wait';
import { ManualLoansApiService } from '@/modules/manual-loan/services/manual-loans-api.service';
import AuroraBtn from '@/modules/market-closed/components/AuroraBtn.vue';
import AuroraBtnDropdown from '@/modules/market-closed/components/AuroraBtnDropdown.vue';
import AuroraListItem from '@/modules/market-closed/components/AuroraListItem.vue';
import { MarketdataApiService } from '@/modules/marketdata/services/marketdata-api.service';
import { MarketplaceApiService } from '@/modules/marketplace/services/marketplace-api.service';
import vuetify from '@/plugins/vuetify';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import FulfillingBouncingCircleSpinner from 'epic-spinners/src/components/lib/FulfillingBouncingCircleSpinner.vue';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify';
import VueI18n from 'vue-i18n';
import VueNativeNotification from 'vue-native-notification';
import VueNativeSock from 'vue-native-websocket';
import Router from 'vue-router';
import shortkey from 'vue-shortkey';
import Vuetify from 'vuetify/lib';
import Vuex from 'vuex';
import { sync } from 'vuex-router-sync';
import { LicenseManager } from 'ag-grid-enterprise';
import App from './App.vue';
import i18n from './localisation/i18n';
import { SponsorshipApiService } from './modules/sponsorship/services/sponsorship-api.service';
import { TermLoansApiService } from './modules/termloans/services/termloans.api.service';
import router from './router/router';
import { createStore } from './store/store';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import NumericInput from '@/modules/common/components/NumericInput.vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import FormatSide from '@/modules/common/components/format-side/FormatSide.vue';
import PrettyNumber from '@/modules/common/components/pretty-number/PrettyNumber.vue';
import { CryptoSubtle } from '@/modules/common/services/crypto/crypto-subtle';
import { FinraTransactionsApiService } from '@/modules/finra/services/finra-transactions-api.service';
import { LenderLoansApiService } from '@/modules/lender/services/lender-loans-api.service';
import { OpenLoansApiService } from '@/modules/open-loans/services/open-loans-api.service';
import { SecLendingApiService } from '@/modules/sec-lending/services/sec-lending-api.service';
import { SnackbarManager } from '@/modules/snackbar/services/snackbar-manager';
import { UserAccountsApiService } from '@/modules/user-accounts/services/user-accounts-api.service';
import { DialogManager } from '@/plugins/dialog-manager';
import { installAxiosInterceptors } from '@/utils/helpers/rest-response';
import { CurrencyDirective } from 'vue-currency-input';
import ConfirmDialogWrapper from './modules/common/components/popups/ConfirmDialogWrapper.vue';
import { CorpActionsApiService } from './modules/corp-actions/services/corp-actions.api.service';

// loading screen starts hidden, so <noscript> looks consistent
// we need to show it before we start loading the app
const loadingDiv = document.getElementById('loading') as HTMLDivElement;
loadingDiv.style.display = 'block';

/**
 * Install global plugins
 */
Vue.use(PiniaVuePlugin);
Vue.use(Vuex);
Vue.use(Vuetify);
Vue.use(Router);
Vue.use(VueI18n);
Vue.use(VueColumnsResizableVuetify);
Vue.use(VueNativeNotification);
Vue.use(shortkey, { prevent: ['input', 'button', 'textarea', '.ag-root-wrapper *'] });
Vue.use(ManualLoansApiService);
Vue.use(BorrowerLoansApiService);
Vue.use(LenderLoansApiService);
Vue.use(OpenLoansApiService);
Vue.use(FinraTransactionsApiService);
Vue.use(MarketdataApiService);
Vue.use(SecLendingApiService);
Vue.use(UserAccountsApiService);
Vue.use(CorpActionsApiService);
Vue.use(MarketplaceApiService);
Vue.use(TermLoansApiService);
Vue.use(CommonApiService);
Vue.use(SponsorshipApiService);
Vue.use(SnackbarManager);
Vue.use(DialogManager);
Vue.use(LoggerService);
Vue.use((v) => {
  // @TODO move to install fns
  v.prototype.$wait = wait;
});

/**
 * Install global components
 */
Vue.component('FulfillingBouncingCircleSpinner', FulfillingBouncingCircleSpinner);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component('BtnDropdown', BtnDropdown);
Vue.component('AuroraBtn', AuroraBtn);
Vue.component('AuroraListItem', AuroraListItem);
Vue.component('AuroraBtnDropdown', AuroraBtnDropdown);
Vue.component('NumericInput', NumericInput);
Vue.component('RateOutput', RateOutput);
Vue.component('PrettyNumber', PrettyNumber);
Vue.component('FormatSide', FormatSide);
Vue.component('ConfirmDialogWrapper', ConfirmDialogWrapper);
Vue.directive('currency', CurrencyDirective);

/**
 * Global config
 */
Vue.config.productionTip = false;

// configure axios to use our api subdomain
// @TODO: we could enable this to always force CORS
// axios.defaults.baseURL = window.location.protocol + "//"
//   + (window.location.host.startsWith("localhost") ? window.location.host : "api." + window.location.host);
installAxiosInterceptors();

/*
initialize global/singletons such as the store.
calling these create* functions will set their singleton / global in their own location.

any dependent instances will need to follow the same pattern, eg;
the EncrypterServices singleton takes the store singleton as constructor arg,
so it has a create as well
*/
const store = createStore(router);

/*
 * websockets
 */
const wsurl =
  (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host + '/ws';

Vue.use(VueNativeSock, wsurl, {
  store,
  // we need to connect manually, otherwise we can't force it to disconnect
  //  which we want to use to refresh sessions
  connectManually: true,
  format: 'json',
  reconnection: true,
  reconnectionDelay: 10000,
});

/**
 * Vuex store ready for use!
 * Syncs the router state to the store using `vuex-router-sync`
 */
sync(store, router);

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

void (async () => {
  if (!isDesktop()) {
    displayMobileError();
    return;
  }

  // configuration comes from the server.
  // if the server is not ready or reachable the client cannot be mounted
  try {
    await store.dispatch('fetchClientConfig');
  } catch (e) {
    displayFailedToFetchClientConfig();
    throw new Error(`load config: ${e}`);
  }

  // encryption services installed as Vue Plugin, seems fashion to call it 'Native'
  // createEncrypter() returns a promise because the application should not
  // start before the encrypt/decrypt functions are fully initialized!
  try {
    const encrypter = await createEncrypter(store, window.crypto.subtle as CryptoSubtle);
    Vue.use(VueNativeEncryption, { encrypter });
  } catch (e) {
    throw new Error(`create encrypter ${e}`);
  }
  // all modules installed, mount the app
  new Vue({
    router,
    store,
    pinia: createPinia(),
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
})();

function isDesktop() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /ipad|tablet|playbook|silk/i.test(userAgent);
  const isMobile = /iphone|ipod|android|ie|blackberry|fennec/i.test(userAgent) && !isTablet;
  return !isMobile && !isTablet;
}

function displayMobileError() {
  const mobileDiv = document.getElementById('mobile') as HTMLDivElement;
  mobileDiv.style.display = 'block';
  loadingDiv.style.display = 'none';
}

function displayFailedToFetchClientConfig() {
  loadingDiv.style.display = 'none';
  const errorDiv = document.getElementById('error') as HTMLDivElement;
  errorDiv.style.display = 'block';
  const counter = document.getElementById('counter') as HTMLSpanElement;

  setInterval(() => {
    const secondsLeft = parseInt(counter.textContent as string) - 1;
    counter.textContent = secondsLeft.toString();
    if (secondsLeft === 0) {
      window.location.reload();
    }
  }, 1000);
}
