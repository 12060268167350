<template>
  <ag-table-client
    :column-defs="columnDefs"
    :get-row-id="getRowId"
    :page="1"
    :page-size="1000"
    :row-data="[]"
    :sort="{ colId: 'matchedAt', sort: 'desc' }"
    table-id="MarketplaceExecutionsTable"
    @ready="onReady"
  />
</template>

<script lang="ts">
import { ClientConfig } from '@/utils/helpers/rest';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ColDef, ManagedGridOptionKey } from 'ag-grid-enterprise';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace';
import { Execution } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { serviceMarketplace } from '@/connect/services/marketplace';
import { useStoreExplorer } from '@/modules/marketplace/store/store-explorer';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreCompanies } from '@/store/store-companies';
import { PlainMessage } from '@/connect';
import { Watch } from 'vue-property-decorator';

const POLLING_INTERVAL = 1;

@Component({
  components: {
    AgTableClient,
  },
  props: {},
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class MarketplaceExecutionsTable extends Vue {
  // store states
  protected readonly clientConfig!: ClientConfig;

  protected storeSecurities = useStoreSecurities();
  protected storeCompanies = useStoreCompanies();
  protected storeExplorer = useStoreExplorer();

  protected executions: Array<PlainMessage<Execution>> = [];
  protected setGridOption!: (option: ManagedGridOptionKey, value: unknown) => void;
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  protected get columnDefs(): ColDef[] {
    return [
      cols.matchedTime(),
      cols.side(),
      cols.counterparty(),
      cols.ticker(),
      cols.cusip(),
      cols.execuctionQty(),
      cols.rateType(),
      cols.rate(),
      cols.priceCurrency(),
      cols.unitPrice(),
      cols.settlementAmount(),
      cols.independentAmountRate(),
      cols.roundingRule(),
      cols.collateralType(),
      cols.settlementType(),
      cols.tradeDate(),
      cols.orderRef(),
      cols.auroraLoanID(),
    ];
  }

  @Watch('storeExplorer.selectedSecurity')
  protected onSelectedSecurityChange(): void {
    void this.queryData();
  }

  protected onReady(config: {
    setGridOption: (option: ManagedGridOptionKey, value: unknown) => void;
  }): void {
    this.setGridOption = config.setGridOption;
  }

  protected async mounted(): Promise<void> {
    await this.queryData();
    this.pollInterval = setInterval(() => this.queryData(), POLLING_INTERVAL * 1000);
  }

  protected getRowId(order: Execution): string {
    return order.execId;
  }

  protected async queryData(): Promise<void> {
    const result = await serviceMarketplace.queryExecutions();
    if (!result.success) {
      this.$snackbar.show({ message: this.$i18n.t(result.error, result.details) as string });
      return;
    }

    this.storeSecurities.addSecurities(result.data.instruments);
    this.storeCompanies.addCompanies(result.data.companies);

    let executions = result.data.executions;
    const selectedSecurity = this.storeExplorer.selectedSecurity;

    if (selectedSecurity) {
      executions = executions.filter((execution) => execution.cusip === selectedSecurity.cusip);
    }

    this.setGridOption('rowData', executions);
  }

  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
</script>
