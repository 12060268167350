<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="600"
    origin="center center"
    overlay-color="secondary"
    overlay-opacity="0.25"
    persistent
    transition="scale-transition"
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('loginTitle2FA') }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="text-center">
              <v-col class="pt-6">
                <p class="text-center">
                  <span class="text-body-1">
                    Your account is secured with Two-Factor Authentication.
                  </span>
                  <br />
                  <span v-if="clientConfig && clientConfig.tfaResetByEmail">
                    Lost your device? Request a reset
                    <router-link :to="{ name: 'lost-device', params: { lostEmail: loginEmail } }">
                      here
                    </router-link>
                  </span>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pt-8">
                <p>Enter the 6-digit code from your app:</p>
                <v-otp-input
                  ref="otpInput"
                  v-model="otp"
                  :disabled="isProcessing"
                  :error-messages="errorMsgs['otp']"
                  length="6"
                  plains
                  type="number"
                  @finish="
                    $v.otp.$touch();
                    submitForm();
                  "
                />
                <label class="error--text">{{ errorMsgs['otp'].join('\n') }}</label>
              </v-col>
            </v-row>

            <v-row v-if="showSuccess">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div class="v-alert v-alert--dense text--primary text-body-2 text-center success">
                  Login successful!
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text :to="{ name: 'login' }">
            {{ $t('dialogs.goBackButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="px-6"
            color="primary"
            :disabled="isProcessing"
            :loading="isProcessing"
            type="submit"
            @click="submitForm"
          >
            {{ $t('dialogs.continueButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Login2FAResponse, MyUser } from '@/modules/common/models';
import { AppState } from '@/store/store';
import { StoreAuth, useStoreAuth } from '@/store/store-auth';
import { storeToRefs } from 'pinia';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapMutations, mapState } from 'vuex';

interface FormErrors {
  otp: string[];
}

@Component({
  props: {
    loginEmail: {
      type: String,
      default: '',
    },
    loginPassword: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
  methods: {
    ...mapMutations(['updateCurrentUser']),
    ...mapActions(['confirm2FALogin']),
  },
  mixins: [validationMixin],
  validations: function (this: Login2FA) {
    return {
      otp: {
        required,
        minLength: minLength(6),
      },
    };
  },
  setup() {
    return storeToRefs(useStoreAuth());
  },
})
export default class Login2FA extends mixins(validationMixin) {
  public $refs!: {
    otpInput?: HTMLInputElement;
  };

  // props
  protected readonly loginEmail!: string;
  protected readonly loginPassword!: string;

  // store state refs
  protected readonly clientConfig!: AppState['clientConfig'];

  // store mutations
  protected readonly updateCurrentUser!: (a: MyUser) => void;
  protected readonly confirm2FALogin!: (payload: {
    email: string;
    tfaCode: string;
  }) => Promise<Login2FAResponse>;

  // pinia
  protected readonly login!: StoreAuth['login'];

  protected otp = '';
  protected isProcessing = false;
  protected showSuccess = false;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      otp: [],
    };

    // otp errors
    if (this.$v.otp.$dirty) {
      if (!this.$v.otp.required) errors.otp.push('please enter a code to continue.');
      if (!this.$v.otp.minLength) errors.otp.push('please enter a 6 digit code.');
    }

    return errors;
  }

  protected mounted(): void {
    this.autofocusOtpInput();
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.isProcessing || !this.validateForm()) {
      return;
    }

    this.isProcessing = true;

    try {
      const result = await this.login({
        email: this.loginEmail,
        password: this.loginPassword,
        otpCode: this.otp,
      });

      // already handled within login
      if (result === undefined) return;

      if (result.success) {
        // success, inform user and then continue
        this.showSuccess = true;

        await this.$router.replace({ name: 'dashboard' });
      } else {
        this.errorMsgs.otp = [this.$tc(result.error)];
        this.autofocusOtpInput();
      }
    } finally {
      this.isProcessing = false;
    }
  }

  private autofocusOtpInput(): void {
    this.$refs.otpInput?.focus();
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.$v.$touch();
    return !this.$v.$anyError;
  }
}
</script>
