import i18n from '@/localisation/i18n';
import { isArray, isEmpty, isNumber, isString } from 'lodash';
import logger from '@/modules/common/services/logger.service';

export const reValidEmailAddress =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const reValidNsccAccountNumber =
  // eslint-disable-next-line no-useless-escape
  /^[A-Za-z0-9]{4,6}$/;

export class FormValidator<T, K extends keyof T> {
  private fields: K[] = [];
  private readonly record: T;
  private i18nFieldMsgPrefix!: string;

  public constructor(record: T, fields: K[], i18nFieldMsgPrefix?: string) {
    this.record = record;
    if (fields.length !== 0) {
      this.fields = fields;
    }
    this.i18nFieldMsgPrefix = i18nFieldMsgPrefix || '';
  }

  public check(): Record<K, string> {
    const errors = this.fields.reduce(
      (acc, key) => {
        const val = this.record[key];

        if (val === undefined || val === null) {
          return {
            ...acc,
            [key]: this.getMessage(key, 'formfield.IsRequired'),
          };
        }

        if (isNumber(val) && val === 0) {
          return {
            ...acc,
            [key]: this.getMessage(key, 'formfield.MustSelect'),
          };
        }

        if (isArray(val) && val.length === 0) {
          return {
            ...acc,
            [key]: this.getMessage(key, 'formfield.MustChoose'),
          };
        }

        if (isString(val) && isEmpty(val.trim())) {
          return {
            ...acc,
            [key]: this.getMessage(key, 'formfield.NotEmpty'),
          };
        }

        if (key === 'emailAddress' && isString(val)) {
          if (!reValidEmailAddress.test(val)) {
            return {
              ...acc,
              [key]: i18n.t('formfield.InvalidEmail'),
            };
          }
        }

        if ((key === 'mainID' || key == 'subID') && isString(val)) {
          if (!reValidNsccAccountNumber.test(val)) {
            return {
              ...acc,
              [key]: i18n.t('formfield.InvalidNsccAccountNumber'),
            };
          }
        }

        return acc;
      },
      {} as Record<K, string>
    );

    return errors;
  }

  private getMessage(fieldName: K, fallbackMessage: string): string {
    const msgkey = `formfield.${fieldName as string}`;
    let msgtext = i18n.t(msgkey) as string;

    // no tailored message for this field -> fallback to generic message
    if (msgtext === msgkey) {
      msgtext = `${i18n.t(this.i18nFieldMsgPrefix + (fieldName as string))} ${i18n.t(
        fallbackMessage
      )}`;
    }

    return msgtext;
  }
}

export function setInputFocus(input: HTMLFormElement | HTMLFormElement[]): void {
  if (input === undefined) {
    return;
  }

  const refElem: HTMLFormElement = Array.isArray(input) ? input[0] : input;

  if (typeof refElem.focus === 'function') {
    // the element itself supports focus()
    setTimeout(() => refElem.focus(), 1);
  } else if (refElem.$el) {
    // <input> may be child of a composed (Vue) element
    const childElem = refElem.$el.querySelector('input');
    if (childElem && typeof childElem.focus === 'function') {
      setTimeout(() => childElem.focus(), 1);
    } else {
      logger.warn('cannot set focus on child elem=', refElem, childElem);
    }
  } else {
    logger.warn('cannot set focus on=', refElem);
  }
}
