// we use >= LogLevel.X so the order matters!
export enum LogLevel {
  Debug,
  Info,
  Warning,
  Error,
  Silent,
}

/**
 * Default logging level
 */
export const defaultLogLevel = LogLevel.Error;
