<template>
  <v-row class="d-flex align-start">
    <v-col cols="6"> </v-col>
    <v-col class="border-left-divider pl-6" cols="6">
      <table class="summary">
        <tbody>
          <tr>
            <td>Current total value</td>
            <td>${{ formatPrice(contract.currentValue) }}</td>
            <td
              :class="{
                'text-red': currentThresholdDeltaPercentage
                  .abs()
                  .greaterThan(contract.threshold.abs()),
              }"
            >
              {{ currentThresholdDeltaPercentage.greaterThan(0) ? '+' : ''
              }}{{ currentThresholdDeltaPercentage.toFixed(2) }}%
            </td>
          </tr>
          <tr v-if="contract.side === 'BORROWER'">
            <td>New total value</td>
            <td>
              <span :class="{ 'text-white': !newTotalValue.eq(contract.currentValue) }">
                ${{ formatPrice(newTotalValue) }}
              </span>
            </td>
            <td
              :class="{
                'text-red': newThresholdDeltaPercentage.abs().greaterThan(contract.threshold.abs()),
              }"
            >
              {{ newThresholdDeltaPercentage.greaterThan(0) ? '+' : ''
              }}{{ newThresholdDeltaPercentage.toFixed(2) }}%
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import Decimal from 'decimal.js';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Contract } from '@/modules/termloans/models';

@Component({
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
    newTotalValue: {
      type: Decimal,
      required: true,
    },
  },
})
export default class TermLoansManageContractTotals extends Vue {
  protected readonly contract!: Contract;
  protected readonly newTotalValue!: Decimal;

  protected get currentThresholdDeltaPercentage(): Decimal {
    const delta = this.contract.currentValue.minus(this.contract.notionalValue);
    return delta.dividedBy(this.contract.notionalValue).times(100);
  }

  protected get newThresholdDeltaPercentage(): Decimal {
    const delta = this.newTotalValue.minus(this.contract.notionalValue);
    return delta.dividedBy(this.contract.notionalValue).times(100);
  }

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }
}
</script>
<style lang="scss" scoped>
.border-left-divider {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.summary {
  width: 100%;
  line-height: 2;
}

.summary td {
  width: 50%;
  color: #888;
}

.text-white {
  color: white;
}

td.text-red {
  color: #ff5252;
}

.summary td:last-child {
  text-align: right;
  padding-right: 1rem;
}
</style>
