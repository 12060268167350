import { Benchmark, RenegotiateSide } from '@/utils/api/loans';
import Decimal from 'decimal.js';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';

export type RawLoanRenegotiation = Raw<LoanRenegotiation>;

export class LoanRenegotiation {
  public id: string;
  public side: RenegotiateSide;
  public rate: Decimal;
  public rateModifier: Benchmark;

  protected constructor(data: RawLoanRenegotiation) {
    this.id = data.id;
    this.side = data.side;
    this.rate = new Decimal(data.rate);
    this.rateModifier = data.rateModifier;
  }

  public static fromData(data: RawLoanRenegotiation): LoanRenegotiation;
  public static fromData(data: RawLoanRenegotiation | null): LoanRenegotiation | null;
  public static fromData(data: RawLoanRenegotiation | undefined): LoanRenegotiation | undefined;
  public static fromData(data: RawLoanRenegotiation): null | undefined | LoanRenegotiation {
    if (data === null) return null;
    if (data === undefined) return undefined;
    return new LoanRenegotiation(data);
  }

  public static mock(data?: null | DeepPartial<RawLoanRenegotiation>): LoanRenegotiation {
    return LoanRenegotiation.fromData(LoanRenegotiation.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawLoanRenegotiation>): RawLoanRenegotiation {
    return {
      id: '0',
      side: RenegotiateSide.Borrower,
      rate: '0.0251',
      rateModifier: Benchmark.NoBenchmark,

      ...data,
    };
  }
}
