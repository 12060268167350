import { DeepPartial, Raw } from '@/modules/common/helpers/api';

export type RawErrorItem = Raw<ErrorItem>;

export class ErrorItem {
  public row: number;
  public field: string;
  public errMsg: string;

  protected constructor(data: RawErrorItem) {
    this.row = data.row;
    this.field = data.field;
    this.errMsg = data.errMsg;
  }

  public static fromData(data: RawErrorItem): ErrorItem {
    return new ErrorItem(data);
  }

  public static mockData(data?: DeepPartial<RawErrorItem>): RawErrorItem {
    return {
      row: 3,
      field: 'Ticker or Cusip',
      errMsg: 'security FOO not found',

      ...data,
    };
  }
}
