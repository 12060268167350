import { ConnectResult, createPromiseClient } from '@/connect';
import { OmsService } from '@/connect/gen/modules/apiengine/services/oms/oms_connect';
import {
  QueryExecutionsResponse,
  QueryOrdersFilter,
  QueryOrdersResponse,
  QueryOrdersSort,
} from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { QuerySortableField } from '@/connect/gen/consts/omsconsts_pb';
import { SortOrder } from '@/connect/gen/consts/queryconsts_pb';
import { SortModelItem } from 'ag-grid-enterprise';
import { Order } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';

export const serviceMarketplace = new (class {
  public readonly promiseClient = createPromiseClient(OmsService);

  public async queryOrders(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    filter: QueryOrdersFilter;
    signal: AbortSignal;
  }): Promise<ConnectResult<QueryOrdersResponse>> {
    const sortFields: Partial<Record<keyof Order | 'security.ticker', QuerySortableField>> = {
      rate: QuerySortableField.RATE,
      side: QuerySortableField.SIDE,
      openQuantity: QuerySortableField.OPEN_QUANTITY,
      quantity: QuerySortableField.TOTAL_QUANTITY,
      minQuantity: QuerySortableField.MIN_QUANTITY,
      companyId: QuerySortableField.COMPANY_NAME,
      counterpartyIds: QuerySortableField.COUNTERPARTIES,
      cusip: QuerySortableField.INSTRUMENT_CUSIP,
      ['security.ticker']: QuerySortableField.INSTRUMENT_TICKER,
      timeInForceType: QuerySortableField.TIME_IN_FORCE,
      orderType: QuerySortableField.TYPE,
      routingStatus: QuerySortableField.ROUTING_STATUS,
      orderStatus: QuerySortableField.STATUS,
      createdAt: QuerySortableField.CREATED_AT,
      updatedAt: QuerySortableField.UPDATED_AT,
    };

    const field = config.sort.colId;
    const order = config.sort.sort === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    const sorts =
      field in sortFields ? [new QueryOrdersSort({ field: sortFields[field], order })] : [];
    const query = {
      filter: config.filter,
      sorts,
      pageNumber: config.page,
      pageLimit: config.pageSize,
    };

    const result = await this.promiseClient.queryOrders(query);
    return result as ConnectResult<QueryOrdersResponse>;
  }

  public async queryExecutions(): Promise<ConnectResult<QueryExecutionsResponse>> {
    const result = await this.promiseClient.queryExecutions({});
    return result as ConnectResult<QueryExecutionsResponse>;
  }
})();
