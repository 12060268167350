import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors/api-error';

/**
 * Not Authorised Error (401)
 *
 * The request was denied due to not being authenticated (user not logged in)
 */
export class NotAuthorisedError<T = Api.ErrorResponse> extends ApiError<T> {
  public constructor(
    public message: string,
    public responseData?: T
  ) {
    super(message);
    this.name = 'NotAuthorisedError';
  }
}
