import Decimal from 'decimal.js';
import { CorporateActions, RawSecurity, Security } from './security';
import { CompanyInfo, RawCompanyInfo } from './company-info';
import { Benchmark, LoanStatus, RenegotiateSide } from '@/utils/api/loans';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { SettlementType } from '@/modules/marketplace/types/marketplace';
import { LoanRenegotiation, RawLoanRenegotiation } from './loan-renegotiation';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { parseISO } from 'date-fns';

export type RawOpenLoans = Raw<
  OpenLoans,
  {
    // always specify existing raw entry types explititly
    items: RawOpenLoan[];
  }
>;

export class OpenLoans {
  public status: string;
  public items: OpenLoan[];
  public total: number;
  public recalledTotal: number;
  public rerateTotal: number;
  public returnProposalTotal: number;
  public corporateActions: CorporateActions;

  protected constructor(data: RawOpenLoans) {
    this.status = data.status;
    this.items = data.items.map((item) => OpenLoan.fromData(item, data.corporateActions));
    this.total = data.total;
    this.recalledTotal = data.recalledTotal;
    this.rerateTotal = data.rerateTotal;
    this.returnProposalTotal = data.returnProposalTotal;
    this.corporateActions = data.corporateActions;
  }

  public static fromData(data: RawOpenLoans): OpenLoans {
    return new OpenLoans(data);
  }
}

export type RawOpenLoan = Raw<
  OpenLoan,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
    counterparty: RawCompanyInfo;
    renegotiation: RawLoanRenegotiation | null;
    openRecalls: RawLoanRecall[];
    openBuyIns?: RawLoanBuyIn[];
    loanReturns: RawLoanReturn[];
  },
  'security' | 'availableActions'
>;

export class OpenLoan {
  public id: string;
  public displayId: string;
  public side?: 'LENDER' | 'BORROWER';
  public security: Security;
  public counterparty: CompanyInfo;
  public counterpartyDisplay: string;
  /**
   * remaining quantity open in the loan
   */
  public openQuantity: number;
  /**
   * remaining quantity that can be recalled
   */
  public openQuantityToRecall: number;
  /**
   * the quantity that is available for return,
   * taking into account any pending returns
   */
  public openQuantityToReturn: number;
  /**
   * total quantity that is actually possible for buy-in,
   * taking into account any pending buy-in still to be processed
   */
  public openQuantityToBuyIn: number;
  public pendingReturnQuantity: number;
  /**
   * returned quantity for today
   */
  public returnedQuantityToday: number;
  /**
   * returned quantity in the whole lifetime of the loan
   */
  public returnedQuantity: number;
  /**
   * current recalled quantity
   */
  public recalledQuantity: number;
  public pendingBuyInQuantity: number;
  public nextAllowedBuyInExecutionDate: Date | null;
  /**
   * quantity that is currently available for buy-in,
   * not accounting for any pending buy-in
   */
  public allowedBuyInExecutionQuantity: number;
  public rate: Decimal;
  public rateModifier: Benchmark;
  public independentAmountRate: Decimal;
  public roundingRule: RoundingRule;
  public contractAmount: Decimal;
  public settlementAmount: Decimal;
  public createdAt: Date;
  public updatedAt: Date;
  public status: LoanStatus;
  public renegotiation: LoanRenegotiation | null;
  public settlementType: SettlementType;
  public openRecalls: LoanRecall[];
  public openBuyIns: LoanBuyIn[];
  public loanReturns: LoanReturn[];
  public sponsorshipSide: 'sponsor' | 'sponsored' | null;
  public termContractDisplayId: string | null;
  /**
   * all actions that can be performed, based
   * on the current state of the loan
   */
  public availableActions: {
    [key in
      | 'cancelPending'
      | 'return'
      | 'cancelReturn'
      | 'recall'
      | 'updateRecall'
      | 'updateLateReturn'
      | 'buyIn' // not available in batch actions yet
      | 'renegotiateFixed'
      | 'renegotiateFloating'
      | 'cancelRenegotiate'
      | 'acceptRenegotiate'
      | 'rejectRenegotiate']?: true;
  };

  protected constructor(data: RawOpenLoan, corporateActions?: CorporateActions) {
    this.id = data.id;
    this.displayId = data.displayId;
    this.side = data.side;
    this.security = Security.fromDataAndCorporateActions(data.equity, corporateActions);
    this.counterparty = CompanyInfo.fromData(data.counterparty);
    this.counterpartyDisplay = data.counterpartyDisplay;
    this.openQuantity = data.openQuantity;
    this.openQuantityToRecall = data.openQuantityToRecall;
    this.openQuantityToReturn = data.openQuantityToReturn;
    this.openQuantityToBuyIn = data.openQuantityToBuyIn;
    this.pendingReturnQuantity = data.pendingReturnQuantity;
    this.returnedQuantityToday = data.returnedQuantityToday;
    this.returnedQuantity = data.returnedQuantity;
    this.recalledQuantity = data.recalledQuantity;
    this.pendingBuyInQuantity = data.pendingBuyInQuantity;
    this.nextAllowedBuyInExecutionDate =
      data.nextAllowedBuyInExecutionDate === null
        ? null
        : parseISO(data.nextAllowedBuyInExecutionDate);
    this.allowedBuyInExecutionQuantity = data.allowedBuyInExecutionQuantity;
    this.rate = new Decimal(data.rate);
    this.rateModifier = data.rateModifier;
    this.independentAmountRate = new Decimal(data.independentAmountRate);
    this.roundingRule = data.roundingRule;
    this.contractAmount = new Decimal(data.contractAmount);
    this.settlementAmount = new Decimal(data.settlementAmount);
    this.createdAt = parseISO(data.createdAt);
    this.updatedAt = parseISO(data.updatedAt);
    this.status = data.status;
    this.renegotiation = LoanRenegotiation.fromData(data.renegotiation);
    this.settlementType = data.settlementType;
    this.openRecalls = data.openRecalls.map<LoanRecall>(LoanRecall.fromData);
    this.openBuyIns = data.openBuyIns?.map<LoanBuyIn>(LoanBuyIn.fromData) ?? [];
    this.loanReturns = data.loanReturns.map<LoanReturn>(LoanReturn.fromData);
    this.sponsorshipSide = data.sponsorshipSide;
    this.termContractDisplayId = data.termContractDisplayId;
    this.availableActions = {};

    this.injectAvailableActions();
  }

  public static fromData(data: RawOpenLoan, corporateActions?: CorporateActions): OpenLoan {
    return new OpenLoan(data, corporateActions);
  }

  public static mock(data?: null | DeepPartial<RawOpenLoan>): OpenLoan {
    return OpenLoan.fromData(OpenLoan.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawOpenLoan>): RawOpenLoan {
    const { equity, counterparty, renegotiation, openRecalls, loanReturns, openBuyIns, ...rest } =
      data ?? {};
    return {
      status: 'OPEN',
      id: '0',
      displayId: '123',
      equity: Security.mockData(equity),
      openQuantity: 1250,
      pendingReturnQuantity: 0,
      pendingBuyInQuantity: 0,
      returnedQuantityToday: 0,
      returnedQuantity: 0,
      recalledQuantity: 0,
      openQuantityToRecall: 1250,
      openQuantityToReturn: 1250,
      openQuantityToBuyIn: 0,
      allowedBuyInExecutionQuantity: 0,
      counterpartyDisplay: 'testing_counterparty_name',
      counterparty: CompanyInfo.mockData(counterparty),
      rate: '1.23',
      rateModifier: Benchmark.NoBenchmark,
      independentAmountRate: '0',
      roundingRule: RoundingRule.NoRounding,
      contractAmount: '290460',
      settlementAmount: '290460',
      createdAt: '2022-10-30',
      updatedAt: '2022-10-30',
      nextAllowedBuyInExecutionDate: null,
      renegotiation: renegotiation ? LoanRenegotiation.mockData(renegotiation) : null,
      settlementType: 'NSCC',
      openRecalls: openRecalls?.map(LoanRecall.mockData) ?? [],
      loanReturns: loanReturns?.map(LoanReturn.mockData) ?? [],
      openBuyIns: openBuyIns?.map(LoanBuyIn.mockData) ?? [],
      sponsorshipSide: null,
      termContractDisplayId: null,

      ...rest,
    };
  }

  /**
   * Injects available actions into the object based on the current loan state
   */
  private injectAvailableActions() {
    if (this.status === 'NEW') {
      return;
    }

    // canceling bilateral loans not supported yet.
    if (this.status === 'PENDING' && this.settlementType != 'BILATERAL') {
      this.availableActions.cancelPending = true;
      return;
    }

    // statuses different than OPEN have already been handled, we can return early
    if (this.status !== 'OPEN') {
      return;
    }

    // only allow renegotiation if the loan was not generated by a term loan contract
    if (this.termContractDisplayId === null) {
      // Renegotiate actions (both lender and borrower)
      if (this.renegotiation === null) {
        if (this.rateModifier === Benchmark.NoBenchmark) {
          this.availableActions.renegotiateFixed = true;
        } else {
          this.availableActions.renegotiateFloating = true;
        }
      } else if (
        (this.side === 'LENDER' && this.renegotiation.side === RenegotiateSide.Lender) ||
        (this.side === 'BORROWER' && this.renegotiation.side === RenegotiateSide.Borrower)
      ) {
        this.availableActions.cancelRenegotiate = true;
      } else {
        this.availableActions.acceptRenegotiate = true;
        this.availableActions.rejectRenegotiate = true;
      }
    }

    // Lender actions
    if (this.side === 'LENDER' || this.sponsorshipSide === 'sponsor') {
      if (this.recalledQuantity > 0 && this.pendingBuyInQuantity < this.recalledQuantity) {
        // If there's a buy-in in progress, only enable if it's less than the recalledQuantity
        this.availableActions.updateRecall = true;
      } else if (this.recalledQuantity === 0) {
        this.availableActions.recall = true;
      }

      if (this.openQuantityToBuyIn - this.pendingBuyInQuantity - this.pendingReturnQuantity > 0) {
        this.availableActions.buyIn = true;
      }
    }

    // Borrower actions
    if (this.side === 'BORROWER' || this.sponsorshipSide === 'sponsor') {
      if (this.pendingReturnQuantity > 0) {
        this.availableActions.cancelReturn = true;
      }
      if (this.openQuantityToReturn > 0) {
        if (this.termContractDisplayId === null || this.recalledQuantity > 0) {
          this.availableActions.return = true;
        }
      }
    }

    // Returns (late returns, maybe others in the future)
    // decision of who can accept/reject/cancel are made on the components themselves
    if (this.loanReturns.length > 0) {
      this.availableActions.updateLateReturn = true;
    }
  }
}

export type RawLenderOpenLoan = RawOpenLoan & {
  side: 'LENDER';
};

export type LenderOpenLoan = OpenLoan & {
  side: 'LENDER';
};

export type RawBorrowerOpenLoan = RawOpenLoan & {
  side: 'BORROWER';
};

export type BorrowerOpenLoan = OpenLoan & {
  side: 'BORROWER';
};

export type OpenLoanItem = LenderOpenLoan | BorrowerOpenLoan;

export type RecallStatus = 'new' | 'approved' | 'made' | 'canceled' | 'pendingcancel';

export type RawLoanRecall = Raw<LoanRecall>;

export class LoanRecall {
  public id: string;
  public status: RecallStatus;
  public dtccRecallId: string;
  public recallTime: Date;
  public allowedBuyInExecutionDate: Date;
  public originalQuantity: number;
  public openQuantity: number;
  public returnedQuantity: number;
  public buyInQuantity: number;
  public legacyRecall: boolean;

  protected constructor(data: RawLoanRecall) {
    this.id = data.id;
    this.status = data.status;
    this.dtccRecallId = data.dtccRecallId;
    this.recallTime = parseISO(data.recallTime);
    this.allowedBuyInExecutionDate = parseISO(data.allowedBuyInExecutionDate);
    this.originalQuantity = data.originalQuantity;
    this.openQuantity = data.openQuantity;
    this.returnedQuantity = data.returnedQuantity;
    this.buyInQuantity = data.buyInQuantity;
    this.legacyRecall = data.legacyRecall;
  }

  public static fromData(data: RawLoanRecall): LoanRecall {
    return new LoanRecall(data);
  }

  public static mock(data?: null | DeepPartial<RawLoanRecall>): LoanRecall {
    return LoanRecall.fromData(LoanRecall.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawLoanRecall>): RawLoanRecall {
    return {
      id: '0',
      status: 'new',
      dtccRecallId: '0',
      recallTime: '2024-09-22',
      allowedBuyInExecutionDate: '2024-09-22',
      originalQuantity: 100,
      openQuantity: 100,
      returnedQuantity: 100,
      buyInQuantity: 100,
      legacyRecall: false,

      ...data,
    };
  }
}

export type RawLoanBuyIn = Raw<LoanBuyIn>;

export class LoanBuyIn {
  public id: string;
  public buyInId: string;
  public state: string;
  public quantity: number;
  public avgPricePerShare: Decimal;

  protected constructor(data: RawLoanBuyIn) {
    this.id = data.id;
    this.buyInId = data.buyInId;
    this.state = data.state;
    this.quantity = data.quantity;
    this.avgPricePerShare = new Decimal(data.avgPricePerShare);
  }

  public static fromData(data: RawLoanBuyIn): LoanBuyIn {
    return new LoanBuyIn(data);
  }

  public static mock(data?: null | DeepPartial<RawLoanBuyIn>): LoanBuyIn {
    return LoanBuyIn.fromData(LoanBuyIn.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawLoanBuyIn>): RawLoanBuyIn {
    return {
      id: '0',
      buyInId: '0',
      state: '',
      quantity: 100,
      avgPricePerShare: '100',

      ...data,
    };
  }
}

export type RawLoanReturn = Raw<LoanReturn>;

export class LoanReturn {
  public id?: string;
  public status?: number;
  public loanId?: string;
  public lenderCompanyId?: string;
  public borrowerCompanyId?: string;
  public borrowerUserId?: string;
  public returnQuantity?: number;
  public createdAt?: Date;

  protected constructor(data: RawLoanReturn) {
    this.id = data.id;
    this.status = data.status;
    this.loanId = data.loanId;
    this.lenderCompanyId = data.lenderCompanyId;
    this.borrowerCompanyId = data.borrowerCompanyId;
    this.borrowerUserId = data.borrowerUserId;
    this.returnQuantity = data.returnQuantity;
    this.createdAt = data.createdAt ? parseISO(data.createdAt) : undefined;
  }

  public static fromData(data: RawLoanReturn): LoanReturn {
    return new LoanReturn(data);
  }

  public static mock(data?: null | DeepPartial<RawLoanReturn>): LoanReturn {
    return LoanReturn.fromData(LoanReturn.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawLoanReturn>): RawLoanReturn {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      id: '0',
      status: 0,
      loanId: '0',
      lenderCompanyId: '0',
      borrowerCompanyId: '0',
      borrowerUserId: '0',
      returnQuantity: 100,
      createdAt: '2024-09-22',

      ...data,
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
