<template>
  <snapshot-manager
    :drivers="['sessionStorage']"
    :get-state-mutators="getStateMutators"
    :snapshot="snapshot"
    storage-key="term-loans-explorer"
  >
    <div class="wrapper d-flex flex-column fill-height">
      <dashboard-panel
        ref="contractsRef"
        :is-collapsed.sync="areContractsCollapsed"
        no-collapse
        :style="{ height: sizes.contractsPanelHeight + 'px' }"
        title="Term Loans"
      >
        <div class="d-flex gap-1 mt-4 ml-4 mb-4">
          <v-btn
            v-if="canBorrow"
            color="primary"
            :disabled="!hasTraderUserRole"
            @click="dialog = { name: 'edit', side: 'BORROWER', contractDisplayId: null }"
          >
            New borrower contract
          </v-btn>
          <v-btn
            v-if="canLend"
            color="primary"
            :disabled="!hasTraderUserRole"
            @click="dialog = { name: 'edit', side: 'LENDER', contractDisplayId: null }"
          >
            New lender contract
          </v-btn>
        </div>

        <term-loans-contracts-table
          v-if="items"
          :items="items"
          :items-per-page="50"
          :page="1"
          :server-items-length="items.length"
          sort-by="createdAt"
          :sort-desc="false"
          @cancel-proposed="cancelProposed"
          @click:row="(contract) => handleContractClick(contract)"
          @delete-draft="deleteDraft"
          @edit="dialog = { name: 'edit', contractDisplayId: $event }"
          @manage="dialog = { name: 'manage', contractDisplayId: $event }"
          @respond="
            (payload) =>
              (dialog = {
                name: 'respond',
                accept: payload.accept,
                contractDisplayId: payload.displayId,
              })
          "
        />
      </dashboard-panel>

      <div
        :class="[
          'resize-handle-h',
          { 'd-none': selectedContract === null || areContractsCollapsed },
        ]"
        @mousedown="startResizeH"
      />

      <dashboard-panel
        v-if="selectedContract"
        ref="aggRef"
        no-collapse
        show-close-icon
        :style="{ height: sizes.aggPanelHeight + 'px' }"
        title="Aggregated Contract Loans"
        @close="selectedContract = null"
      >
        <term-loans-contract-agg-loans
          :contract="selectedContract"
          @click:row="(contract) => handleAggClick(contract)"
        />
      </dashboard-panel>

      <term-loans-edit-contract-dialog
        v-if="dialog.name === 'edit'"
        :display-id="dialog.contractDisplayId"
        :new-contract-side="dialog.side"
        @close-modal="closeDialog"
      />

      <term-loans-manage-contract-dialog
        v-if="dialog.name === 'manage'"
        :display-id="dialog.contractDisplayId"
        @close-modal="closeDialog"
      />

      <term-loans-contract-respond-proposed-dialog
        v-if="dialog.name === 'respond'"
        :accept="dialog.accept"
        :contract="items?.find((item) => item.displayId === dialog.contractDisplayId) || null"
        @close-modal="closeDialog"
      />
    </div>
  </snapshot-manager>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import TermLoansContractsTable from '@/modules/termloans/components/TermLoansContractsTable.vue';
import TermLoansEditContractDialog from '@/modules/termloans/components/TermLoansEditContractDialog.vue';
import TermLoansManageContractDialog from '@/modules/termloans/components/TermLoansManageContractDialog.vue';
import TermLoansContractRespondProposedDialog from '@/modules/termloans/components/TermLoansContractRespondProposedDialog.vue';
import TermLoansContractAggLoans from '@/modules/termloans/components/TermLoansContractAggLoans.vue';
import { mapGetters, mapState } from 'vuex';
import { SocketEvents } from '@/store/store';
import { Watch } from 'vue-property-decorator';
import { Contract, TermLoanContractResponse } from '@/modules/termloans/models';

const DEFAULT_SIZES = {
  // height values don't reflect the rendered height (because of "flex-grow")
  contractsPanelHeight: 200,
  aggPanelHeight: 200,
};

const MIN_ROW_HEIGHT = 200;

interface Dialog {
  name: 'edit' | 'manage' | 'respond' | null;
  side?: 'BORROWER' | 'LENDER';
  accept?: boolean;
  contractDisplayId: string | null;
}

@Component({
  components: {
    DashboardPanel,
    SnapshotManager,
    TermLoansContractsTable,
    TermLoansEditContractDialog,
    TermLoansManageContractDialog,
    TermLoansContractRespondProposedDialog,
    TermLoansContractAggLoans,
  },
  computed: {
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canLend']),
    ...mapState(['socketEvents']),
  },
})
export default class TermLoansContractsView extends Vue {
  public $refs!: {
    contractsRef: Vue;
    aggRef: Vue;
  };
  protected socketEvents!: SocketEvents;

  protected hasTraderUserRole!: boolean;
  protected canBorrow!: boolean;
  protected canLend!: boolean;

  protected items: Contract[] | null = null;
  protected selectedContract: TermLoanContractResponse | null = null;
  protected dialog: Dialog = { name: null, contractDisplayId: null };
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  protected areContractsCollapsed = false;

  protected sizes = { ...DEFAULT_SIZES };
  // for performance resons (we don't want to JSON.stringify every time the mouse moves)
  // we have a separate object that we manualy update when resizing stops
  // (the snapshot getter observes this var)
  protected storedSizes = { ...DEFAULT_SIZES };
  // detect if the user has moved the window to a different screen
  protected currentScreenX: number = window.screenX;

  protected startY = 0;

  protected get snapshot(): Record<string, string> {
    return {
      sizes: JSON.stringify(this.storedSizes),

      payload: JSON.stringify({
        areContractsCollapsed: this.areContractsCollapsed,
        selectedContract: TermLoanContractResponse.toData(this.selectedContract),
      }),
    };
  }

  @Watch('socketEvents.termLoans')
  protected onSocketEvents(): void {
    void this.fetch();
  }

  protected async mounted(): Promise<void> {
    // because the snapshot getter is triggered before mounted,
    // it overrides what's currently in sessionStorage with the default values
    // save it again with what we initially got from sessionStorage before mount
    // (i.e retrigger the getter with the correct values)
    this.storedSizes = { ...this.sizes };
    window.addEventListener('resize', this.handleScreenChange);
    await this.fetch();
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      sizes: (value) => {
        const storedSizes = JSON.parse(value);
        this.sizes = storedSizes;
      },
      payload: (value) => {
        const payload = JSON.parse(value);
        for (const key in payload) {
          this[key] =
            key === 'selectedContract' && payload[key] !== null
              ? TermLoanContractResponse.fromData(payload[key])
              : payload[key];
        }
      },
    };
  }

  protected async fetch(): Promise<void> {
    this.items = await this.$api.termLoans.fetchContracts();
    if (this.selectedContract) {
      this.selectedContract = await this.$api.termLoans.fetchDetails(
        this.selectedContract.displayId
      );
    }
  }

  protected async handleContractClick(contract: Contract): Promise<void> {
    this.selectedContract = await this.$api.termLoans.fetchDetails(contract.displayId);
  }

  protected async handleAggClick(params: {
    termContractDisplayId: string;
    cusip: string;
  }): Promise<void> {
    try {
      await this.$router.push({ name: 'termloans.loans', params });
    } catch (e) {
      // ignore "redundant navigation to current location" warning and log all the others
      if ((e as Error).name !== 'NavigationDuplicated') {
        throw e;
      }
    }
  }

  protected async cancelProposed(contractDisplayId: string): Promise<void> {
    await this.$api.termLoans.cancelProposal(contractDisplayId);
    this.$snackbar.show({
      color: 'primary',
      message: `Your proposal has been cancelled.`,
      timeout: 3000,
    });
  }

  protected async deleteDraft(contractDisplayId: string): Promise<void> {
    await this.$api.termLoans.deleteDraft(contractDisplayId);
    this.$snackbar.show({
      color: 'primary',
      message: `Your draft has been deleted.`,
      timeout: 3000,
    });
  }

  protected closeDialog(): void {
    this.dialog = { name: null, contractDisplayId: null };
  }

  protected startResizeH(event: MouseEvent): void {
    document.body.style.cursor = 'row-resize';
    this.startY = event.clientY;

    // because heights of both panel are re-calculated using flex-grow to auto-fill 50% of the height initially
    // therefor we need to get the calculated height post mount then add or remove from it when
    // resizing happens- otherwise the resizing will add or remove based on the defined height
    // causing a laggish - effect
    // @TODO: both - 4 below are a hack to mitigate the scrolling bar showing up when resizing
    let initialHeightUpper = this.$refs.contractsRef.$el.clientHeight - 4;
    let initialHeightLower = this.$refs.aggRef.$el.clientHeight - 4;

    const onMouseMove = (e: MouseEvent): void => {
      const deltaY = e.clientY - this.startY;

      // re-assign real height
      this.sizes.contractsPanelHeight = initialHeightUpper;
      this.sizes.aggPanelHeight = initialHeightLower;

      // prevent resizing the divs further than the minimum
      const shouldResize =
        initialHeightUpper + deltaY >= MIN_ROW_HEIGHT &&
        initialHeightLower - deltaY >= MIN_ROW_HEIGHT;

      if (shouldResize) {
        initialHeightUpper += deltaY;
        this.sizes.aggPanelHeight = initialHeightUpper;

        initialHeightLower -= deltaY;
        this.sizes.aggPanelHeight = initialHeightLower;
        this.startY = e.clientY;
      }
    };

    const onMouseUp = (): void => {
      document.body.style.cursor = 'auto';
      this.storedSizes = { ...this.sizes };
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }

  protected handleScreenChange(): void {
    if (window.screenX !== this.currentScreenX) {
      // because our users might have multiple monitors
      // we need to reset the panels widths and heights to inital values
      this.sizes = { ...DEFAULT_SIZES };
      this.storedSizes = { ...DEFAULT_SIZES };
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
}

.gap-1 {
  gap: 1rem;
}

::v-deep {
  .aurora-desktop-widget {
    height: 100%;
  }
}

.resize-handle-h {
  height: 4px;
  width: calc(100% - 8px);
  margin: 0 auto;
  text-align: center;
  border: 2px solid transparent;
  user-select: none;
  border-radius: 4px 4px;
  cursor: row-resize;
  &:hover {
    background: #ebeced;
    opacity: 0.1;
  }
}
</style>
