import Decimal from 'decimal.js';
import { parseISO } from 'date-fns';

export function fileNameFromHeaders(headers: Record<string, string>): string {
  if ('content-disposition' in headers) {
    const filenameChunks = headers['content-disposition']
      .split(';')
      .filter((v) => v.startsWith('filename='));
    if (filenameChunks.length !== 1) {
      return '';
    }

    return filenameChunks[0].substring('filename='.length);
  }

  return '';
}

export function ensureDecimal(decimalOrString: Decimal | string): Decimal {
  if (typeof decimalOrString === 'string') {
    return new Decimal(decimalOrString);
  }
  return decimalOrString;
}

export function ensureDate(dateOrString: Date | string): Date {
  if (typeof dateOrString === 'string') {
    return parseISO(dateOrString);
  }
  return dateOrString;
}
