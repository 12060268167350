<template>
  <div>
    <dashboard-panel no-collapse title="open positions">
      <snapshot-manager
        :drivers="['url', 'sessionStorage']"
        :get-state-mutators="getStateMutators"
        :snapshot="snapshot"
        storage-key="snapshot-broker-open-loans-list"
      >
        <broker-open-loans-list
          :borrower.sync="borrower"
          :display-id.sync="displayId"
          :lender.sync="lender"
          :omit-headers="['side', 'counterpartyDisplay']"
          :page.sync="page"
          :page-size.sync="pageSize"
          :security.sync="security"
          :sort.sync="sort"
          :statuses.sync="statuses"
        />
      </snapshot-manager>
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import BrokerOpenLoansList from '@/modules/broker-admin/components/BrokerOpenLoansList.vue';
import { CompanyInfo, Security } from '@/modules/common/models';
import { LoanStatus } from '@/utils/api/loans';
import { SortModelItem } from 'ag-grid-enterprise';

const MAX_ITEMS_PER_PAGE = 1000;

@Component({
  provide() {
    return {
      gridStateKey: 'BrokerOpenLoansListView',
    };
  },
  components: {
    DashboardPanel,
    SnapshotManager,
    BrokerOpenLoansList,
  },
})
export default class BrokerOpenLoansListView extends Vue {
  protected sort: SortModelItem = { colId: 'updatedAt', sort: 'desc' };
  protected page = 1;
  protected pageSize = MAX_ITEMS_PER_PAGE;
  protected security: Security | null = null;
  protected side: 'LENDER' | 'BORROWER' | null = null;
  protected showAll = false;
  protected lender: null | CompanyInfo = null;
  protected borrower: null | CompanyInfo = null;
  protected statuses: null | LoanStatus[] = null;
  protected displayId: null | string = null;

  protected get snapshot(): Record<string, string | SortModelItem> {
    const snapshot: Record<string, string | SortModelItem> = {
      showAll: this.showAll ? 'true' : 'false',
      sort: `${this.sort.sort === 'desc' ? '-' : ''}${this.sort.colId}`,
      page: this.page ? this.page.toString() : '1',
      pageSize: this.pageSize ? this.pageSize.toString() : MAX_ITEMS_PER_PAGE.toString(),
    };
    if (this.lender) {
      snapshot.lender = this.lender.displayBoxId as string;
    }
    if (this.borrower) {
      snapshot.borrower = this.borrower.displayBoxId as string;
    }
    if (this.security) {
      snapshot.cusip = this.security.cusip;
    }
    if (this.statuses) {
      snapshot.statuses = this.statuses ? this.statuses.join(',') : '';
    }
    if (this.displayId) {
      snapshot.displayId = this.displayId;
    }

    return snapshot;
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      lender: async (value) => {
        try {
          const res = await this.$api.userAccounts.fetchCompanyByDisplayBoxId(value);
          if (res) {
            this.lender = res;
          } else {
            throw new Error('Failed to fetch selected lender');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      borrower: async (value) => {
        try {
          const res = await this.$api.userAccounts.fetchCompanyByDisplayBoxId(value);
          if (res) {
            this.borrower = res;
          } else {
            throw new Error('Failed to fetch selected borrower');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      cusip: async (value) => {
        try {
          const res = await this.$api.manualLoans.fetchSecurityByCusip(value);
          if (res) {
            this.security = res;
          } else {
            throw new Error('Failed to fetch selected security');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      statuses: (value) => {
        if (value.length > 0) {
          this.statuses = value.split(',') as LoanStatus[];
        }
      },
      displayId: (value) => {
        this.displayId = value;
      },
      sort: (value) => {
        this.sort = {
          colId: value.replace(/^-/, ''),
          sort: value.startsWith('-') ? 'desc' : 'asc',
        };
      },
      page: (value) => {
        this.page = parseInt(value, 10);
      },
      pageSize: (value) => {
        this.pageSize = parseInt(value, 10);
      },
    };
  }
}
</script>

<style lang="scss"></style>
