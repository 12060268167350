import { CustomRule } from 'vuelidate/lib/validators';
import { Security } from '@/modules/common/models';

/**
 * Vuelidate custom validator:
 * A security must be tradeable before we can submit an order
 */

// canTradeSecurity - a security is tradeable if it does NOT have a 'cannotTradeMessage'
export const canTradeSecurity = (): CustomRule => (security: Security | null) => {
  return security !== null && !security.cannotTradeMessage;
};
