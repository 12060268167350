<template>
  <span class="is-date">{{ prettyFormat() }}</span>
</template>

<script lang="ts">
/**
 * Display date nicely
 */
import { Component, Vue } from 'vue-property-decorator';
import { isSameDay } from 'date-fns';
import { formatDate } from '@/utils/helpers/dates';

@Component({
  props: {
    when: Date,
    alwaysLong: Boolean,
  },
})
export default class PrettyDate extends Vue {
  private when!: Date;
  private alwaysLong!: boolean;

  private baseFormat = 'MMM dd';

  protected prettyFormat(): string {
    if (isSameDay(this.when, new Date()) && !this.alwaysLong) {
      return `${this.$t('today')}`;
    } else {
      return formatDate(this.when, `${this.baseFormat}`);
    }
  }
}
</script>
<style lang="scss" scoped>
.is-date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
