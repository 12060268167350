<template>
  <v-app>
    <v-main>
      <AppUpgrade v-if="isUpgradeAvailable"></AppUpgrade>
      <Sidebar v-if="isLoggedIn" />
      <Header v-if="isLoggedIn"></Header>
      <v-container class="fill-height align-start" fluid>
        <router-view></router-view>
      </v-container>
      <Snackbar></Snackbar>
      <ConfirmDialogImperative></ConfirmDialogImperative>
      <OnlineIndicator v-if="isLoggedIn"></OnlineIndicator>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import AppUpgrade from '@/AppUpgrade.vue';
import Header from '@/modules/common/components/Header.vue';
import OnlineIndicator from '@/modules/common/components/OnlineIndicator.vue';
import ConfirmDialogImperative from '@/modules/common/components/popups/ConfirmDialogImperative.vue';
import logger from '@/modules/common/services/logger.service';
import { updateStoreTime } from '@/modules/market-closed/helpers/market-closed';
import Sidebar from '@/modules/sidebar/components/Sidebar.vue';
import Snackbar from '@/modules/snackbar/components/Snackbar.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapState } from 'vuex';
import { useWake } from './composables/sleep';
import { useStoreUI } from './store/store-ui';

@Component({
  name: 'App',
  components: {
    AppUpgrade,
    Header,
    Sidebar,
    Snackbar,
    ConfirmDialogImperative,
    OnlineIndicator,
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['frontendHash']),
  },
  methods: {
    ...mapActions(['refreshCurrentTime']),
  },
  setup() {
    const storeUI = useStoreUI();
    storeUI.restoreIsDarkMode();

    useWake(() => location.reload());
  },
})
export default class App extends Vue {
  protected isUpgradeAvailable = false;
  protected isLoggedIn!: boolean;
  protected frontendHash!: string;

  protected refreshCurrentTime!: () => void;

  @Watch('frontendHash')
  protected observeUpgrade(): void {
    const buildHash = import.meta.env.VITE_APP_FRONTEND_HASH;
    logger.debug(`build.frontendHash=${buildHash} state.frontendHash=${this.frontendHash}`);

    if (!buildHash || !this.frontendHash) {
      // this frontend does not know about versioning (dev?)
      return;
    }

    this.isUpgradeAvailable = buildHash !== this.frontendHash;
  }

  protected mounted(): void {
    const buildHash = import.meta.env.VITE_APP_FRONTEND_HASH;
    logger.debug(`build.frontendHash=${buildHash} state.frontendHash=${this.frontendHash}`);

    // periodically update the store time, used to determine timeframes by market-closed
    updateStoreTime(this.refreshCurrentTime);
  }
}
</script>

<style lang="sass">
$color-pack: false

/* Vuetify uses Google’s Roboto font and Material Design Icons. The simplest way to install these are to include their CDN’s in your main index.html file. */
/* <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet"> */
@import './public/vuetify/roboto-font.css'
/* <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet"> */
@import './public/vuetify/materialdesignicons.min.css'

@import './public/ag-grid/ag-grid.css'
@import './public/ag-grid/ag-theme-material.css'
@import './public/ag-grid/ag-theme-balham.css'
</style>

<style lang="scss">
// Provable Labs Brand Colors (DO NOT CHANGE)
$pl-white: #ffffff;
$pl-black: #16212b;
// NOTE: no dark in the branding document, replaced by 'kind of black'!
$pl-dark-green: #16212b;
$pl-medium-green: #327d7b;
$pl-light-green: #5bc477;
$pl-dark-gradient-left: #26757e;
$pl-dark-gradient-right: #39d1a5;
$pl-light-gradient-left: #499e70;
$pl-light-gradient-right: #6eeb7e;
$pl-gray: #4a4a4a;

$pl-font-bold: 'Eina04-Bold';
$pl-font-semibold: 'Eina04-SemiBold';
$pl-font-regular: 'Eina04-Regular';
$pl-font-light: 'Eina04-Light';
$pl-font-bolditalic: 'Eina04-BoldItalic';
$pl-font-semibolditalic: 'Eina04-SemiBoldItalic';
$pl-font-regularitalic: 'Eina04-RegularItalic';
$pl-font-lightitalic: 'Eina04-LightItalic';

// fix: vuetify uses !important on display which overrides v-show directive
.v-application *[style*='display: none'],
*[style*='display: none'] {
  display: none !important;
}

.v-toolbar .v-btn:not(:first-child) {
  margin-left: 24px;
}

.pl-gradient-colors {
  background: linear-gradient(0deg, $pl-light-gradient-left, $pl-light-gradient-right);
  background-clip: text;
  color: transparent;
  font-size: 20px;
  font-weight: 800;
}

.aurora-drag-handle {
  cursor: move;
}

.aurora-data-table {
  .row.header {
    border-bottom: solid $pl-gray 1px;

    .col {
      opacity: 0.7;
      font-size: 0.75rem;
      font-weight: bold;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}

html,
body {
  overscroll-behavior: none;
}

.row {
  flex: 0 1 auto;
}

.row {
  flex: 0 1 auto;
}

.v-data-table {
  th.is-numeric,
  td.is-numeric,
  span.is-numeric {
    font-family: 'Courier New', Courier, monospace !important;
  }

  .row-icon {
    padding-right: 0 !important;
  }

  .row-icon {
    padding-right: 0 !important;
  }
}

.theme--dark.v-data-table > .v-data-table__wrapper > table {
  td:not(.v-data-table__mobile-row),
  th:not(.v-data-table__mobile-row) {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-bottom: solid #393939 1px;
  }
}

table.is-stacked-order-list {
  td {
    border-bottom-color: transparent !important;
  }
}

.v-data-table {
  // no spacing around checkboxes in a table
  .v-input--selection-controls.v-input--hide-details {
    margin-top: 0;
    padding-top: 0;
  }
}

.v-data-table.border-only-on-hover {
  th:not(:first-child):not(:last-child):not(.vertical-sep-left):not(.vertical-sep-right) {
    border-right: 1px solid transparent !important;
  }

  td:not(:first-child):not(:last-child):not(.vertical-sep-left):not(.vertical-sep-right) {
    border-right: 1px solid transparent !important;
  }

  &.on-hover {
    td:not(:last-child):not(.vertical-sep-left):not(.vertical-sep-right) {
      border-right: 1px solid #393939 !important;
    }
  }
}

/* custom dialog styles */
.au-popup-dialog {
  position: relative;

  .v-card {
    padding-top: 20px;
  }

  .v-card__title {
    $v-card-title-border: 6px;

    border-left: #2c8d3a $v-card-title-border solid;
    border-radius: 0 !important;
    padding-left: 28px - $v-card-title-border;
    margin-bottom: 12px;
    text-transform: capitalize;

    .headline-2 {
      font-size: 16px;
      padding-right: 16px;
    }

    .v-divider--vertical {
      border-color: $pl-light-green;
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .v-card-title-error {
    $v-card-title-border: 6px;
    border-left: #ff5252 $v-card-title-border solid;
  }

  .v-card__text.au-user-feedback {
    min-height: 100px;
  }

  .v-card__actions {
    // @TODO: give action-bar a different background or border?
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 16px;
  }

  .v-alert {
    margin-bottom: 0;

    .title {
      color: unset;
    }
  }

  .v-data-table__wrapper {
    tr:hover {
      background: unset !important;
    }
  }

  .message-spacer {
    min-height: 65px;
  }

  .v-btn__content {
    padding-left: 8px;
    padding-right: 8px;
  }

  .v-card__actions > .v-btn.v-btn .v-icon--left {
    margin-left: 0 !important;
  }

  .v-list-item--dense,
  .v-list--dense .v-list-item {
    min-height: unset;
  }

  // why so much margin and padding above every input ???
  // probably to mirror the spacing below the input reserved for error messages
  .v-input--selection-controls.v-input--hide-details {
    margin-top: 0;
    padding-top: 0;
  }
}

.aurora-fab-button.bottom.left {
  left: 16px;
  bottom: 16px;
}

.aurora-fab-button.bottom.center {
  left: calc(50% - 32px);
  bottom: 16px;
}

.aurora-fab-button.bottom.right {
  right: 16px;
  bottom: 16px;
}

.aurora-keep-case {
  text-transform: none;
}

.is-security-name {
  code {
    color: #fff;
    background-color: $pl-gray;
    font-size: 11px;
    margin-left: 4px;
  }
}

/* Maximum width for flex elements displaying a security ticker */
.max-ticker-width {
  // Ticker can be up to 5 characters long
  max-width: calc(5ch * 1.4);
}

/* animations */
.flip-list-move {
  transition: transform 0.8s !important;
}

html {
  // set background color that will be seen when elastic scrolling is in effect (MacOs)
  background-color: #272727; // dark theme @TODO light theme

  // remove unnecessary scrollbar on entire page (added by Vuetify css reset)
  overflow-y: auto;

  ::-webkit-scrollbar-corner {
    // remove white in right bottom corner whell both X and Y scrollbars are visible
    background: transparent;
  }

  /* beautify scrollbars */
  --scrollbarBG: #e1e1e1; // dark background;
  --thumbBG: #929292; // secondary color;
}

.ag-theme-material-dark {
  // make table more compact
  --ag-row-height: 33px;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarBG); // hide thumb
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG); // show thumb
}

/* @TODO: who overrides vertical-align and sets it to top?? */
th,
td {
  vertical-align: inherit !important;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // hack to make autofill inputs transparent (unless you wait 7200s)
  transition: background-color 7200s;
  -webkit-text-fill-color: #fff !important;
}

// Hide the step spinner for number inputs
input[type='number'] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 0;
    pointer-events: none;
  }
}

// the last column of the table will be pinned to the right
// when the class "last-col-sticky" is added to the table
.last-col-sticky .v-data-table__wrapper tr td:last-child {
  position: sticky;
  right: 0;
  width: 0;
}

.theme--light {
  .last-col-sticky .v-data-table__wrapper tr td:last-child {
    background: #ffffff;
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }
  .last-col-sticky .v-data-table__wrapper tr td:last-child:hover,
  .last-col-sticky .v-data-table__wrapper tr:hover td {
    background: #eeeeee;
  }
}

.theme--dark {
  .last-col-sticky .v-data-table__wrapper tr td:last-child {
    background: #1e1e1e;
    border-left: thin solid rgba(255, 255, 255, 0.12);
  }
  .last-col-sticky .v-data-table__wrapper tr.v-data-table__selected td:last-child {
    background: #505050;
  }
  .last-col-sticky .v-data-table__wrapper tr td:last-child:hover,
  .last-col-sticky .v-data-table__wrapper tr:hover td,
  .last-col-sticky .v-data-table__wrapper tr.v-data-table__selected:hover td:last-child {
    background: #616161;
  }

  /* beautify scrollbars */
  --scrollbarBG: #1e1e1e; // dark background;
  --thumbBG: #607d8b; // secondary color;
}

.icon-action {
  opacity: 0.4;
  transition: opacity linear 0.15s;
}

.icon-action:hover {
  opacity: 1;
}
</style>
