import { SuppressHeaderKeyboardEventParams } from 'ag-grid-enterprise';

const SELECTOR_GRID_BODY = `.ag-body`;
const SELECTOR_GRID_CELL = `.ag-cell`;
const SELECTOR_FOCUSABLE =
  ':is(button, a[href], input:not([type="hidden"]), select, textarea, [tabindex]):not([disabled], [tabindex^="-"]), [role="gridcell"]';

function getAllGridCells(el: HTMLElement): HTMLElement[] {
  return Array.from(
    el.closest(SELECTOR_GRID_BODY)?.querySelectorAll<HTMLElement>(SELECTOR_GRID_CELL) ?? []
  );
}

function getAllFocusableElementsOf(el: Element): HTMLElement[] {
  return Array.from(el.querySelectorAll<HTMLElement>(SELECTOR_FOCUSABLE));
}

/**
 * Capture whether the user is tabbing forwards or backwards and suppress keyboard event if tabbing
 * outside of the children
 */
export function suppressKeyboardEvent({ event }: SuppressHeaderKeyboardEventParams): boolean {
  const { key, shiftKey: isShiftKeyPressed } = event;

  if (key !== 'Tab') return false;

  // The event has not complete, the current active element is not the one we'll be focusing in a moment
  const allCells = getAllGridCells(document.activeElement as HTMLElement);
  const lastFocusedElementIndex = allCells.indexOf(document.activeElement as HTMLElement);
  const activeElement =
    lastFocusedElementIndex < 0
      ? (document.activeElement as HTMLElement)
      : allCells[
          (allCells.length + lastFocusedElementIndex + (isShiftKeyPressed ? -1 : 1)) %
            allCells.length
        ];

  // Handle cell children tabbing
  const eGridCell = activeElement.closest(SELECTOR_GRID_CELL);
  if (eGridCell === null) return false;

  // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
  const focusableChildrenElements = getAllFocusableElementsOf(eGridCell);
  if (focusableChildrenElements.length === 0) return false;

  const currentIndex = focusableChildrenElements.indexOf(activeElement);

  const isTabForward = !isShiftKeyPressed;
  if (isTabForward) {
    const isLastChildFocused = currentIndex === focusableChildrenElements.length - 1;
    if (isLastChildFocused) return false;

    if (currentIndex !== -1 || document.activeElement === eGridCell) {
      event.preventDefault();
      focusableChildrenElements[currentIndex + 1].focus();
    }
    return true;
  }

  // Suppress keyboard event if tabbing backwards within the cell
  // and the current focused element is not the first child

  // Manually set focus to the last child element if cell doesn't have focused children
  const hasFocusedChildrenInCell = currentIndex > -1;
  if (!hasFocusedChildrenInCell) {
    const lastCellChildEl = focusableChildrenElements[focusableChildrenElements.length - 1];
    lastCellChildEl.focus();

    // Cancel keyboard press, so that it doesn't focus on the last child and
    // then pass through the keyboard press to move to the 2nd last child element
    event.preventDefault();
    return true;
  }

  const isFirstChildFocused = currentIndex === 0;
  if (isFirstChildFocused) return false;

  event.preventDefault();
  focusableChildrenElements[currentIndex - 1].focus();
  return true;
}
