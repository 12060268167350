import { GridApi, GridState } from 'ag-grid-enterprise';
import { pick } from 'lodash';

const GRID_COLUMN_STATE_KEY = 'gridColumnState';

export function persistColumnState(
  gridStateKey: string | undefined,
  gridApi: GridApi | null,
  tableId: string | undefined
): void {
  if (gridStateKey && gridApi && tableId) {
    saveToStorage(gridStateKey, tableId, gridApi.getState());
  }
}

export function retrieveColumnState(gridStateKey: string, tableId: string): GridState | null {
  const storedState = sessionStorage.getItem(getStorageKey(gridStateKey, tableId));
  return storedState ? JSON.parse(storedState) : null;
}

function saveToStorage(gridStateKey: string, tableId: string, state: GridState): void {
  sessionStorage.setItem(
    getStorageKey(gridStateKey, tableId),
    JSON.stringify(
      // we pick because we don't want to save things like selected items, and possibly other things
      pick(state, ['columnPinning', 'columnSizing', 'columnOrder', 'columnVisibility', 'sort'])
    )
  );
}

function getStorageKey(gridStateKey: string, tableId: string): string {
  return `${GRID_COLUMN_STATE_KEY}--${gridStateKey}--${tableId}`;
}
