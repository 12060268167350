<template>
  <v-container class="update-bar px-16 mr-16" fluid>
    <v-row align="center">
      <v-col>
        <countdown
          action-text="reload-frontend-now"
          class="mt-0 px-0"
          expired-message="rebooting"
          :height="54"
          info-color="success"
          info-message="reload-frontend"
          :when="rebootAt"
          with-action-button
          @expired-countdown="reloadFrontend"
        ></countdown>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Countdown from '@/modules/common/components/Countdown.vue';
import { add as dateAdd } from 'date-fns';

@Component({
  components: { Countdown },
  data: () => ({}),
})
export default class AppUpgrade extends Vue {
  // give users 2 minutes to finish what they are doing
  private rebootAt = dateAdd(new Date(), { seconds: 120 });

  protected reloadFrontend(): void {
    location.reload();
  }
}
</script>

<style lang="scss" scoped>
.v-main {
  .update-bar {
    background-color: #000;
    position: relative;
    z-index: 210;
    border: solid slategray 1px;
    border-radius: 8px;
  }
}
</style>
