<!-- This component implements <ConfirmDialogMarkup> in an imperative way.

     It is very practical because it can be triggered from anywhere in the application.
     However, because it's a direct child of <App>, it only emits events to <App>,
     making composing related components with $emit impossible. -->
<template>
  <confirm-dialog-markup v-model="value" :options="options" @accept="accept" @reject="reject" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ConfirmDialogImperativeOptions } from '@/plugins/dialog-manager';
import ConfirmDialogMarkup from '@/modules/common/components/popups/ConfirmDialogMarkup.vue';

@Component({
  components: {
    ConfirmDialogMarkup,
  },
})
export default class ConfirmDialogImperative extends Vue {
  protected value = false;
  protected options: ConfirmDialogImperativeOptions = {};

  protected created(): void {
    this.$dialog.$root.$on('askQuestion', (options: ConfirmDialogImperativeOptions) => {
      this.ask(options);
    });
    this.$dialog.$root.$on('confirmMessage', (options: ConfirmDialogImperativeOptions) => {
      this.confirm(options);
    });
    this.$dialog.$root.$on('alertMessage', (options: ConfirmDialogImperativeOptions) => {
      this.alert(options);
    });
    this.$dialog.$root.$on('closeDialog', () => {
      this.close();
    });
  }

  private ask(options: ConfirmDialogImperativeOptions) {
    this.options = options;
    this.value = true;
  }

  private confirm(options: ConfirmDialogImperativeOptions) {
    this.options = {
      color: 'primary',
      acceptText: 'Okay',
      isRejectable: false,
      ...options,
    };
    this.value = true;
  }

  private alert(options: ConfirmDialogImperativeOptions) {
    this.options = {
      color: 'error',
      acceptText: 'Okay',
      isRejectable: false,
      ...options,
    };
    this.value = true;
  }

  private accept() {
    if (this.options.onAccept) {
      this.options.onAccept();
    }
    this.close();
  }

  private reject() {
    if (this.options.onReject) {
      this.options.onReject();
    }
    this.close();
  }

  private close() {
    this.value = false;
  }
}
</script>
