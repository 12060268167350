<template>
  <v-card class="flex d-flex flex-column">
    <loan-details-dialog
      v-if="detailLoan"
      ref="loan-details-dialog"
      :loan-id="detailLoan.id"
      @close-modal="detailLoan = null"
    />
    <snapshot-manager
      :drivers="['sessionStorage']"
      :get-state-mutators="getStateMutators"
      :snapshot="snapshot"
      storage-key="snapshot-marketplace-symbol-overview-loans"
    >
      <open-loans-list
        :counterparty="null"
        :detail-loan.sync="detailLoan"
        :items-per-page="itemsPerPage"
        :omit-headers="[
          'equity.ticker',
          'equity.cusip',
          'renegotiateRate',
          'nextAllowedBuyInExecutionDate',
          'recalledQuantity',
          'displayId',
          'dtccRefId',
          'lenderDisplay',
          'borrowerDisplay',
          'independentAmount',
          'yesterdayIndependentAmount',
        ]"
        :page.sync="page"
        :page-size="1000"
        :security="security"
        :show-dropdown-actions="false"
        :show-select="false"
        :show-table-filters="false"
        :show-tabs="false"
        :side="null"
        :sort.sync="sort"
      />
    </snapshot-manager>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import { LoginState, SocketEvents } from '@/store/store';
import { ClientConfig } from '@/utils/helpers/rest';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import { OpenLoan, Security } from '@/modules/common/models';
import OpenLoansList from '@/modules/open-loans/components/OpenLoansList.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import { SortModelItem } from 'ag-grid-enterprise';

const MAX_ITEMS_PER_PAGE = 50;

@Component({
  components: {
    RateOutput,
    LoanStatusChip,
    LoanDetailsDialog,
    SnapshotManager,
    OpenLoansList,
  },
  props: {
    security: Object as PropType<Security>,
  },
  computed: {
    ...mapState(['clientConfig', 'loginState', 'socketEvents']),
  },
})
export default class MarketplaceSymbolOverviewLoans extends Vue {
  // props
  protected readonly security!: Security;

  // store state
  protected clientConfig!: ClientConfig;
  protected loginState!: LoginState;
  protected socketEvents!: SocketEvents;

  protected page = 1;
  protected itemsPerPage = MAX_ITEMS_PER_PAGE;
  protected side: 'LENDER' | 'BORROWER' | null = null;
  protected sort: SortModelItem = { colId: 'createdAt', sort: 'desc' };

  protected detailLoan: OpenLoan | null = null;

  protected get snapshot(): Record<string, string | SortModelItem> {
    const snapshot: Record<string, string | SortModelItem> = {
      sort: JSON.stringify(this.sort || { coldId: 'createdAt', sort: 'desc' }), // or 'asc'?
      page: this.page ? this.page.toString() : '1',
      itemsPerPage: this.itemsPerPage
        ? this.itemsPerPage.toString()
        : MAX_ITEMS_PER_PAGE.toString(),
    };
    if (this.security) {
      snapshot.cusip = this.security.cusip;
    }

    return snapshot;
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      sort: (value) => {
        this.sort = JSON.parse(value);
      },
      page: (value) => {
        this.page = parseInt(value, 10);
      },
      itemsPerPage: (value) => {
        this.itemsPerPage = parseInt(value, 10);
      },
    };
  }
}
</script>
