<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    light
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title> Enable 2FA </v-card-title>

        <v-card-text v-if="has2faEnabled && !isProcessing">
          Two-Factor Authentication is already enabled.
        </v-card-text>
        <v-card-text v-else>
          <p>
            {{ $t('doEnable2FA.text') }}
          </p>
          <v-row>
            <v-col class="pa-0" cols="5">
              <qrcode :options="{ width: 250 }" :value="qrCode"></qrcode>
            </v-col>
            <v-col class="pt-5">
              <p>{{ $t('registerManual2FA.title') }}</p>
              <p>{{ $t('registerManual2FA.text') }}</p>
              <p>{{ $t('registerAccount2FA.text', { account: accountName }) }}</p>
              <p>{{ $t('registerKey2FA.text', { key: issuerSecret }) }}</p>
              <p>{{ $t('registerTimeBased2FA.text') }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-8">
              <p>Enter the 6-digit code from your app:</p>
              <v-otp-input
                ref="otpInput"
                v-model="otp"
                :disabled="isProcessing"
                :error-messages="errorMsgs['otp']"
                length="6"
                plain
                type="number"
                @finish="
                  $v.otp.$touch();
                  submitForm();
                "
              />
              <label class="error--text">{{ errorMsgs['otp'].join('\n') }}</label>
            </v-col>
          </v-row>

          <v-row v-if="showSuccess">
            <v-col class="pa-0 px-1 col-6 offset-3">
              <div class="v-alert v-alert--dense text--primary text-body-2 text-center success">
                Two-Factor Authentication has been enabled.
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">
            {{ $tc('dialogs.cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="px-6"
            color="primary"
            :disabled="isProcessing || has2faEnabled"
            type="submit"
            @click="submitForm"
          >
            Enable 2FA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import wait from '@/modules/common/services/wait';
import { AppState } from '@/store/store';
import Component, { mixins } from 'vue-class-component';
import { minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapActions, mapState } from 'vuex';
import { errorString } from '@/utils/helpers/rest-response';

interface FormErrors {
  otp: string[];
}

@Component({
  methods: {
    ...mapActions(['fetchCurrent2FA', 'enable2FALogin']),
  },
  computed: {
    ...mapState(['loginState']),
  },
  mixins: [validationMixin],
  validations: function (this: Add2faDialog) {
    return {
      otp: {
        required,
        minLength: minLength(6),
      },
    };
  },
})
export default class Add2faDialog extends mixins(validationMixin) {
  public $refs!: {
    otpInput?: HTMLInputElement;
  };

  // store state
  protected readonly loginState!: AppState['loginState'];

  // store actions
  protected readonly fetchCurrent2FA!: () => Promise<void>;
  protected readonly enable2FALogin!: (tfaCode: string) => Promise<void>;

  protected otp = '';
  protected isProcessing = false;
  protected showSuccess = false;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      otp: [],
    };

    // otp errors
    if (this.$v.otp.$dirty) {
      if (!this.$v.otp.required) errors.otp.push('please enter a code to continue.');
      if (!this.$v.otp.minLength) errors.otp.push('please enter a 6 digit code.');
    }

    return errors;
  }

  protected get issuer(): string {
    return this.loginState.tfa?.tfaIssuer || '';
  }

  protected get accountName(): string {
    return this.loginState.tfa
      ? `${this.loginState.tfa.tfaIssuer}:${this.loginState.tfa.tfaIssuerName}`
      : '';
  }

  protected get issuerSecret(): string {
    return this.loginState.tfa?.tfaIssuerSecret || '';
  }

  protected get qrCode(): string {
    return `otpauth://totp/${this.accountName}?secret=${this.issuerSecret}&amp;issuer=${this.issuer}`;
  }

  protected get has2faEnabled(): boolean {
    return !!this.loginState.tfa?.tfaIsEnabled;
  }

  protected mounted(): void {
    this.autofocusOtpInput();
    void this.fetchCurrent2FA();
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.isProcessing || !this.validateForm()) {
      return;
    }

    this.isProcessing = true;

    try {
      // validation has passed
      await this.enable2FALogin(this.otp);

      // success, alert user and then close form
      this.showSuccess = true;

      // reload 2fa status in the background
      void this.fetchCurrent2FA();

      await wait(1200);
      await this.closeDialog();
    } catch (err) {
      this.errorMsgs.otp = [errorString(err)];
      this.autofocusOtpInput();
    } finally {
      this.isProcessing = false;
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  private autofocusOtpInput(): void {
    this.$refs.otpInput?.focus();
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.$v.$touch();
    return !this.$v.$anyError;
  }
}
</script>
