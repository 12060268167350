<template>
  <broker-stats />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BrokerStats from '@/modules/broker-admin/components/BrokerStats.vue';

@Component({
  components: {
    BrokerStats,
  },
})
export default class BrokerStatsHome extends Vue {}
</script>

<style lang="scss" scoped></style>
