import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { RawSecurity, Security } from '@/modules/common/models';
import { RawVenueTopOfBookItem, VenueTopOfBookItem } from '@/modules/marketplace/models';

export type RawTopOfBookWatchlistsResponse = Raw<
  TopOfBookWatchlistsResponse,
  {
    // always specify existing raw entry types explititly
    items: RawTopOfBookWatchlist[];
  }
>;

export class TopOfBookWatchlistsResponse {
  public items: TopOfBookWatchlist[];

  protected constructor(data: RawTopOfBookWatchlistsResponse) {
    this.items = data.items.map<TopOfBookWatchlist>(TopOfBookWatchlist.fromData);
  }

  public static fromData(data: RawTopOfBookWatchlistsResponse): TopOfBookWatchlistsResponse {
    return new TopOfBookWatchlistsResponse(data);
  }
}

export type RawTopOfBookWatchlist = Raw<TopOfBookWatchlist>;

export class TopOfBookWatchlist {
  public displayId: string;
  public id: string;
  public name: string;
  public shared: boolean;

  protected constructor(data: RawTopOfBookWatchlist) {
    this.displayId = data.displayId;
    this.id = data.id;
    this.name = data.name;
    this.shared = data.shared;
  }

  public static fromData(data: RawTopOfBookWatchlist): TopOfBookWatchlist {
    return new TopOfBookWatchlist(data);
  }

  public static mock(data?: null | DeepPartial<RawTopOfBookWatchlist>): TopOfBookWatchlist {
    return TopOfBookWatchlist.fromData(TopOfBookWatchlist.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawTopOfBookWatchlist>): RawTopOfBookWatchlist {
    return {
      id: 'cafec0de-face-f00d-acdc-abcdef123000',
      displayId: 'fooDisplayId',
      name: 'FOO',
      shared: false,

      ...data,
    };
  }
}

export type RawTopOfBookWatchlistContent = Raw<
  TopOfBookWatchlistContent,
  {
    // always specify existing raw entry types explititly
    data: RawVenueTopOfBookItem[];
  }
>;

export class TopOfBookWatchlistContent extends TopOfBookWatchlist {
  public data: VenueTopOfBookItem[];

  protected constructor(data: RawTopOfBookWatchlistContent) {
    super(data);

    this.data = data.data.map<VenueTopOfBookItem>(VenueTopOfBookItem.fromData);
  }

  public static fromData(data: RawTopOfBookWatchlistContent): TopOfBookWatchlistContent {
    return new TopOfBookWatchlistContent(data);
  }
}

export type RawTopOfBookWatchlistWithInstrumentsResponse = Raw<
  TopOfBookWatchlistWithInstrumentsResponse,
  {
    // always specify existing raw entry types explititly
    instruments: RawSecurity[];
  },
  'securities'
>;

export class TopOfBookWatchlistWithInstrumentsResponse extends TopOfBookWatchlist {
  public securities: Security[];

  protected constructor(data: RawTopOfBookWatchlistWithInstrumentsResponse) {
    super(data);

    this.securities = data.instruments.map<Security>(Security.fromData);
  }

  public static fromData(
    data: RawTopOfBookWatchlistWithInstrumentsResponse
  ): TopOfBookWatchlistWithInstrumentsResponse;
  public static fromData(
    data: RawTopOfBookWatchlistWithInstrumentsResponse | null
  ): TopOfBookWatchlistWithInstrumentsResponse | null;
  public static fromData(
    data: RawTopOfBookWatchlistWithInstrumentsResponse | undefined
  ): TopOfBookWatchlistWithInstrumentsResponse | undefined;
  public static fromData(
    data: RawTopOfBookWatchlistWithInstrumentsResponse
  ): null | undefined | TopOfBookWatchlistWithInstrumentsResponse {
    return new TopOfBookWatchlistWithInstrumentsResponse(data);
  }

  public static mock(
    data?: null | DeepPartial<RawTopOfBookWatchlistWithInstrumentsResponse>
  ): TopOfBookWatchlistWithInstrumentsResponse {
    return TopOfBookWatchlistWithInstrumentsResponse.fromData(
      TopOfBookWatchlistWithInstrumentsResponse.mockData(data)
    );
  }

  public static mockData(
    data?: null | DeepPartial<RawTopOfBookWatchlistWithInstrumentsResponse>
  ): RawTopOfBookWatchlistWithInstrumentsResponse {
    return {
      ...TopOfBookWatchlist.mockData(data),
      instruments: (data?.instruments ?? []).map(Security.mockData),
    };
  }
}

export type RawTopOfBookWatchlistSecuritiesUploadResponse = Raw<
  TopOfBookWatchlistSecuritiesUploadResponse,
  {
    // always specify existing raw entry types explititly
    instruments: RawSecurity[];
  },
  'securities'
>;

export class TopOfBookWatchlistSecuritiesUploadResponse {
  public securities: Security[];

  protected constructor(data: RawTopOfBookWatchlistSecuritiesUploadResponse) {
    this.securities = data.instruments.map<Security>(Security.fromData);
  }

  public static fromData(
    data: RawTopOfBookWatchlistSecuritiesUploadResponse
  ): TopOfBookWatchlistSecuritiesUploadResponse;
  public static fromData(
    data: RawTopOfBookWatchlistSecuritiesUploadResponse | null
  ): TopOfBookWatchlistSecuritiesUploadResponse | null;
  public static fromData(
    data: RawTopOfBookWatchlistSecuritiesUploadResponse | undefined
  ): TopOfBookWatchlistSecuritiesUploadResponse | undefined;
  public static fromData(
    data: RawTopOfBookWatchlistSecuritiesUploadResponse
  ): null | undefined | TopOfBookWatchlistSecuritiesUploadResponse {
    return new TopOfBookWatchlistSecuritiesUploadResponse(data);
  }
}
