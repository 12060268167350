import { Raw } from '@/modules/common/helpers/api';

export type RawLoginConfirmIdentityResponse = Raw<LoginConfirmIdentityResponse>;

export class LoginConfirmIdentityResponse {
  public status: string;

  protected constructor(data: RawLoginConfirmIdentityResponse) {
    this.status = data.status;
  }

  public static fromData(data: RawLoginConfirmIdentityResponse): LoginConfirmIdentityResponse {
    return new LoginConfirmIdentityResponse(data);
  }
}
