import { Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, RawSecurity, Security } from '@/modules/common/models';
import {
  OrderSide,
  OrderType,
  RoutingStatus,
  SettlementType,
  TimeInForceType,
} from '@/modules/marketplace/types/marketplace';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import Decimal from 'decimal.js';

type RawOrderBasketParams = Raw<
  OrderBasketParams,
  {
    // always specify existing raw entry types explititly
    entries: RawOmsBasketItem[];
  }
>;

export abstract class OrderBasketParams {
  public companyId: string;
  public userId: string;
  public side: OrderSide;
  public entries: OmsBasketItem[];

  protected constructor(data: RawOrderBasketParams) {
    this.companyId = data.companyId;
    this.userId = data.userId;
    this.side = data.side;
    this.entries = data.entries.map<OmsBasketItem>(OmsBasketItem.fromData);
  }

  public static toData(model: OrderBasketParams): RawOrderBasketParams {
    return {
      companyId: model.companyId,
      userId: model.userId,
      side: model.side,
      entries: model.entries.map(OmsBasketItem.toData),
    };
  }
}

export type RawOmsBasketItem = Raw<
  OmsBasketItem,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
  },
  'security'
>;

export class OmsBasketItem {
  public security: Security;
  public side: OrderSide; // required by backend when we post back the basket, but not used in the UI
  public quantity: number;
  public type: OrderType;
  public rate: Decimal;
  public routingStatus: RoutingStatus;
  public timeInForceType: TimeInForceType;
  public counterparties: CompanyInfo[];
  public minQuantity: number;
  public row: number;
  public roundingRule: RoundingRule;
  public independentAmountRate: Decimal;
  public settlementType: SettlementType;

  protected constructor(data: RawOmsBasketItem) {
    this.security = Security.fromData(data.equity);
    this.side = data.side;
    this.quantity = data.quantity;
    this.type = data.type;
    this.rate = new Decimal(data.rate);
    this.routingStatus = data.routingStatus;
    this.timeInForceType = data.timeInForceType;
    this.counterparties = data.counterparties.map<CompanyInfo>(CompanyInfo.fromData);
    this.minQuantity = data.minQuantity;
    this.row = data.row;
    this.roundingRule = data.roundingRule;
    this.independentAmountRate = new Decimal(data.independentAmountRate);
    this.settlementType = data.settlementType;
  }

  public static fromData(data: RawOmsBasketItem): OmsBasketItem {
    return new OmsBasketItem(data);
  }

  public static toData(model: OmsBasketItem): RawOmsBasketItem {
    return {
      equity: Security.toData(model.security),
      side: model.side,
      quantity: model.quantity,
      type: model.type,
      rate: model.rate.toString(),
      routingStatus: model.routingStatus,
      timeInForceType: model.timeInForceType,
      counterparties: model.counterparties.map(CompanyInfo.toData),
      minQuantity: model.minQuantity,
      row: model.row,
      roundingRule: model.roundingRule,
      independentAmountRate: model.independentAmountRate.toString(),
      settlementType: model.settlementType,
    };
  }
}
