<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="1200"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="step !== 'confirmation' && closeDialog()"
    @keydown.esc="handleEscKey"
    @shortkey.native="handleEscKey"
  >
    <v-card elevation="0">
      <v-btn class="close-icon" icon @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-row
        v-if="fetchStatus === 'fetching'"
        class="justify-center pt-16 flex-column align-center"
      >
        <fulfilling-bouncing-circle-spinner :animation-duration="1500" color="#4caf50" :size="50" />
        <h3 class="mt-2">Fetching contract details...</h3>
      </v-row>

      <v-row
        v-else-if="fetchStatus === 'failed'"
        class="justify-center pt-16 flex-column align-center"
      >
        <v-alert dense type="error">
          {{ fetchError }}
        </v-alert>
      </v-row>

      <template v-else-if="fetchStatus === 'completed' && contract">
        <v-card-title class="mb-0">
          <div class="d-flex align-center">
            <span class="headline"> Manage <format-side :side="contract.side" /> Contract </span>
            <term-loans-status-chip class="justify-center ml-2" :contract="contract" />
          </div>
        </v-card-title>

        <term-loans-manage-quantities
          v-if="step === 'manage'"
          :contract="contract"
          :items.sync="updatedItems"
          :new-total-value="newTotalValue"
          @close-modal="closeDialog"
          @next="step = 'confirmation'"
        />

        <term-loans-manage-contract-confirmation
          v-if="step === 'confirmation'"
          :contract="contract"
          :items="updatedItems"
          :new-total-value="newTotalValue"
          @back="step = 'manage'"
          @close-modal="closeDialog"
        />
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Api } from '@/modules/common/types/api';
import { mapState } from 'vuex';
import TermLoansContractBodySummary from '@/modules/termloans/components/TermLoansContractBodySummary.vue';
import TermLoansManageQuantities from '@/modules/termloans/components/TermLoansManageQuantities.vue';
import TermLoansManageContractTotals from '@/modules/termloans/components/TermLoansManageContractTotals.vue';
import Decimal from 'decimal.js';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import TermLoansStatusChip from '@/modules/termloans/components/TermLoansStatusChip.vue';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import TermLoansManageContractConfirmation from '@/modules/termloans/components/TermLoansManageContractConfirmation.vue';
import { getItemPosition } from '@/modules/termloans/helpers/termloans';
import { SocketEvents } from '@/store/store';
import { TermLoanContractResponse } from '@/modules/termloans/models';

@Component({
  components: {
    TermLoansContractBodySummary,
    TermLoansManageQuantities,
    TermLoansManageContractConfirmation,
    TermLoansStatusChip,
    TermLoansManageContractTotals,
  },
  computed: {
    ...mapState(['socketEvents']),
  },
  props: {
    displayId: {
      type: String,
      required: true,
    },
  },
})
export default class TermLoansManageContractDialog extends Vue {
  // store
  protected socketEvents!: SocketEvents;

  // props
  protected readonly displayId!: string;

  protected securitySelectorValidationError: string | null = null;
  protected contract: TermLoanContractResponse | null = null;
  protected updatedItems: Api.TermLoans.UpdatedItem[] = [];
  protected step: 'manage' | 'confirmation' = 'manage';
  protected fetchStatus: 'fetching' | 'completed' | 'failed' = 'fetching';
  protected fetchError = '';
  protected apiError: string | null = null;

  protected get newTotalValue(): Decimal {
    return this.updatedItems.reduce((acc, item) => {
      return acc.plus(item.newQuantity ? item.newQuantity * item.lastClosePrice.toNumber() : 0);
    }, new Decimal(0));
  }

  protected mounted(): void {
    void this.fetch();
  }

  protected async fetch(): Promise<void> {
    try {
      this.contract = await this.$api.termLoans.fetchDetails(this.displayId);
      this.fetchStatus = 'completed';

      this.updatedItems = this.contract.items.map((item) => {
        return {
          cusip: item.security.cusip,
          ticker: item.security.ticker,
          lastClosePrice: item.security.lastClosePrice,
          currentQuantity: item.totalOpenQuantity,
          position: getItemPosition(
            item.security.lastClosePrice,
            item.totalOpenQuantity,
            (this.contract as TermLoanContractResponse).currentValue
          ),
          newQuantity: item.totalOpenQuantity,
          hasExceeded: false,
        };
      });
    } catch (err) {
      this.fetchError = new ApiError(i18nServerMessage(err as Error)).message;
      this.fetchStatus = 'failed';
    }
  }

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }

  protected handleEscKey(): void {
    if (this.step === 'confirmation') {
      this.step = 'manage';
    } else {
      this.closeDialog();
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  min-height: 15rem;
}

.actions {
  gap: 1rem;
}

.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}
</style>
