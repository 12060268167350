<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <term-loans-contract-body-summary :contract="contract" />
    <v-divider class="mt-3" />
    <div class="mt-4">Securities</div>
    <v-divider class="mt-4" />
    <div class="table-container mt-4">
      <div>
        <v-data-table
          v-if="contract.securities.length"
          disable-filtering
          disable-pagination
          disable-sort
          fixed-header
          hide-default-footer
          item-key="key"
          :items="contract.securities"
          width="100%"
        >
          <template #item="{ item }">
            <tr>
              <td>
                {{ item.ticker }}
              </td>
              <td>
                {{ item.cusip }}
              </td>
              <td>
                {{ item.name }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import TermLoansContractBodySummary from '@/modules/termloans/components/TermLoansContractBodySummary.vue';
import { Contract } from '@/modules/termloans/models';

@Component({
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
  },
  components: {
    TermLoansContractBodySummary,
  },
})
export default class TermLoansContractFullSummary extends mixins(validationMixin) {
  // props
  protected contract!: Contract;

  protected ratePrecision = RATE_PRECISION;
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  max-height: 30vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
