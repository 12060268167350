<template>
  <v-form :class="{ dense }" data-test-uploader-form="dialog" novalidate @submit.prevent>
    <v-card
      :class="{ 'pa-0': dense }"
      @dragover="$event.preventDefault()"
      @drop="dropHandler($event)"
    >
      <input
        ref="fileInput"
        :accept="uploadFileTypes"
        class="d-none"
        type="file"
        @change="onFileChange"
      />
      <template v-if="$slots.title">
        <v-card-title class="mb-0">
          <div class="d-flex flex-column">
            <span class="headline"><slot name="title" /></span>
          </div>
          <v-spacer />
        </v-card-title>
      </template>

      <v-card-text>
        <div class="pr-4 pl-2">
          <div class="drop-explanation d-flex flex-column align-center justify-center">
            <div>
              You can drag and drop a file, or copy and paste a spreadsheet or CSV anywhere here
            </div>
            <v-icon size="64"> mdi-cloud-upload </v-icon>
            <slot name="description" />
          </div>

          <!-- validation or api errors were found while submitting -->
          <v-alert v-if="errorMsg" class="mt-8" dense type="error">
            {{ errorMsg }}
          </v-alert>

          <!-- make right click "paste" possible; hide when uploading to avoid UI glitches -->
          <div v-if="!isUploading" class="right-click-paste-helper" contenteditable="true" />
        </div>
      </v-card-text>

      <v-card-actions class="d-flex">
        <div
          :class="`d-flex flex-grow-1 align-end ${hideCancelBtn ? 'justify-end' : 'justify-space-between '}`"
        >
          <v-btn v-if="!hideCancelBtn" color="secondary" @click="$emit('close-modal')">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            data-test="uploader-browse-files"
            :disabled="isUploading"
            :small="dense"
            type="submit"
            @click="uploadFile()"
          >
            Browse files
          </v-btn>
        </div>
      </v-card-actions>

      <v-progress-linear v-if="isUploading" color="primary" indeterminate />
    </v-card>
  </v-form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiError } from '@/utils/errors';

interface UploadErrorItem {
  row: number;
  errMsg: string;
  field?: string;
}

@Component({
  props: {
    execute: Function,
    hideCancelBtn: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    withDummyHeader: {
      type: Boolean,
      default: true,
    },
  },
})
export default class Uploader extends Vue {
  public $refs!: {
    fileInput: HTMLInputElement;
  };

  /*
   * Using a callback instead of v-on is an unorthodox solution.
   * Here it permits the whole submit/error worlkflow to be contained inside the component.
   */
  protected readonly execute!: <T>(file: File) => Promise<T>;
  protected readonly hideCancelBtn!: boolean;
  protected readonly dense!: boolean;
  protected readonly withDummyHeader!: boolean;

  protected readonly uploadFileTypes = '.csv, .tsv, .xlsx, .dummy, text/*';
  protected file: File | null = null;
  protected isUploading = false;
  protected errorMsg: string | null = null;
  protected parseErrors: UploadErrorItem[] = [];

  // using public methods in this component so we can more easily test
  // drag-and-drop and copy/paste
  public mounted(): void {
    document.addEventListener('paste', this.processPasteUpload);
  }

  public beforeDestroy(): void {
    document.removeEventListener('paste', this.processPasteUpload);
  }

  public onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.file = target.files && target.files[0];
    this.$emit('upload-method', 'file-input');
    void this.submit();
  }

  public async submit(): Promise<void> {
    this.errorMsg = null;
    this.parseErrors = [];
    this.isUploading = true;

    try {
      const res = await this.execute(this.file as File);
      this.$emit('upload', res);
    } catch (err) {
      const apiError = err as ApiError;

      if (Array.isArray(apiError.responseData?.errors)) {
        this.$emit('upload-with-errors', apiError.responseData);
        this.$emit(
          'parse-errors',
          apiError.responseData.errors.sort((a, b) => a.row - b.row)
        );
      } else if (apiError.responseData?.msgargs) {
        this.errorMsg = this.$i18n.t(
          apiError.responseData.msgkey as string,
          apiError.responseData.msgargs
        ) as string;
      } else if (apiError.responseData?.msgkey) {
        this.errorMsg = this.$i18n.t(apiError.responseData.msgkey as string) as string;
      } else {
        this.errorMsg = apiError.message;
      }
    } finally {
      this.isUploading = false;
      // reset file input (Chrome needs this to make sure user can upload same file again)
      this.$refs.fileInput.value = '';
      this.file = null;
    }
  }

  public uploadFile(): void {
    this.$refs.fileInput.click();
  }

  public dropHandler(ev: DragEvent): void {
    // prevent file from being opened or downloaded
    ev.preventDefault();

    if (!ev.dataTransfer) {
      return;
    }
    if (ev.dataTransfer.items.length !== 1) {
      this.errorMsg = 'Please drag only one file at a time';
      return;
    }
    if (ev.dataTransfer.items[0].kind !== 'file') {
      return;
    }

    this.file = ev.dataTransfer.items[0].getAsFile();

    if (this.file === null) {
      return;
    }

    if (
      ![
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/tab-separated-values',
      ].includes(this.file.type)
    ) {
      this.errorMsg = 'Please upload a .csv, .tsv. or .xlsx file';
      return;
    }

    this.$emit('upload-method', 'drag-and-drop');
    void this.submit();
  }

  public processPasteUpload(ev: ClipboardEvent): void {
    if (!ev.clipboardData) {
      return;
    }
    this.errorMsg = null;
    try {
      const raw = ev.clipboardData.getData('text');
      this.file = this.getFileFromClipboard(raw);
      this.$emit('upload-method', 'paste');
      void this.submit();
    } catch (e) {
      this.errorMsg = (e as Error).message;
    }
  }

  public getFileFromClipboard(content: string): File {
    // If it has a tab, it must be a TSV file
    // (i.e. CSV file with tabs will fail)
    const delimiter = content.includes('\t') ? '\t' : ',';

    if (this.withDummyHeader) {
      content = `backend expects a header row and will discard it\n${content}`;
    }

    // Use the original content (non-parsed) and
    // and use delimiter to determine file name and type
    return new File([new Blob([content])], `upload.${delimiter === ',' ? 'csv' : 'tsv'}`, {
      type: delimiter === ',' ? 'text/csv' : 'text/tab-separated-values',
    });
  }
}
</script>
<style lang="scss" scoped>
.drop-explanation {
  border: 1px dashed #ccc;
  padding: 2rem;
}

.dense .drop-explanation {
  padding: 1rem;
}

.right-click-paste-helper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  caret-color: transparent;
  outline: none;
  color: transparent;
}
</style>
