<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="700px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t('loginForgotPasswordTitle', { title: clientConfig.systemTitleShort })
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="message-spacer" cols="12">
                <v-alert v-if="svErrors !== ''" dense type="error"
                  >{{ svErrors }}<br />{{ $t('loginForgotPasswordError2') }}
                </v-alert>
                <v-alert v-else elevation="2" icon="mdi-information"
                  >{{ $t('loginForgotPasswordInfo1', { title: clientConfig.systemTitleShort })
                  }}<br />{{ $t('loginForgotPasswordInfo2') }}
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  ref="autofocus"
                  v-model="emailAddress"
                  autocomplete="username email"
                  :error-messages="clErrors.emailAddress"
                  :label="$t('loginEmail')"
                  required
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text to="/login"> Back to login </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="150" type="submit">
            <v-icon left>mdi-email</v-icon>
            {{ $t('loginResetButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import { errorString } from '@/utils/helpers/rest-response';
import { FormValidator, setInputFocus } from '@/utils/helpers/input-form';
import { mapActions, mapState } from 'vuex';
import i18n from '@/localisation/i18n';

@Component({
  props: {
    lostEmail: {
      type: String,
      default: '',
    },
    lostMessage: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    emailRules: [(v: string) => /.+@.+/.test(v) || v === '' || i18n.t('formfield.InvalidEmail')],
  }),
  computed: {
    ...mapState(['clientConfig']),
  },
  methods: {
    ...mapActions(['requestPasswordReset']),
  },
})
export default class LostPassword extends Vue {
  public $refs!: {
    autofocus: HTMLFormElement;
  };

  public emailAddress = '';

  // props
  protected readonly lostEmail!: string;
  protected readonly lostMessage!: string;

  protected requestPasswordReset!: (email: string) => Promise<void>;

  protected clErrors: Record<string, string> = {};
  protected svErrors = '';

  protected mounted(): void {
    this.emailAddress = this.lostEmail;
    this.svErrors = this.lostMessage;

    setInputFocus(this.$refs.autofocus);
  }

  protected async onSubmit(): Promise<void> {
    this.svErrors = '';
    this.clErrors = new FormValidator(this, ['emailAddress']).check();

    if (isEmpty(this.clErrors)) {
      await this.doSubmit();
    } else {
      setInputFocus(this.$refs.autofocus);
    }
  }

  protected async doSubmit(): Promise<void> {
    try {
      await this.requestPasswordReset(this.emailAddress);

      this.showSnackbarMessage(
        this.$t('loginResetPasswordEmailSent', {
          emailAddress: this.emailAddress,
        }) as string,
        this.$t('loginClose') as string
      );
      await this.$router.push({
        name: 'login',
        params: {
          email: this.emailAddress,
          message: 'loginResetPasswordEmailSent',
        },
      });
    } catch (e) {
      this.svErrors = errorString(e);
      setInputFocus(this.$refs.autofocus);
    }
  }

  private showSnackbarMessage(message: string, actionText: string) {
    this.$snackbar.show({ message: message, actionText: actionText });
  }
}
</script>
