// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/loanreturnconsts.proto (package aurora.core.consts.loanreturn, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum aurora.core.consts.loanreturn.LoanReturnStatus
 */
export enum LoanReturnStatus {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RETURN_PENDING = 1;
   */
  RETURN_PENDING = 1,

  /**
   * @generated from enum value: RETURN_COMPLETED = 2;
   */
  RETURN_COMPLETED = 2,

  /**
   * @generated from enum value: RETURN_REJECTED = 3;
   */
  RETURN_REJECTED = 3,

  /**
   * @generated from enum value: PENDING_CANCEL = 4;
   */
  PENDING_CANCEL = 4,

  /**
   * @generated from enum value: CANCELED = 5;
   */
  CANCELED = 5,

  /**
   * @generated from enum value: DROPPED = 6;
   */
  DROPPED = 6,

  /**
   * @generated from enum value: RETURN_PENDING_LENDERS_APPROVAL = 7;
   */
  RETURN_PENDING_LENDERS_APPROVAL = 7,

  /**
   * @generated from enum value: RETURN_REJECTED_BY_LENDER = 8;
   */
  RETURN_REJECTED_BY_LENDER = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanReturnStatus)
proto3.util.setEnumType(LoanReturnStatus, "aurora.core.consts.loanreturn.LoanReturnStatus", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "RETURN_PENDING" },
  { no: 2, name: "RETURN_COMPLETED" },
  { no: 3, name: "RETURN_REJECTED" },
  { no: 4, name: "PENDING_CANCEL" },
  { no: 5, name: "CANCELED" },
  { no: 6, name: "DROPPED" },
  { no: 7, name: "RETURN_PENDING_LENDERS_APPROVAL" },
  { no: 8, name: "RETURN_REJECTED_BY_LENDER" },
]);

/**
 * possible reasons for returns to be rejected
 *
 * @generated from enum aurora.core.consts.loanreturn.LoanReturnRejectReason
 */
export enum LoanReturnRejectReason {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: OUTSIDE_SUPPORTED_HOURS = 1;
   */
  OUTSIDE_SUPPORTED_HOURS = 1,

  /**
   * @generated from enum value: UNSUPPORTED_MESSAGE_DETAILS = 2;
   */
  UNSUPPORTED_MESSAGE_DETAILS = 2,

  /**
   * @generated from enum value: UNKNOWN_LOAN_ID = 3;
   */
  UNKNOWN_LOAN_ID = 3,

  /**
   * @generated from enum value: INELIGIBLE = 4;
   */
  INELIGIBLE = 4,

  /**
   * @generated from enum value: OTHER = 5;
   */
  OTHER = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanReturnRejectReason)
proto3.util.setEnumType(LoanReturnRejectReason, "aurora.core.consts.loanreturn.LoanReturnRejectReason", [
  { no: 0, name: "NONE" },
  { no: 1, name: "OUTSIDE_SUPPORTED_HOURS" },
  { no: 2, name: "UNSUPPORTED_MESSAGE_DETAILS" },
  { no: 3, name: "UNKNOWN_LOAN_ID" },
  { no: 4, name: "INELIGIBLE" },
  { no: 5, name: "OTHER" },
]);

