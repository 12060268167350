// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/login/security.proto (package aurora.core.apiengine.loginv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message aurora.core.apiengine.loginv1.LogoutRequest
 */
export class LogoutRequest extends Message<LogoutRequest> {
  constructor(data?: PartialMessage<LogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutRequest {
    return new LogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutRequest | PlainMessage<LogoutRequest> | undefined, b: LogoutRequest | PlainMessage<LogoutRequest> | undefined): boolean {
    return proto3.util.equals(LogoutRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.LogoutResponse
 */
export class LogoutResponse extends Message<LogoutResponse> {
  constructor(data?: PartialMessage<LogoutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.LogoutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutResponse {
    return new LogoutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutResponse | PlainMessage<LogoutResponse> | undefined, b: LogoutResponse | PlainMessage<LogoutResponse> | undefined): boolean {
    return proto3.util.equals(LogoutResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.GetSSOSettingsRequest
 */
export class GetSSOSettingsRequest extends Message<GetSSOSettingsRequest> {
  constructor(data?: PartialMessage<GetSSOSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.GetSSOSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSOSettingsRequest {
    return new GetSSOSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSOSettingsRequest {
    return new GetSSOSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSOSettingsRequest {
    return new GetSSOSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSOSettingsRequest | PlainMessage<GetSSOSettingsRequest> | undefined, b: GetSSOSettingsRequest | PlainMessage<GetSSOSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetSSOSettingsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.GetSSOSettingsResponse
 */
export class GetSSOSettingsResponse extends Message<GetSSOSettingsResponse> {
  /**
   * @generated from oneof aurora.core.apiengine.loginv1.GetSSOSettingsResponse.metadata
   */
  metadata: {
    /**
     * @generated from field: string metadata_url = 1;
     */
    value: string;
    case: "metadataUrl";
  } | {
    /**
     * @generated from field: string metadata_filename = 2;
     */
    value: string;
    case: "metadataFilename";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetSSOSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.GetSSOSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "metadata" },
    { no: 2, name: "metadata_filename", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "metadata" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSOSettingsResponse {
    return new GetSSOSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSOSettingsResponse {
    return new GetSSOSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSOSettingsResponse {
    return new GetSSOSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSOSettingsResponse | PlainMessage<GetSSOSettingsResponse> | undefined, b: GetSSOSettingsResponse | PlainMessage<GetSSOSettingsResponse> | undefined): boolean {
    return proto3.util.equals(GetSSOSettingsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.SetSSOSettingsRequest
 */
export class SetSSOSettingsRequest extends Message<SetSSOSettingsRequest> {
  /**
   * @generated from oneof aurora.core.apiengine.loginv1.SetSSOSettingsRequest.metadata
   */
  metadata: {
    /**
     * @generated from field: string metadata_url = 1;
     */
    value: string;
    case: "metadataUrl";
  } | {
    /**
     * @generated from field: aurora.core.apiengine.loginv1.MetadataFile metadata_file = 2;
     */
    value: MetadataFile;
    case: "metadataFile";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SetSSOSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.SetSSOSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "metadata" },
    { no: 2, name: "metadata_file", kind: "message", T: MetadataFile, oneof: "metadata" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetSSOSettingsRequest {
    return new SetSSOSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetSSOSettingsRequest {
    return new SetSSOSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetSSOSettingsRequest {
    return new SetSSOSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetSSOSettingsRequest | PlainMessage<SetSSOSettingsRequest> | undefined, b: SetSSOSettingsRequest | PlainMessage<SetSSOSettingsRequest> | undefined): boolean {
    return proto3.util.equals(SetSSOSettingsRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.MetadataFile
 */
export class MetadataFile extends Message<MetadataFile> {
  /**
   * @generated from field: string file_name = 1;
   */
  fileName = "";

  /**
   * @generated from field: bytes file = 2;
   */
  file = new Uint8Array(0);

  constructor(data?: PartialMessage<MetadataFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.MetadataFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetadataFile {
    return new MetadataFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetadataFile {
    return new MetadataFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetadataFile {
    return new MetadataFile().fromJsonString(jsonString, options);
  }

  static equals(a: MetadataFile | PlainMessage<MetadataFile> | undefined, b: MetadataFile | PlainMessage<MetadataFile> | undefined): boolean {
    return proto3.util.equals(MetadataFile, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.SetSSOSettingsResponse
 */
export class SetSSOSettingsResponse extends Message<SetSSOSettingsResponse> {
  constructor(data?: PartialMessage<SetSSOSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.SetSSOSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetSSOSettingsResponse {
    return new SetSSOSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetSSOSettingsResponse {
    return new SetSSOSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetSSOSettingsResponse {
    return new SetSSOSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetSSOSettingsResponse | PlainMessage<SetSSOSettingsResponse> | undefined, b: SetSSOSettingsResponse | PlainMessage<SetSSOSettingsResponse> | undefined): boolean {
    return proto3.util.equals(SetSSOSettingsResponse, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.RemoveSSOSettingRequest
 */
export class RemoveSSOSettingRequest extends Message<RemoveSSOSettingRequest> {
  constructor(data?: PartialMessage<RemoveSSOSettingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.RemoveSSOSettingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveSSOSettingRequest {
    return new RemoveSSOSettingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveSSOSettingRequest {
    return new RemoveSSOSettingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveSSOSettingRequest {
    return new RemoveSSOSettingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveSSOSettingRequest | PlainMessage<RemoveSSOSettingRequest> | undefined, b: RemoveSSOSettingRequest | PlainMessage<RemoveSSOSettingRequest> | undefined): boolean {
    return proto3.util.equals(RemoveSSOSettingRequest, a, b);
  }
}

/**
 * @generated from message aurora.core.apiengine.loginv1.RemoveSSOSettingResponse
 */
export class RemoveSSOSettingResponse extends Message<RemoveSSOSettingResponse> {
  constructor(data?: PartialMessage<RemoveSSOSettingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.apiengine.loginv1.RemoveSSOSettingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveSSOSettingResponse {
    return new RemoveSSOSettingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveSSOSettingResponse {
    return new RemoveSSOSettingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveSSOSettingResponse {
    return new RemoveSSOSettingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveSSOSettingResponse | PlainMessage<RemoveSSOSettingResponse> | undefined, b: RemoveSSOSettingResponse | PlainMessage<RemoveSSOSettingResponse> | undefined): boolean {
    return proto3.util.equals(RemoveSSOSettingResponse, a, b);
  }
}

