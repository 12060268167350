import { alignRight, component } from '@/modules/common/components/ag-table/columns/utils';
import { VBtn, VIcon } from 'vuetify/lib';
import { computed, h } from 'vue';
import {
  QueryMarketplaceItemWithCusipAndTicker,
  useStoreExplorer,
} from '@/modules/marketplace/store/store-explorer';
import { ColDef, NestedFieldPaths, ValueFormatterParams } from 'ag-grid-enterprise';
import { useStoreCompanies } from '@/store/store-companies';
import { OmsOrderType } from '@/connect/gen/consts/omsconsts_pb';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import Decimal from 'decimal.js';
import {
  BookOrder,
  QueryMarketplaceItem,
} from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { store as globalStore } from '@/store/store';

export const defaultColDef: ColDef = {
  sortable: false,
  filter: false,
  suppressHeaderMenuButton: false,
};

export const colDefs = computed<ColDef[]>(() => {
  const store = useStoreExplorer();
  const myCompanyId = globalStore.state.loginState.user?.companyID;

  const cols = [
    {
      field: 'cusip',
      rowGroup: true,
      hide: true,
      valueFormatter: (
        params: ValueFormatterParams<{ ticker: string; cusip: string; name: string }>
      ) => {
        const data = params.data;
        return `${data?.ticker} - ${data?.name} (${data?.cusip})`;
      },
    },
    // left panel: orders from my company with the selected direction
    {
      headerName: store.direction,
      headerClass: `${store.direction}-header text-capitalize justify-center`,
      children: [
        // first the actions
        {
          cellRendererSelector: (params) =>
            params.data[`${store.direction}Order`]
              ? myOrderActionsAdapter({ data: params.data, direction: store.direction })
              : null,
          width: 160,
        },
        // and then the cols
        ...createCols(store.direction),
      ],
    },
    // right panel: orders offered to my company andmy orders with the opposite direction
    {
      headerName: store.oppositeDirection,
      headerClass: `${store.oppositeDirection}-header text-capitalize justify-center`,
      children: [
        // first the reversed cols
        ...createCols(store.oppositeDirection).reverse(),
        // and then the actions
        {
          cellRendererSelector: (params) => {
            const order = params.data[`${store.oppositeDirection}Order`];
            if (!order) {
              return null;
            }
            if (order.companyId !== myCompanyId) {
              return order.orderType === OmsOrderType.IOI
                ? null
                : otherOrderActionsAdapter({ data: params.data });
            }
            return myOrderActionsAdapter({
              data: params.data,
              direction: store.oppositeDirection,
            });
          },
          width: 160,
        },
      ],
    },
  ];

  return cols;

  function createCols(direction: 'borrow' | 'lend'): Array<ColDef<QueryMarketplaceItem>> {
    const storeCompanies = useStoreCompanies();
    const orderKey: 'borrowOrder' | 'lendOrder' = `${direction}Order`;
    const field = (name: keyof BookOrder) =>
      `${orderKey}.${name}` as NestedFieldPaths<QueryMarketplaceItem, unknown, []>;

    return [
      {
        field: field('orderType'),
        headerName: 'Type',
        valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) => {
          const orderType = params.data ? params.data[orderKey]?.orderType : undefined;
          switch (orderType) {
            case OmsOrderType.IOI:
              return 'IOI';
            case OmsOrderType.LIMIT:
              return 'Limit';
            case OmsOrderType.MARKET:
              return 'Market';
            default:
              return '';
          }
        },
        cellClassRules: {
          'ioi-order': (params: ValueFormatterParams<QueryMarketplaceItem>) =>
            params.value === OmsOrderType.IOI,
        },
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['IOI', 'Limit'],
          suppressSelectAll: true,
        },
      },
      {
        field: field('companyId'),
        headerName: 'Company',
        valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) => {
          const company = storeCompanies.getCompany(params.value);
          if (direction === store.oppositeDirection && company?.id === myCompanyId) return 'Me';
          return company?.name || '';
        },

        hide: direction !== store.oppositeDirection,
      },
      {
        field: field('counterpartyCompanyIds'),
        headerName: 'Eligible',
        valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) => {
          if (!params.value) return '';
          if (!params.value.length) return 'Any';
          return params.value.map((id) => storeCompanies.getCompany(id)?.displayBoxId).join(', ');
        },

        hide: direction === store.oppositeDirection,
      },
      {
        field: field('quantity'),
        headerName: 'Quantity',
        valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) => {
          return params.value ? formatPrettyNumber(params.value) : '';
        },
        ...alignRight(),
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: ['equals', 'lessThan', 'greaterThan'],
          debounceMs: 200,
          maxNumConditions: 1,
        },
      },
      {
        field: field('contractAmount'),
        headerName: 'Value',
        ...alignRight(),
        valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) => {
          return params.value ? `$${getPriceAsString(params.value, 0)}` : '';
        },
      },
      {
        field: field('rate'),
        headerName: 'Rate (%)',
        valueFormatter: (params: ValueFormatterParams<QueryMarketplaceItem>) => {
          if (!params.value) return '';
          return new Decimal(params.value).toFixed(4);
        },
        ...alignRight({ hasPostfix: true }),
        cellClassRules: {
          'highlighted-border-right': () => store.direction === direction,
        },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['equals', 'lessThan', 'greaterThan'],
          debounceMs: 200,
          maxNumConditions: 1,
        },
      },
    ];
  }
});

const myOrderActionsAdapter = component(
  (props: { data: QueryMarketplaceItemWithCusipAndTicker; direction: 'borrow' | 'lend' }) => () =>
    h(
      'div',
      {
        style: { display: 'flex', gap: '0.5rem', scale: '0.8', paddingTop: '3px' },
      },
      [
        h(
          VBtn,
          {
            class: ['icon-action'],
            props: {
              xSmall: true,
              icon: true,
            },
            on: {
              click: () => {
                const store = useStoreExplorer();
                const orderRef = props.data[`${props.direction}Order`]?.clientOrderRef;
                store.showForm = true;
                store.orderRef = orderRef ?? null;
              },
            },
          },
          [h(VIcon, { props: { size: '24' } }, 'mdi-pencil')]
        ),
        h(
          VBtn,
          {
            class: ['icon-action'],
            props: {
              xSmall: true,
              icon: true,
            },
            on: {
              click: () => {
                const store = useStoreExplorer();
                const orderRef = props.data[`${props.direction}Order`]?.clientOrderRef;
                if (orderRef) {
                  store.cancelOrder(orderRef);
                }
              },
            },
          },
          [h(VIcon, { props: { size: '24' } }, 'mdi-close-circle')]
        ),
      ]
    )
);

const otherOrderActionsAdapter = component(
  (props: { data: QueryMarketplaceItemWithCusipAndTicker }) => () =>
    h(
      'div',
      {
        style: { scale: '0.8', marginTop: '-3px' },
      },
      [
        h(
          VBtn,
          {
            props: { xSmall: true, color: 'primary' },
            on: {
              click: () => {
                const store = useStoreExplorer();
                store.orderRef = null;
                store.executePopulateForm(
                  props.data[`${store.oppositeDirection}Order`] as BookOrder
                );
              },
            },
          },
          'Match'
        ),
      ]
    )
);
