import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';

export class CommonApiService extends ApiService {
  // We don't set because there is no common namespace
  // private featureUrl = '';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.common = singleton;
    } else {
      v.prototype.$api = {
        manualLoans: singleton,
      };
    }
  }

  public async fetchKillSwitchState(): Promise<{
    isKillSwitchOn: boolean;
    isHaltTradingOn: boolean;
  }> {
    const { data } = await this.axios.get(`${this.baseUrl}/broker-admin/oms/v2/control-panel`);
    return data;
  }

  public async updateKillSwitch(action: 'enable' | 'disable'): Promise<void> {
    await this.axios.put(`${this.baseUrl}/broker-admin/oms/v2/control-panel/killswitch/${action}`);
  }

  public async updateHaltTrading(action: 'enable' | 'disable'): Promise<void> {
    await this.axios.put(
      `${this.baseUrl}/broker-admin/oms/v2/control-panel/halt-trading/${action}`
    );
  }
}
