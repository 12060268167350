<template>
  <v-card>
    <v-card-title class="mb-0">
      <div class="d-flex flex-column">
        <span class="headline"
          ><slot :name="!items.length && normalizedErrors.length ? 'error-title' : 'title'"
        /></span>
      </div>
      <v-spacer />
    </v-card-title>

    <div class="overflow-y-auto">
      <v-card-text v-if="normalizedErrors.length > 0">
        <v-alert class="mt-4" dense type="error">
          There were some errors while processing the file. Please review the affected rows before
          uploading again.
        </v-alert>

        <v-data-table
          class="table-container mt-4 error--text"
          disable-filtering
          disable-pagination
          disable-sort
          fixed-header
          :headers="errorHeaders"
          hide-default-footer
          item-key="key"
          :items="normalizedErrors"
        >
          <template #item="{ item }">
            <slot name="error-item" v-bind="{ item }" />
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text v-if="items.length">
        <div class="summary pl-2">
          <slot name="summary" />
        </div>

        <div class="table-container mt-4">
          <div>
            <v-data-table
              disable-filtering
              disable-pagination
              disable-sort
              fixed-header
              :headers="headers"
              hide-default-footer
              item-key="key"
              :items="items"
              width="100%"
            >
              <template #item="{ item }">
                <slot name="item" v-bind="{ item }" />
              </template>
            </v-data-table>
          </div>
        </div>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row v-if="showError || showSuccess">
          <v-col class="pa-0 px-1 col-6 offset-3">
            <div
              class="v-alert v-alert--dense text--primary text-body-2 text-center"
              :class="{ error: showError, success: showSuccess }"
            >
              <div v-if="showError">
                {{ errorMsg }}
              </div>
              <div v-if="showSuccess">
                <slot name="success-message" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <v-card-actions class="d-flex">
      <div class="d-flex flex-grow-1 justify-space-between align-end">
        <v-btn class="mt-6" color="secondary" @click="$emit('back')"> Back </v-btn>
        <aurora-btn
          v-if="items.length && !showError"
          class="mt-6"
          color="primary"
          :disabled="isCreating"
          :timeframe="timeframe"
          @click="submit()"
        >
          <slot name="create-button-text" />
        </aurora-btn>
      </div>
    </v-card-actions>

    <v-progress-linear v-if="isCreating" color="primary" indeterminate />
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import wait from '@/modules/common/services/wait';
import { ApiError } from '@/utils/errors';
import { DataTableHeader } from 'vuetify';
import type { UploadMethod } from '@/modules/common/types/upload';
import { SponsoredLoanBasketResponse } from '@/modules/sponsorship/models';
import { ParseOmsBasketResponse } from '@/modules/marketplace/models';
import { ErrorItem } from '@/modules/common/models';

@Component({
  props: {
    basketResponse: {
      type: Object as PropType<SponsoredLoanBasketResponse | ParseOmsBasketResponse>,
      required: true,
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
    },
    errorHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      default: [],
    },
    timeframe: {
      type: String as PropType<string>,
      required: true,
    },
    uploadMethod: {
      type: String as PropType<UploadMethod>,
      default: '',
    },
    execute: Function,
  },
})
export default class UploadConfirmBasket extends Vue {
  // props
  protected readonly basketResponse!: SponsoredLoanBasketResponse | ParseOmsBasketResponse;
  protected readonly headers!: DataTableHeader[];
  protected readonly errorHeaders!: DataTableHeader[];
  protected readonly timeframe!: string;
  protected readonly uploadMethod!: UploadMethod;

  /*
   * Using a callback instead of v-on is an unorthodox solution.
   * Here it permits the whole submit/error worlkflow to be contained inside the component.
   */
  protected readonly execute!: (
    basketResponse: SponsoredLoanBasketResponse | ParseOmsBasketResponse
  ) => Promise<void>;

  protected showSuccess = false;
  protected errorMsg: string | null = null;
  protected isCreating = false;

  protected get items(): unknown[] {
    return 'items' in this.basketResponse ? this.basketResponse.items : this.basketResponse.entries;
  }

  protected get normalizedErrors(): ErrorItem[] {
    return (
      this.basketResponse.errors?.map(
        (error) => ((error.row = this.uploadMethod === 'paste' ? error.row - 1 : error.row), error)
      ) ?? []
    );
  }

  protected get showError(): boolean {
    return !!this.errorMsg;
  }

  protected async submit(): Promise<void> {
    if (this.isCreating) {
      return;
    }

    this.isCreating = true;

    try {
      await this.execute(this.basketResponse);
      this.showSuccess = true;
      await wait(1200);
      this.$emit('close-modal');
    } catch (err) {
      const apiError = err as ApiError;
      if (apiError.responseData?.msgkey) {
        this.errorMsg = this.$i18n.t(apiError.responseData.msgkey as string) as string;
      } else {
        this.errorMsg = apiError.message;
      }
    } finally {
      this.isCreating = false;
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }

  .v-data-table td {
    white-space: nowrap;
    overflow: hidden;
  }

  .v-data-table.error--text thead th {
    color: inherit !important;
  }
}

.v-card {
  display: flex;
  flex-direction: column;
  max-height: 85vh;
}

.table-container {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}

.summary:after {
  /* add colon */
  content: ':';
  margin-left: -2px;
}

.summary strong {
  color: white;
}
</style>
