import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import {
  CompanyInfo,
  ErrorItem,
  RawCompanyInfo,
  RawErrorItem,
  RawSecurity,
  Security,
} from '@/modules/common/models';
import Decimal from 'decimal.js';
import { Benchmark } from '@/utils/api/loans';

type Side = 'LENDER' | 'BORROWER';

export type RawSponsoredLoanBasketRequest = Raw<SponsoredLoanBasketRequest>;

export class SponsoredLoanBasketRequest {
  public file: File;
  public side: Side;

  protected constructor(data: RawSponsoredLoanBasketRequest) {
    this.file = data.file;
    this.side = data.side;
  }

  public static fromData(data: RawSponsoredLoanBasketRequest): SponsoredLoanBasketRequest {
    return new SponsoredLoanBasketRequest(data);
  }

  public static toFormData(model: SponsoredLoanBasketRequest): FormData {
    const formData = new FormData();
    formData.append('file', model.file);
    formData.append('side', model.side);
    return formData;
  }
}

export type RawSponsoredLoanBasketResponse = Raw<
  SponsoredLoanBasketResponse,
  {
    // always specify existing raw entry types explititly
    items: RawSponsoredLoanDetailsResponseItem[];
  },
  'errors'
>;

export class SponsoredLoanBasketResponse {
  public side: Side;
  public items: SponsoredLoanDetailsResponseItem[];
  public errors: null | ErrorItem[];

  protected constructor(data: RawSponsoredLoanBasketResponse | RawSponsoredLoanErrorResponse) {
    if ('items' in data) {
      this.side = data.side;
      this.items = data.items.map(SponsoredLoanDetailsResponseItem.fromData);
      this.errors = null;
    } else {
      this.side = '' as Side;
      this.items = data.entries.map(SponsoredLoanDetailsResponseItem.fromData);
      this.errors = data.errors.map(ErrorItem.fromData);
    }
  }

  public static fromData(
    data: RawSponsoredLoanBasketResponse | RawSponsoredLoanErrorResponse
  ): SponsoredLoanBasketResponse {
    return new SponsoredLoanBasketResponse(data);
  }

  public static mock(
    data?: DeepPartial<RawSponsoredLoanBasketResponse> | DeepPartial<RawSponsoredLoanErrorResponse>
  ): SponsoredLoanBasketResponse {
    return SponsoredLoanBasketResponse.fromData(SponsoredLoanBasketResponse.mockData(data));
  }

  public static mockData(
    data?: DeepPartial<RawSponsoredLoanBasketResponse> | DeepPartial<RawSponsoredLoanErrorResponse>
  ): RawSponsoredLoanBasketResponse | RawSponsoredLoanErrorResponse {
    data ??= { items: [] };

    if ('items' in data) {
      const { items, ...rest } = data ?? {};

      return {
        side: 'LENDER',
        items: items?.map(SponsoredLoanDetailsResponseItem.mockData) ?? [],

        ...rest,
      };
    } else {
      const { entries, errors } = (data ?? {}) as DeepPartial<RawSponsoredLoanErrorResponse>;

      return {
        side: '' as Side,
        items: entries?.map(SponsoredLoanDetailsResponseItem.mockData) ?? [],
        errors: errors?.map(ErrorItem.mockData) ?? [],
      };
    }
  }
}

export type RawSponsoredLoanDetailsResponseItem = Raw<
  SponsoredLoanDetailsResponseItem,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
    counterparty: RawCompanyInfo;
  },
  'security'
>;

export class SponsoredLoanDetailsResponseItem {
  public security: Security;
  public counterparty: CompanyInfo;
  public quantity: number;
  public rate: Decimal;
  public benchmark: Benchmark;
  public row: number;

  protected constructor(data: RawSponsoredLoanDetailsResponseItem) {
    this.security = Security.fromData(data.equity);
    this.counterparty = CompanyInfo.fromData(data.counterparty);
    this.quantity = data.quantity;
    this.rate = new Decimal(data.rate);
    this.benchmark = data.benchmark;
    this.row = data.row;
  }

  public static fromData(
    data: RawSponsoredLoanDetailsResponseItem
  ): SponsoredLoanDetailsResponseItem {
    return new SponsoredLoanDetailsResponseItem(data);
  }

  public static mock(
    data?: DeepPartial<RawSponsoredLoanDetailsResponseItem>
  ): SponsoredLoanDetailsResponseItem {
    return SponsoredLoanDetailsResponseItem.fromData(
      SponsoredLoanDetailsResponseItem.mockData(data)
    );
  }

  public static mockData(
    data?: DeepPartial<RawSponsoredLoanDetailsResponseItem>
  ): RawSponsoredLoanDetailsResponseItem {
    const { equity, counterparty, ...rest } = data ?? {};

    return {
      equity: Security.mockData(equity),
      counterparty: CompanyInfo.mockData(counterparty),
      quantity: 100,
      rate: '1',
      benchmark: Benchmark.NoBenchmark,
      row: 1,

      ...rest,
    };
  }
}

export interface RawSponsoredLoanErrorResponse {
  errors: RawErrorItem[];
  entries: RawSponsoredLoanDetailsResponseItem[];
}
