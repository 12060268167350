<!-- eslint-disable vue/no-v-html -->
<!-- This is the base component for <ConfirmDialogImperative> and <ConfirmDialogMarkup>
     It only knows how to render the props and emit events -->
<template>
  <v-dialog
    v-shortkey="value ? ['esc'] : []"
    :content-class="`position-${mergedOptions.positionAt} border-${mergedOptions.borderColor}`"
    max-width="600"
    origin="center center"
    overlay-color="secondary"
    overlay-opacity="0.25"
    :persistent="mergedOptions.persistent"
    transition="scale-transition"
    :value="value"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-card>
      <v-card-title class="headline">
        {{ mergedOptions.title }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-if="mergedOptions.icon !== ''" class="pl-1" cols="1">
              <v-icon :color="mergedOptions.color">
                {{ mergedOptions.icon }}
              </v-icon>
            </v-col>
            <v-col class="pa-0">
              <div class="pa-0" v-html="mergedOptions.message"></div>
            </v-col>
          </v-row>
          <v-row v-if="options.shouldManuallyConfirm">
            <v-col class="pa-0" cols="12">
              <v-text-field
                v-model="confirmationEmail"
                autofocus
                :color="mergedOptions.color"
                placeholder="This action may be detrimental. Type your email here to confirm."
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="mergedOptions.isRejectable"
          class="px-5"
          data-test-confirm-dialog="reject"
          @click="$emit('reject')"
        >
          {{ mergedOptions.rejectText }}
        </v-btn>
        <v-btn
          class="ml-5 px-5"
          :color="mergedOptions.color"
          data-test-confirm-dialog="accept"
          :disabled="isAcceptButtonDisabled"
          @click="$emit('accept')"
        >
          {{ mergedOptions.acceptText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DialogOptions } from '@/plugins/dialog-manager';
import { PropType } from 'vue';
import { mapState } from 'vuex';
import { AppState } from '@/store/store';

const defaultOptions: DialogOptions = {
  title: 'Confirm',
  message: 'Question?',
  icon: '',
  color: 'error',
  borderColor: 'none',
  positionAt: 'center',
  acceptText: 'Agree',
  persistent: false,
  isRejectable: true,
  rejectText: 'Disagree',
};

@Component({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<DialogOptions>,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapState(['loginState']),
  },
})
export default class ConfirmDialogMarkup extends Vue {
  protected readonly value!: boolean;
  protected readonly options!: DialogOptions;

  // store state
  protected readonly loginState!: AppState['loginState'];

  protected confirmationEmail: string | null = null;

  protected get mergedOptions(): DialogOptions {
    return { ...defaultOptions, ...this.options };
  }

  protected get isAcceptButtonDisabled(): boolean {
    if (!this.options.shouldManuallyConfirm) {
      return false;
    }
    return (
      this.confirmationEmail?.toLocaleLowerCase().trim() !==
      this.loginState?.user?.emailAddress.toLocaleLowerCase()
    );
  }

  @Watch('value', { immediate: true })
  protected onValueChange(value: boolean): void {
    if (value) {
      // <ConfirmDialogImperative> is mounted and never destroyed in <App>
      // so we need to reset the confirmationEmail manually every time the dialog is opened
      // to make sure we don't carry over the email from the last time
      this.confirmationEmail = null;
    }
  }

  public closeDialog(): void {
    if (this.mergedOptions.persistent) {
      // when persistent, we force the user to click a button
      // (click outside or press ESC does nothing)
      return;
    }
    this.$emit('reject');
    this.$emit('update:value', false);
  }
}
</script>

<!-- Cannot be "scoped", because classes are being forwarded via :content-class -->
<style lang="scss">
.position-top {
  position: absolute;
  top: 80px;
  bottom: auto;
}

.position-bottom {
  position: absolute;
  bottom: 10px;
  top: auto;
}

.border-error {
  border: solid red 1px;
}

.border-warning {
  border: solid orange 1px;
}
</style>
