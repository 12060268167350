import { Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, Security } from '@/modules/common/models';
import { SponsoredLoanBasketResponse } from '@/modules/sponsorship/models';
import { Benchmark } from '@/utils/api/loans';
import Decimal from 'decimal.js';

type Side = 'LENDER' | 'BORROWER';

export type RawSponsoredLoanCreateRequest = Raw<
  SponsoredLoanCreateRequest,
  {
    // always specify existing raw entry types explititly
    items: RawSponsoredLoanDetails[];
  }
>;

export class SponsoredLoanCreateRequest {
  public items: SponsoredLoanDetails[];
  public side: Side;

  protected constructor(uploadLoansResult: SponsoredLoanCreateRequest) {
    this.items = uploadLoansResult.items;
    this.side = uploadLoansResult.side;
  }

  public static fromBasketResponse(
    basketResponse: SponsoredLoanBasketResponse
  ): SponsoredLoanCreateRequest {
    return new SponsoredLoanCreateRequest({
      side: basketResponse.side,
      items: basketResponse.items.map(SponsoredLoanDetails.fromData),
    });
  }

  public static fromData(data: {
    side: Side;
    security: Security;
    counterparty: CompanyInfo;
    quantity: number;
    rate: Decimal;
    benchmark: Benchmark | null;
  }): SponsoredLoanCreateRequest {
    return new SponsoredLoanCreateRequest({
      side: data.side,
      items: [SponsoredLoanDetails.fromData(data)],
    });
  }
}

export type RawSponsoredLoanDetails = Raw<
  SponsoredLoanDetails,
  {
    security: Security;
    counterparty: CompanyInfo;
    rate: Decimal;
  },
  'securityId' | 'counterpartyId'
>;

export class SponsoredLoanDetails {
  public securityId: string;
  public counterpartyId: string;
  public quantity: number;
  public rate: string;
  public benchmark: Benchmark | null;

  protected constructor(data: RawSponsoredLoanDetails) {
    this.securityId = data.security.id;
    this.counterpartyId = data.counterparty.companyName;
    this.quantity = data.quantity;
    this.rate = data.rate.toString();
    this.benchmark = data.benchmark;
  }

  public static fromData(data: RawSponsoredLoanDetails): SponsoredLoanDetails {
    return new SponsoredLoanDetails(data);
  }
}
