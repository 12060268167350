import i18n from '@/localisation/i18n';
import { MarketTimeframes } from '@/modules/market-closed/types/market-closed';
import { Role } from '@/utils/helpers/permissions';
import { format as formatDate } from 'date-fns';
import Decimal from 'decimal.js';
import { extend } from 'lodash';
import {
  AuctionSecurity,
  BespokeAuction,
  Leakage,
  Order,
  OrderTicket,
} from '@/modules/auction/models';

// ORM actions
export enum RestOperation {
  Noop = 0,
  Create,
  Read,
  Update,
  Delete,
}

export enum AuctionMarket {
  SecuritiesLending = 'seclending',
  Swaps = 'swaps',
  Options = 'options',
  Combo = 'combo',
}

export enum AuctionStyle {
  ABA = 'aba',
}

// for the backend: buy=bid=loan; sell=ask=borrow
export enum Direction {
  Lend = 'lend',
  Borrow = 'borrow',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OrderType = {
  Limit: 'LIMIT',
  Market: 'MARKET',
} as const;
export type OrderType = (typeof OrderType)[keyof typeof OrderType];

// REST buffers
export interface ClientConfig {
  systemTitleShort: string;
  systemTitleLong: string;
  systemBrokerName: string;
  systemBrokerFullName: string;
  systemBrokerLegalName: string;
  systemBrokerSiteURL: string;
  systemBrokerContactEmail: string;
  systemProdLike: boolean;
  systemEnv: 'prod' | 'uat' | 'sandbox' | 'review' | 'demo' | 'test' | 'dev' | 'playground';
  systemRelease: string;
  systemRootURL: string;
  frontendHash: string;
  timezone: string;
  timezoneLabel: string;
  timezoneOffset: string;
  auctionMarketTimeIncrements: string;
  auctionMarketTimeMinOpen: string;
  auctionMarketDaysMaxOpen: number;
  auctionMarketAllowDuplicates: boolean;
  auctionMarketFatFingerMargin: number;
  auctionMarketBaseCurrency: string;
  tfaResetByEmail: boolean;
  maxRate: number;
  sentryServerDSN: string;
  sentryEnvironment: string;
  demoMode: boolean;

  /**
   * Enables/disabled the use of market timeframes to allow interaction with various features
   */
  marketTimeframesEnabled: boolean;
  marketTimeframes: MarketTimeframes;
  omsAllowMarketOrders: boolean;
  currentTimeUTC: Date;
  holidaysLast12Months: string[]; // yyyy-MM-dd

  // feature flags
  createAuctionsEnabled: boolean;
  executeAuctionsEnabled: boolean;
  createLoansEnabled: boolean;
  varCalcEnabled: boolean;
  orderbookCounterpartyChoiceEnabled: boolean;
  orderbookSettlementTypeChoiceEnabled: boolean;
  orderbookMinQuantityEditable: boolean;
  seclendingAnalyticsEnabled: boolean;
  termLoansEnabled: boolean;
  finraTransactionsEnabled: boolean;
  corpActionsEnabled: boolean;
  nsccDashboardEnabled: boolean;
  bilateralLoansEnabled: boolean;
  occBilateralLoansEnabled: boolean;
  orderManagerV2Enabled: boolean;
}

export interface UXConfig {
  hasGlobalSearch: boolean;
  hasSLAuction: boolean;
  // when forceMarketTimeframesCheckingEvenWhenDisabled is true we will enforce timeframes,
  //  even when they're disabled (for this env)
  forceMarketTimeframesCheckingEvenWhenDisabled: boolean;
  // when forceMarketTimeframesClosed is true we will pretend all timeframes are closed
  //  (so all timeframe dependant features will be disabled)
  forceMarketTimeframesClosed: boolean;
  dismissedPreEstablishedInfo: boolean;
}

export interface LoginSalt {
  salt: string;
}

export interface LoginPreparePasswordResponse {
  email: string;
  freshAccount: boolean;
}

export interface User2FASettings {
  id: string;
  tfaIsEnabled: boolean;
  tfaIssuer: string;
  tfaIssuerName: string;
  tfaIssuerSecret: string;
}

export interface RfcRequest {
  rfcText: string;
  rfcPath: string;
  rfcPage: string;
}

export interface CompanyAccountLite {
  id: string;
  name: string;
}

export interface TradingPermissionOption {
  permission: number | null; // null = inherit from company
}

export interface InvestorProfile {
  id: string;
  label: string;
}

// buffer behind the 'InitiateAuction' and 'OrderTicketDialog' dialogs
export interface AuctionDialogBuffer {
  securityId: string;
  security: AuctionSecurity | null;
  leakDirection: boolean;
  shareableDirection: Direction;
  leakQuantity: boolean;
  shareableQuantity: number;
  leakRate: boolean;
  shareableRate: Decimal;
  leakIsStackedOrder: boolean;
  auctionDay: string;
  auctionTime: string;
  participantList: string[];
  firstOrderTicket: OrderTicket;
}

export function createAuctionDialogBuffer(defaultParticipants: string[]): AuctionDialogBuffer {
  const order = Order.create({ volume: 0, rate: new Decimal(0) });
  const ticket = OrderTicket.create({ direction: Direction.Borrow, orders: [order] });
  return {
    securityId: '',
    security: null,
    leakDirection: true,
    shareableDirection: Direction.Borrow,
    leakQuantity: true,
    shareableQuantity: 0, // best volume in all order lines (matching best price)
    leakRate: true,
    shareableRate: new Decimal(0), // best price in all order lines
    leakIsStackedOrder: true,
    auctionDay: formatDate(new Date(), 'yyyy-MM-dd'),
    auctionTime: formatDate(new Date(), 'HH:mm'),
    participantList: defaultParticipants,
    firstOrderTicket: ticket,
  };
}

export function auctionTotalFilledVolume(auction: BespokeAuction): number {
  return auction.companyOrderTickets.reduce((r, o) => {
    return r + (o.filledVolume || 0);
  }, 0);
}

export interface OrderInput {
  quantity: number;
  rate: string;
}

export interface OrderTicketInput extends Leakage {
  id: string;
  auctionId: string;
  direction: Direction;
  notes: string;
  orders: OrderInput[];
}

interface StackableOrder extends Order {
  isStackedOrder: boolean;
}

export function getBestOrderFromTicket(
  auction: BespokeAuction,
  direction: string,
  companyOrderTickets: OrderTicket[]
): Leakage {
  const ret = Leakage.none();

  // filter on 'direction'
  const nestedOrders = companyOrderTickets
    .filter((orderTicket) => orderTicket.direction === direction)
    .map((orderTicket) =>
      orderTicket.orders.map(
        (order) =>
          extend({}, order, { isStackedOrder: orderTicket.orders.length > 1 }) as StackableOrder
      )
    );

  // merge orders of all tickets into 1 array
  // abort if there are no orders with the right 'direction'?
  const auctionOrders: StackableOrder[] = ([] as StackableOrder[]).concat(...nestedOrders);
  if (auctionOrders.length === 0) {
    return ret;
  }

  // sort all orders to find the best on top
  const sortedOrders = auctionOrders.sort((ol1, ol2) => {
    if (direction === Direction.Lend) {
      return ol1.rate.comparedTo(ol2.rate);
    } else {
      return ol2.rate.comparedTo(ol1.rate);
    }
  });

  const bestOrder = sortedOrders[0];
  if (auction.isLeakedDirection) {
    ret.leakedDirection = direction;
  }
  if (auction.isLeakedQuantity) {
    ret.leakedQuantity = bestOrder.quantity;
  }
  if (auction.isLeakedRate) {
    ret.leakedRate = bestOrder.rate;
  }
  if (auction.isLeakedIsStackedOrder) {
    ret.leakedIsStackedOrder = bestOrder.isStackedOrder;
  }

  return ret;
}

export interface ClosedOrderTicket extends OrderTicket {
  filledVolume: number | null;
}

export interface NewsItem {
  key: string;
  title: string;
  value: Decimal;
  change: Decimal;
}

// @TODO: for now most as strings!
export interface WatchItem {
  status: string;
  security: string;
  ticker: string;
  interest: string;
  lastTrade: string;
  market: string;
  volume: number;
  rate: number;
  duration: string;
  auctionType: string;
}

export interface DesktopFeature {
  category: string;
  title: string;
  description: string;
  link: string;
  role: Role;
}

export function featureFactory(
  category: string,
  title: string,
  description: string,
  link: string,
  role: Role
): DesktopFeature {
  return {
    category: i18n.tc(category),
    title: i18n.tc(title),
    description: i18n.tc(description),
    link: link ? link : '',
    role: role,
  };
}
