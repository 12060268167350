<template>
  <!-- template must have 1 direct child, we wrap the contents in a <div>
       with "display: contents", making sure layout rendering is not affected -->
  <div style="display: contents">
    <items-batch-confirmation-dialog
      :execute="execute"
      item-id-key="displayId"
      :items="items"
      success-message="Returns successfully initiated."
      :table-headers="[
        { text: 'Counterparty' },
        { text: 'Ticker' },
        { text: 'CUSIP' },
        { text: 'Rebate', align: 'end' },
        { text: `Return Quantity`, value: 'toUpdate', align: 'end' },
        { text: 'Available To Return', value: 'openQuantityToReturn', align: 'end' },
      ]"
      title="Return Loans"
      v-on="$listeners"
    >
      <template #description>
        You are about to return <strong><pretty-number :value="totalQuantity" /></strong> shares
        across <strong><pretty-number :value="items.length" /></strong> loans:
      </template>
      <template #row="{ item }">
        <td>
          <span :class="{ 'orange--text': isAfterCutOffTime(item) }">{{
            item.counterpartyDisplay
          }}</span>
        </td>
        <td>
          {{ item.security.ticker }}
        </td>
        <td>
          {{ item.security.cusip }}
        </td>
        <td class="text-end">
          <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
        </td>
        <td class="text-end">
          <pretty-number :value="newQuantities[item.id] || 0" />
        </td>
        <td class="text-end">
          <pretty-number :value="item.openQuantityToReturn" />
        </td>
      </template>
      <template #disclaimer>
        <v-alert
          v-if="atLeastOneAfterCutOffTime"
          class="mt-8 ml-8 mr-8 mb-4"
          outlined
          type="warning"
        >
          Some returns initiated will require the lender to approve the return before they get
          officially recorded to the trade and/or settlement instructions sent.
        </v-alert>
      </template>
    </items-batch-confirmation-dialog>
  </div>
</template>

<script lang="ts">
import type { Api } from '@/modules/borrower/types/borrower-loans';
import ItemsBatchConfirmationDialog from '@/modules/common/components/ItemsBatchConfirmationDialog.vue';
import { NewQuantities } from '@/modules/open-loans/helpers/multipleUpdates';
import { BorrowerOpenLoan, OpenLoan, OpenLoanItem } from '@/modules/common/models';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

type LoanReturnRequestItem = Api.LoanReturnRequestItem;

@Component({
  components: {
    ItemsBatchConfirmationDialog,
  },
  computed: {
    ...mapGetters(['isAfterCutOffTime']),
  },
  props: {
    items: Array as PropType<OpenLoanItem[]>,
    newQuantities: {
      type: Object as PropType<NewQuantities>,
      required: true,
      default: () => ({}),
    },
  },
})
export default class BatchReturnDialog extends Vue {
  // props
  protected readonly items!: BorrowerOpenLoan[];
  protected readonly newQuantities!: NewQuantities;

  // store state
  protected isAfterCutOffTime!: (loan: OpenLoan) => boolean;

  protected get totalQuantity(): number {
    return Object.values(this.newQuantities).reduce((acc, val) => acc + val, 0);
  }

  protected get atLeastOneAfterCutOffTime(): boolean {
    return this.items.some(this.isAfterCutOffTime);
  }

  private async execute(): Promise<{ failedIds?: string[] }> {
    const returns = this.items.map((loan) => {
      return {
        displayId: loan.displayId,
        quantity: this.newQuantities[loan.id],
      } as LoanReturnRequestItem;
    });
    const result = await this.$api.borrowerLoans.batchReturnBorrowerLoan(returns);
    const failedIds = result.failedItems.map((i) => i.requestId);

    // failing ids only, we are losing the error messages here
    return { failedIds };
  }
}
</script>
<style lang="scss" scoped></style>
