import { Raw } from '@/modules/common/helpers/api';
import { OrderBasketParams, RawOmsBasketItem } from '@/modules/marketplace/models';
import { ErrorItem, RawErrorItem } from '@/modules/common/models';

type Side = 'LENDER' | 'BORROWER';

export type RawParseOmsBasketRequest = Raw<ParseOmsBasketRequest>;

export class ParseOmsBasketRequest {
  public file: File;
  public side: Side;

  protected constructor(data: RawParseOmsBasketRequest) {
    this.file = data.file;
    this.side = data.side;
  }

  public static fromData(data: RawParseOmsBasketRequest): ParseOmsBasketRequest {
    return new ParseOmsBasketRequest(data);
  }

  public static toFormData(model: ParseOmsBasketRequest): FormData {
    const formData = new FormData();
    formData.append('file', model.file);
    formData.append('side', model.side);
    return formData;
  }
}

export type RawParseOmsBasketResponse = Raw<
  ParseOmsBasketResponse,
  {
    // always specify existing raw entry types explititly
    entries: RawOmsBasketItem[];
    errors: null | RawErrorItem[];
  }
>;

export class ParseOmsBasketResponse extends OrderBasketParams {
  public errors: null | ErrorItem[];

  protected constructor(data: RawParseOmsBasketResponse) {
    super(data);

    this.errors = data.errors?.map(ErrorItem.fromData) ?? null;
  }

  public static fromData(data: RawParseOmsBasketResponse): ParseOmsBasketResponse {
    return new ParseOmsBasketResponse(data);
  }
}
