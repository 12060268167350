<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(title) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="svErrors">
            <v-alert dense type="error">{{ svErrors }}</v-alert>
          </v-container>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.emailAddress"
                  autocomplete="email"
                  autofocus
                  :error-messages="clErrors.emailAddress"
                  :label="$t('loginEmail')"
                  required
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="crudAction === RestOperation.Create">
              <v-col>
                <v-alert border="left" dense type="info">
                  The user will receive an invitation by email to activate the account and set a
                  password. Login is blocked until the account has been activated.
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.name"
                  autocomplete="username"
                  :error-messages="clErrors.name"
                  :label="$t('Full Name')"
                  placeholder="e.g. Susan Smith"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  ref="companies"
                  v-model="user.companyID"
                  :error-messages="clErrors.companyID"
                  :item-text="formatCompanyName"
                  item-value="id"
                  :items="companies"
                  :label="$t('Company')"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <!-- If there are roles pending approval, we show the pending role not the actual role in the role
                                                                    dropdown. This way the trader-admin can cancel the pending role assignment or switch it to a
                                                                    different role assignment -->
                <v-select
                  v-if="
                    user.accountStatus === 'pending-approval-for-roles' &&
                    user.rolesPendingApproval !== null
                  "
                  ref="roles"
                  v-model="user.rolesPendingApproval[0]"
                  :error-messages="clErrors.roles"
                  :items="roles"
                  :label="$t('Role')"
                />
                <v-select
                  v-else
                  ref="roles"
                  v-model="user.roles[0]"
                  :error-messages="clErrors.roles"
                  :items="roles"
                  :label="$t('Role')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  ref="tradingPermissions"
                  v-model="user.tradingPermissions"
                  :error-messages="clErrors.tradingPermissions"
                  :item-text="formatTradingPermissionsLabel"
                  item-value="permission"
                  :items="allowedPermissions"
                  :label="$t(`tradingPermissions.title`)"
                ></v-select>
              </v-col>
            </v-row>
            <template v-if="company !== undefined">
              <v-row v-if="user.tradingPermissions === null">
                <v-col cols="12">
                  <v-sheet class="elevation-14" elevation="10">
                    <v-alert border="left" dense icon="mdi-information" type="info">
                      <span>
                        {{ $t('tradingPermissions.title') }} for the company are:
                        <em>
                          {{ $t(`tradingPermissions.options.${company.tradingPermissions}`) }}
                        </em>
                      </span>
                    </v-alert>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row
                v-else-if="
                  // no need to explain 'disabled'
                  user.tradingPermissions !== 0 &&
                  // and no need to explain when the permissions are the same
                  effectiveTradingPermissions !== user.tradingPermissions
                "
              >
                <v-col cols="12">
                  <v-alert border="left" dense icon="mdi-information" type="info">
                    <div>User permissions may be limited by permissions set on the company.</div>
                    <div>
                      Trading permissions for {{ company.name }} are:
                      <em>
                        {{ $t(`tradingPermissions.options.${company.tradingPermissions}`) }}.
                      </em>
                    </div>
                    <div>
                      As a result, the effective trading permissions of this user are:
                      <em>
                        {{ $t(`tradingPermissions.options.${effectiveTradingPermissions}`) }}.
                      </em>
                    </div>
                  </v-alert>
                </v-col>
              </v-row>
            </template>

            <v-row v-if="crudAction === RestOperation.Update">
              <v-col>
                <v-checkbox
                  v-model="user.resetPassword"
                  label="Send a Reset Password Link to the email address above"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>

          <v-alert
            v-if="originalCompany && company && originalCompany.id !== company.id"
            border="left"
            dense
            icon="mdi-alert"
            type="warning"
          >
            You're about to change the company for this user from
            {{ originalCompany.name }} to {{ company.name }}!
          </v-alert>
        </v-card-text>

        <v-card-actions class="pt-4">
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeModalDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="processing"
            :loading="processing"
            min-width="120"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('adminSaveUser') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { isEmpty } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { RestOperation, TradingPermissionOption } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { FormValidator } from '@/utils/helpers/input-form';
import i18n from '@/localisation/i18n';
import { formatTradingPermissionOption } from '@/modules/broker-admin/utils';
import { CompanyAccount } from '@/utils/api/broker';
import { UserRequest } from '@/modules/user-accounts/models';

@Component({
  components: {},
  props: {
    crudAction: Number,
    title: String,
    user: Object as PropType<UserRequest>,
  },
  data: () => ({
    emailRules: [(v: string) => /.+@.+/.test(v) || v === '' || i18n.t('formfield.InvalidEmail')],
    clErrors: {},
    svErrors: '',
    RestOperation,
    roles: [
      { text: i18n.tc('trader-user'), value: 'trader-user' },
      { text: i18n.tc('trader-viewer'), value: 'trader-viewer' },
      { text: i18n.tc('trader-admin'), value: 'trader-admin' },
      { text: i18n.tc('ops-user'), value: 'ops-user' },
      { text: i18n.tc('config-admin'), value: 'config-admin' },
    ],
    allowedPermissions: [],
    originalCompany: null,
  }),
  methods: {
    ...mapActions([
      'fetchBrokerCompanies',
      'fetchTradingPermissionOptions',
      'createBrokerAdminUser',
      'updateBrokerAdminUser',
    ]),
  },
  computed: {
    ...mapState(['companies', 'tradingPermissionOptions']),
  },
})
export default class BrokerUserDialog extends Vue {
  private crudAction!: RestOperation;
  private user!: UserRequest;
  private originalCompany: CompanyAccount | undefined;

  private companies!: CompanyAccount[];
  private tradingPermissionOptions!: TradingPermissionOption[];
  private allowedPermissions!: TradingPermissionOption[];

  private fetchBrokerCompanies!: () => Promise<null>;
  private fetchTradingPermissionOptions!: () => Promise<void>;
  private createBrokerAdminUser!: (user: UserRequest) => Promise<void>;
  private updateBrokerAdminUser!: (user: UserRequest) => Promise<void>;

  private processing = false;

  private clErrors: { [index: string]: string } = {};
  private svErrors = '';

  protected get company(): CompanyAccount | undefined {
    return this.companies.find((c) => c.id === this.user.companyID);
  }

  protected get effectiveTradingPermissions(): number {
    if (!this.company) {
      return 0;
    }

    if (this.user.tradingPermissions === null) {
      return this.company.tradingPermissions;
    }

    return this.company.tradingPermissions & this.user.tradingPermissions;
  }

  private async mounted() {
    try {
      await this.fetchBrokerCompanies();
      await this.fetchTradingPermissionOptions();
      // add `null` as option for permissions for 'inherit from company'
      this.allowedPermissions = this.tradingPermissionOptions.concat({ permission: null });
      // store the original company for the user when we opened the dialog,
      //  so we can warn if it's about to be changed
      this.originalCompany = this.company;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  private async onSubmit() {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      this.svErrors = '';
      this.clErrors = new FormValidator(this.user, [
        'companyID',
        'name',
        'emailAddress',
        'roles',
      ]).check();

      // if updating a user with pending role approvals, they should be submitted as roles or
      // they will be reset.
      if (
        this.user.accountStatus === 'pending-approval-for-roles' &&
        this.user.rolesPendingApproval !== null
      ) {
        this.user.roles = this.user.rolesPendingApproval;
      }

      // submit user
      if (isEmpty(this.clErrors)) {
        if (this.crudAction === RestOperation.Create) {
          await this.createBrokerAdminUser(this.user);
        } else {
          await this.updateBrokerAdminUser(this.user);
        }

        this.closeModalDialog();
      }
    } catch (e) {
      this.svErrors = errorString(e);
    } finally {
      this.processing = false;
    }
  }

  private formatCompanyName(c: CompanyAccount): string {
    return `${c.name} ${c.displayBoxID != null ? ' (' + c.displayBoxID + ')' : ''}`;
  }

  private formatTradingPermissionsLabel(item: TradingPermissionOption): string {
    return this.$i18n.tc(formatTradingPermissionOption(item));
  }

  private closeModalDialog() {
    // allow parent to cleanup
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped></style>
