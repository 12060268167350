<template>
  <v-snackbar
    v-model="show"
    :color="options.error ? 'error' : 'primary'"
    data-test="confirmation-snackbar"
  >
    {{ options.message }}
    <template #action>
      <v-btn
        v-if="options.showViewButton"
        data-test="view-order-button"
        small
        text
        @click="
          $emit('view-order', options.orderRef);
          show = false;
        "
      >
        View Order
      </v-btn>
      <v-btn color="white" icon small text @click="show = false">
        <v-icon size="16">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { ConfirmationSnackbarOptions } from '@/modules/marketplace/types/marketplace';
import { Watch } from 'vue-property-decorator';

@Component({
  props: {
    options: Object as PropType<ConfirmationSnackbarOptions>,
  },
})
export default class MarketplaceActionConfirmationSnackbar extends Vue {
  protected show = true;
  protected options!: ConfirmationSnackbarOptions;

  @Watch('show')
  protected onShowChange(): void {
    if (!this.show) {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped></style>
