<script setup lang="ts">
import { shallowRef, watchEffect } from 'vue';
import router from '@/router/router';
import { useStoreAuth } from '@/store/store-auth';
import i18n from '@/localisation/i18n';

const props = defineProps<{ ticket: string }>();

const storeAuth = useStoreAuth();

const authenticationAlertType = shallowRef<null | 'error' | 'info'>(null);
const authenticationTitle = shallowRef<null | string>(null);
const authenticationMessage = shallowRef<null | string>(null);

watchEffect(async () => {
  authenticationMessage.value = null;
  const result = await storeAuth.loginWithSAMLTicket(props.ticket);

  if (result.success) {
    await router.replace({ name: 'dashboard' });
    return;
  } else if (result.error === 'rest.UserNotApproved') {
    authenticationAlertType.value = 'info';
    authenticationTitle.value = 'Account Approval Pending';
  } else {
    authenticationAlertType.value = 'error';
    authenticationTitle.value = 'Single sign-on failed';
  }

  authenticationMessage.value = i18n.t(result.error, result.details) as string;
});
</script>

<template>
  <v-container class="flex-column justify-center fill-height">
    <v-alert
      v-if="authenticationTitle && authenticationMessage"
      dense
      :type="authenticationAlertType"
    >
      <h3>{{ authenticationTitle }}</h3>
      {{ authenticationMessage }}
    </v-alert>
    <template v-else>
      <v-progress-circular color="primary" indeterminate :size="200" />
      <h3 class="mt-4">Signing in...</h3>
    </template>
  </v-container>
</template>
