import { CryptoSubtle } from '@/modules/common/services/crypto/crypto-subtle';
import { KeyDerivation } from '@/modules/common/services/crypto/key-derivation';
import { PublicKeyEncrypter } from '@/modules/common/services/crypto/public-key-encrypter';
import { SymmetricEncrypter } from '@/modules/common/services/crypto/symmetric-encrypter';
import { AppState } from '@/store/store';
import Vue from 'vue';
import { Store } from 'vuex';

export interface EncrypterService {
  isCryptoSupported(): Promise<boolean>;

  getSymmetricEncrypter(): SymmetricEncrypter;

  generateNewKey(): string;
}

// Always pass the store as a parameter to the plugin
export class VueNativeEncryption {
  public static install(v: typeof Vue, options: { encrypter: RealEncrypterService }): void {
    v.prototype.$es = options.encrypter;
  }
}

// Return a promise because the plugin should not be used before the encrypt/decrypt functions are initialized!
export async function createEncrypter(
  st: Store<AppState>,
  subtle: CryptoSubtle
): Promise<EncrypterService> {
  return await RealEncrypterService.create(st, subtle);
}

export class RealEncrypterService implements EncrypterService {
  private readonly store: Store<AppState>;
  private readonly pubkeyEncrypter: PublicKeyEncrypter;
  private symmetricEncrypter!: SymmetricEncrypter;
  private keyDerivation!: KeyDerivation;

  private constructor(st: Store<AppState>, subtle: CryptoSubtle) {
    this.store = st;
    this.pubkeyEncrypter = new PublicKeyEncrypter(window, subtle);
  }

  public static async create(
    st: Store<AppState>,
    subtle: CryptoSubtle
  ): Promise<RealEncrypterService> {
    const self = new RealEncrypterService(st, subtle);

    self.keyDerivation = await KeyDerivation.create(subtle);
    self.symmetricEncrypter = new SymmetricEncrypter(window, subtle, self.keyDerivation);

    return self;
  }

  public isCryptoSupported(): Promise<boolean> {
    return this.keyDerivation.isPbkdf2Supported();
  }

  public getSymmetricEncrypter(): SymmetricEncrypter {
    if (this.symmetricEncrypter === undefined) {
      throw new Error('SymmetricEncrypter not ready');
    }
    return this.symmetricEncrypter;
  }

  public generateNewKey(): string {
    // 32 bytes is the limit of the mnemonic, so we stick to 32 bytes
    // we're also using 256 bit AES, etc. everywhere so no reason to use 64 bytes
    return this.symmetricEncrypter.getRandomBytes(32).toString('base64');
  }
}

export function useEncrypterService(): EncrypterService {
  return Vue.prototype.$es;
}
