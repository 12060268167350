<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="900"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-card v-shortkey="['enter']" @shortkey="submitForm()">
      <v-card-title class="mb-0">
        <div class="d-flex align-center">
          <span class="headline">{{ title }}</span>
        </div>
        <v-spacer />
      </v-card-title>

      <v-card-text>
        <v-alert v-if="failedIds.length" dense type="error">
          {{ items.length - failedIds.length }}
          {{ items.length - failedIds.length > 1 ? 'actions' : 'action' }} succeeded /
          {{ failedIds.length }} failed (marked in red below)
        </v-alert>

        <div v-else class="pr-4 pl-2">
          <v-row class="d-flex align-center">
            <v-col class="col-12">
              <slot name="description">
                <span>{{ description }}</span>
              </slot>
            </v-col>
          </v-row>
        </div>

        <div class="table-container mt-4">
          <div>
            <v-data-table
              disable-filtering
              disable-pagination
              disable-sort
              fixed-header
              :headers="tableHeaders"
              hide-default-footer
              item-key="key"
              :items="items"
              width="100%"
            >
              <template #item="{ item }">
                <tr :class="{ 'error--text': failedIds.includes(item[itemIdKey]) }">
                  <slot :item="item" name="row" :pretty-price="prettyPrice" />
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row v-if="showError || showSuccess">
          <v-col class="pa-0 px-1 col-6 offset-3">
            <div
              class="v-alert v-alert--dense text--primary text-body-2 text-center"
              :class="{ error: showError, success: showSuccess }"
            >
              <div v-if="showError">
                {{ errorMsgs.apiErrors.join('\n') }}
              </div>
              <div v-if="showSuccess">{{ successMessage }}</div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <slot name="disclaimer" />

      <v-card-actions class="d-flex px-8 py-4">
        <div v-if="failedIds.length" class="d-flex flex-grow-1 justify-end align-end">
          <v-btn color="primary" data-test="close-btn" @click="closeDialog()">Back</v-btn>
        </div>

        <div v-else class="d-flex flex-grow-1 justify-space-between align-end">
          <v-btn color="secondary" data-test="cancel-btn" @click="goBack()">Back</v-btn>
          <v-btn
            color="primary"
            :disabled="formStatus !== 'idle'"
            :loading="formStatus === 'submitting'"
            type="submit"
            @click="submitForm()"
          >
            Confirm
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import Decimal from 'decimal.js';
import { DataTableHeader } from 'vuetify';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import wait from '@/modules/common/services/wait';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { AxiosError } from 'axios';
import { BorrowerOpenLoan, LenderOpenLoan } from '@/modules/common/models';
import { Order } from '@/modules/auction/models';

interface FormErrors {
  apiErrors: string[];
}

@Component({
  props: {
    items: Array as PropType<LenderOpenLoan[] | BorrowerOpenLoan[] | Order[]>,
    execute: Function,
    title: String,
    description: String,
    tableHeaders: Array as PropType<DataTableHeader[]>,
    successMessage: String,
    itemIdKey: String,
  },
})
export default class ItemsBatchConfirmationDialog extends Vue {
  protected readonly items!: LenderOpenLoan[] | BorrowerOpenLoan[];
  /*
   * Using a callback instead of v-on is an unorthodox solution.
   * Here it permits the whole submit/error worlkflow to be contained inside the component.
   */
  protected readonly execute!: () => Promise<{ failedIds?: string[] }>;
  protected readonly title!: string;
  protected readonly description!: string;
  protected readonly tableHeaders!: DataTableHeader[];
  protected readonly successMessage!: string;
  protected readonly itemIdKey!: string;

  protected showSuccess = false;
  protected apiErrors: string[] = [];
  protected formStatus: DialogFormStatus = 'idle';
  protected failedIds: string[] = [];

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
    };

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected goBack(): void {
    this.$emit('back');
  }

  protected async submitForm(): Promise<void> {
    if (this.formStatus !== 'idle') {
      return;
    }

    this.formStatus = 'submitting';

    try {
      const { failedIds } = await this.execute();
      if (failedIds?.length) {
        this.failedIds = failedIds;
      } else {
        this.showSuccess = true;
        this.formStatus = 'closing';
        await wait(1200);
        this.$emit('success');
        this.closeDialog();
      }
    } catch (err) {
      this.apiErrors = [i18nServerMessage(err as AxiosError)];
    } finally {
      this.formStatus = 'idle';
    }
  }

  protected prettyPrice(value: Decimal): string {
    return getPriceAsString(value, PRICE_PRECISION);
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 30vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
