import { ColDef } from 'ag-grid-enterprise';
import { alignLeft, comparator, component, mergeClasses } from './utils';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { h } from 'vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { DepthOfBookItem } from '@/modules/marketplace/models';
import { isEqual } from 'lodash';
import Decimal from 'decimal.js';
import { equals } from './utils';

export function borrowCounterparty(): ColDef<DepthOfBookItem> {
  return {
    field: 'borrow.counterparty',
    colId: 'borrow.counterparty',
    headerName: 'Counterparty',
    enableCellChangeFlash: true,
    equals: (a, b) => isEqual(a, b),
    valueFormatter: (params) => (!params.value ? '–' : params.value.companyName),
    ...alignLeft(),
  };
}

export function borrowQuantity(): ColDef<DepthOfBookItem> {
  return {
    field: 'borrow.quantity',
    colId: 'borrow.quantity',
    headerName: 'Quantity',
    enableCellChangeFlash: true,
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value)),
    comparator: comparator.number,
    ...alignLeft(),
  };
}

const independentAmountRateAdapter = component<{
  rate: number | string | Decimal | null | undefined;
}>(
  (props) => () =>
    !props.rate ? h('span', '–') : h(RateOutput, { props: { rate: props.rate, precision: 2 } })
);

export function borrowIndependentAmountRate(): ColDef<DepthOfBookItem> {
  return {
    field: 'borrow.independentAmountRate',
    colId: 'borrow.independentAmountRate',
    headerName: 'IA Limit',
    enableCellChangeFlash: true,
    equals: equals.decimal,
    cellDataType: 'text',
    cellRendererSelector: (params) =>
      independentAmountRateAdapter({
        rate: params.value,
      }),
    comparator: comparator.decimal,
    ...alignLeft(),
  };
}

const rateAdapter = component<{
  rate: number | string | Decimal | null | undefined;
}>(
  (props) => () =>
    props.rate === null || props.rate === undefined ? h('span', '–') : h(RateOutput, { props })
);

export function borrowRate(): ColDef<DepthOfBookItem> {
  return {
    field: 'borrow.rate',
    colId: 'borrow.rate',
    headerName: 'Bid',
    enableCellChangeFlash: true,
    equals: equals.decimal,
    cellDataType: 'text',
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
      }),
    comparator: comparator.decimal,
    ...mergeClasses([alignLeft(), { cellClass: 'highlighted-border-right' }]),
  };
}

export function lendRate(): ColDef<DepthOfBookItem> {
  return {
    field: 'lend.rate',
    colId: 'lend.rate',
    headerName: 'Ask',
    enableCellChangeFlash: true,
    equals: equals.decimal,
    cellDataType: 'text',
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
      }),
    comparator: comparator.decimal,
    ...alignLeft(),
  };
}

export function lendIndependentAmountRate(): ColDef<DepthOfBookItem> {
  return {
    field: 'lend.independentAmountRate',
    colId: 'lend.independentAmountRate',
    headerName: 'IA Limit',
    enableCellChangeFlash: true,
    equals: equals.decimal,
    cellDataType: 'text',
    cellRendererSelector: (params) =>
      independentAmountRateAdapter({
        rate: params.value,
      }),
    comparator: comparator.decimal,
    ...alignLeft(),
  };
}

export function lendQuantity(): ColDef<DepthOfBookItem> {
  return {
    field: 'lend.quantity',
    colId: 'lend.quantity',
    headerName: 'Quantity',
    enableCellChangeFlash: true,
    equals: (a, b) => isEqual(a, b),
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value)),
    comparator: comparator.number,
    ...alignLeft(),
  };
}

export function lendCounterparty(): ColDef<DepthOfBookItem> {
  return {
    field: 'lend.counterparty',
    colId: 'lend.counterparty',
    headerName: 'Counterparty',
    enableCellChangeFlash: true,
    equals: (a, b) => isEqual(a, b),
    valueFormatter: (params) => (!params.value ? '–' : params.value.companyName),
    ...alignLeft(),
  };
}
