<!--
  Combines <v-btn>s and <v-menu> creating a button dropdown
  (much like https://getbootstrap.com/docs/4.0/components/dropdowns/)

  It has two variants:

  1) Regular: clicking anywhere in the button triggers the menu
      - mainText prop for the main-button
      - <slot> expects a <v-list>

  2) Split: a) clicking on the main-button triggers an action
            b) clicking on the chevron opens the menu

      - split prop enables variant
      - mainText prop for the main-button
      - @click event for the main-button
      - <slot> expects a <v-list>

  Optional props:
      - button size: x-small, small, large, x-large
      - icon to override default
-->
<template>
  <div class="wrapper">
    <!-- split variant: main-button with @click + arrow-button with menu activator -->
    <template v-if="split">
      <v-btn
        :class="['main-button', { split }]"
        :disabled="disabled"
        v-bind="buttonProps"
        @click="$emit('click')"
      >
        {{ mainText }}
      </v-btn>

      <v-menu left offset-y>
        <template #activator="{ on }">
          <v-btn class="arrow-button" :disabled="disabled" v-bind="buttonProps" v-on="on">
            <v-icon> {{ icon }}</v-icon>
          </v-btn>
        </template>
        <slot />
      </v-menu>
    </template>

    <!-- regular variant: just the main-button with menu activator -->
    <template v-else>
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn class="main-button" :disabled="disabled" v-bind="buttonProps" v-on="on">
            {{ mainText }}
            <v-icon right>{{ icon }}</v-icon>
          </v-btn>
        </template>
        <slot />
      </v-menu>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

interface ButtonProps {
  color: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'x-small': boolean;
  small: boolean;
  large: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'x-large': boolean;
}

@Component({
  props: {
    mainText: String,
    split: { type: Boolean, default: false },
    color: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: 'mdi-chevron-down' },
    'x-small': { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    'x-large': { type: Boolean, default: false },
  },
})
export default class BtnDropdown extends Vue {
  protected readonly mainText!: string;
  protected readonly split!: boolean;
  protected readonly color!: string;
  protected readonly disabled!: boolean;
  protected readonly icon!: string;
  protected readonly xSmall!: boolean;
  protected readonly small!: boolean;
  protected readonly large!: boolean;
  protected readonly xLarge!: boolean;

  protected get buttonProps(): ButtonProps {
    return {
      color: this.color,
      'x-small': this.xSmall,
      small: this.small,
      large: this.large,
      'x-large': this.xLarge,
    };
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--dark.v-list {
    background-color: #333;
  }
}

.wrapper {
  line-height: 18px; // @TODO: check how to fix on all size variants
}

.main-button.split {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.arrow-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 30px !important;
  min-width: 30px !important;
  margin-left: 1px;
}
</style>
