<!-- v-select only triggers @change after the UI has been updated -->
<!-- this component allows us to revert the change to the original value if the user rejects the dialog -->
<template>
  <confirm-dialog-wrapper :options="confirmOptions">
    <template #default="{ confirm }">
      <v-autocomplete
        v-bind="$attrs"
        v-model="localValue"
        :auto-select-first="hasSingleMatch"
        :disabled="disabled"
        :item-text="itemText"
        :items="items"
        :search-input.sync="searchInput"
        @change="onSelect(confirm, $event)"
      />
    </template>
  </confirm-dialog-wrapper>
</template>

<script lang="ts">
import ConfirmDialogMarkup from '@/modules/common/components/popups/ConfirmDialogMarkup.vue';
import { DialogOptions } from '@/plugins/dialog-manager';
import { PropType } from 'vue';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  props: {
    value: [Object, String],
    confirmOptions: Object as PropType<DialogOptions>,
    shouldBypass: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: Array,
    itemText: null as unknown as PropType<string | ((item: unknown) => string)>,
  },
  components: {
    ConfirmDialogMarkup,
  },
})
export default class ConfirmSelect extends Vue {
  // props
  protected readonly value!: string | null;
  protected readonly confirmOptions!: DialogOptions;
  protected readonly shouldBypass!: boolean;
  protected readonly disabled!: boolean;
  protected readonly items!: unknown[];
  protected readonly itemText!: string | ((item: unknown) => string);

  protected searchInput = '';
  protected localValue: string | null = null;
  protected previousValue: string | null = null;

  protected get itemLabels(): string[] {
    const itemText = this.itemText;
    const getLabel =
      typeof itemText === 'string'
        ? (item: unknown) => (item as Record<string, string>)[itemText]
        : itemText;
    return this.items.map(getLabel).map((text) => text.trim().toLowerCase());
  }

  protected get hasSingleMatch(): boolean {
    const searchInput = this.searchInput?.trim().toLowerCase() ?? '';
    return this.itemLabels.filter((label) => label.includes(searchInput)).length === 1;
  }

  @Watch('value')
  protected onValueChange(value: string | null): void {
    this.localValue = value;
  }

  protected created(): void {
    this.localValue = this.value;
  }

  protected onSelect(
    confirm: (onAccept: () => void, onReject?: () => void) => void,
    value: string
  ): void {
    if (this.shouldBypass) {
      this.setValue(value);
      return;
    }

    this.previousValue = this.localValue;
    this.localValue = value;

    confirm(
      () => this.setValue(value),
      () => (this.localValue = this.previousValue)
    );
  }

  protected setValue(value: string | null): void {
    this.localValue = value;
    this.$emit('change', value);
  }
}
</script>
