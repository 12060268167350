<!--
  Responsible for persisting and restoring component state snapshots.
  It can use URL and/or sessionStorage as drivers.
  Note: Gracefully handles errors and won't interrupt user flow.
  Instead, it logs warnings for any encountered issues.
-->
<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span v-if="shouldRenderSlot" style="display: contents">
    <slot />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { PropType } from 'vue/types/v3-component-props';

@Component({
  props: {
    storageKey: {
      type: String,
      required: true,
    },
    drivers: {
      type: Array,
      required: true,
    },
    snapshot: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
    getStateMutators: {
      type: Function,
      required: true,
    },
  },
})
export default class SnapshotManager extends Vue {
  protected readonly storageKey!: string;
  protected readonly drivers!: Array<'url' | 'sessionStorage'>;
  protected readonly snapshot!: Record<string, string>;
  protected readonly getStateMutators!: () => Record<
    string,
    (value: string) => void | Promise<void>
  >;

  // state mutators can be async, set to true once all promises are resolved
  // (avoiding all sorts of edge cases in the child component)
  protected shouldRenderSlot = false;

  @Watch('snapshot')
  protected async save(): Promise<void> {
    try {
      await this.saveToUrl();
      this.saveToStorage();
    } catch (e) {
      // snapshots are enhancements, not critical, no need to interrupt the user flow
      this.$log.warn(e);
    }
  }

  protected async created(): Promise<void> {
    try {
      // try loading snapshot from URL first
      const urlValues = this.getFromUrl();
      if (urlValues && Object.keys(urlValues).length > 0) {
        await this.mutateState(urlValues);
        this.saveToStorage();
      }
      // nothing on URL, try to load from storage
      else {
        const storageValues = this.getFromStorage();
        if (storageValues && Object.keys(storageValues).length > 0) {
          await this.mutateState(storageValues);
          await this.saveToUrl();
        }
      }
      // ready to pass values to the slot, render it
      this.shouldRenderSlot = true;
    } catch (e) {
      // snapshots are enhancements, not critical, no need to interrupt the user flow
      this.$log.warn(e);
    }
  }

  protected getFromUrl(): Record<string, string> | null {
    if (this.drivers.includes('url')) {
      return this.$router.currentRoute.query as Record<string, string>;
    }
    return null;
  }

  protected getFromStorage(): Record<string, string> | null {
    if (this.drivers.includes('sessionStorage')) {
      const storageState = sessionStorage.getItem(this.storageKey);
      if (storageState) {
        return JSON.parse(storageState);
      }
    }
    return null;
  }

  protected async saveToUrl(): Promise<void> {
    if (this.drivers.includes('url')) {
      try {
        await this.$router.replace({
          path: this.$router.currentRoute.path,
          query: this.snapshot,
        });
      } catch (e) {
        // ignore "redundant navigation to current location" warning and log all the others
        if ((e as Error).name !== 'NavigationDuplicated') {
          throw e;
        }
      }
    }
  }

  protected saveToStorage(): void {
    if (this.drivers.includes('sessionStorage')) {
      sessionStorage.setItem(this.storageKey, JSON.stringify(this.snapshot));
    }
  }

  protected async mutateState(values: Record<string, string>): Promise<void> {
    const promises = this.getStateMutatorsPromises(values);
    await Promise.all(promises);
  }

  protected getStateMutatorsPromises(
    values: Partial<Record<string, string>>
  ): Array<Promise<void>> {
    return Object.entries(this.getStateMutators()).reduce<Array<Promise<void>>>(
      (promises, [name, mutator]) => {
        const value = values[name];
        if (typeof value !== 'undefined') {
          // wrap with "Promise.resolve" to support both sync/async mutators
          promises.push(Promise.resolve(mutator(value)));
        }
        return promises;
      },
      []
    );
  }
}
</script>

<style lang="scss" scoped></style>
