import { ApiError } from '@/utils/errors/api-error';

/**
 * Internal Server Error (500)
 *
 * The server failed to fullfill the request for unknown reasons (internal server error)
 */
export class InternalServerError extends ApiError {
  public constructor(public message: string) {
    super(message);
    this.name = 'InternalServerError';
  }
}
