<template>
  <span class="d-inline-block text-truncate" :style="`max-width: ${maxWidth}`">
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on"> {{ formattedInitiator }} </span>
      </template>
      <span> {{ formattedInitiator }}</span>
    </v-tooltip>
  </span>
</template>

<script lang="ts">
import { EventInitiator } from '@/modules/marketplace/models';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
@Component({
  props: {
    initiator: {
      type: Object as PropType<EventInitiator>,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '4rem',
    },
  },
})
export default class FormatEventInitiator extends Vue {
  protected readonly initiator!: EventInitiator;
  protected readonly maxWidth!: string;

  protected get formattedInitiator(): string {
    if (this.initiator.kind === 'TRADER') {
      return this.initiator.email || this.initiator.externalIdentifier || '';
    }
    return this.initiator.kind.toLowerCase();
  }
}
</script>
<style lang="scss" scoped></style>
