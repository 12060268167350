import { defineStore } from 'pinia';
import Vue, { computed, ref, watch } from 'vue';
import { GridApi } from 'ag-grid-enterprise';
import { QueryMarketplaceItem } from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { useStoreSecurities } from '@/store/store-securities';
import { PopulateFormPayload } from '@/modules/marketplace/components/MarketplaceOrderForm.vue';
import Decimal from 'decimal.js';
import { OmsOrderType } from '@/connect/gen/consts/omsconsts_pb';
import { SettlementType } from '@/connect/gen/consts/settlementconsts_pb';
import { CompanyInfo, RawSecurity, Security } from '@/modules/common/models';
import { BookOrder } from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { useStoreSocket } from '@/store/store-socket';
import { createDataSource } from './datasource-explorer';
import { store as globalStore } from '@/store/store';
import { useStoreCompanies } from '@/store/store-companies';

export interface QueryMarketplaceItemWithCusipAndTicker extends QueryMarketplaceItem {
  cusip: string;
  ticker: string;
}

export const useStoreExplorer = defineStore('marketplaceExplorer', () => {
  const canBorrow = computed(() => globalStore.getters.canBorrow);
  const canLend = computed(() => globalStore.getters.canLend);
  const direction = ref<'lend' | 'borrow'>(getDefaultDirection());
  watch(direction, (val) => {
    sessionStorage.setItem('marketplace-direction', val);
  });
  function getDefaultDirection() {
    return (
      (sessionStorage.getItem('marketplace-direction') as 'lend' | 'borrow') ||
      (canLend.value ? 'lend' : 'borrow')
    );
  }
  function setDefaultDirection() {
    direction.value = getDefaultDirection();
  }
  const oppositeDirection = computed(() => (direction.value === 'borrow' ? 'lend' : 'borrow'));

  const showForm = ref(sessionStorage.getItem('marketplace-show-form') === 'true' || false);
  watch(showForm, (val) => {
    sessionStorage.setItem('marketplace-show-form', String(val));
  });

  const orderRef = ref<string | null>(null);
  const selectedSecurity = ref<Security | null>(null);
  watch(selectedSecurity, () => {
    gridApi.value?.refreshServerSide({
      purge: true,
    });
  });

  const gridApi = ref<GridApi | null>(null);
  const areGroupsExpanded = ref(true);

  function toggleExpandCollapse() {
    if (areGroupsExpanded.value) {
      gridApi.value?.collapseAll();
    } else {
      gridApi.value?.expandAll();
    }
    areGroupsExpanded.value = !areGroupsExpanded.value;
  }

  let populateForm: ((payload: PopulateFormPayload) => void) | null = null;

  // The Marketplace OrderForm automatically calls this function on mount.
  // Even though this is a solution that feels out of place, there are
  // interesting benefits in having a pinia store managing the form state.
  // Maybe this could be a first step towards a nicer state management
  // (avoiding prop drilling and cumbersome parent/child relationships).
  function registerPopulateForm(fn: (payload: PopulateFormPayload) => void) {
    populateForm = fn;
  }

  // match button calls this function
  function executePopulateForm(order: BookOrder) {
    if (!populateForm) return;
    // converts the payload from one type to the other
    // needed until surrounding code becomes connect-go

    const security = useStoreSecurities().getSecurity(order.cusip);
    const company = useStoreCompanies().getCompany(order.companyId);
    const payload: PopulateFormPayload = {
      side: direction.value === 'borrow' ? 'BORROWER' : 'LENDER',
      quantity: Number(order.quantity),
      minQuantity: Number(order.minExecutionQuantity),
      filled: 0,
      security: Security.fromData(security as RawSecurity),
      counterparties: [
        {
          companyId: order.companyId,
          displayBoxId: company!.displayBoxId,
          // @TODO: this is a dangerous type cast, revisit!
        } as CompanyInfo,
      ],
      rate: new Decimal(order.rate),
      orderType:
        order.orderType === OmsOrderType.LIMIT
          ? 'LIMIT'
          : order.orderType === OmsOrderType.MARKET
            ? 'MARKET'
            : 'IOI',
      timeInForceType: 'DAY',
      settlementType:
        order.settlementType === SettlementType.NSCC
          ? 'NSCC'
          : order.settlementType === SettlementType.BILATERAL
            ? 'BILATERAL'
            : 'OCC',
      roundingRule: 'NO_ROUNDING',
      independentAmountRate: new Decimal(order.independentAmountRate),
      isAnonymous: false,
    };

    populateForm(payload);
    showForm.value = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function cancelOrder(orderRef: string) {
    Vue.prototype.$dialog.ask({
      message: 'You are about to cancel the order.',
      title: 'Order cancellation',
      rejectText: 'Keep order',
      acceptText: 'Cancel order',
      onAccept: async () => {
        try {
          await Vue.prototype.$api.marketplace.cancelOrder(orderRef);
          Vue.prototype.$snackbar.confirm('Order has been successfully canceled');
        } catch (err) {
          const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
          Vue.prototype.$snackbar.error(`Operation failed: ${errorMessage}`);
        }
      },
    });
  }

  const storeSocket = useStoreSocket();

  storeSocket.on('refreshVenueOrders', (payload: { clientOrderRef: string; cusip: string }) => {
    // delay refresh, so backend has time to update depth-of-book
    setTimeout(() => {
      gridApi.value?.refreshServerSide({
        // we don't pass a route when the order has a cusip not yet present in the grid
        // as a result, the grid will collapse all previously expanded groups
        route: gridApi.value?.getRowNode(payload.cusip) !== undefined ? [payload.cusip] : undefined,
        purge: true,
      });
    }, 2000);
  });

  return {
    direction,
    oppositeDirection,
    gridApi,
    dataSource: createDataSource(),
    showForm,
    orderRef,
    registerPopulateForm,
    executePopulateForm,
    cancelOrder,
    selectedSecurity,
    areGroupsExpanded,
    toggleExpandCollapse,
    canBorrow,
    canLend,
    setDefaultDirection,
  };
});

export type StoreExplorer = ReturnType<typeof useStoreExplorer>;
