import { format as dateFormatter, parse } from 'date-fns';

/**
 * Formats a date object to a string
 */
export function formatDate(
  date: unknown,
  format: string,
  options?: Parameters<typeof dateFormatter>[2]
): string {
  try {
    if (typeof date === 'number' || date instanceof Date) {
      return dateFormatter(date, format, options);
    } else if (typeof date === 'string') {
      // attempt to turn into a date object
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.valueOf())) throw new Error('invalid date');

      return dateFormatter(parsedDate, format, options);
    } else {
      return '';
    }
  } catch (err) {
    return `${date}`;
  }
}

export function parseDate(date: string, format: string = 'yyyy-MM-dd', timezone?: string): Date {
  timezone ??= Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = new Date(new Date().toLocaleString('en', { timeZone: timezone }));
  return parse(date, format, now);
}
