import { Raw } from '@/modules/common/helpers/api';
import { Security } from '@/modules/common/models';

export type RawWatchlistItemCreateRequest = Raw<
  WatchlistItemCreateRequest,
  {
    instruments: string[];
  },
  'securities'
>;

export class WatchlistItemCreateRequest {
  public name: string;
  public shared: boolean;
  public securities: Security[];

  protected constructor(model: WatchlistItemCreateRequest) {
    this.name = model.name;
    this.shared = model.shared;
    this.securities = model.securities;
  }

  public static fromModel(model: WatchlistItemCreateRequest): WatchlistItemCreateRequest {
    return new WatchlistItemCreateRequest(model);
  }

  public static toData(model: WatchlistItemCreateRequest): RawWatchlistItemCreateRequest {
    return {
      name: model.name,
      shared: model.shared,
      instruments: model.securities.map((security) => security.cusip),
    };
  }
}

export type RawWatchlistItemUpdateRequest = Raw<
  WatchlistItemUpdateRequest,
  {
    instruments: string[];
  },
  'securities'
>;

export class WatchlistItemUpdateRequest {
  public name: string;
  public shared: boolean;
  public securities: Security[];

  protected constructor(model: WatchlistItemUpdateRequest) {
    this.name = model.name;
    this.shared = model.shared;
    this.securities = model.securities;
  }

  public static fromModel(model: WatchlistItemUpdateRequest): WatchlistItemUpdateRequest {
    return new WatchlistItemUpdateRequest(model);
  }

  public static toData(model: WatchlistItemUpdateRequest): RawWatchlistItemUpdateRequest {
    return {
      name: model.name,
      shared: model.shared,
      instruments: model.securities.map((security) => security.cusip),
    };
  }
}
