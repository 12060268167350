import { ShallowRef, getCurrentScope, onScopeDispose, shallowRef } from 'vue';

export function useInterval(
  handler: TimerHandler,
  interval: number
): { start: () => void; stop: () => void } {
  let timerId: null | number = null;

  function start() {
    if (timerId !== null) return;
    timerId = window.setInterval(handler, interval);
  }

  function stop() {
    if (timerId === null) return;
    window.clearInterval(timerId);
    timerId = null;
  }

  return { start, stop };
}

export function useEventListener<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions
): void {
  const effectScope = getCurrentScope();

  if (effectScope === undefined) {
    throw new Error('`useEventListener` composable must be called from within setup context');
  }

  effectScope.run(() => {
    addEventListener(type, listener, options);
    onScopeDispose(() => removeEventListener(type, listener, options));
  });
}

export function useIsOnline(): ShallowRef<boolean> {
  const isOnline = shallowRef(navigator.onLine);

  function updateIsOnline() {
    isOnline.value = navigator.onLine;
  }

  useEventListener('online', updateIsOnline);
  useEventListener('offline', updateIsOnline);

  return isOnline;
}
