<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="800"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-btn class="close-icon" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <span class="headline">
            {{ accept ? 'Accept' : 'Reject' }}
            <format-side :side="contract.side" /> Contract
          </span>
        </v-card-title>

        <v-card-text>
          <v-container v-shortkey="['enter']" @shortkey="respond()">
            <v-row class="text--primary">
              <v-col class="py-0">
                <h2 class="text-h6 text--secondary">{{ accept ? 'Accept' : 'Reject' }}?</h2>
                <term-loans-contract-full-summary :contract="contract" />
              </v-col>
            </v-row>

            <v-row v-if="showError">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div
                  class="v-alert v-alert--dense text--primary text-body-2 text-center"
                  :class="{ error: showError }"
                >
                  <div v-if="showError">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex">
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="closeDialog()">
              Back
            </v-btn>
            <v-btn
              class="ml-4"
              :color="accept ? 'primary' : 'red'"
              type="submit"
              @click="respond()"
            >
              {{ accept ? 'Accept' : 'Reject' }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import TermLoansContractFullSummary from '@/modules/termloans/components/TermLoansContractFullSummary.vue';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { Contract } from '@/modules/termloans/models';

@Component({
  props: {
    accept: {
      type: Boolean,
      required: true,
    },
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
  },
  components: {
    TermLoansContractFullSummary,
  },
})
export default class TermLoansContractRespondProposedDialog extends mixins(validationMixin) {
  // props
  protected contract!: Contract;
  protected accept!: boolean;

  protected formStatus: DialogFormStatus = 'idle';
  protected apiErrors: string[] = [];

  protected get showError(): boolean {
    return !!this.apiErrors.length;
  }

  protected async respond(): Promise<void> {
    if (this.formStatus !== 'idle') {
      return;
    }

    this.formStatus = 'submitting';

    try {
      if (this.accept) {
        await this.$api.termLoans.acceptProposal(this.contract.displayId);
        this.$snackbar.show({
          color: 'primary',
          message: `The contract has been accepted.`,
          timeout: 3000,
        });
      } else {
        await this.$api.termLoans.rejectProposal(this.contract.displayId);
        this.$snackbar.show({
          color: 'primary',
          message: `The contract has been rejected.`,
          timeout: 3000,
        });
      }

      this.closeDialog();
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [`Operation Failed: ${errorMessage}`];
      this.formStatus = 'idle';
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    overflow-y: hidden;
  }
}

.col-6 + .col-6 {
  padding-left: 12px;
}

.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}
</style>
