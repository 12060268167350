import { ConnectResult, createPromiseClient } from '@/connect';
import {
  QueryMarketplaceFilter,
  QueryMarketplaceRequest,
  QueryMarketplaceResponse,
} from '@/connect/gen/modules/apiengine/services/venue/venue_pb';
import { VenueService } from '@/connect/gen/modules/apiengine/services/venue/venue_connect';
import { Side } from '@/connect/gen/consts/commonconsts_pb';

export interface QueryMarketplaceConfig {
  page: number;
  pageSize: number;
  lendFilter: QueryMarketplaceFilter;
  borrowFilter: QueryMarketplaceFilter;
  tradeIntention: Side;
}

export const serviceMarketplace = new (class {
  public readonly promiseClient = createPromiseClient(VenueService);

  public async queryMarketplace(
    config: QueryMarketplaceConfig
  ): Promise<ConnectResult<QueryMarketplaceResponse>> {
    const query = new QueryMarketplaceRequest({
      borrowFilter: config.borrowFilter,
      lendFilter: config.lendFilter,
      tradeIntention: config.tradeIntention,
      pageNumber: config.page,
      pageLimit: config.pageSize,
    });
    const result = await this.promiseClient.queryMarketplace(query);
    return result;
  }

  public async querySecurities(
    config: QueryMarketplaceConfig
  ): Promise<Record<string, { ticker: string; cusip: string }>> {
    const result = await this.promiseClient.queryMarketplaceInstruments(config);
    return result.success ? result.data.instruments : {};
  }
})();
