<template>
  <div>
    <dashboard-panel no-collapse title="Locates">
      <borrower-locates />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import BorrowerLocates from '@/modules/borrower/components/BorrowerLocates.vue';

@Component({
  components: {
    DashboardPanel,
    BorrowerLocates,
  },
})
export default class BorrowerLocatesView extends Vue {}
</script>

<style lang="scss"></style>
