import { Raw } from '@/modules/common/helpers/api';
import { RawSecurity, Security } from '@/modules/common/models';

export type RawLoanSubmissionsUploadResponse = Raw<
  LoanSubmissionsUploadResponse,
  {
    items: RawLoanSubmissionUpload[];
  }
>;

export class LoanSubmissionsUploadResponse {
  public items: LoanSubmissionUpload[];

  protected constructor(data: RawLoanSubmissionsUploadResponse) {
    this.items = data.items.map(LoanSubmissionUpload.fromData);
  }

  public static fromData(data: RawLoanSubmissionsUploadResponse): LoanSubmissionsUploadResponse {
    return new LoanSubmissionsUploadResponse(data);
  }
}

export type RawLoanSubmissionUpload = Raw<
  LoanSubmissionUpload,
  {
    instrument: RawSecurity;
  },
  'security'
>;

export class LoanSubmissionUpload {
  public quantity: number;
  public security: Security;

  protected constructor(data: RawLoanSubmissionUpload) {
    this.quantity = data.quantity;
    this.security = Security.fromData(data.instrument);
  }

  public static fromData(data: RawLoanSubmissionUpload): LoanSubmissionUpload {
    return new LoanSubmissionUpload(data);
  }
}
