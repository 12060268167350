<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="600"
    origin="center center"
    overlay-color="secondary"
    overlay-opacity="0.25"
    persistent
    transition="scale-transition"
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card v-if="clientConfig">
        <v-card-title>
          <span class="headline">{{
            $t('loginConfirmIdentityTitle', { title: clientConfig.systemTitleShort })
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-6">
            <v-alert v-if="svErrors" type="error">
              {{ svErrors }}
              <br />
              {{ $t('loginConfirmIdentityError2') }}
            </v-alert>
            <v-alert v-else border="left" color="warning" icon="mdi-information" text>
              {{ $t('loginConfirmIdentityInfo1', { title: clientConfig.systemTitleShort }) }}
              <br />
              {{ $t('loginConfirmIdentityInfo2', { emailAddress: emailAddress }) }}
            </v-alert>
          </v-container>

          <v-container class="mt-6">
            <v-text-field
              v-model="resetCode"
              autofocus
              :error-messages="clErrors.resetCode"
              :label="$t('resetCode')"
              required
            ></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text to="/">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="px-8 mx-4" color="primary" type="submit" @click="onSubmit">
            <v-icon left>mdi-account-key</v-icon>
            {{ $t('loginConfirmIdentityButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import { AppState } from '@/store/store';
import { StoreAuth, useStoreAuth } from '@/store/store-auth';
import { FormValidator } from '@/utils/helpers/input-form';
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  props: {
    loginEmail: {
      type: String,
      default: 'your inbox',
    },
    secretCode: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['socketWLToken', 'clientConfig']),
  },
  setup() {
    return storeToRefs(useStoreAuth());
  },
})
export default class ConfirmIdentity extends Vue {
  public resetCode = '';
  protected emailAddress = '';

  // props
  protected readonly loginEmail!: string;
  protected readonly secretCode!: string;

  // store state refs
  protected readonly clientConfig!: AppState['clientConfig'];
  protected readonly socketWLToken!: AppState['socketWLToken'];

  // pinia
  protected readonly confirmIdentity!: StoreAuth['confirmIdentity'];

  protected clErrors: Record<string, string> = {};
  protected svErrors = '';

  protected async mounted(): Promise<void> {
    this.emailAddress = this.loginEmail;
    this.resetCode = this.secretCode;
    if (this.secretCode !== '') {
      await this.onSubmit();
    }
  }

  protected async onSubmit(): Promise<void> {
    this.svErrors = '';
    this.clErrors = new FormValidator(this, ['resetCode']).check();
    if (!isEmpty(this.clErrors)) {
      return;
    }

    const result = await this.confirmIdentity(this.loginEmail, this.resetCode);
    if (!result.success) {
      const error = i18n.tc(result.error);
      this.svErrors = error;
      this.resetMessage(error, i18n.tc('loginRetry') as string);
    }
  }

  protected resetMessage(message: string, actionText: string): void {
    this.$snackbar.show({ message: message, actionText: actionText });
  }
}
</script>

<style lang="scss" scoped></style>
