<template>
  <span>
    <v-dialog
      v-if="step === 'edit'"
      v-shortkey="['esc']"
      content-class="au-popup-dialog"
      max-width="1220"
      overlay-color="secondary"
      overlay-opacity="0.80"
      persistent
      :value="openLoans !== null"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      @shortkey.native="closeDialog()"
    >
      <v-form novalidate @submit.prevent>
        <v-card v-shortkey="['enter']" class="form-summary" @shortkey="goToSummary()">
          <v-card-title class="mb-0">
            <div class="d-flex align-center">
              <span class="headline">
                {{ action }}
                {{
                  isGroupedBySecurity(security)
                    ? `${security.ticker} [${security.cusip}]`
                    : isGroupedByCounterparty(counterparty)
                      ? `${counterparty.companyName} (${counterparty.displayBoxId})`
                      : ''
                }}
              </span>
            </div>
            <v-spacer />
          </v-card-title>

          <!-- Header -->
          <v-card-text>
            <v-row class="d-flex align-center pl-4">
              <v-col cols="12">
                <h4 v-if="aggregate" class="summary mb-4">
                  <span v-if="action === 'return'">
                    There are
                    <strong>
                      <pretty-number :value="aggregate.totalOpenQtyForReturn" />
                    </strong>
                    shares available to {{ action }} (<pretty-number
                      :value="aggregate.totalPendingReturnQty"
                    />
                    pending return).
                  </span>
                  <span v-if="action === 'recall'">
                    There are
                    <strong>
                      <pretty-number
                        :value="
                          action === 'recall'
                            ? aggregate.totalOpenQty - aggregate.totalRecallQty
                            : aggregate.totalOpenQty
                        "
                      />
                    </strong>
                    shares available to {{ action }}.
                  </span>
                  <span>&nbsp;</span>
                  <format-side v-if="side === 'LENDER'" side="lender">Lending</format-side>
                  <format-side v-else side="borrower">Borrowing</format-side>
                  <strong> &nbsp;<pretty-number :value="aggregate.totalOpenQty" /></strong>
                  shares
                  <template v-if="isAggregatedBySecurity(aggregate)">
                    to
                    <strong> {{ aggregate.counterpartyCount }} </strong> counterparties in
                    <strong>{{ aggregate.loansCount }} loans</strong>, totaling
                    <strong> ${{ formatPrice(aggregate.totalValue) }} </strong> with an average rate
                    of
                    <strong> <rate-output :rate="aggregate.avgRate" /> </strong>
                  </template>
                  <template v-else-if="isAggregatedByCounterparty(aggregate)">
                    across
                    <strong> {{ aggregate.securityCount }} </strong> different securities in
                    <strong>{{ aggregate.loansCount }} loans</strong>, totaling
                    <strong> ${{ formatPrice(aggregate.totalValue) }} </strong> with an average rate
                    of
                    <strong> <rate-output :rate="aggregate.avgRate" /> </strong>
                  </template>
                </h4>
                <v-divider />
              </v-col>
            </v-row>

            <div class="pl-4 mb-2 controls">
              <confirm-autocomplete
                ref="change-strategy"
                :confirm-options="{
                  message: `You are about to change the ${action} strategy. The current total will be redistributed accordingly.`,
                  title: `Change ${action} strategy`,
                  rejectText: 'Keep strategy',
                  acceptText: 'Apply new strategy',
                  color: 'primary',
                }"
                item-text="text"
                item-value="value"
                :items="strategyItems"
                label="Strategy"
                :should-bypass="totalQuantity <= 0"
                :value="selectedStrategy"
                @change="onChangeStrategy"
              />

              <numeric-input
                autofocus
                data-test="total-quantity"
                :disabled="selectedStrategy === 'custom'"
                :error-messages="totalQuantityErrorMessage"
                :label="`Total ${action} quantity`"
                :min="1"
                placeholder="0"
                :step="100"
                type="integer"
                :value="totalQuantity"
                @input="distributeQuantity($event)"
              >
                <template #append>
                  <v-btn
                    class="pa-0"
                    data-test="total-quantity-max"
                    outlined
                    x-small
                    @click="distributeQuantity(maxQuantity)"
                  >
                    MAX
                  </v-btn>
                </template>
              </numeric-input>

              <!-- Filters -->
              <confirm-autocomplete
                v-if="groupingType !== 'counterparty'"
                class="counterparties"
                clearable
                :confirm-options="{
                  message:
                    'You are about to change the counterparty filter. The current total will be redistributed accordingly.',
                  title: 'Change counterparty filter',
                  rejectText: 'Keep counterparty',
                  acceptText: 'Apply new filter',
                  color: 'primary',
                }"
                data-test="counterparty-filter"
                :item-text="
                  (counterparty) => `${counterparty.companyName} (${counterparty.displayBoxId})`
                "
                :items="counterparties"
                placeholder="All counterparties"
                return-object
                :should-bypass="totalQuantity <= 0"
                :value="selectedCounterparty"
                @change="onChangeCounterparty"
              />
              <confirm-autocomplete
                v-if="groupingType !== 'security'"
                class=""
                clearable
                :confirm-options="{
                  message:
                    'You are about to change the security filter. The current total will be redistributed accordingly.',
                  title: 'Change security filter',
                  rejectText: 'Keep security',
                  acceptText: 'Apply new filter',
                  color: 'primary',
                }"
                data-test="security-filter"
                :item-text="(security) => `${security.ticker} (${security.cusip})`"
                :items="equitites"
                placeholder="All securities"
                return-object
                :should-bypass="totalQuantity <= 0"
                :value="selectedSecurity"
                @change="onChangeSecurity"
              />
            </div>

            <v-divider />

            <mass-modify-loans-edit-table
              :action="action"
              :grouping-type="groupingType"
              :is-read-only="selectedStrategy !== 'custom'"
              :items="filteredOpenLoans"
              :new-quantities="newQuantities"
              :server-items-length="filteredOpenLoans.length"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              style="min-height: 40vh"
              @click-single-quantity="handleClickSingleQuantity"
              @update-single-quantity="updateSingleQuantity"
              @update:options="debouncedFetchLoans"
            />

            <v-alert
              v-if="openLoans.total > openLoans.items.length"
              class="mt-6 mb-4 pl-4 font-weight-light"
              color="blue-grey"
              dense
              type="error"
            >
              Showing the first {{ openLoansLimit }} of {{ openLoans.total }} loans. Sort or filter
              differently to see the other loans.
            </v-alert>
          </v-card-text>

          <v-card-actions class="d-flex px-8 py-4">
            <div class="d-flex flex-grow-1 justify-space-between align-end">
              <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">Back</v-btn>
              <aurora-btn
                color="primary"
                data-test="go-to-summary"
                :timeframe="actionTimeframe"
                type="submit"
                @click="goToSummary()"
              >
                {{ action === 'recall' ? 'Recall' : 'Return' }}
              </aurora-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <batch-recall-dialog
      v-if="step === 'confirm' && action === 'recall'"
      :items="loansToUpdate"
      :new-quantities="newQuantities"
      @back="step = 'edit'"
      @close-modal="closeDialog()"
      @success="
        $emit('success');
        closeDialog();
      "
    />

    <batch-return-dialog
      v-if="step === 'confirm' && action === 'return'"
      :items="loansToUpdate"
      :new-quantities="newQuantities"
      @back="step = 'edit'"
      @close-modal="closeDialog()"
      @success="
        $emit('success');
        closeDialog();
      "
    />
  </span>
</template>

<script lang="ts">
import BatchReturnDialog from '@/modules/borrower/components/BatchReturnDialog.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { CompanyInfo, OpenLoanItem, OpenLoans, Security } from '@/modules/common/models';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import BatchRecallDialog from '@/modules/lender/components/BatchRecallDialog.vue';
import ConfirmAutocomplete from '@/modules/open-loans/components/ConfirmAutocomplete.vue';
import MassModifyLoansEditTable from '@/modules/open-loans/components/MassModifyLoansEditTable.vue';
import {
  NewQuantities,
  areSingleQuantitiesWithinAvailable,
  availableQuantity,
  availableToUpdate,
  distributeEqualShares,
  distributeSorted,
  isTotalQuantityWithinAvailable,
} from '@/modules/open-loans/helpers/multipleUpdates';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import {
  AggregatedLoanByCounterpartyItem,
  AggregatedLoanBySecurityItem,
  AggregatedOpenLoansParams,
  OpenLoansParams,
} from '@/utils/api/loans';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { debounce } from 'lodash';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    action: {
      type: String as PropType<MultipleLoanAction>,
    },
    security: {
      type: Object as PropType<{
        ticker: string;
        cusip: string;
      } | null>,
      default: null,
    },
    counterparty: {
      type: Object as PropType<CompanyInfo>,
      default: null,
    },
    loans: {
      type: Array as PropType<OpenLoanItem[]>,
      default: null,
    },
  },
  components: {
    CounterpartySearch,
    MassModifyLoansEditTable,
    ConfirmAutocomplete,
    BatchRecallDialog,
    BatchReturnDialog,
  },
})
export default class MassModifyLoansDialog extends Vue {
  // props
  protected readonly action!: MultipleLoanAction;
  protected readonly security!: {
    ticker: string;
    cusip: string;
  } | null;
  protected readonly counterparty!: CompanyInfo | null;
  protected readonly loans!: OpenLoanItem[] | null;

  protected openLoans: OpenLoans = OpenLoans.fromData({
    items: [],
    total: 0,
    recalledTotal: 0,
    rerateTotal: 0,
    corporateActions: {},
    status: '',
    returnProposalTotal: 0,
  });
  protected aggregate: {
    loansCount: number;
    totalOpenQty: number;
    totalOpenQtyForReturn: number;
    totalPendingReturnQty: number;
    totalRecallQty: number;
  } | null = null;

  protected newQuantities: NewQuantities = {};

  protected selectedStrategy: 'leastProfitable' | 'leastLoans' | 'equalShares' | 'custom' =
    'leastProfitable';
  protected strategyItems = [
    { text: 'Least profitable', value: 'leastProfitable' },
    { text: 'Affect the least amount of loans', value: 'leastLoans' },
    { text: 'Distribute equally', value: 'equalShares' },
    { text: 'Custom', value: 'custom' },
  ];

  protected selectedCounterparty: CompanyInfo | null = null;
  protected selectedSecurity: Security | null = null;
  protected sortBy = 'rate';
  protected sortDesc = true;
  protected openLoansLimit = 50;

  protected step: 'edit' | 'confirm' = 'edit';
  protected successMessage!: string;
  protected formStatus: DialogFormStatus = 'idle';
  protected hasSubmittedOnce = false;

  // when both "sortBy" and "sortDesc" and changed (by changing strategy), update:options is fired twice
  // debounce to avoid unnecessary API calls
  protected debouncedFetchLoans = debounce(this.fetchLoans, 10);

  protected get maxQuantity(): number {
    return availableQuantity(this.filteredOpenLoans);
  }

  protected get side(): 'LENDER' | 'BORROWER' {
    return this.action === 'recall' ? 'LENDER' : 'BORROWER';
  }

  protected get groupingType(): 'counterparty' | 'security' | null {
    return this.loans !== null ? null : this.security?.ticker ? 'security' : 'counterparty';
  }

  protected get filteredOpenLoans(): OpenLoanItem[] {
    return (this.openLoans.items as OpenLoanItem[]).filter((loan) => availableToUpdate(loan) > 0);
  }

  protected get loansToUpdate(): OpenLoanItem[] {
    // only interested in loans with a input quantity greater than 0
    return this.filteredOpenLoans.filter((loan) => this.newQuantities[loan.id] > 0);
  }

  protected get counterparties(): CompanyInfo[] {
    // get from FE (instead of all possible counterparties)
    // because we always want a subset of the fetched loans
    const counterparties = this.filteredOpenLoans.reduce<CompanyInfo[]>((acc, next) => {
      if (!acc.find((counterparty) => counterparty.companyId === next.counterparty.companyId)) {
        acc.push(next.counterparty);
      }
      return acc;
    }, []);

    return counterparties.sort((a, b) =>
      a.companyName < b.companyName ? -1 : a.companyName > b.companyName ? 1 : 0
    );
  }

  protected get equitites(): Security[] {
    // get from FE (instead of all possible equitites)
    // because we always want a subset of the fetched loans
    const securities = this.filteredOpenLoans.reduce<Security[]>((acc, next) => {
      if (!acc.find((security) => security.ticker === next.security.ticker)) {
        acc.push(next.security);
      }
      return acc;
    }, []);

    return securities.sort((a, b) => (a.ticker < b.ticker ? -1 : a.ticker > b.ticker ? 1 : 0));
  }

  protected get totalQuantity(): number {
    const total = this.newQuantities
      ? Object.values(this.newQuantities).reduce((acc, next) => acc + next, 0)
      : 0;

    return total;
  }

  protected get totalQuantityErrorMessage(): string {
    if (this.hasSubmittedOnce && !this.totalQuantity) {
      return 'must be greater than 0';
    }

    if (!isTotalQuantityWithinAvailable(this.totalQuantity, this.filteredOpenLoans)) {
      return 'max recall quantity exceeded';
    }

    return '';
  }

  protected get actionTimeframe(): 'recallLoans' | 'settleLoans' {
    return this.action === 'recall' ? 'recallLoans' : 'settleLoans';
  }

  protected isGroupedForReturn(_subject: unknown): _subject is {
    loansCount: number;
    totalOpenQty: number;
    totalOpenQtyForReturn: number;
    totalPendingReturnQty: number;
    totalRecallQty: number;
  } {
    return this.action === 'return';
  }

  protected isGroupedForRecall(_subject: unknown): _subject is {
    totalRecallQty: number;
  } {
    return this.action === 'recall';
  }

  protected isGroupedBySecurity<T extends object>(_subject: T | null): _subject is T {
    return this.groupingType === 'security';
  }

  protected isAggregatedBySecurity(_subject: unknown): _subject is AggregatedLoanBySecurityItem {
    return this.groupingType === 'security';
  }

  protected isGroupedByCounterparty<T extends object>(_subject: T | null): _subject is T {
    return this.groupingType === 'counterparty';
  }

  protected isAggregatedByCounterparty(
    _subject: unknown
  ): _subject is AggregatedLoanByCounterpartyItem {
    return this.groupingType === 'counterparty';
  }

  // mounted is too late, because by that point we have already manipulated sortBy and sortDesc
  // and we have race conditions problems with the child table ("update:options" event being fired twice)
  protected async created(): Promise<void> {
    this.onChangeStrategy('leastProfitable');
    await this.fetchAggregate();

    // we don't need to "fetchLoans" here, because the child table will do it for us
    // (it emits the "update:options" event when it mounts)
  }

  protected async fetchAggregate(): Promise<void> {
    try {
      if (this.loans !== null) {
        const aggregate = {
          loansCount: this.loans.length,
          totalOpenQty: 0,
          totalOpenQtyForReturn: 0,
          totalPendingReturnQty: 0,
          totalRecallQty: 0,
        };
        for (const loan of this.loans) {
          // full recall/return
          aggregate.totalOpenQty +=
            this.action === 'recall' ? loan.openQuantityToRecall : loan.openQuantityToReturn;
          aggregate.totalOpenQtyForReturn += loan.openQuantityToReturn;
          aggregate.totalPendingReturnQty += loan.pendingReturnQuantity;
          aggregate.totalRecallQty += loan.recalledQuantity;
        }
        this.aggregate = aggregate;
        this.redistribute();
        return;
      }

      const params: AggregatedOpenLoansParams = {
        group: this.groupingType,
        filters:
          this.groupingType === 'security'
            ? { cusip: this.security?.cusip, side: this.side }
            : { counterpartyCompanyId: this.counterparty?.companyId, side: this.side },
      };

      const res = await this.$api.openLoans.fetchAggregatedOpenLoans(params);
      if (res.items.length === 1) {
        this.aggregate = res.items[0];
      } else {
        throw new Error('Response should contain exactly one item');
      }
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected loanMatchesFilter(loan: OpenLoanItem): boolean {
    if (
      this.selectedCounterparty !== null &&
      this.selectedCounterparty.companyId !== loan.counterparty.companyId
    ) {
      return false;
    }
    if (this.selectedSecurity !== null && this.selectedSecurity.cusip !== loan.security.cusip) {
      return false;
    }
    return true;
  }

  protected async fetchLoans(): Promise<void> {
    try {
      if (this.loans !== null) {
        this.openLoans = OpenLoans.fromData({
          items: [],
          total: 0,
          recalledTotal: 0,
          rerateTotal: 0,
          corporateActions: {},
          status: '',
          returnProposalTotal: 0,
        });
        this.openLoans.items = this.loans.filter(this.loanMatchesFilter.bind(this));
        this.redistribute();
        return;
      }

      const params: OpenLoansParams = {
        pagination: { page: 1, limit: this.openLoansLimit },
        sort: `${this.sortDesc ? '-' : '+'}${this.sortBy}`,
        filters: {
          cusip:
            this.groupingType === 'security' ? this.security?.cusip : this.selectedSecurity?.cusip,
          counterpartyCompanyId:
            this.groupingType === 'counterparty'
              ? this.counterparty?.companyId
              : this.selectedCounterparty?.companyId,
          side: this.side,
        },
      };

      this.openLoans = await this.$api.openLoans.fetchOpenLoans(params);
      this.redistribute();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected handleClickSingleQuantity(): void {
    if (this.selectedStrategy === 'custom') {
      return;
    }

    this.$dialog.ask({
      message: 'You are about to switch to custom mode. Are you sure?',
      title: 'Switch to custom mode',
      rejectText: 'Cancel',
      acceptText: 'Switch to custom mode',
      color: 'primary',
      onAccept: () => this.onChangeStrategy('custom'),
    });
  }

  protected updateSingleQuantity(payload: { loan: OpenLoanItem; quantity: number }): void {
    this.newQuantities[payload.loan.id] = payload.quantity;
  }

  protected redistribute(): void {
    if (this.totalQuantity !== null) {
      this.distributeQuantity(this.totalQuantity);
    }
  }

  protected distributeQuantity(totalQuantity: number): void {
    if (this.selectedStrategy === 'custom') {
      // events triggered by the single loan inputs also trigger this event,
      // but we are not interested in recalculating the single quantities in this case
      // because the user is manually entering one by one
      return;
    }
    this.newQuantities =
      this.selectedStrategy === 'equalShares'
        ? distributeEqualShares(this.filteredOpenLoans, totalQuantity)
        : distributeSorted(this.filteredOpenLoans, totalQuantity);
  }

  protected onChangeStrategy(strategy: typeof this.selectedStrategy): void {
    this.selectedStrategy = strategy;

    if (this.selectedStrategy === 'custom') {
      return;
    }

    this.redistribute();

    if (this.selectedStrategy === 'leastProfitable' || this.selectedStrategy === 'equalShares') {
      this.sortBy = 'rate';
      this.sortDesc = this.action === 'recall';
    }
    if (this.selectedStrategy === 'leastLoans') {
      this.sortBy = this.action === 'recall' ? 'openQuantityToRecall' : 'openQuantityToReturn';
      this.sortDesc = true;
    }
  }

  protected async onChangeCounterparty(counterparty: CompanyInfo): Promise<void> {
    this.selectedCounterparty = counterparty;
    await this.fetchLoans();

    if (this.selectedStrategy === 'custom') {
      this.newQuantities = distributeSorted(this.filteredOpenLoans, this.totalQuantity);
    } else {
      this.redistribute();
    }
  }

  protected async onChangeSecurity(security: Security): Promise<void> {
    this.selectedSecurity = security;
    await this.fetchLoans();

    if (this.selectedStrategy === 'custom') {
      this.newQuantities = distributeSorted(this.filteredOpenLoans, this.totalQuantity);
    } else {
      this.redistribute();
    }
  }

  protected goToSummary(): void {
    this.step = this.validateForm() ? 'confirm' : 'edit';
  }

  protected validateForm(): boolean {
    // this boolean ensures we only start validating the total input
    // after the user has submitted the form at least once
    this.hasSubmittedOnce = true;

    if (this.totalQuantityErrorMessage !== '') {
      return false;
    }

    if (!areSingleQuantitiesWithinAvailable(this.newQuantities, this.filteredOpenLoans)) {
      return false;
    }

    return true;
  }

  protected goBack(): void {
    this.step = 'edit';
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected formatPrice(price: string | Decimal): string {
    return getPriceAsString(typeof price === 'string' ? +price : price, PRICE_PRECISION);
  }
}
</script>
<style lang="scss" scoped>
.controls {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 1.5rem;
}

.table-container tr,
.summary {
  /* Tone down grey to increase contrast between bold and non-bold text */
  color: #888;
}

.highlight,
.summary strong {
  color: white;
  font-weight: bold;
}

.theme--light .table-container tr,
.theme--light .summary {
  color: #777;
}

.theme--light .highlight,
.theme--light .summary strong {
  color: black;
}

::v-deep {
  .v-dialog {
    overflow: hidden;
    overscroll-behavior: contain;
  }

  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 40vh;
  min-height: 40vh;
}

.table-container > div {
  display: flex;
  overflow-y: auto;
}
</style>
