import { Raw } from '@/modules/common/helpers/api';
import { RawSecurity, Security } from '@/modules/common/models';

export type RawTermContractUploadSecuritiesResponse = Raw<
  TermContractUploadSecuritiesResponse,
  {
    instruments: RawSecurity[];
  },
  'securities'
>;

export class TermContractUploadSecuritiesResponse {
  public securities: Security[];

  protected constructor(data: RawTermContractUploadSecuritiesResponse) {
    this.securities = data.instruments.map<Security>(Security.fromData);
  }

  public static fromData(
    data: RawTermContractUploadSecuritiesResponse
  ): TermContractUploadSecuritiesResponse {
    return new TermContractUploadSecuritiesResponse(data);
  }
}
