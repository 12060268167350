<template>
  <div class="d-flex flex-row justify-end row-actions">
    <aurora-btn
      v-if="selectedTabName === 'incoming'"
      class="ml-1 action-accept"
      data-test="accept-loan"
      :disabled="!hasTraderUserRole || !item.isActionable"
      icon
      small
      timeframe="createLoans"
      :title="$t('manualLoans.itemActions.acceptLoan')"
      @click="$emit('accept', item)"
    >
      <v-icon>mdi-check</v-icon>
    </aurora-btn>
    <aurora-btn
      v-if="selectedTabName === 'incoming'"
      class="ml-1 action-reject"
      data-test="reject-loan"
      :disabled="!hasTraderUserRole || !item.isActionable"
      icon
      small
      timeframe="createLoans"
      :title="$t('manualLoans.itemActions.rejectLoan')"
      @click="$emit('reject', item)"
    >
      <v-icon>mdi-close</v-icon>
    </aurora-btn>
    <aurora-btn
      v-if="selectedTabName === 'outgoing'"
      class="ml-1 action-cancel"
      data-test="cancel-loan"
      :disabled="!hasTraderUserRole || !item.isActionable"
      icon
      small
      timeframe="createLoans"
      :title="$t('manualLoans.itemActions.cancelLoan')"
      @click="$emit('cancel', item)"
    >
      <v-icon>mdi-delete</v-icon>
    </aurora-btn>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { ManualLoanListDisplayItem } from '@/modules/manual-loan/types/manual-loans';

type TabName = 'incoming' | 'outgoing';

@Component({
  props: {
    item: Object as PropType<ManualLoanListDisplayItem>,
    selectedTabName: String as PropType<TabName>,
  },
  computed: {
    // mapState doesn't fully support non-namespaced modules yet
    // https://github.com/vuejs/vuex/issues/1592
    // ...mapState('manualLoans', ['manualLoanRequests']),
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class ManualLoansListTableActions extends Vue {
  // props
  protected readonly selectedTabName!: TabName;
  protected readonly item!: ManualLoanListDisplayItem;

  // store state
  protected readonly hasTraderUserRole!: boolean;
}
</script>

<style lang="scss" scoped>
.row-actions > * {
  opacity: 0.4;
  pointer-events: none;
  transition: opacity linear 0.15s;
}

.row-actions:hover > * {
  opacity: 1;
  pointer-events: inherit;
}
</style>
