<template>
  <v-data-table
    class="d-flex flex flex-column last-col-sticky"
    dense
    fixed-header
    :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
    :headers="tableHeaders"
    item-key="id"
    :items="items"
    :items-per-page="itemsPerPage"
    must-sort
    :page="page"
    :server-items-length="serverItemsLength"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    v-on="$listeners"
  >
    <template #[`item.status`]="{ item }">
      <term-loans-status-chip class="justify-center" :contract="item" />
    </template>
    <template #[`item.side`]="{ item }">
      <format-side :side="item.side" />
    </template>
    <template #[`item.counterparty`]="{ item }">
      {{ item.counterparty.companyName }} ({{ item.counterparty.displayBoxId }})
    </template>
    <template #[`item.rate`]="{ item }">
      <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
    </template>
    <template #[`item.currentValue`]="{ item }">
      <span :class="{ 'text-red': valueOutsideThreshold(item) }">
        ${{ formatPrice(item.currentValue) }}
      </span>
    </template>
    <template #[`item.notionalValue`]="{ item }"> ${{ formatPrice(item.notionalValue) }} </template>
    <template #[`item.threshold`]="{ item }"> %{{ formatThreshold(item.threshold) }} </template>
    <template #[`item.createdAt`]="{ item }">
      <strong>{{ formatDate(item.createdAt) }}</strong>
      <span class="ml-1 font-weight-light">{{ formatTime(item.createdAt) }}</span>
    </template>
    <template #[`item.startDate`]="{ item }">
      <strong>{{ formatDate(item.startDate) }}</strong>
    </template>
    <template #[`item.endDate`]="{ item }">
      <strong>{{ formatDate(item.endDate) }}</strong>
    </template>
    <template #[`item.trackingIndex`]="{}"> Custom </template>
    <!-- actions -->
    <template #[`item.actions`]="{ item }">
      <div class="actions d-flex justify-end">
        <!-- @TODO: restrict users and timeframe -->
        <v-btn
          v-if="item.availableActions.view"
          class="icon-action"
          data-test="view"
          :disabled="!hasTraderUserRole"
          icon
          x-small
          @click.stop="$emit('manage', item.displayId)"
        >
          <v-icon size="24">mdi-eye</v-icon>
        </v-btn>
        <confirm-dialog-wrapper
          v-if="item.availableActions.cancelDraft"
          :options="{
            message: 'You are about to delete a draft contract.',
            title: 'Delete contract',
            rejectText: 'Keep contract',
            acceptText: 'Delete contract',
          }"
          v-on="$listeners"
        >
          <template #default="{ confirm }">
            <v-btn
              class="icon-action"
              data-test="delete-draft"
              :disabled="!hasTraderUserRole"
              icon
              x-small
              @click.stop="confirm(() => $emit('delete-draft', item.displayId))"
            >
              <v-icon size="24">mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </confirm-dialog-wrapper>
        <confirm-dialog-wrapper
          v-if="item.availableActions.cancelProposed"
          :options="{
            message: 'You are about to cancel a proposed contract.',
            title: 'Cancel proposal',
            rejectText: 'Keep proposal',
            acceptText: 'Cancel proposal',
          }"
          v-on="$listeners"
        >
          <template #default="{ confirm }">
            <v-btn
              class="icon-action"
              data-test="cancel-proposed"
              :disabled="!hasTraderUserRole"
              icon
              x-small
              @click.stop="confirm(() => $emit('cancel-proposed', item.displayId))"
            >
              <v-icon size="24">mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </confirm-dialog-wrapper>
        <v-btn
          v-if="item.availableActions.manageLoans"
          class="icon-action"
          data-test="manage-contract"
          :disabled="!hasTraderUserRole"
          icon
          x-small
          @click.stop="$emit('manage', item.displayId)"
        >
          <v-icon size="24">mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="item.availableActions.edit"
          class="icon-action"
          data-test="edit"
          :disabled="!hasTraderUserRole"
          icon
          x-small
          @click.stop="$emit('edit', item.displayId)"
        >
          <v-icon size="24">mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="item.availableActions.reject"
          class="icon-action"
          data-test="reject"
          :disabled="!hasTraderUserRole"
          icon
          x-small
          @click.stop="$emit('respond', { accept: false, displayId: item.displayId })"
        >
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-if="item.availableActions.accept"
          class="icon-action"
          data-test="accept"
          :disabled="!hasTraderUserRole"
          icon
          x-small
          @click.stop="$emit('respond', { accept: true, displayId: item.displayId })"
        >
          <v-icon size="24">mdi-check</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import TermLoansStatusChip from '@/modules/termloans/components/TermLoansStatusChip.vue';
import { Contract } from '@/modules/termloans/models';
import { formatDecimalAsString, getPriceAsString } from '@/utils/helpers/auction-numbers';
import { formatDate } from '@/utils/helpers/dates';
import Decimal from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapGetters } from 'vuex';

const allTableHeaders: DataTableHeader[] = [
  {
    text: 'Status',
    value: 'status',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'center',
    // width: '8rem',
  },
  {
    text: 'Side',
    value: 'side',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
    sortable: false,
    width: '8rem',
  },
  {
    text: 'Counterparty',
    value: 'counterparty',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'start',
  },
  {
    text: 'Rate',
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'end',
  },
  {
    text: 'Current value',
    value: 'currentValue',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'end',
  },
  {
    text: 'Contract value',
    value: 'notionalValue',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'end',
  },
  {
    text: 'Threshold',
    value: 'threshold',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'end',
  },
  {
    text: 'Start',
    value: 'startDate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'end',
  },
  {
    text: 'End',
    value: 'endDate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'end',
  },
  {
    text: 'Creation',
    value: 'createdAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'end',
  },
  {
    text: 'Tracking Index',
    value: 'trackingIndex',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    sortable: false,
    align: 'start',
  },
  // "spacer" column before "actions" is required for .last-col-sticky class to work nicely
  {
    text: '',
    value: 'spacer',
    sortable: false,
    width: '100%',
  },
  {
    text: '', // No label (less is more!)
    value: 'actions',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
    sortable: false,
    width: '100%',
  },
];

@Component({
  components: {
    BtnDropdown,
    TermLoansStatusChip,
  },
  props: {
    items: {
      type: Array as PropType<Contract[]>,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class TermLoansContractsTable extends Vue {
  protected readonly items!: Contract[];
  protected readonly page!: number;
  protected readonly itemsPerPage!: number;
  protected readonly sortBy!: string;
  protected readonly sortDesc!: boolean;
  protected readonly serverItemsLength!: number;

  protected readonly hasTraderUserRole!: boolean;
  protected tableHeaders = allTableHeaders;

  protected valueOutsideThreshold(item: Contract): boolean {
    const delta = item.currentValue.dividedBy(item.notionalValue).minus(1).times(100);
    return delta.abs().greaterThan(item.threshold);
  }

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }

  protected formatThreshold(threshold: Decimal): string {
    return formatDecimalAsString(threshold, 2);
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM dd');
  }

  protected formatTime(value: Date): string {
    return formatDate(value, 'HH:mm');
  }
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
}

.text-red {
  color: #ff5252;
}

.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  td {
    cursor: pointer;
  }

  .v-chip {
    min-width: 4rem;
  }
}
</style>
