<template>
  <div>
    <dashboard-panel no-collapse title="users">
      <broker-users />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import BrokerUsers from '@/modules/broker-admin/components/BrokerUsers.vue';

@Component({
  provide() {
    return {
      gridStateKey: 'BrokerUsersView',
    };
  },
  components: {
    DashboardPanel,
    BrokerUsers,
  },
})
export default class BrokerUsersView extends Vue {}
</script>

<style lang="scss"></style>
