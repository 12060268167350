import { AuroraError } from './aurora-error';

/**
 * Permission Error
 *
 * raise this error if:
 *    user.id returned by the backend does not match that of the frontend.
 *    encryption secrets returned by the backend do no match logged on user.
 */
export class PermissionError extends AuroraError {
  public constructor(public message: string) {
    super(message);
    this.name = 'PermissionError';

    // use built in stack trace so the trace starts here
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
