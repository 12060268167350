import {
  alignLeft,
  alignRight,
  component,
} from '@/modules/common/components/ag-table/columns/utils';
import { CompanyInfo, OpenLoanItem } from '@/modules/common/models';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { ColDef } from 'ag-grid-enterprise';
import { h } from 'vue';
import { NewQuantities, availableToUpdate } from '@/modules/open-loans/helpers/multipleUpdates';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import * as commonCols from './common';
import { VBtn } from 'vuetify/lib';
import NumericInput from '@/modules/common/components/NumericInput.vue';

export function cols({ getIsReadOnly }: { getIsReadOnly: () => boolean }): {
  cusip(): ColDef<OpenLoanItem, string>;
  ticker(): ColDef<OpenLoanItem, string>;
  counterparty(): ColDef<OpenLoanItem, CompanyInfo>;
  rate(): ColDef<OpenLoanItem>;
  toUpdate(config: {
    action: MultipleLoanAction;
    getNewQuantities: () => NewQuantities;
    clickSingleQuantity: () => void;
    updateSingleQuantity: (loan: OpenLoanItem, quantity: number) => void;
  }): ColDef<OpenLoanItem>;
  openQuantityToRecall(): ColDef<OpenLoanItem, number>;
  openQuantityToReturn(): ColDef<OpenLoanItem, number>;
  openQuantity(): ColDef<OpenLoanItem, number>;
  settlementValue(): ColDef<OpenLoanItem>;
} {
  function cusip(): ColDef<OpenLoanItem, string> {
    return commonCols.cusip<OpenLoanItem>();
  }

  function ticker(): ColDef<OpenLoanItem, string> {
    return commonCols.ticker<OpenLoanItem>();
  }

  function counterparty(): ColDef<OpenLoanItem, CompanyInfo> {
    return {
      field: 'counterparty',
      colId: 'counterparty',
      headerName: 'Counterparty',
      valueFormatter: (params) => `${params.value?.companyName} (${params.value?.displayBoxId})`,
      ...alignLeft(),
    };
  }

  function rate(): ColDef<OpenLoanItem> {
    return commonCols.rate<OpenLoanItem>();
  }

  function toUpdate(config: {
    action: MultipleLoanAction;
    getNewQuantities: () => NewQuantities;
    clickSingleQuantity: () => void;
    updateSingleQuantity: (loan: OpenLoanItem, quantity: number) => void;
  }): ColDef<OpenLoanItem> {
    return {
      colId: 'toUpdate',
      headerName: `${config.action === 'recall' ? 'Recall' : 'Return'} Quantity`,
      cellRendererSelector: (params) =>
        toUpdateAdapter({
          item: params.data as OpenLoanItem,
          getIsReadOnly,
          getNewQuantities: config.getNewQuantities,
          clickSingleQuantity: config.clickSingleQuantity,
          updateSingleQuantity: config.updateSingleQuantity,
        }),
      ...alignLeft(),
    };
  }

  function openQuantityToRecall(): ColDef<OpenLoanItem, number> {
    return commonCols.quantity({
      field: 'openQuantityToRecall',
      headerName: 'Available To Recall',
      sortable: !getIsReadOnly(),
    });
  }

  function openQuantityToReturn(): ColDef<OpenLoanItem, number> {
    return commonCols.quantity({
      field: 'openQuantityToReturn',
      headerName: 'Available To Return',
      sortable: !getIsReadOnly(),
    });
  }

  function openQuantity(): ColDef<OpenLoanItem, number> {
    return commonCols.quantity({
      field: 'openQuantity',
      headerName: 'Open',
      sortable: !getIsReadOnly(),
    });
  }

  function settlementValue(): ColDef<OpenLoanItem> {
    return {
      colId: 'settlementValue',
      headerName: 'Settlement Value',
      valueFormatter: (params) =>
        getPriceAsString(
          params.data!.openQuantity * params.data!.security.lastClosePrice.toNumber(),
          PRICE_PRECISION
        ),
      ...alignRight(),
    };
  }

  return {
    ticker,
    cusip,
    counterparty,
    rate,
    toUpdate,
    openQuantityToRecall,
    openQuantityToReturn,
    openQuantity,
    settlementValue,
  };
}

const toUpdateAdapter = component<{
  item: OpenLoanItem;
  getIsReadOnly: () => boolean;
  getNewQuantities: () => NewQuantities;
  clickSingleQuantity: () => void;
  updateSingleQuantity: (loan: OpenLoanItem, quantity: number) => void;
}>((props) => () => {
  const isReadOnly = props.getIsReadOnly();
  const newQuantities = props.getNewQuantities();
  const loanQuantity = newQuantities[props.item.id] || 0;
  return h(
    'div',
    {
      class: 'd-flex',
      style: 'gap: 0.25rem',
    },
    [
      h(
        'div',
        {
          class: 'pa-2x single-loan-input',
          attrs: { 'data-test': 'single-loan-input', tabindex: isReadOnly ? 0 : -1 },
          on: {
            ...(isReadOnly
              ? {
                  click: props.clickSingleQuantity,

                  // also support space bar. AgGrid has a bug that doesn't
                  // let us process Enter key without a key modifier
                  keydown: (event: KeyboardEvent) => {
                    if (![' ', 'Enter'].includes(event.key)) return;
                    event.preventDefault();
                    props.clickSingleQuantity();
                  },
                }
              : {}),
          },
        },
        [
          h(NumericInput, {
            class: 'loan-quantity',
            attrs: { 'data-test': 'loan-quantity', 'data-has-quantity': loanQuantity > 0 },
            props: {
              dense: true,
              disabled: isReadOnly,
              errorMessages:
                newQuantities[props.item.id] > availableToUpdate(props.item) ? 'exceeded' : '',
              min: 0,
              outlined: true,
              placeholder: '0',
              small: true,
              step: 100,
              type: 'integer',
              value: loanQuantity,
            },
            on: {
              input: (value: number) => props.updateSingleQuantity(props.item, value),
            },
          }),
        ]
      ),
      h(
        VBtn,
        {
          class: [
            'pa-0 max-btn',
            {
              'fade-max-icon':
                availableToUpdate(props.item) !== newQuantities[props.item.id] ||
                availableToUpdate(props.item) === 0,
            },
          ],
          props: {
            disabled: isReadOnly,
            outlined: true,
            xSmall: true,
          },
          on: {
            click: () => {
              props.clickSingleQuantity();
              props.updateSingleQuantity(props.item, availableToUpdate(props.item));
            },
          },
        },
        'Max'
      ),
    ]
  );
});
