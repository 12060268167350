import { ConnectResult, createPromiseClient } from '@/connect';
import { LoansService } from '@/connect/gen/modules/apiengine/services/loans/loans_connect';
import {
  LoansHistoryQueryFilter,
  LoansHistoryQuerySort,
  QueryLoansHistoryResponse,
} from '@/connect/gen/modules/apiengine/services/loans/loans_pb';
import { LoanHistorySortableField } from '@/connect/gen/consts/loanhistoryconsts_pb';
import { SortOrder } from '@/connect/gen/consts/queryconsts_pb';
import { SortModelItem } from 'ag-grid-enterprise';

export const serviceLoans = new (class {
  public readonly promiseClient = createPromiseClient(LoansService);

  public async queryLoansHistory(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    filter: LoansHistoryQueryFilter;
    signal: AbortSignal;
  }): Promise<ConnectResult<QueryLoansHistoryResponse>> {
    const sortFields: Record<string, LoanHistorySortableField> = {
      'loanState.openQuantity': LoanHistorySortableField.OPEN_QUANTITY,
      'loanState.counterparty': LoanHistorySortableField.COUNTERPARTY,
      'loanState.instrument.ticker': LoanHistorySortableField.INSTRUMENT_TICKER,
      'loanState.instrument.cusip': LoanHistorySortableField.INSTRUMENT_CUSIP,
      'loanState.openQuantityChange': LoanHistorySortableField.OPEN_QUANTITY_CHANGE,
      'loanState.rateDetails': LoanHistorySortableField.RATE,
      'loanState.settlementAmount': LoanHistorySortableField.SETTLEMENT_AMOUNT,
      'loanState.settlementAmountChange': LoanHistorySortableField.SETTLEMENT_AMOUNT_CHANGE,
      'loanState.interestDueAmount': LoanHistorySortableField.RATE_AMOUNT,
      'loanState.settlementType': LoanHistorySortableField.SETTLEMENT_TYPE,
      eventType: LoanHistorySortableField.EVENT_TYPE,
      eventTime: LoanHistorySortableField.EVENT_TIME,
      initiator: LoanHistorySortableField.INITIATOR,
    };

    const field = config.sort.colId;
    const order = config.sort.sort === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    const sorts =
      field in sortFields ? [new LoansHistoryQuerySort({ field: sortFields[field], order })] : [];
    const query = {
      filter: config.filter,
      sorts,
      pageNumber: config.page,
      pageLimit: config.pageSize,
    };
    // eslint-disable-next-line no-console
    // console.log(JSON.stringify(query, null, 4));
    const result = await this.promiseClient.queryLoansHistory(query);

    return result as ConnectResult<QueryLoansHistoryResponse>;
  }
})();
