// This file contains our custom data types that we use in our Protobuf payloads.

// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file common/protos/types.proto (package aurora.core.common.protos, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Side } from "../../consts/commonconsts_pb.js";
import { Benchmark } from "../../consts/benchmarkconsts_pb.js";

/**
 * @generated from enum aurora.core.common.protos.PbTradingPermissions
 */
export enum PbTradingPermissions {
  /**
   * @generated from enum value: LENDER = 0;
   */
  LENDER = 0,

  /**
   * @generated from enum value: BORROWER = 1;
   */
  BORROWER = 1,

  /**
   * @generated from enum value: LENDER_OR_BORROWER = 2;
   */
  LENDER_OR_BORROWER = 2,

  /**
   * @generated from enum value: DISABLED = 3;
   */
  DISABLED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PbTradingPermissions)
proto3.util.setEnumType(PbTradingPermissions, "aurora.core.common.protos.PbTradingPermissions", [
  { no: 0, name: "LENDER" },
  { no: 1, name: "BORROWER" },
  { no: 2, name: "LENDER_OR_BORROWER" },
  { no: 3, name: "DISABLED" },
]);

/**
 * @generated from message aurora.core.common.protos.PbDecimal
 */
export class PbDecimal extends Message<PbDecimal> {
  /**
   * @generated from field: int32 sign = 1;
   */
  sign = 0;

  /**
   * @generated from field: int32 exponent = 2;
   */
  exponent = 0;

  /**
   * @generated from field: bytes coefficient = 3;
   */
  coefficient = new Uint8Array(0);

  constructor(data?: PartialMessage<PbDecimal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.PbDecimal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sign", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "exponent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "coefficient", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PbDecimal {
    return new PbDecimal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PbDecimal {
    return new PbDecimal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PbDecimal {
    return new PbDecimal().fromJsonString(jsonString, options);
  }

  static equals(a: PbDecimal | PlainMessage<PbDecimal> | undefined, b: PbDecimal | PlainMessage<PbDecimal> | undefined): boolean {
    return proto3.util.equals(PbDecimal, a, b);
  }
}

/**
 * @generated from message aurora.core.common.protos.PbDate
 */
export class PbDate extends Message<PbDate> {
  /**
   * @generated from field: int32 year = 1;
   */
  year = 0;

  /**
   * @generated from field: int32 month = 2;
   */
  month = 0;

  /**
   * @generated from field: int32 day = 3;
   */
  day = 0;

  constructor(data?: PartialMessage<PbDate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.PbDate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PbDate {
    return new PbDate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PbDate {
    return new PbDate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PbDate {
    return new PbDate().fromJsonString(jsonString, options);
  }

  static equals(a: PbDate | PlainMessage<PbDate> | undefined, b: PbDate | PlainMessage<PbDate> | undefined): boolean {
    return proto3.util.equals(PbDate, a, b);
  }
}

/**
 * @generated from message aurora.core.common.protos.PbCompanyInfo
 */
export class PbCompanyInfo extends Message<PbCompanyInfo> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: string company_name = 2;
   */
  companyName = "";

  /**
   * @generated from field: string nscc_account_id = 3;
   */
  nsccAccountId = "";

  /**
   * @generated from field: optional string display_box_id = 4;
   */
  displayBoxId?: string;

  constructor(data?: PartialMessage<PbCompanyInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.PbCompanyInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nscc_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "display_box_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PbCompanyInfo {
    return new PbCompanyInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PbCompanyInfo {
    return new PbCompanyInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PbCompanyInfo {
    return new PbCompanyInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PbCompanyInfo | PlainMessage<PbCompanyInfo> | undefined, b: PbCompanyInfo | PlainMessage<PbCompanyInfo> | undefined): boolean {
    return proto3.util.equals(PbCompanyInfo, a, b);
  }
}

/**
 * @generated from message aurora.core.common.protos.PbActioningUserWithSide
 */
export class PbActioningUserWithSide extends Message<PbActioningUserWithSide> {
  /**
   * @generated from field: string db_user_id = 1;
   */
  dbUserId = "";

  /**
   * @generated from field: aurora.core.consts.common.Side side = 2;
   */
  side = Side.BORROWER;

  /**
   * @generated from field: optional string external_id = 3;
   */
  externalId?: string;

  constructor(data?: PartialMessage<PbActioningUserWithSide>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.PbActioningUserWithSide";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "db_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "side", kind: "enum", T: proto3.getEnumType(Side) },
    { no: 3, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PbActioningUserWithSide {
    return new PbActioningUserWithSide().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PbActioningUserWithSide {
    return new PbActioningUserWithSide().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PbActioningUserWithSide {
    return new PbActioningUserWithSide().fromJsonString(jsonString, options);
  }

  static equals(a: PbActioningUserWithSide | PlainMessage<PbActioningUserWithSide> | undefined, b: PbActioningUserWithSide | PlainMessage<PbActioningUserWithSide> | undefined): boolean {
    return proto3.util.equals(PbActioningUserWithSide, a, b);
  }
}

/**
 * @generated from message aurora.core.common.protos.PbActioningUser
 */
export class PbActioningUser extends Message<PbActioningUser> {
  /**
   * @generated from field: string db_user_id = 1;
   */
  dbUserId = "";

  /**
   * @generated from field: optional string external_id = 2;
   */
  externalId?: string;

  constructor(data?: PartialMessage<PbActioningUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.PbActioningUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "db_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PbActioningUser {
    return new PbActioningUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PbActioningUser {
    return new PbActioningUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PbActioningUser {
    return new PbActioningUser().fromJsonString(jsonString, options);
  }

  static equals(a: PbActioningUser | PlainMessage<PbActioningUser> | undefined, b: PbActioningUser | PlainMessage<PbActioningUser> | undefined): boolean {
    return proto3.util.equals(PbActioningUser, a, b);
  }
}

/**
 * Instruments can be identified by their CUSIP or Ticker Symbol
 *
 * @generated from message aurora.core.common.protos.PbInstrument
 */
export class PbInstrument extends Message<PbInstrument> {
  /**
   * A **CUSIP** number is a unique identification number assigned to stocks and registered bonds in the United States and Canada.
   * It comprises nine characters and includes letters and numbers.
   * Example: use `02079K305` for `Alphabet Inc` (formerly known as Google)
   *
   * @generated from field: string cusip = 1;
   */
  cusip = "";

  /**
   * A stock symbol (*ticker*) is an arrangement of characters (*usually letters*) representing publicly-traded securities on an exchange.
   * When a company issues securities to the public marketplace, it selects an available symbol for its shares, often related to the company name.
   * Example: use `AAPL` for `Apple Computers Inc.`
   *
   * @generated from field: string ticker = 2;
   */
  ticker = "";

  /**
   * Name of the instrument
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * Last close price (not always provided)
   *
   * @generated from field: optional string last_close_price = 4;
   */
  lastClosePrice?: string;

  /**
   * DB ID (not always provided)
   *
   * @generated from field: optional string id = 5;
   */
  id?: string;

  constructor(data?: PartialMessage<PbInstrument>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.PbInstrument";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticker", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_close_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PbInstrument {
    return new PbInstrument().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PbInstrument {
    return new PbInstrument().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PbInstrument {
    return new PbInstrument().fromJsonString(jsonString, options);
  }

  static equals(a: PbInstrument | PlainMessage<PbInstrument> | undefined, b: PbInstrument | PlainMessage<PbInstrument> | undefined): boolean {
    return proto3.util.equals(PbInstrument, a, b);
  }
}

/**
 * @generated from message aurora.core.common.protos.PbRateDetails
 */
export class PbRateDetails extends Message<PbRateDetails> {
  /**
   * required
   *
   * @generated from field: string rate = 1;
   */
  rate = "";

  /**
   * @generated from field: optional aurora.core.consts.benchmark.Benchmark benchmark = 2;
   */
  benchmark?: Benchmark;

  constructor(data?: PartialMessage<PbRateDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.PbRateDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "benchmark", kind: "enum", T: proto3.getEnumType(Benchmark), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PbRateDetails {
    return new PbRateDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PbRateDetails {
    return new PbRateDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PbRateDetails {
    return new PbRateDetails().fromJsonString(jsonString, options);
  }

  static equals(a: PbRateDetails | PlainMessage<PbRateDetails> | undefined, b: PbRateDetails | PlainMessage<PbRateDetails> | undefined): boolean {
    return proto3.util.equals(PbRateDetails, a, b);
  }
}

/**
 * @generated from message aurora.core.common.protos.SettlementTransactionDetails
 */
export class SettlementTransactionDetails extends Message<SettlementTransactionDetails> {
  /**
   * @generated from field: string cusip = 1;
   */
  cusip = "";

  /**
   * @generated from field: int64 quantity = 2;
   */
  quantity = protoInt64.zero;

  /**
   * @generated from field: aurora.core.common.protos.PbDecimal amount = 3;
   */
  amount?: PbDecimal;

  /**
   * @generated from field: string status = 4;
   */
  status = "";

  /**
   * @generated from field: string recipient_of_message = 5;
   */
  recipientOfMessage = "";

  /**
   * @generated from field: string deliverer = 6;
   */
  deliverer = "";

  /**
   * @generated from field: string receiver = 7;
   */
  receiver = "";

  /**
   * received from DTCC, identifying this DO/PO operation
   *
   * @generated from field: string transaction_id = 8;
   */
  transactionId = "";

  /**
   * aurora defined id, identifying the loan operation resulting in this DO/PO operation
   *
   * @generated from field: optional string op_id = 9;
   */
  opId?: string;

  constructor(data?: PartialMessage<SettlementTransactionDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.SettlementTransactionDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cusip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "amount", kind: "message", T: PbDecimal },
    { no: 4, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "recipient_of_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "deliverer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "receiver", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "op_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SettlementTransactionDetails {
    return new SettlementTransactionDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SettlementTransactionDetails {
    return new SettlementTransactionDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SettlementTransactionDetails {
    return new SettlementTransactionDetails().fromJsonString(jsonString, options);
  }

  static equals(a: SettlementTransactionDetails | PlainMessage<SettlementTransactionDetails> | undefined, b: SettlementTransactionDetails | PlainMessage<SettlementTransactionDetails> | undefined): boolean {
    return proto3.util.equals(SettlementTransactionDetails, a, b);
  }
}

/**
 * @generated from message aurora.core.common.protos.ErrDetail
 */
export class ErrDetail extends Message<ErrDetail> {
  /**
   * @generated from field: string reason = 1;
   */
  reason = "";

  constructor(data?: PartialMessage<ErrDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "aurora.core.common.protos.ErrDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ErrDetail {
    return new ErrDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ErrDetail {
    return new ErrDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ErrDetail {
    return new ErrDetail().fromJsonString(jsonString, options);
  }

  static equals(a: ErrDetail | PlainMessage<ErrDetail> | undefined, b: ErrDetail | PlainMessage<ErrDetail> | undefined): boolean {
    return proto3.util.equals(ErrDetail, a, b);
  }
}

