import { LogLevel, defaultLogLevel } from '@/modules/common/constants/logging.const';
import logger from '@/modules/common/services/logger.service';

export class LoggerPreferenceManager {
  private localStorage?: Storage;

  public constructor() {
    if (window.localStorage !== undefined && window.localStorage !== null) {
      this.localStorage = window.localStorage;
    }

    if (localStorage) {
      if (localStorage.getItem('AURORA_DEBUGGING') === 'ENABLED') {
        this.enableDebugging();
      }
    }
  }

  public hasPreference(): boolean {
    return localStorage && localStorage.getItem('AURORA_DEBUGGING') !== null;
  }

  public enableDebugging(): void {
    logger.setDebugLevel(LogLevel.Debug);

    if (localStorage) {
      localStorage.setItem('AURORA_DEBUGGING', 'ENABLED');
    }
  }

  public disableDebugging(): void {
    logger.setDebugLevel(defaultLogLevel);

    if (localStorage) {
      localStorage.setItem('AURORA_DEBUGGING', 'DISABLED');
    }
  }
}

export const loggerPreference = new LoggerPreferenceManager();

window.auroraEnableDebugging = loggerPreference.enableDebugging;
window.auroraDisableDebugging = loggerPreference.disableDebugging;
