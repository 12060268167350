<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    scrollable
    :value="dialog"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-card>
      <feedback-form @close-dialog="closeDialog">
        <!-- card actions slot -->
        <template #cardActions="{ onSubmit }">
          <v-btn class="px-6" color="secondary" @click="closeDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer />
          <v-btn class="px-6" color="primary" type="submit" @click="onSubmit">
            {{ $t('submitWish.button') }}
          </v-btn>
        </template>
      </feedback-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import FeedbackForm from '@/modules/feedback/components/FeedbackForm.vue';

@Component({
  components: { FeedbackForm },
  props: {
    showDialog: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'showDialog',
    event: 'change:showDialog',
  },
})
export default class FeedbackDialog extends Vue {
  protected readonly showDialog!: boolean;

  protected get dialog(): boolean {
    return this.showDialog;
  }

  protected set dialog(value: boolean) {
    this.$emit('change:showDialog', value);
  }

  protected closeDialog(): void {
    this.dialog = false;
  }
}
</script>

<style lang="scss" scoped></style>
