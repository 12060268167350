<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('changePassword.title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  ref="current"
                  v-model="currentPassword"
                  :append-icon="revealPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  autocomplete="current-password"
                  autofocus
                  counter
                  :hint="verifyMessage"
                  :label="$t('loginCurrentPassword')"
                  required
                  :rules="passwordRules"
                  :type="revealPassword ? 'text' : 'password'"
                  @click:append="revealPassword = !revealPassword"
                ></v-text-field>
              </v-col>
            </v-row>
            <template v-if="isEnabledNewPassword">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    ref="password"
                    v-model="loginPassword"
                    :append-icon="revealPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    autocomplete="new-password"
                    counter
                    :error-messages="clErrors.loginPassword"
                    :label="$t('loginPassword')"
                    required
                    :type="revealPassword ? 'text' : 'password'"
                    @click:append="revealPassword = !revealPassword"
                    @input="onPasswordChange"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    ref="again"
                    v-model="loginPasswordAgain"
                    :append-icon="revealPasswordAgain ? 'mdi-eye' : 'mdi-eye-off'"
                    autocomplete="new-password"
                    counter
                    :error-messages="clErrors.loginPasswordAgain"
                    :label="$t('loginPasswordAgain')"
                    required
                    :type="revealPasswordAgain ? 'text' : 'password'"
                    @click:append="revealPasswordAgain = !revealPasswordAgain"
                    @input="onPasswordAgainChange"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label>Password strength</label>
                  <password
                    v-model="loginPassword"
                    :strength-meter-only="true"
                    @feedback="onPasswordFeedback"
                    @score="onScoreChange"
                  />
                </v-col>
                <v-col cols="12">
                  <div class="alert-wrapper">
                    <v-alert v-if="svErrors !== ''" dense type="error">{{ svErrors }}</v-alert>
                    <v-alert
                      v-else-if="warning !== '' || suggestions.length !== 0"
                      dense
                      :type="messageType"
                    >
                      <div
                        v-if="suggestions.length !== 0"
                        class="d-flex flex-column"
                        :color="messageType"
                        dense
                      >
                        <span v-for="suggestion in suggestions" :key="suggestion">
                          {{ $t(suggestion) }}
                        </span>
                      </div>
                      <span v-if="warning !== ''">{{ $t(warning) }}</span>
                    </v-alert>
                    <v-alert v-else-if="passwordScore >= minPasswordScore" dense type="info">
                      {{ $t('goodPassword.description') }}
                    </v-alert>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeModalDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="isProcessing"
            :loading="isProcessing"
            min-width="120"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('saveButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import { SymmetricEncrypter } from '@/modules/common/services/crypto/symmetric-encrypter';
import { AppState } from '@/store/store';
import { FormValidator, setInputFocus } from '@/utils/helpers/input-form';
import { LoginSalt } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import axios from 'axios';
import { isEmpty } from 'lodash';
import Password, { PasswordFeedback, PasswordScore } from 'vue-password-strength-meter';
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';

@Component({
  components: { Password },
  data: () => ({
    passwordRules: [(v: string) => !!v || i18n.t('formfield.IsRequired')],
  }),
  computed: {
    ...mapState(['loginState']),
  },
  methods: {
    ...mapActions(['changeLoginPassword']),
  },
})
export default class ChangePassword extends Vue {
  public $refs!: {
    current: HTMLFormElement;
    password: HTMLFormElement;
    again: HTMLFormElement;
  };

  public loginPassword = '';
  public loginPasswordAgain = '';

  // store state refs
  protected readonly loginState!: AppState['loginState'];

  // store actions
  protected readonly changeLoginPassword!: (payload: {
    oldHashedPassword: string;
    hashedPassword: string;
  }) => void;

  protected isProcessing = false;

  protected currentPassword = '';

  protected minPasswordScore: PasswordScore = 3;
  protected passwordScore: PasswordScore = 0;

  protected revealPassword = false;
  protected revealPasswordAgain = false;

  protected isEnabledNewPassword = true;
  protected verifyMessage = '';

  protected messageType = 'info';
  protected warning = '';
  protected suggestions: string[] = [];
  protected canSubmit = false;

  protected clErrors: Record<string, string> = {};
  protected svErrors = '';

  private symmetricEncrypter: SymmetricEncrypter = this.$es.getSymmetricEncrypter();

  protected mounted(): void {
    this.verifyMessage = i18n.t('login-it-is-you') as string;
    setInputFocus(this.$refs.current);
  }

  protected onPasswordFeedback(feedback: PasswordFeedback): void {
    this.warning = feedback.warning;
    this.suggestions = feedback.suggestions;

    this.messageType = 'info';
    if (this.warning !== '') {
      this.messageType = 'error';
    }
  }

  protected onScoreChange(score: PasswordScore): void {
    this.passwordScore = score;
    if (score && score >= this.minPasswordScore) {
      this.canSubmit = true;
    } else {
      this.canSubmit = false;
    }
  }

  protected onPasswordChange(_newPassword: string): void {
    this.svErrors = '';
  }

  protected onPasswordAgainChange(_newPassword: string): void {
    this.svErrors = '';

    if (this.passwordScore >= this.minPasswordScore) {
      this.warning = '';

      if (this.loginPassword !== this.loginPasswordAgain) {
        this.warning = i18n.tc('errPasswordsNotTheSame');
        this.messageType = 'error';
      }
    }
  }

  protected async onSubmit(): Promise<void> {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;

    try {
      this.svErrors = '';
      this.clErrors = new FormValidator(this, ['loginPassword', 'loginPasswordAgain']).check();

      if (isEmpty(this.clErrors)) {
        await this.doSubmit();
      }
    } finally {
      this.isProcessing = false;
    }
  }

  private async doSubmit() {
    // password strength check is a responsibility of the client
    // the server only sees the encrypted password
    if (this.passwordScore < this.minPasswordScore) {
      this.svErrors = i18n.t('errPasswordTooSimple') as string;
      setInputFocus(this.$refs.password);
      return;
    }
    if (this.loginPassword !== this.loginPasswordAgain) {
      this.svErrors = i18n.t('errPasswordsNotTheSame') as string;
      setInputFocus(this.$refs.again);
      return;
    }

    // get salt (for entered email address) from the server
    let salt: Buffer;
    try {
      const response = await axios.get(`/api/1/login-salt/${this.loginState.user?.emailAddress}`);
      salt = Buffer.from((response.data as LoginSalt).salt, 'base64');
    } catch (e) {
      setInputFocus(this.$refs.password);
      this.svErrors = i18n.t('login-password-not-available') as string;
      return;
    }

    // hash user input for current password for the server to check
    let oldHashedPassword: string;
    try {
      oldHashedPassword = await this.symmetricEncrypter.hashPassword(this.currentPassword, salt);
    } catch (e) {
      setInputFocus(this.$refs.current);
      this.svErrors = i18n.t('login-password-not-available') as string;
      return;
    }

    // re-encrypt private key with the new password
    let hashedPassword: string;
    try {
      // hash the *new* password with the *fixed* salt for the server
      hashedPassword = await this.symmetricEncrypter.hashPassword(this.loginPassword, salt);
    } catch (e) {
      setInputFocus(this.$refs.password);
      this.svErrors = i18n.t('login-password-not-available') as string;
      return;
    }

    // submit hashed *old* password and hashed *new* password to the server
    try {
      await this.changeLoginPassword({ oldHashedPassword, hashedPassword });

      this.$snackbar.confirm(i18n.t('login-password-changed') as string);
      this.closeModalDialog();
    } catch (e) {
      this.svErrors = errorString(e);
      setInputFocus(this.$refs.password);
    }
  }

  private closeModalDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
/* Overwrite component's default */
.Password {
  max-width: 100%;
}
/* Alert content can have 1, 2 or 3 lines of text; applying a min-height minimises layout shifts */
.alert-wrapper {
  min-height: 82px;
}
</style>
