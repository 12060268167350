<script lang="ts">
import Component from 'vue-class-component';
import EnforceTimeframe from '@/modules/market-closed/components/EnforceTimeframe.vue';
import { VBtn } from 'vuetify/lib';

@Component({
  components: {
    VBtn,
  },
})
export default class AuroraBtn extends EnforceTimeframe {
  protected componentName = 'VBtn';
}
</script>
