import { defineStore } from 'pinia';
import { watch } from 'vue';
import Vuetify from '@/plugins/vuetify';
import { useStoredTabSyncRef } from '@/composables/stored-tab-sync-ref';

export const useStoreUI = defineStore('ui', () => {
  const isDarkMode = useStoredTabSyncRef('isDarkMode', true);

  function restoreIsDarkMode() {
    Vuetify.framework.theme.dark = isDarkMode.value;
  }

  watch(() => isDarkMode.value, restoreIsDarkMode);

  return {
    isDarkMode,
    restoreIsDarkMode,
  };
});

export type StoreUI = ReturnType<typeof useStoreUI>;
