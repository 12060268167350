import i18n from '@/localisation/i18n';
import { ColDef } from 'ag-grid-enterprise';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { alignLeft } from './utils';
import { CounterpartyCredit } from '@/utils/api/credits';
export { checkbox } from './common';

export function companyDisplay(): ColDef<CounterpartyCredit> {
  return {
    field: 'companyDisplay',
    colId: 'companyDisplay',
    headerName: i18n.tc('adminCounterpartyCredits.name'),
    valueFormatter: (params) =>
      params.node?.isRowPinned() ? i18n.tc('adminCounterpartyCredits.grand-total') : params.value,
    ...alignLeft(),
  };
}

export const totalBilateral = (): ColDef<CounterpartyCredit> =>
  cols.price({
    field: 'totalBilateral',
    headerName: i18n.tc('adminCounterpartyCredits.Bilateral'),
  });

export const totalInSFT = (): ColDef<CounterpartyCredit> =>
  cols.price({
    field: 'totalInSFT',
    headerName: i18n.tc('adminCounterpartyCredits.NSCC'),
  });

export const grandTotal = (): ColDef<CounterpartyCredit> =>
  cols.price({
    field: 'grandTotal',
    headerName: i18n.tc('adminCounterpartyCredits.grand-total'),
  });
