<template>
  <v-alert
    v-if="!uxConfig.dismissedPreEstablishedInfo"
    border="left"
    class="ma-3"
    dismissible
    icon="mdi-information"
    text
    type="info"
    @input="dismissPreEstablishedInfo()"
  >
    The Pre-Established Loans feature can be used to book existing / pre-established bilateral loans
    to novate to DTCC's SFT Clearing Service.
  </v-alert>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { UXConfig } from '@/utils/helpers/rest';
import { extend } from 'lodash';
import Component from 'vue-class-component';

@Component({
  components: {},
  methods: {
    ...mapMutations(['updateUXConfig']),
  },
  computed: {
    ...mapState(['uxConfig']),
  },
})
export default class PreEstablishedInfo extends Vue {
  protected uxConfig!: UXConfig;
  protected updateUXConfig!: (config: UXConfig) => void;

  protected dismissPreEstablishedInfo(): void {
    const config: UXConfig = extend({}, this.uxConfig, { dismissedPreEstablishedInfo: true });
    this.updateUXConfig(config);
  }
}
</script>

<style lang="scss"></style>
