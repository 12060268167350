import Decimal from 'decimal.js';
import { parseISO } from 'date-fns';

export interface ValueAtRiskPosition {
  cusip: string;
  ticker: string;
  quantity: number;
  closePrice: Decimal;
  valueAtRisk: Decimal;

  notional: Decimal;
  utilization: Decimal;
}

export interface ValueAtRiskResponse {
  date: Date;
  positions: ValueAtRiskPosition[];
  valueAtRisk: Decimal;
}

export function normalizeValueAtRiskPosition(pos: ValueAtRiskPosition, totalVaR: Decimal): void {
  // input from server will have rate as a string, we convert it to Decimal
  const posWithStrLastPrice: { closePrice: string | Decimal } = pos;
  if (typeof posWithStrLastPrice.closePrice === 'string') {
    pos.closePrice = new Decimal(posWithStrLastPrice.closePrice);
  }
  const posWithStrValueAtRisk: { valueAtRisk: string | Decimal } = pos;
  if (typeof posWithStrValueAtRisk.valueAtRisk === 'string') {
    pos.valueAtRisk = new Decimal(posWithStrValueAtRisk.valueAtRisk);
  }

  pos.notional = pos.closePrice.times(Math.abs(pos.quantity));
  pos.utilization = pos.valueAtRisk.div(totalVaR).toDecimalPlaces(5);
}

export function normalizeValueAtRiskResponse(resp: ValueAtRiskResponse): void {
  // input from server will have date as a string, we convert it to Date
  const lWithStrDate: { date: string | Date } = resp;
  if (typeof lWithStrDate.date === 'string') {
    resp.date = parseISO(lWithStrDate.date);
  }

  // input from server will have rate as a string, we convert it to Decimal
  const posWithStrValueAtRisk: { valueAtRisk: string | Decimal } = resp;
  if (typeof posWithStrValueAtRisk.valueAtRisk === 'string') {
    resp.valueAtRisk = new Decimal(posWithStrValueAtRisk.valueAtRisk);
  }

  resp.positions.forEach((p) => normalizeValueAtRiskPosition(p, resp.valueAtRisk));
}
