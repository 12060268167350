<template>
  <v-dialog
    content-class="au-popup-dialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-top-transition"
    :value="true"
  >
    <v-card class="d-flex flex-column flex fill-height overflow-hidden">
      <div>
        <v-toolbar color="primary darken-2" elevation="0" tile>
          <v-btn dark icon @click="onCloseDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="headline">
              {{ clientConfig.systemTitleLong }}&nbsp;{{ $t('settings.title') }}
            </span>
          </v-toolbar-title>
        </v-toolbar>
      </div>

      <div class="d-flex flex flex-row overflow-auto">
        <!-- side navigation menu -->
        <v-navigation-drawer class="col-auto" permanent>
          <v-subheader class="text-capitalize">{{ $t('personalSettings.title') }}</v-subheader>
          <v-list dense nav>
            <v-list-item
              data-test-id="settings-user-account"
              :to="{ name: 'settings.userSettings', params: { returnPath } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-security</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-capitalize">
                {{ $t('userSettingsAccount.title') }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              data-test-id="settings-two-factor-authentication"
              :to="{ name: 'settings.register2fa', params: { returnPath } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-two-factor-authentication</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-capitalize">
                {{ $t('userSettings2FA.title') }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="showDesktopSettings"
              data-test-id="settings-desktop-settings"
              :to="{ name: 'settings.desktopConfig', params: { returnPath } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-desktop-mac-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-capitalize">
                {{ $t('userSettingsDesktop.title') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="mt-10 mb-10"></v-divider>

          <v-list dense nav>
            <!-- @TODO: temporarily disabled until FAQ content is updated -->
            <!-- <v-list-item :to="{ name: 'settings.faq', params: { returnPath } }">
              <v-list-item-content class="text-capitalize"> FAQ </v-list-item-content>
            </v-list-item> -->

            <v-list-item
              data-test-id="settings-send-feedback"
              :to="{ name: 'settings.feedback', params: { returnPath } }"
            >
              <v-list-item-content class="text-capitalize">send feedback</v-list-item-content>
            </v-list-item>

            <v-list-item
              data-test-id="settings-about-us"
              :href="clientConfig.systemBrokerSiteURL"
              target="_blank"
            >
              <v-list-item-content class="text-capitalize">about us</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <!-- child router view -->
        <div class="d-flex flex flex-column pa-8">
          <router-view class="d-flex flex flex-column pa-0 ma-0 overflow-y-auto" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { noop } from 'lodash';
import { ClientConfig } from '@/utils/helpers/rest';

@Component({
  props: {
    returnPath: {
      type: String,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class Settings extends Vue {
  // props
  protected readonly returnPath!: string;

  // store state/actions/getters/etc
  protected clientConfig!: ClientConfig;

  protected get showDesktopSettings(): boolean {
    return (
      this.clientConfig.demoMode ||
      !this.clientConfig.systemProdLike ||
      this.clientConfig.systemEnv === 'sandbox'
    );
  }

  /**
   * Close the settings dialog, and return back to previous route
   */
  protected onCloseDialog(): void {
    if (this.returnPath && this.$route.fullPath !== this.returnPath) {
      // returnPath contains a full pathname
      void this.$router.push(this.returnPath);
    } else {
      // catch and ignore the redirect-warning triggerend when navigating to 'dashboard'
      void this.$router.push({ name: 'dashboard' }).catch(noop);
    }
  }
}
</script>

<style lang="scss" scoped></style>
