import { ConnectResult, SetSSOSettingsResponse, createPromiseClient } from '@/connect';
import { SecurityService } from '@/connect/gen/modules/apiengine/services/login/security_connect';

export type * from '@/connect/gen/modules/apiengine/services/login/security_pb';

export type SSOSettings =
  | { type: 'file'; name: string }
  | { type: 'url'; url: string }
  | { type: 'none' };

export const serviceSecurity = new (class {
  public readonly promiseClient = createPromiseClient(SecurityService);

  public async getSSOSettings() {
    const settings = await this.promiseClient.getSSOSettings({});
    if (!settings.success) return settings;

    let result: ConnectResult<SSOSettings, never>;

    switch (settings.data.metadata.case) {
      case 'metadataFilename': {
        const fileName = settings.data.metadata.value;
        result = { success: true, data: { type: 'file', name: fileName } } as const;
        break;
      }
      case 'metadataUrl': {
        const url = settings.data.metadata.value;
        result = { success: true, data: { type: 'url', url } } as const;
        break;
      }
      default:
        result = { success: true, data: { type: 'none' } } as const;
        break;
    }

    return result;
  }

  public async setSSOSettingsFile(file: File) {
    const buffer = await file.arrayBuffer();
    const result = await this.promiseClient.setSSOSettings({
      metadata: {
        case: 'metadataFile',
        value: { fileName: file.name, file: new Uint8Array(buffer) },
      },
    });
    return result as ConnectResult<
      SetSSOSettingsResponse,
      | 'rest.MissingSSOMetadata'
      | 'rest.MissingSSOEntityDescriptor'
      | 'rest.MissingSSOEntityId'
      | 'rest.MissingSSOCertificate'
      | 'rest.PermissionDenied'
    >;
  }

  public async setSSOSettingsURL(url: string) {
    const result = await this.promiseClient.setSSOSettings({
      metadata: {
        case: 'metadataUrl',
        value: url,
      },
    });
    return result as ConnectResult<
      SetSSOSettingsResponse,
      | 'rest.MissingSSOMetadata'
      | 'rest.MissingSSOEntityDescriptor'
      | 'rest.MissingSSOEntityId'
      | 'rest.MissingSSOCertificate'
      | 'rest.PermissionDenied'
      | 'rest.InvalidXML'
      | 'rest.FetchingMetadataFailed'
    >;
  }

  public async removeSSOSettings() {
    const result = await this.promiseClient.removeSSOSettings({});
    return result as ConnectResult<SetSSOSettingsResponse, 'rest.PermissionDenied'>;
  }
})();
