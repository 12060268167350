<template>
  <v-card class="flex d-flex flex-column fill-height">
    <!-- edit user popup -->
    <trader-user-dialog
      v-if="dialogOptions.isActive"
      :admin-count="dialogOptions.adminCount"
      :crud-action="dialogOptions.crudAction"
      :title="dialogOptions.title"
      :user="dialogBuffer"
      @close-modal="closeModal"
    />
    <v-card-title>
      <span class="headline">{{ $t('adminSelectUsers.title') }}</span>
    </v-card-title>
    <v-card-subtitle>
      <span class="text--secondary headline-2">{{ $t('adminSelectUsers.description') }}</span>
    </v-card-subtitle>
    <v-card-text>
      <!-- add user account -->
      <v-btn class="ma-6" color="primary" data-test-id="add-user-button" @click="createUser">
        <v-icon left> mdi-plus</v-icon>
        {{ $t('adminAddUser') }}
      </v-btn>

      <!-- search bar -->
      <v-text-field
        v-model="tableSearch"
        clearable
        :label="$t('adminSearchUser')"
        prepend-inner-icon="mdi-magnify"
        @input="setQuickFilterText(tableSearch)"
      />
    </v-card-text>

    <ag-table-client
      v-if="companyUsers"
      :column-defs="columnDefs"
      :get-row-id="getRowId"
      :page-size="1000"
      :row-data="companyUsers"
      :sort="{ colId: 'name', sort: 'asc' }"
      @ready="onReady"
    />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import i18n from '@/localisation/i18n';
import TraderUserDialog from '@/modules/trader-admin/components/TraderUserDialog.vue';
import PermissionValidator, { Role } from '@/utils/helpers/permissions';
import { RestOperation } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { AppState } from '@/store/store';
import TraderUsersActions from '@/modules/trader-admin/components/TraderUsersActions.vue';
import TraderUsersRiskLimitIcons from '@/modules/trader-admin/components/TraderUsersRiskLimitIcons.vue';
import { AgTableClient } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/users';
import { ColDef } from 'ag-grid-enterprise';
import { User, UserRequest } from '@/modules/user-accounts/models';

const defaultUser = UserRequest.create();

@Component({
  components: {
    TraderUserDialog: TraderUserDialog,
    TraderUsersActions: TraderUsersActions,
    TraderUsersRiskLimitIcons: TraderUsersRiskLimitIcons,
    AgTableClient: AgTableClient,
  },
  props: {
    userFilter: String,
  },
  methods: {
    ...mapActions([
      'fetchTraderAdminUsers',
      'deleteTraderAdminUser',
      'disableTraderAdminUser',
      'enableTraderAdminUser',
    ]),
  },
  computed: {
    ...mapState(['loginState', 'companyUsers']),
  },
})
export default class TraderUsers extends Vue {
  // Props
  protected readonly userFilter?: string;

  // store state
  protected readonly loginState!: NonNullableAll<AppState['loginState']>;
  protected readonly companyUsers!: AppState['companyUsers'];
  // store actions
  protected readonly fetchTraderAdminUsers!: () => Promise<void>;
  protected readonly deleteTraderAdminUser!: (user: User) => Promise<void>;
  protected readonly disableTraderAdminUser!: (user: User) => Promise<void>;
  protected readonly enableTraderAdminUser!: (user: User) => Promise<void>;

  protected tableSearch = '';
  protected setQuickFilterText!: (text: string) => void;

  protected addBuffer: UserRequest | null = null;
  protected dialogBuffer: UserRequest | null = null;
  protected dialogOptions = {
    isActive: false,
    crudAction: RestOperation.Noop,
    title: 'User',
    adminCount: 0,
    cancelOptions: ['escape', 'outside'], // no 'X' for real FORMs
  };

  protected get columnDefs(): ColDef[] {
    return [
      cols.name(),
      cols.company(),
      cols.email(),
      cols.role(),
      cols.traderAccountStatus(),
      cols.tfa(),
      cols.riskLimits(),
      cols.actions({
        editUser: this.editUser,
        deleteUser: this.deleteUser,
        disableUser: this.disableUser,
        enableUser: this.enableUser,
      }),
    ];
  }

  protected onReady(config: { setQuickFilterText: (text: string) => void }): void {
    this.setQuickFilterText = config.setQuickFilterText;
  }

  protected async mounted(): Promise<void> {
    try {
      await this.fetchTraderAdminUsers();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected getRowId(userAccount: User): string {
    return userAccount.id;
  }

  protected createUser(): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Create;
    this.dialogOptions.title = 'Add Company User';
    this.dialogOptions.adminCount = this.countAdministrators();

    // addBuffer is a 2nd buffer behind the dialog so that the admin
    // can escape from `Create New User` and return with partially filled record
    // browsing the user list will not destroy the partially filled record
    if (this.addBuffer === null) {
      this.addBuffer = UserRequest.clone(defaultUser);
    }
    this.dialogBuffer = this.addBuffer;

    // managing users of my company only!
    this.addBuffer.companyID = this.loginState.user.companyID;
    this.addBuffer.companyTradingPermissions = this.loginState.user.companyTradingPermissions;
  }

  protected editUser(rowUser: User): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Update;
    this.dialogOptions.title = 'Update User Profile';
    this.dialogOptions.adminCount = this.countAdministrators();
    // do not modify the row while typing
    this.dialogBuffer = UserRequest.fromUser(rowUser);
  }

  protected closeModal(): void {
    // wipe user data
    this.dialogOptions.isActive = false;
    this.dialogOptions.crudAction = RestOperation.Noop;
    this.dialogOptions.title = '';
    this.addBuffer = null;
    this.dialogBuffer = null;
  }

  protected deleteUser(rowUser: User): void {
    // can't delete own account if you are the last admin
    if (rowUser.id === this.loginState.user.id && this.countAdministrators() === 1) {
      this.$dialog.alert({
        title: i18n.t('adminDeleteUser'),
        icon: 'mdi-alert',
        message: i18n.t('adminDeleteLastAdminAccount', {
          userName: rowUser.name,
          companyName: rowUser.companyName,
          emailAddress: rowUser.emailAddress,
        }),
        shouldManuallyConfirm: true,
      });
      return;
    }

    this.$dialog.ask({
      title: i18n.t('adminDeleteUser'),
      color: 'error',
      icon: 'mdi-alert',
      message: i18n.t('adminDeleteUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDeleteUser'),
      onAccept: () => this.doDeleteUser(rowUser),
      rejectText: i18n.t('cancelButton'),
      shouldManuallyConfirm: true,
    });
  }

  protected disableUser(rowUser: User): void {
    this.$dialog.ask({
      title: i18n.t('adminDisableUser'),
      color: 'warning',
      icon: 'mdi-account-off',
      message: i18n.t('adminDisableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDisableUser'),
      onAccept: () => this.doDisableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
      shouldManuallyConfirm: true,
    });
  }

  protected enableUser(rowUser: User): void {
    this.$dialog.ask({
      title: i18n.t('adminEnableUser'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminEnableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminEnableUser'),
      onAccept: () => this.doEnableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  private async doDeleteUser(rowUser: User) {
    try {
      await this.deleteTraderAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDeletedUserMessage'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doDisableUser(rowUser: User) {
    try {
      await this.disableTraderAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDisabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doEnableUser(rowUser: User) {
    try {
      await this.enableTraderAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminEnabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private countAdministrators(): number {
    return this.companyUsers.reduce((count: number, user: User) => {
      const validator = new PermissionValidator(user.roles);
      const hasPermission = validator.hasPermission(Role.TraderAdmin);
      return hasPermission ? count + 1 : count;
    }, 0);
  }
}
</script>

<style lang="scss" scoped></style>
