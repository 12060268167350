<template>
  <v-card v-if="loginState.tfa">
    <add-2fa-dialog v-if="showAdd2faDialog" @close-modal="showAdd2faDialog = false" />
    <remove-2fa-dialog v-if="showRemove2faDialog" @close-modal="showRemove2faDialog = false" />

    <v-card-title>
      <span class="headline">{{ $t('register2FA.title') }}</span>
      <v-spacer></v-spacer>
      <span class="text--primary headline-2">
        Status:
        {{ loginState.tfa.tfaIsEnabled ? $t('isEnabled.text') : $t('isDisabled.text') }}
      </span>
    </v-card-title>

    <v-card-text>
      <p>{{ $t('register2FA.text') }}</p>

      <!-- 2FA is enabled: -->
      <template v-if="loginState.tfa.tfaIsEnabled">
        <p>{{ $t('isEnabled2FA.text') }}</p>
        <v-btn
          class="mt-8"
          color="red"
          @click="
            showRemove2faDialog = true;
            editConfig = true;
          "
        >
          Disable 2FA
        </v-btn>
      </template>

      <!-- 2FA is disabled: -->
      <template v-else>
        <p>{{ $t('isDisabled2FA.text') }}</p>
        <v-btn class="mt-8" color="primary" @click="showAdd2faDialog = true"> Enable 2FA </v-btn>
      </template>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { mapActions, mapState } from 'vuex';
import { AppState } from '@/store/store';
import Remove2faDialog from '@/modules/auth/components/Remove2faDialog.vue';
import Add2faDialog from '@/modules/auth/components/Add2faDialog.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    Add2faDialog,
    Remove2faDialog,
  },
  methods: {
    ...mapActions(['fetchCurrent2FA']),
  },
  computed: {
    ...mapState(['loginState']),
  },
})
export default class RegisterDevice2FA extends Vue {
  // store actions
  protected readonly fetchCurrent2FA!: () => Promise<void>;

  // store state
  protected readonly loginState!: AppState['loginState'];

  protected showAdd2faDialog = false;
  protected showRemove2faDialog = false;

  protected async mounted(): Promise<void> {
    try {
      await this.fetchCurrent2FA();
    } catch (e) {
      this.$log.warn(e);
    }
  }
}
</script>
<style lang="scss" scoped></style>
