<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="600"
    origin="center center"
    overlay-color="secondary"
    overlay-opacity="0.25"
    persistent
    transition="scale-transition"
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t('loginResetDeviceTitle', { title: clientConfig?.systemTitleShort })
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-6">
            <v-alert v-if="tfaError" type="error">
              {{ tfaError }}
              <br />
              {{ $t('loginResetDeviceError2') }}
            </v-alert>
            <v-alert v-else border="left" color="warning" icon="mdi-information" text>
              {{ $t('loginResetDeviceInfo1', { title: clientConfig?.systemTitleShort }) }}
              <br />
              {{ $t('loginResetDeviceInfo2', { emailAddress: emailAddress }) }}
            </v-alert>
          </v-container>

          <v-container class="mt-6">
            <v-text-field
              v-model="resetCode"
              autofocus
              :error-messages="clErrors.resetCode"
              :label="$t('resetCode')"
              required
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text to="/login">
            {{ $t('loginButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="px-8 mx-4"
            color="primary"
            :disabled="isProcessing"
            :loading="isProcessing"
            type="submit"
            @click="onSubmit"
          >
            <v-icon left>mdi-shield-off</v-icon>
            {{ $t('loginResetDeviceButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { errorString } from '@/utils/helpers/rest-response';
import { isEmpty } from 'lodash';
import { FormValidator } from '@/utils/helpers/input-form';
import { mapActions, mapState } from 'vuex';
import { AppState } from '@/store/store';

@Component({
  props: {
    loginEmail: {
      type: String,
      default: 'your inbox',
    },
    lostMessage: {
      type: String,
      default: '',
    },
    secretCode: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions(['reset2FALogin']),
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class ResetDevice2FA extends Vue {
  public resetCode = '';

  // props
  protected readonly loginEmail!: string;
  protected readonly lostMessage!: string;
  protected readonly secretCode!: string;

  // store
  protected readonly reset2FALogin!: (encSecret: string) => void;
  protected readonly clientConfig!: AppState['clientConfig'];

  protected emailAddress = '';
  protected clErrors: Record<string, string> = {};
  protected tfaError = '';

  protected isProcessing = false;

  protected async mounted(): Promise<void> {
    this.emailAddress = this.loginEmail;
    this.resetCode = this.secretCode;
    if (this.secretCode !== '') {
      await this.onSubmit();
    }
  }

  protected async onSubmit(): Promise<void> {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;

    try {
      this.tfaError = '';
      this.clErrors = new FormValidator(this, ['resetCode']).check();

      if (isEmpty(this.clErrors)) {
        await this.doSubmit();
      }
    } finally {
      this.isProcessing = false;
    }
  }

  protected async doSubmit(): Promise<void> {
    try {
      await this.reset2FALogin(this.resetCode);

      this.resetMessage(
        this.$t('loginResetDeviceEmailSucceeded') as string,
        this.$t('loginClose') as string
      );

      await this.$router.push({ name: 'login' });
    } catch (e) {
      this.tfaError = errorString(e);
    }
  }

  private resetMessage(message: string, actionText: string) {
    this.$snackbar.show({ message: message, actionText: actionText });
  }
}
</script>

<style lang="scss" scoped></style>
