<template>
  <!-- v-on="$listeners" binds the click event if it's passed -->
  <!-- useful because v-chip has different css:hover when @click is passed (or not) -->

  <v-chip :color="statusColors[contract.status]" small v-on="$listeners">
    {{ contract.status }}
  </v-chip>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Contract, TermLoanStatus } from '@/modules/termloans/models';

const statusColors: Record<TermLoanStatus, string> = {
  draft: 'grey',
  proposed: 'blue',
  rejected: 'red',
  accepted: 'warning',
  active: 'green',
  expired: 'grey',
  canceled: 'grey',
};

@Component({
  props: {
    contract: Object as PropType<Contract>,
  },
})
export default class TermLoansStatusChip extends Vue {
  protected readonly contract!: Contract;

  protected statusColors = statusColors;
}
</script>

<style lang="scss" scoped>
.v-chip {
  text-transform: lowercase;
}
</style>
