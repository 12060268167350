import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import { CompanyInfo } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { PlainMessage } from '@/connect';
import { PbCompanyInfo } from '@/connect/gen/common/protos/types_pb';
import { mapValues } from 'lodash';

export const useStoreCompanies = defineStore('companies', () => {
  // Once we migrate to Vue3 we should use shallowReactive
  const companies = shallowRef(new Map<string, PlainMessage<CompanyInfo>>());

  function addCompanies(items: Record<string, PlainMessage<CompanyInfo | PbCompanyInfo>>) {
    // @TODO: we need a consistent approach in the BE so we don't have to do this
    const mapped = mapValues(items, (v) =>
      'companyId' in v
        ? {
            id: v.companyId,
            name: v.companyName,
            nsccAccountId: v.nsccAccountId,
            displayBoxId: v.displayBoxId,
          }
        : v
    );
    companies.value = new Map([...companies.value, ...Object.entries(mapped)]);
  }

  function getCompany(cusip: string) {
    return companies.value.get(cusip);
  }

  return {
    addCompanies,
    getCompany,
  };
});

export type CompaniesStore = ReturnType<typeof useStoreCompanies>;
