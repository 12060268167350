import {
  ConfirmIdentityRequest,
  ConfirmIdentityResponse,
  ConnectResult,
  LoginWithUserCredentialsRequest,
  LoginWithUserCredentialsResponse,
  LogoutRequest,
  LogoutResponse,
  PlainMessage,
  RefreshTokenRequest,
  RefreshTokenResponse,
  createPromiseClient,
  serviceSecurity,
} from '@/connect';
import { LoginService } from '@/connect/gen/modules/apiengine/services/login/login_connect';

export type * from '@/connect/gen/modules/apiengine/services/login/login_pb';

export const serviceLogin = new (class {
  public readonly promiseClient = createPromiseClient(LoginService);

  public async refreshToken(request: PlainMessage<RefreshTokenRequest>) {
    const result = await this.promiseClient.refreshToken(request);
    return result as ConnectResult<RefreshTokenResponse, 'rest.RefreshTokenFailed'>;
  }

  public async confirmIdentity(request: PlainMessage<ConfirmIdentityRequest>) {
    const result = await this.promiseClient.confirmIdentity(request);
    return result as ConnectResult<ConfirmIdentityResponse, never>;
  }

  public async loginWithUserCredentials(request: PlainMessage<LoginWithUserCredentialsRequest>) {
    const result = await this.promiseClient.loginWithUserCredentials(request);
    return result as ConnectResult<
      LoginWithUserCredentialsResponse,
      | 'rest.LoginIncorrect'
      | 'rest.LoginTooManyAttempts'
      | 'rest.Login2FARequired'
      | 'rest.LoginConfirmIdentityRequired'
      | 'rest.LoginConfirmIPAddrRequired'
      | 'rest.InvalidActivationCode'
      | 'rest.ExpiredActivationCode'
      | 'rest.LoginInvalidPin'
      | 'rest.LoginInvalidPinAlreadyUsed'
    >;
  }

  public async loginWithSAMLTicket(ticket: string) {
    const result = await this.promiseClient.loginWithSAMLTicket({ ticket });
    return result as ConnectResult<
      LoginWithUserCredentialsResponse,
      'rest.RefreshTokenFailed' | 'rest.UserNotApproved'
    >;
  }

  public async logout() {
    const result = await serviceSecurity.promiseClient.logout({} as PlainMessage<LogoutRequest>);
    return result as ConnectResult<LogoutResponse, never>;
  }
})();
