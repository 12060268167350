<template>
  <div class="home">
    <section class="hero is-fullheight is-bold">
      <div class="hero-body">
        <div class="container">
          <h1>{{ $t('offline-title') }}</h1>
          <h2 class="subtitle">{{ $t('offline-subtitle') }}</h2>
          <div class="level-item">
            <a class="button is-primary is-medium" href="/">{{ $t('reload-application') }}</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  data: () => ({}),
})
export default class ServerOffline extends Vue {}
</script>

<style lang="scss" scoped>
.home {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
