import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { OmsOrder, RawOmsOrder } from '@/modules/marketplace/models';
import { sortBy } from 'lodash-es';

export type RawOmsOrdersResponse = Raw<
  OmsOrdersResponse,
  {
    // always specify existing raw entry types explititly
    data: RawOmsOrder[];
  }
>;

export class OmsOrdersResponse {
  public data: OmsOrder[];

  protected constructor(data: RawOmsOrdersResponse) {
    this.data = sortBy(data.data.map(OmsOrder.fromData), 'orderRef');
  }

  public static fromData(data: RawOmsOrdersResponse): OmsOrdersResponse {
    return new OmsOrdersResponse(data);
  }

  public static mock(data?: DeepPartial<RawOmsOrdersResponse>): OmsOrdersResponse {
    return OmsOrdersResponse.fromData(OmsOrdersResponse.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawOmsOrdersResponse>): RawOmsOrdersResponse {
    return {
      data: data?.data?.map(OmsOrder.mockData) ?? [],
    };
  }
}
