import { WatchSource, watch } from 'vue';

/**
 * A generic lock class that manages concurrent locks.
 *
 * @template T - The type of the locks.
 */
export class Lock<T> {
  /**
   * A set of active locks.
   */
  private locks = new Set<T>();

  /**
   * A function to release the lock.
   */
  private release?: () => void;

  /**
   * A promise that resolves when all locks are released.
   */
  private promise = Promise.resolve();

  /**
   * Waits until all locks are released or bypassed.
   *
   * @param bypass - An optional array of locks to bypass.
   * @returns A promise that resolves when all locks are released or bypassed.
   */
  public wait(bypass?: T[]): Promise<void> {
    if (bypass && [...this.locks].every((lock) => bypass.includes(lock))) {
      return Promise.resolve();
    }
    return this.promise;
  }

  /**
   * Adds a lock.
   *
   * @param lock - The lock to add.
   */
  public lock(lock: T): void {
    if (!this.locks.size) {
      this.promise = new Promise((resolve) => (this.release = resolve));
    }
    this.locks.add(lock);
  }

  /**
   * Removes a lock.
   *
   * @param lock - The lock to remove.
   */
  public unlock(lock: T): void {
    this.locks.delete(lock);
    if (this.locks.size === 0) {
      this.release?.();
    }
  }

  /**
   * Binds a reactive boolean reference to a lock, automatically adding or removing the lock
   * based on the reference's value.
   *
   * @param lock - The lock to bind.
   * @param flag - A Vue WatchSource that determines the lock's state. When the flag's value is true, the lock is added; when false, the lock is removed.
   */
  public bind(lock: T, flag: WatchSource<boolean>): void {
    watch(flag, (doLock) => (doLock ? this.lock(lock) : this.unlock(lock)), {
      immediate: true,
      flush: 'sync',
    });
  }

  public async run(lock: T, callback: () => void | Promise<unknown>): Promise<void> {
    this.lock(lock);
    try {
      await callback();
    } finally {
      this.unlock(lock);
    }
  }
}
