import Vue from 'vue';
import { ApiService } from '@/modules/common/services/api.service';
import { Api } from '@/modules/common/types/api';
import {
  GetAllContractsRequest,
  LoanSubmissionsUploadResponse,
  PostContractRequest,
  RawGetAllContractsRequest,
  RawLoanSubmissionsUploadResponse,
  RawTermContractUploadSecuritiesResponse,
  RawTermLoanContractResponse,
  TermContractUploadSecuritiesResponse,
  TermLoanContractResponse,
} from '@/modules/termloans/models';

export class TermLoansApiService extends ApiService {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.termLoans = singleton;
    } else {
      v.prototype.$api = {
        termLoans: singleton,
      };
    }
  }

  public async fetchContracts(): Promise<GetAllContractsRequest['items']> {
    const url = this.baseUrl + '/term-loan-contract';
    const { data } = await this.axios.get<RawGetAllContractsRequest>(url);

    return GetAllContractsRequest.fromData(data).items;
  }

  public async fetchDetails(displayId: string): Promise<TermLoanContractResponse> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}`;
    const { data } = await this.axios.get<RawTermLoanContractResponse>(url);

    return TermLoanContractResponse.fromData(data);
  }

  public create = async (
    payload: PostContractRequest
  ): Promise<Api.TermLoans.ContractFormResponse> => {
    const url = this.baseUrl + '/term-loan-contract';
    const res = await this.axios.post<Api.TermLoans.ContractFormResponse>(
      url,
      PostContractRequest.toData(payload)
    );
    return res.data;
  };

  public update = async (
    displayId: string,
    payload: PostContractRequest
  ): Promise<Api.TermLoans.ContractFormResponse> => {
    const url = this.baseUrl + '/term-loan-contract/' + displayId;
    const res = await this.axios.post<Api.TermLoans.ContractFormResponse>(
      url,
      PostContractRequest.toData(payload)
    );
    return res.data;
  };

  public manageLoans = async (
    displayId: string,
    payload: Api.TermLoans.ContractManageLoansRequest
  ): Promise<Api.TermLoans.ContractFormResponse> => {
    const url = this.baseUrl + '/term-loan-contract/' + displayId + '/loans';
    const res = await this.axios.post<Api.TermLoans.ContractFormResponse>(url, payload);
    return res.data;
  };

  public async deleteDraft(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}`;
    return this.axios.delete(url).then(() => undefined);
  }

  public async cancelProposal(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/cancel`;
    return this.axios.post(url).then(() => undefined);
  }

  public async acceptProposal(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/accept`;
    return this.axios.post(url).then(() => undefined);
  }

  public async rejectProposal(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/reject`;
    return this.axios.post(url).then(() => undefined);
  }

  public async uploadTermLoansSecurities(
    file: File
  ): Promise<TermContractUploadSecuritiesResponse> {
    const url = this.baseUrl + '/term-loan-contract/instruments/upload';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.axios.post<RawTermContractUploadSecuritiesResponse>(url, formData, {
      headers,
    });

    return TermContractUploadSecuritiesResponse.fromData(data);
  }

  public async uploadTermLoansQuantities(
    displayId: string,
    file: File
  ): Promise<LoanSubmissionsUploadResponse> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/loans/upload`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.axios.post<RawLoanSubmissionsUploadResponse>(url, formData, {
      headers,
    });

    return LoanSubmissionsUploadResponse.fromData(data);
  }
}
