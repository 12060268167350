<template>
  <v-snackbar
    v-model="snackbar"
    :color="options.color"
    :multi-line="true"
    :timeout="options.timeout"
    :top="options.top"
    transition="slide-y-reverse-transition"
  >
    {{ options.message }}

    <template #action="{ attrs }">
      <v-btn
        class="darken-2"
        v-bind="attrs"
        :color="options.color"
        elevation="0"
        small
        @click="
          snackbar = false;
          options.onAction();
        "
      >
        {{ options.actionText }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { extend, noop } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { SnackbarOptions } from '@/modules/snackbar/services/snackbar-manager';

const defaultOptions: SnackbarOptions = {
  top: false,
  message: '',
  color: 'secondary',
  timeout: 10000,
  actionText: 'Close',
  onAction: noop,
};

/**
 * Snackbar is a small component to display global messages
 */
@Component({})
export default class Snackbar extends Vue {
  protected snackbar = false;
  protected options = defaultOptions;

  protected created(): void {
    this.$snackbar.$on('showMessage', (options: Partial<SnackbarOptions>) => {
      this.show(options);
    });
  }

  private show(options: Partial<SnackbarOptions>): void {
    this.options = extend({}, defaultOptions, options);
    this.snackbar = true;
  }
}
</script>
