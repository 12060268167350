<template>
  <div>
    <v-data-table
      class="d-flex flex flex-column col-6"
      dense
      disable-pagination
      fixed-header
      :headers="tableColumns"
      height="100%"
      hide-default-footer
      :items="blacklistRecords"
      :no-data-text="
        $tc('no IPs are currently blacklisted', blacklistRecords.length, {
          count: blacklistRecords.length,
        })
      "
      sort-by="blockEndsAt"
    >
      <template #[`item.ip`]="{ item }">
        <span>{{ item.ip }}</span>
      </template>
      <template #[`item.blockEndsAt`]="{ item }">
        <span>
          {{ formatDate(item.blockEndsAt) }} ({{ formatDistance(item.blockEndsAt, now) }})
        </span>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex flex-row justify-end row-actions">
          <v-btn class="ma-1" icon small @click="unbanIp(item.ip)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { errorString } from '@/utils/helpers/rest-response';
import axios from 'axios';
import { formatDate } from '@/utils/helpers/dates';
import { formatDistance } from 'date-fns';

interface ApiBlacklistRecord {
  ip: string;
  blockStartsAt: string;
  blockEndsAt: string;
}

interface BlacklistRecord {
  ip: string;
  blockEndsAt: Date;
}

@Component({
  data: () => ({
    blacklistRecords: [],
    now: new Date(),
    tableColumns: [
      { text: 'IP', value: 'ip', class: 'text-no-wrap text-truncate' },
      {
        text: 'Blocked Until',
        value: 'blockEndsAt',
        class: 'text-no-wrap text-truncate',
      },
      { value: 'actions', sortable: false, align: 'end', width: 30 },
    ],
  }),
})
export default class BrokerIpBlacklist extends Vue {
  private blacklistRecords!: BlacklistRecord[];
  private now!: Date;
  private pollInterval: ReturnType<typeof setInterval> | null = null;

  protected async mounted(): Promise<void> {
    this.startRefreshingNow();

    await this.refreshIps();
  }

  protected destroyed(): void {
    this.stopRefreshingNow();
  }

  protected formatDate(date: Date): string {
    return formatDate(date, 'hh:mm:ss MM/dd/yy');
  }

  private startRefreshingNow(): void {
    this.stopRefreshingNow();
    this.pollInterval = setInterval(() => {
      this.now = new Date();
    }, 1000);
  }

  private stopRefreshingNow(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  private formatDistance(d: Date, now: Date): string {
    return formatDistance(d, now, { addSuffix: true, includeSeconds: true });
  }

  private async refreshIps(): Promise<void> {
    try {
      this.blacklistRecords = await this.getBlacklistRecords();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  private async getBlacklistRecords(): Promise<BlacklistRecord[]> {
    const { data } = await axios.get<{ ips: ApiBlacklistRecord[] }>(
      `/api/1/broker-admin/ipblacklist`
    );

    return data.ips && data.ips.length
      ? data.ips.map((record) => ({
          ip: record.ip,
          blockEndsAt: new Date(record.blockEndsAt),
        }))
      : [];
  }

  private async unbanIp(ip: string): Promise<void> {
    try {
      await axios.delete(`/api/1/broker-admin/ipblacklist/${ip}`);
      this.$snackbar.confirm('IP has been unbanned');
    } catch (e) {
      this.$snackbar.error(errorString(e));
    }

    void this.refreshIps();
  }
}
</script>

<style lang="scss" scoped></style>
