<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="850"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistents
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <uploader
      v-if="step === 'upload'"
      :execute="executeUpload"
      @close-modal="closeDialog()"
      @upload="goToConfirm($event)"
      @upload-method="uploadMethod = $event"
      @upload-with-errors="goToConfirmWithErrors($event)"
    >
      <template #title>
        Upload
        <format-side v-if="side" :side="side" />
        positions
      </template>
      <template #description>
        <div>Expected columns (* is optional):</div>
        <div>Counterparty | Ticker or CUSIP | Quantity | Rate | Benchmark*</div>
      </template>
    </uploader>

    <upload-confirm-basket
      v-if="step === 'confirm'"
      :basket-response="basketResponse"
      :error-headers="[
        { text: 'Row', value: 'row' },
        { text: 'Field', value: 'field' },
        { text: 'Error', value: 'errMsg' },
      ]"
      :execute="executeConfirm"
      :headers="[
        { text: 'Side', value: 'side' },
        { text: 'Counterparty', value: 'counterparty' },
        { text: 'Ticker', value: 'security.ticker' },
        { text: 'CUSIP', value: 'security.cusip' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Rate', value: 'rate', align: 'end' },
      ]"
      timeframe="createLoans"
      :upload-method="uploadMethod"
      @back="step = 'upload'"
      @close-modal="closeDialog()"
    >
      <template #title>New <format-side :side="basketResponse.side" /> positions</template>
      <template v-if="loginState.user" #summary>
        You are about to create <strong>{{ basketResponse.items.length }}</strong> positions
        <span>
          with an <strong>Independent Amount</strong> of
          <strong>
            <rate-output :rate="loginState.user.companyPreferredIndependentAmountRate" trim-zeros />
            IA rate
          </strong>
          and
          <span v-if="hasRoundingRule && loginState.user.companyPreferredRoundingRule">
            <strong>Rounding Rule</strong> of
            <strong>
              {{ roundingRuleToShortString(loginState.user.companyPreferredRoundingRule) }}
            </strong>
          </span>
          <strong v-else>No Rounding Rule</strong> applied
        </span>
      </template>
      <template #item="{ item }">
        <tr>
          <td>
            <format-side :side="basketResponse.side" />
          </td>
          <td>{{ item.counterparty.companyName }} ({{ item.counterparty.displayBoxId }})</td>
          <td>{{ item.security.ticker }}</td>
          <td>{{ item.security.cusip }}</td>
          <td>
            <pretty-number :value="item.quantity" />
          </td>
          <td class="text-end">
            <rate-output :rate="item.rate" />
          </td>
        </tr>
      </template>
      <template #create-button-text>Create positions</template>
      <template #success-message>Loans successfully created</template>
      <template #error-title>Failed <format-side :side="side" /> positions</template>
      <template #error-item="{ item }">
        <tr>
          <td>{{ item.row }}</td>
          <td>{{ item.field }}</td>
          <td class="text-capitalize">{{ item.errMsg }}</td>
        </tr>
      </template>
    </upload-confirm-basket>
  </v-dialog>
</template>

<script lang="ts">
import UploadConfirmBasket from '@/modules/common/components/UploadConfirmBasket.vue';
import UploadParseErrors from '@/modules/common/components/UploadParseErrors.vue';
import Uploader from '@/modules/common/components/Uploader.vue';
import type { UploadMethod } from '@/modules/common/types/upload';
import {
  RoundingRule,
  roundingRuleToShortString,
} from '@/modules/sec-lending/helpers/contract-details';
import {
  SponsoredLoanBasketRequest,
  SponsoredLoanBasketResponse,
  SponsoredLoanCreateRequest,
} from '@/modules/sponsorship/models';
import { LoginState } from '@/store/store';
import { ClientConfig } from '@/utils/helpers/rest';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ErrorItem } from '@/modules/common/models';

@Component({
  props: {
    side: {
      type: String as PropType<'BORROWER' | 'LENDER'>,
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig', 'loginState']),
  },
  components: {
    Uploader,
    UploadParseErrors,
    UploadConfirmBasket,
  },
})
export default class SponsorshipUploadDialog extends Vue {
  // store
  protected readonly clientConfig!: ClientConfig;
  protected readonly loginState!: LoginState;

  // props
  protected readonly side!: 'BORROWER' | 'LENDER';

  protected step: 'upload' | 'confirm' = 'upload';
  protected uploadMethod: UploadMethod | null = null;
  protected basketResponse!: SponsoredLoanBasketResponse;
  protected errors: ErrorItem[] = [];
  protected roundingRuleToShortString = roundingRuleToShortString;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected SponsoredLoanBasketRequest = SponsoredLoanBasketRequest;

  protected get hasRoundingRule(): boolean {
    if (!this.loginState.user) {
      return false;
    }
    return (
      this.loginState.user.companyPreferredRoundingRule !== null &&
      this.loginState.user.companyPreferredRoundingRule !== RoundingRule.NoRounding
    );
  }

  protected async executeUpload(file: File): Promise<SponsoredLoanBasketResponse> {
    return await this.$api.sponsorship.uploadLoans(
      SponsoredLoanBasketRequest.fromData({ side: this.side, file })
    );
  }

  protected async executeConfirm(basketResponse: SponsoredLoanBasketResponse): Promise<void> {
    await this.$api.sponsorship.createLoans(
      SponsoredLoanCreateRequest.fromBasketResponse(basketResponse)
    );
  }

  protected goToConfirm(uploadLoansResult: SponsoredLoanBasketResponse): void {
    this.basketResponse = uploadLoansResult;
    this.step = 'confirm';
  }

  protected goToConfirmWithErrors(uploadLoansResult: SponsoredLoanBasketResponse): void {
    this.basketResponse = uploadLoansResult;
    this.errors = uploadLoansResult.errors ?? [];
    this.step = 'confirm';
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>
