<template>
  <confirm-autocomplete
    :confirm-options="{
      title: 'Not implemented yet',
      message:
        'This feature is not implemented yet. Please upload a file or manually input the securities.',
      color: 'primary',
      acceptText: 'Okay',
      isRejectable: false,
    }"
    item-text="text"
    item-value="value"
    :items="[
      { value: '', text: 'Custom' },
      { value: 'SP500', text: 'S&P 500' },
      { value: 'R2000', text: 'Russell 2000' },
      { value: 'N100', text: 'Nasdaq 100' },
      { value: 'NCOMP', text: 'Nasdaq Comp' },
      { value: 'DJIA', text: 'DJIA' },
    ]"
    label="Select a stock index"
    :value="selectedIndex"
    @change="onChange"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ConfirmAutocomplete from '@/modules/open-loans/components/ConfirmAutocomplete.vue';

@Component({
  components: {
    ConfirmAutocomplete,
  },
})
export default class TermLoansSelectIndex extends Vue {
  protected selectedIndex: string = '';

  protected async onChange(value: string): Promise<void> {
    this.selectedIndex = value;
    await this.$wait(100);
    this.selectedIndex = '';
  }
}
</script>

<style lang="scss" scoped></style>
