<template>
  <div>
    <v-row no-gutters>
      <independent-amounts-statistics />
      <value-at-risk-statistics />
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import IndependentAmountsStatistics from '@/modules/analytics/components/IndependentAmountsStatistics.vue';
import ValueAtRiskStatistics from '@/modules/analytics/components/ValueAtRiskStatistics.vue';

@Component({
  components: { ValueAtRiskStatistics, IndependentAmountsStatistics },
})
export default class DashboardView extends Vue {}
</script>

<style lang="scss"></style>
