<!--
  Disables any element when the market is closed, displaying a tooltip explaining why.
  If the market is open, forwards all props and slots .

  This component is not meant to be used directly, but rather extended by other components.
  Check other components in this folder for examples.
-->
<template>
  <v-tooltip v-if="isTimeframeDisabled || tooltip" nudge-top="3" top>
    <template #activator="{ on, attrs }">
      <div class="activator" v-bind="attrs" v-on="on">
        <component
          :is="componentName"
          v-bind="$attrs"
          :class="cssClass"
          :disabled="isTimeframeDisabled || $attrs.disabled"
        >
          <template v-for="(index, name) in $slots" #[name]>
            <slot :name="name" />
          </template>
        </component>
      </div>
    </template>
    <span>{{ tooltip ?? timeframeMessage }}</span>
  </v-tooltip>

  <!-- no need for tooltip/disable: just forward everything -->
  <component
    :is="componentName"
    v-else
    v-bind="$attrs"
    @click="!$attrs.disabled && $emit('click', $event)"
  >
    <template v-for="(index, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
  </component>
</template>

<script lang="ts">
import { isFeatureEnabled } from '@/modules/market-closed/helpers/market-closed';
import { TimeframeKey } from '@/modules/market-closed/types/market-closed';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import Vue, { type PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

const tooltips: Record<TimeframeKey, string> = {
  maintenanceWindow: 'market is currently closed',
  executeAuctions: 'market is currently closed',
  createLoans: 'market is currently closed',
  rollLoans: 'market is currently closed',
  settleLoans: 'market is currently closed',
  cancelLoans: 'currently unavailable',
  recallLoans: 'currently unavailable',
  buyInLoans: 'currently unavailable',
  rateModLoans: 'currently unavailable',
};

@Component({
  props: {
    /**
     * if passed, the timeframe to use to control this button.
     * Possible options: maintenanceWindow | executeAuctions | createLoans | rollLoans | settleLoans | cancelLoans | recallLoans | buyInLoans | rateModLoans
     */
    timeframe: {
      type: String,
      required: false,
    },
    tooltip: {
      type: null as unknown as PropType<string | null>,
      default: null,
    },
  },
  computed: {
    ...mapState(['clientConfig', 'uxConfig', 'currentTimeUTC']),
  },
})
export default class EnforceTimeframe extends Vue {
  protected readonly timeframe!: TimeframeKey;

  // Must be implemented by the extending component
  protected componentName!: string;

  protected clientConfig!: ClientConfig;
  protected uxConfig!: UXConfig;
  protected currentTimeUTC!: Date;

  /**
   * Determines if the market is closed for the feature defined
   * by the provided timeframe key
   */
  protected get isTimeframeDisabled(): boolean {
    return (
      this.timeframe &&
      !isFeatureEnabled(this.timeframe, this.clientConfig, this.uxConfig, this.currentTimeUTC)
    );
  }

  protected get timeframeMessage(): string {
    return tooltips[this.timeframe] ?? 'feature is currently disabled';
  }

  protected get cssClass(): string[] {
    const classes = ['wrapped-component'];
    if (this.$vnode?.data?.staticClass) {
      classes.push(this.$vnode.data.staticClass);
    }
    return classes;
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .wrapped-component {
    pointer-events: all;
    cursor: no-drop;
  }
}
</style>
