import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { ColDef } from 'ag-grid-enterprise';
import { alignLeft, alignRight, comparator, component, mergeClasses } from './utils';
import { formatCounterparties } from '@/modules/marketplace/helpers/marketplace';
import { h } from 'vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { VenueTopOfBookItem } from '@/modules/marketplace/models';
import { isEqual } from 'lodash';
import { equals } from './utils';
import Decimal from 'decimal.js';

export function ticker(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'security.ticker',
    colId: 'securityTicker',
    headerName: 'Ticker',
    ...alignLeft(),
    flex: 101,
  };
}

export function cusip(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'security.cusip',
    colId: 'securityCUSIP',
    headerName: 'CUSIP',
    ...mergeClasses([alignLeft(), { cellClass: 'highlighted-border-right' }]),
    flex: 130,
  };
}

export function borrowEligibleTotalQuantity(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'borrow.eligibleTotalQuantity',
    colId: 'borrow.eligibleTotalQuantity',
    headerName: 'Eligible Total Demand',
    enableCellChangeFlash: true,
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value)),
    comparator: comparator.number,
    ...alignRight(),
    flex: 205,
  };
}

export function borrowCounterparty(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'borrow.counterparties',
    colId: 'borrow.counterparties',
    headerName: 'Counterparty',
    enableCellChangeFlash: true,
    equals: (a, b) => isEqual(a, b),
    valueFormatter: (params) => (!params.value ? '–' : formatCounterparties(params.value)),
    ...alignRight(),
    flex: 177,
  };
}

export function borrowQuantity(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'borrow.quantity',
    colId: 'borrow.quantity',
    headerName: 'Quantity',
    enableCellChangeFlash: true,
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value)),
    comparator: comparator.number,
    ...alignRight(),
    flex: 134,
  };
}

const rateAdapter = component<{
  rate: number | string | Decimal | null | undefined;
}>(
  (props) => () =>
    props.rate === null || props.rate === undefined ? h('span', '–') : h(RateOutput, { props })
);

export function borrowRate(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'borrow.rate',
    colId: 'borrow.rate',
    headerName: 'Best Bid',
    enableCellChangeFlash: true,
    equals: equals.decimal,
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
      }),
    comparator: comparator.decimal,
    cellDataType: true,
    ...mergeClasses([
      alignRight({ hasPostfix: true }),
      { cellClass: 'borrower-color highlighted-border-right' },
    ]),
    flex: 134,
  };
}

export function lendRate(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'lend.rate',
    colId: 'lend.rate',
    headerName: 'Best Ask',
    enableCellChangeFlash: true,
    equals: (a, b) => isEqual(a, b),
    cellRendererSelector: (params) =>
      rateAdapter({
        rate: params.value,
      }),
    comparator: comparator.decimal,
    cellDataType: true,
    ...mergeClasses([alignLeft(), { cellClass: 'lender-color' }]),
    flex: 118,
  };
}

export function lendQuantity(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'lend.quantity',
    colId: 'lend.quantity',
    headerName: 'Quantity',
    enableCellChangeFlash: true,
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value)),
    comparator: comparator.number,
    ...alignLeft(),
    flex: 104,
  };
}

export function lendCounterparty(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'lend.counterparties',
    colId: 'lend.counterparties',
    headerName: 'Counterparty',
    enableCellChangeFlash: true,
    equals: (a, b) => isEqual(a, b),
    valueFormatter: (params) => (!params.value ? '–' : formatCounterparties(params.value)),
    ...alignLeft(),
    flex: 179,
  };
}

export function lendEligibleTotalQuantity(): ColDef<VenueTopOfBookItem> {
  return {
    field: 'lend.eligibleTotalQuantity',
    colId: 'lend.eligibleTotalQuantity',
    headerName: 'Eligible Total Supply',
    enableCellChangeFlash: true,
    valueFormatter: (params) => (!params.value ? '–' : formatPrettyNumber(params.value)),
    comparator: comparator.number,
    ...alignLeft(),
    flex: 166,
  };
}
