<template>
  <v-container class="flex-column justify-center fill-height">
    <v-progress-circular color="primary" indeterminate :size="200" />
    <h3 class="mt-4">Logging out...</h3>
  </v-container>
</template>

<script lang="ts">
import logger from '@/modules/common/services/logger.service';
import { StoreAuth, useStoreAuth } from '@/store/store-auth';
import { storeToRefs } from 'pinia';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  setup() {
    return storeToRefs(useStoreAuth());
  },
})
export default class Logout extends Vue {
  // pinia
  protected readonly logout!: StoreAuth['logout'];

  protected async created(): Promise<void> {
    try {
      // now that we have a proper page state rendered, try to logout and redirect
      await this.logout();
      await this.$router.replace({ name: 'login' });
    } catch (err) {
      // critical error, must redirect to error page, to avoid possible redirection loops
      logger.error('failed to logout', err);
      await this.$router.replace({ name: 'error-500' });
    }
  }
}
</script>

<style lang="scss" scoped></style>
