<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="700px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            freshAccount
              ? $t('setPasswordFreshAccountTitle', { title: clientConfig.systemTitleShort })
              : $t('setPasswordTitle', { title: clientConfig.systemTitleShort })
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="emailAddress"
                  autocomplete="new-password"
                  disabled
                  :error-messages="clErrors.emailAddress"
                  :label="$t('loginEmail')"
                  required
                  :rules="emailRules"
                  type="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  ref="password"
                  v-model="loginPassword"
                  :append-icon="revealPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  autocomplete="new-password"
                  counter
                  :error-messages="clErrors.loginPassword"
                  :label="$t('loginPassword')"
                  required
                  :type="revealPassword ? 'text' : 'password'"
                  @click:append="revealPassword = !revealPassword"
                  @input="onPasswordChange"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  ref="again"
                  v-model="loginPasswordAgain"
                  :append-icon="revealPasswordAgain ? 'mdi-eye' : 'mdi-eye-off'"
                  autocomplete="new-password"
                  counter
                  :error-messages="clErrors.loginPasswordAgain"
                  :label="$t('loginPasswordAgain')"
                  required
                  :type="revealPasswordAgain ? 'text' : 'password'"
                  @click:append="revealPasswordAgain = !revealPasswordAgain"
                  @input="onPasswordAgainChange"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>Password strength</label>
                <password
                  v-model="loginPassword"
                  :strength-meter-only="true"
                  @feedback="onPasswordFeedback"
                  @score="onScoreChange"
                />
              </v-col>
              <v-col cols="12">
                <div class="alert-wrapper">
                  <v-alert v-if="svErrors !== ''" dense type="error">{{ svErrors }}</v-alert>
                  <v-alert
                    v-else-if="warning !== '' || suggestions.length !== 0"
                    dense
                    :type="messageType"
                  >
                    <div
                      v-if="suggestions.length !== 0"
                      class="d-flex flex-column"
                      :color="messageType"
                    >
                      <span v-for="suggestion in suggestions" :key="suggestion">
                        {{ $t(suggestion) }}
                      </span>
                    </div>
                    <span v-if="warning !== ''">{{ $t(warning) }}</span>
                  </v-alert>
                  <v-alert v-else-if="passwordScore >= minPasswordScore" dense type="info">
                    {{ $t('goodPassword.description') }}
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text to="/login">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="!canSubmit" type="submit">
            <v-icon left>mdi-shield-key</v-icon>
            {{ $t('setPasswordButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { isEmpty } from 'lodash';
import axios, { AxiosError } from 'axios';
import i18n from '@/localisation/i18n';
import { LoginPreparePasswordResponse, LoginSalt } from '@/utils/helpers/rest';
import { errorString, i18nServerMessage } from '@/utils/helpers/rest-response';
import { FormValidator, setInputFocus } from '@/utils/helpers/input-form';
import { SymmetricEncrypter } from '@/modules/common/services/crypto/symmetric-encrypter';
import Password, { PasswordFeedback } from 'vue-password-strength-meter';
import { mapActions, mapState } from 'vuex';

@Component({
  components: { Password },
  props: {
    secretCode: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    emailRules: [(v: string) => /.+@.+/.test(v) || v === '' || i18n.tc('formfield.InvalidEmail')],
  }),
  methods: {
    ...mapActions(['prepareNewLoginPassword']),
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class SetPassword extends Vue {
  public $refs!: {
    password: HTMLFormElement;
    again: HTMLFormElement;
  };
  public loginPassword = '';
  public loginPasswordAgain = '';

  // props
  protected readonly secretCode!: string;

  protected freshAccount = false;
  protected emailAddress = '';
  protected revealPassword = false;
  protected revealPasswordAgain = false;

  protected minPasswordScore = 3;
  protected passwordScore = 0;

  protected messageType = 'info';
  protected warning = '';
  protected suggestions: string[] = [];
  protected canSubmit = false;

  protected clErrors: Record<string, string> = {};
  protected svErrors = '';

  private processing = false;
  private symmetricEncrypter!: SymmetricEncrypter;

  private prepareNewLoginPassword!: (encSecret: string) => Promise<LoginPreparePasswordResponse>;

  protected async created(): Promise<void> {
    this.symmetricEncrypter = this.$es.getSymmetricEncrypter();

    try {
      const data = await this.prepareNewLoginPassword(this.secretCode);

      this.emailAddress = data.email;
      this.freshAccount = data.freshAccount;

      setInputFocus(this.$refs.password);
    } catch (e) {
      await this.$router.push({
        name: 'lost-password',
        params: { lostEmail: this.emailAddress, lostMessage: errorString(e) },
      });
    }
  }

  protected onPasswordFeedback(feedback: PasswordFeedback): void {
    this.warning = feedback.warning;
    this.suggestions = feedback.suggestions;

    this.messageType = 'info';
    if (this.warning !== '') {
      this.messageType = 'error';
    }
  }

  protected onScoreChange(score: number): void {
    this.passwordScore = score;
    if (score && score >= this.minPasswordScore) {
      this.canSubmit = true;
    } else {
      this.canSubmit = false;
    }
  }

  protected onPasswordChange(_newPassword: string): void {
    this.svErrors = '';
  }

  protected onPasswordAgainChange(_newPassword: string): void {
    this.svErrors = '';

    if (this.passwordScore >= this.minPasswordScore) {
      this.warning = '';

      if (this.loginPassword !== this.loginPasswordAgain) {
        this.warning = i18n.tc('errPasswordsNotTheSame');
        this.messageType = 'error';
      }
    }
  }

  protected async onSubmit(): Promise<void> {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      this.svErrors = '';
      this.clErrors = new FormValidator(this, ['loginPassword', 'loginPasswordAgain']).check();

      if (isEmpty(this.clErrors)) {
        await this.doSubmit();
      }
    } finally {
      this.processing = false;
    }
  }

  private async doSubmit() {
    // password strength check is a responsibility of the client
    // the server only sees the encrypted password
    if (this.passwordScore < this.minPasswordScore) {
      this.svErrors = i18n.tc('errPasswordTooSimple');
      setInputFocus(this.$refs.password);
      return;
    }
    if (this.loginPassword !== this.loginPasswordAgain) {
      this.svErrors = i18n.tc('errPasswordsNotTheSame');
      setInputFocus(this.$refs.again);
      return;
    }

    // get salt (for entered email address) from the server
    let salt: Buffer;
    try {
      const response = await axios.get(`/api/1/login-salt/${this.emailAddress}`);
      salt = Buffer.from((response.data as LoginSalt).salt, 'base64');
    } catch (e) {
      setInputFocus(this.$refs.password);
      this.svErrors = i18n.tc('rest.ServiceUnavailable');
      return;
    }

    // Send 1 password-hash to the server
    const hashedPassword = await this.symmetricEncrypter.hashPassword(this.loginPassword, salt);
    try {
      await axios.post('/api/1/login-set-password', {
        encSecret: this.secretCode,
        newPassword: hashedPassword,
      });
    } catch (e) {
      let err = this.$t('loginResetPasswordEmailFailed', {
        emailAddress: this.emailAddress,
      }) as string;
      const data = (e as AxiosError).response?.data as undefined | { msgkey: unknown };
      if (data?.msgkey) {
        err = i18nServerMessage(e as AxiosError);
      }
      this.svErrors = err;
      setInputFocus(this.$refs.password);
      return;
    }

    await this.$router.push({
      name: 'login',
      params: { email: this.emailAddress, message: 'loginResetPasswordEmailSucceeded' },
    });
  }
}
</script>

<style lang="scss" scoped>
/* Overwrite component's default */
.Password {
  max-width: 100%;
}

/* Alert content can have 1, 2 or 3 lines of text; applying a min-height minimises layout shifts */
.alert-wrapper {
  min-height: 82px;
}
</style>
