// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/loanbuyinconsts.proto (package aurora.core.consts.loanbuyin, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * LoanBuyInStatus : the state a Buy-In is in now
 *
 * @generated from enum aurora.core.consts.loanbuyin.LoanBuyInStatus
 */
export enum LoanBuyInStatus {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: ACCEPTED = 2;
   */
  ACCEPTED = 2,

  /**
   * @generated from enum value: REJECTED = 3;
   */
  REJECTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanBuyInStatus)
proto3.util.setEnumType(LoanBuyInStatus, "aurora.core.consts.loanbuyin.LoanBuyInStatus", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "PENDING" },
  { no: 2, name: "ACCEPTED" },
  { no: 3, name: "REJECTED" },
]);

/**
 * @generated from enum aurora.core.consts.loanbuyin.LoanBuyInRejectReason
 */
export enum LoanBuyInRejectReason {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: OUTSIDE_SUPPORTED_HOURS = 1;
   */
  OUTSIDE_SUPPORTED_HOURS = 1,

  /**
   * @generated from enum value: UNSUPPORTED_MESSAGE_DETAILS = 2;
   */
  UNSUPPORTED_MESSAGE_DETAILS = 2,

  /**
   * @generated from enum value: UNKNOWN_EXECID_NOT_YOUR_LOAN = 3;
   */
  UNKNOWN_EXECID_NOT_YOUR_LOAN = 3,

  /**
   * @generated from enum value: LOAN_INELIGIBLE = 4;
   */
  LOAN_INELIGIBLE = 4,

  /**
   * @generated from enum value: INVALID_BUYIN_QTY = 5;
   */
  INVALID_BUYIN_QTY = 5,

  /**
   * @generated from enum value: OTHER = 6;
   */
  OTHER = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanBuyInRejectReason)
proto3.util.setEnumType(LoanBuyInRejectReason, "aurora.core.consts.loanbuyin.LoanBuyInRejectReason", [
  { no: 0, name: "NONE" },
  { no: 1, name: "OUTSIDE_SUPPORTED_HOURS" },
  { no: 2, name: "UNSUPPORTED_MESSAGE_DETAILS" },
  { no: 3, name: "UNKNOWN_EXECID_NOT_YOUR_LOAN" },
  { no: 4, name: "LOAN_INELIGIBLE" },
  { no: 5, name: "INVALID_BUYIN_QTY" },
  { no: 6, name: "OTHER" },
]);

