<template>
  <v-autocomplete
    v-model="currSecurity"
    auto-select-first
    :autofocus="currSecurity == null"
    :color="color"
    :disabled="disabled"
    :error-messages="errorMessages"
    hide-no-data
    hide-selected
    item-text="description"
    item-value="id"
    :items="securitiesNormalized"
    :label="label"
    :loading="isSearching"
    outlined
    :placeholder="$t('securityAuction.tip')"
    prepend-icon="mdi-database-search"
    return-object
    @update:search-input="onSearchSecurities"
  ></v-autocomplete>
</template>

<script lang="ts">
/**
 * <security-selector> is a component to query the database and select a valid security
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';
import { extend } from 'lodash';
import { formatSecurityDescription } from '@/utils/helpers/securities';
import { AuctionSecurity, RawAuctionSecurity } from '@/modules/auction/models';

const maxEquityDescriptionLength = 60;

interface ExtendedSecurity extends AuctionSecurity {
  description: string;
}

@Component({
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    security: {
      type: Object,
    },
    color: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String || [],
      default: '',
    },
  },
  model: {
    prop: 'security',
    event: 'changed',
  },
  data: () => ({
    currSecurity: null,
    isSearching: false,
    equityError: [],
  }),
})
export default class SecuritySelector extends Vue {
  private security!: AuctionSecurity;
  private currSecurity!: AuctionSecurity | ExtendedSecurity;

  // securities autocomplete/search options
  private isSearching = false;
  private securities: AuctionSecurity[] = [];
  private maxQueryCount = 30;
  private lastQuery = '';
  private lastQueryCount = 0;

  // getter for securities with a bit of cleanup
  private get securitiesNormalized(): ExtendedSecurity[] {
    return this.securities.map((eq) => {
      return extend(eq, { description: this.formatSecurityDescription(eq) });
    });
  }

  // watch for a security to be selected from the dropdown and forward it to the parent
  @Watch('currSecurity')
  private onChangedSecurityByDropdown(selected: AuctionSecurity) {
    this.$emit('changed', selected);
  }

  // watch for the parent to change the security and update the dropdown
  @Watch('security')
  private onChangedByParent(security: AuctionSecurity) {
    this.currSecurity = security;
  }

  private mounted() {
    if (this.security) {
      this.securities = [this.security];
      this.currSecurity = this.security;
    }
  }

  private formatSecurityDescription(security: AuctionSecurity): string {
    return formatSecurityDescription(security, maxEquityDescriptionLength);
  }

  // typed in security search box
  private async onSearchSecurities(newQuery: string) {
    // clicked an entry in the list, no need to search
    if (
      !newQuery ||
      (this.currSecurity &&
        'description' in this.currSecurity &&
        newQuery === this.currSecurity.description)
    ) {
      return;
    }

    // securities have already been loaded
    if (
      this.securities.length > 0 &&
      this.lastQuery.length > 0 &&
      newQuery.indexOf(this.lastQuery) !== -1 &&
      this.lastQueryCount < this.maxQueryCount
    ) {
      return;
    }

    // securities have already been requested
    if (this.isSearching) {
      return;
    }
    this.isSearching = true;

    // submit new query
    try {
      const q = `/api/1/equities/search?query=${newQuery}&limit=${this.maxQueryCount}`;

      const { data } = await axios.get<{
        status: string;
        equities: RawAuctionSecurity[];
        recordCount: number;
      }>(q);
      this.securities = data.equities.map<AuctionSecurity>(AuctionSecurity.fromData);
      this.lastQuery = newQuery;
      this.lastQueryCount = this.securities.length;
    } catch (e) {
      if ((e as AxiosError).response && (e as AxiosError).response?.status === 404) {
        this.$log.debug(`no results for ${newQuery}`);
      } else {
        this.$log.warn(e);
      }
    } finally {
      this.isSearching = false;
    }
  }
}
</script>
