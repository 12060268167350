export enum Venue {
  FinraVenueProvable = 'PVBL',
}

export interface FinraTransactionsRequest {
  filters: {
    showAll: boolean;
    side: 'LENDER' | 'BORROWER' | null;
    counterpartyCompanyId: string | null;
    cusip: string | null;
  };

  // sorting:
  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface FinraTransactionsParams {
  filters?: Partial<FinraTransactionsRequest['filters']>;
  sort?: Partial<FinraTransactionsRequest['sort']>;
  pagination?: Partial<FinraTransactionsRequest['pagination']>;
}
