// NOTE: must match constants in `modules/consts/permconsts.go`
export enum TradingPermission {
  DisabledPermissions = 0b0000,
  LenderPermissions = 0b0001,
  BorrowerPermissions = 0b0010,
  LenderOrBorrowerPermissions = LenderPermissions | BorrowerPermissions,
}

/**
 * WithTradingPermissions can be used to accept any object that has a tradingPermissions property
 */
interface WithTradingPermissions {
  tradingPermissions: TradingPermission;
}

/**
 * check that the user has at least one of the required permissions
 *
 * @param usersPermission - the trading permissions that the user has
 * @param oneOf - required permissions for the calling function/page, just one of them is required
 */
export function hasOneOfTradingPermission(
  usersPermission: TradingPermission | WithTradingPermissions,
  oneOf: TradingPermission
): boolean {
  const permissions =
    typeof usersPermission === 'object' ? usersPermission.tradingPermissions : usersPermission;

  if (!oneOf) {
    // no restrictions, allow any user to go there
    return true;
  }

  return (oneOf & permissions) != 0;
}
