//
// SymmetricEncrypter wraps the interface SubtleCryptoLike
// At runtime, 'window.crypto.subtle' is injected but
// the test suite injects a mocked implementation
//
import { CryptoSubtle } from '@/modules/common/services/crypto/crypto-subtle';
import { KeyDerivation } from '@/modules/common/services/crypto/key-derivation';
import { WindowBase64 } from '@/modules/common/services/crypto/window-base64';
import { AES_GCM } from '@lib/asmcrypto';
import assert from 'assert';
import randomBytes from 'randombytes';

const DEFAULT_KEY_ITERATIONS = 36000;
const ONE_ITERATION = 1;

export class SymmetricEncrypter {
  private static readonly defaultSaltLen = 10; /* can permit changes, no more than 512 bit (128 bytes) */
  private static readonly tagLenBits = 128; /* fixed */
  private static readonly ivLenBits = 128; /* fixed */

  private readonly iterations: number;
  private readonly decoder: WindowBase64;
  private readonly keyDerivation: KeyDerivation;

  public constructor(
    decoder: WindowBase64,
    subtle: CryptoSubtle,
    derivation: KeyDerivation,
    iterations?: number
  ) {
    this.decoder = decoder;
    this.iterations = iterations || DEFAULT_KEY_ITERATIONS;
    this.keyDerivation = derivation;
  }

  private static parseCipherHeader(ct: Buffer): {
    salt: Buffer;
    iterations: number;
    header: Buffer;
    iv: Buffer;
    ctt: Buffer;
  } {
    try {
      // copy buffer to avoid messing with the original
      const copy = Buffer.from(ct);

      let c = 0;
      const saltLen = copy.readUInt8(c);
      c += 1;
      const salt = copy.slice(1, c + saltLen);
      c += saltLen;
      const iterations = copy.readUInt32LE(c);
      c += 4;
      const header = copy.slice(0, c);
      const iv = copy.slice(c, 16 + c);
      c += 16;
      const ctt = copy.slice(c);

      return {
        salt: salt,
        iterations: iterations,
        header: header,
        iv: iv,
        ctt: ctt,
      };
    } catch (e) {
      throw new Error('invalid ciphertext header');
    }
  }

  public getIterationCount(): number {
    return this.iterations;
  }

  public async computeSubKey(key: Buffer, salt: Buffer): Promise<Buffer> {
    const oneIteration = 1;
    return this.keyDerivation.compute(key, salt, oneIteration);
  }

  /**
   * @param plainPassword
   * @param salt
   * @return base64 hashed password
   */
  public async hashPassword(plainPassword: string, salt: Buffer): Promise<string> {
    const buf = await this.keyDerivation.compute(
      Buffer.from(plainPassword, 'utf8'),
      salt,
      this.getIterationCount()
    );
    return buf.toString('base64');
  }

  /**
   * encrypt plaintext using password
   *
   * @param plainText
   * @param pw
   * @return base64 ciphertext
   */
  public async encryptWithPassword(plainText: string, pw: string): Promise<string> {
    if (pw.length < 1) {
      throw new Error('password missing');
    }

    const buf = await this.encryptBuffer(
      Buffer.from(plainText, 'utf8'),
      Buffer.from(pw, 'utf8'),
      this.getIterationCount()
    );

    return buf.toString('base64');
  }

  /**
   * encrypt plaintext using key
   *
   * @param plainText
   * @param key
   * @return base64 ciphertext
   */
  public async encryptWithKey(plainText: string, key: string): Promise<string> {
    if (key.length < 1) {
      throw new Error('key missing');
    }

    this.verifyBase64(key);

    const buf = await this.encryptBuffer(
      Buffer.from(plainText, 'utf8'),
      Buffer.from(key, 'base64'),
      ONE_ITERATION
    );

    return buf.toString('base64');
  }

  /**
   * decrypt ciphertext using password
   *
   * @param cipherText base64
   * @param pw
   * @return plaintext
   */
  public async decryptWithPassword(cipherText: string, pw: string): Promise<string> {
    if (pw.length < 1) {
      throw new Error('password missing');
    }

    // raise an exception if the ciphertext is not base64 encoded.
    // do NOT remove: decrypt will generate a buffer overflow or, worse, never return while eating cpu cycles!
    this.verifyBase64(cipherText);

    const buf = await this.decryptBuffer(
      Buffer.from(cipherText, 'base64'),
      Buffer.from(pw, 'utf8')
    );

    return buf.toString('utf8');
  }

  /**
   * decrypt ciphertext using key
   *
   * @param cipherText base64
   * @param key
   * @return plaintext
   */
  public async decryptWithKey(cipherText: string, key: string): Promise<string> {
    if (key.length < 1) {
      throw new Error('key missing');
    }

    // raise an exception if the ciphertext is not base64 encoded.
    // do NOT remove: decrypt will generate a buffer overflow or, worse, never return while eating cpu cycles!
    this.verifyBase64(cipherText);
    this.verifyBase64(key);

    const buf = await this.decryptBuffer(
      Buffer.from(cipherText, 'base64'),
      Buffer.from(key, 'base64')
    );

    return buf.toString('utf8');
  }

  // internal encrypt function; only public to expose it to unit testing
  public encryptBuffer(pt: Buffer, pw: Buffer, iterations?: number): Promise<Buffer> {
    return Promise.resolve().then(() => {
      const salt = this.generateSalt();
      const iv = this.generateIV();

      iterations = typeof iterations === 'undefined' ? KeyDerivation.defaultIterations : iterations;
      return this.encryptWithSaltAndIV(pt, pw, salt, iv, iterations);
    });
  }

  // internal decrypt function; only public to expose it to unit testing
  public async decryptBuffer(ct: Buffer, pw: Buffer): Promise<Buffer> {
    assert(ct instanceof Buffer, 'cipherText must be provided as a Buffer');
    assert(pw instanceof Buffer, 'password must be provided as a Buffer');

    const { salt, iterations, header, iv, ctt } = SymmetricEncrypter.parseCipherHeader(ct);

    const keyBuf = await this.keyDerivation.compute(pw.slice(0), salt.slice(0), iterations);

    const plainText = AES_GCM.decrypt(
      new Uint8Array(ctt),
      new Uint8Array(keyBuf),
      new Uint8Array(iv),
      new Uint8Array(header),
      SymmetricEncrypter.tagLenBits / 8
    );

    return Buffer.from(plainText);
  }

  public getRandomBytes(length: number): Buffer {
    return randomBytes(length);
  }

  private generateSalt(): Buffer {
    return this.getRandomBytes(SymmetricEncrypter.defaultSaltLen);
  }

  private generateIV(): Buffer {
    return this.getRandomBytes(SymmetricEncrypter.ivLenBits / 8);
  }

  private verifyBase64(input: string) {
    // atob() will raise an exception if the input is not base64 encoded.
    this.decoder.atob(input);
  }

  private encryptWithSaltAndIV(
    pt: Buffer,
    pw: Buffer,
    saltBuf: Buffer,
    iv: Buffer,
    iterations: number
  ): Promise<Buffer> {
    return Promise.resolve().then(() => {
      assert(pt instanceof Buffer, 'pt must be provided as a Buffer');
      assert(pw instanceof Buffer, 'pw must be provided as a Buffer');
      assert(iv instanceof Buffer, 'IV must be provided as a Buffer');
      assert(saltBuf instanceof Buffer, 'saltBuff must be provided as a Buffer');
      assert(iv.length === 16, 'IV must be exactly 16 bytes');

      const SL = Buffer.alloc(1);
      const S = saltBuf;
      const I = Buffer.alloc(4);
      SL.writeUInt8(saltBuf.length, 0);
      I.writeUInt32LE(iterations, 0);
      const header = SL.toString('hex') + S.toString('hex') + I.toString('hex');

      return this.keyDerivation.compute(pw, saltBuf, iterations).then((keyBuf) => {
        const ct1 = AES_GCM.encrypt(
          new Uint8Array(pt),
          new Uint8Array(keyBuf),
          new Uint8Array(iv),
          new Uint8Array(Buffer.from(header, 'hex')),
          SymmetricEncrypter.tagLenBits / 8
        );
        const ct2 = Buffer.from(ct1).toString('hex');

        // iter || saltLen8 || salt || iv || tag || ct
        return Buffer.from([header, iv.toString('hex'), ct2].join(''), 'hex');
      });
    });
  }
}
