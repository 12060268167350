<template>
  <v-container fluid>
    <v-container class="mt-4">
      <v-row>
        <v-col>
          <security-selector
            v-model="eqtySelected"
            color="form-field"
            :error-messages="clErrors.security"
            :hide-details="false"
            :label="$t('security')"
            noautofocus
            :placeholder="$t('securityBorrowerLocate.tip')"
          ></security-selector>
        </v-col>
        <v-col clas="py-0">
          <volume-input
            ref="volume"
            label="Quantity"
            :step="1"
            tabindex
            :volume="borrowerLocateBuffer.quantity"
            @changed-error="onVolumeError"
            @changed-volume="onVolumeChange"
          ></volume-input>
        </v-col>
      </v-row>
      <v-row v-if="quantityError !== ''" align="center" class="ma-0 pa-0">
        <v-col></v-col>
        <v-col class="ma-0 pa-0 pr-1">
          <v-alert class="py-1" color="error" dense text>{{ quantityError }}</v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 ml-8">
          <template v-if="isLoaded">
            <v-btn block color="primary" @click="requestLocates">
              <v-icon left> mdi-card-search-outline</v-icon>
              Request Locate
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-8" fluid>
      <borrower-locates-table
        :borrower-locates="borrowerLocates"
        @locate-intent="onBorrowLocateIntent"
      />
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';

import SystemBar from '@/modules/common/components/SystemBar.vue';
import BorrowerLocateDialog from '@/modules/borrower/components/BorrowerLocateDialog.vue';
import BorrowerLocatesTable from '@/modules/borrower/components/BorrowerLocatesTable.vue';
import SecuritySelector from '@/modules/orders/components/SecuritySelector.vue';
import VolumeInput from '@/modules/common/components/format-volume/VolumeInput.vue';

import { ClientConfig, RestOperation } from '@/utils/helpers/rest';
import { BorrowIntent } from '@/utils/api/locates';
import { FormValidator, setInputFocus } from '@/utils/helpers/input-form';
import { isEmpty } from 'lodash';
import { AuctionSecurity } from '@/modules/auction/models';
import { BorrowerLocate } from '@/modules/borrower/models';

@Component({
  components: {
    SystemBar,
    BorrowerLocatesTable,
    BorrowerLocateDialog,
    SecuritySelector,
    VolumeInput,
  },
  props: {},
  data: () => ({
    isLoaded: false,
    eqtySelected: null,
    quantityError: '',

    borrowerLocateBuffer: null,
  }),
  methods: {
    ...mapActions(['fetchBorrowerLocates', 'submitBorrowerLocate']),
  },
  computed: {
    ...mapState(['borrowerLocates', 'clientConfig']),
  },
})
export default class BorrowerLocates extends Vue {
  public $refs!: {
    volume: HTMLFormElement;
  };

  protected fetchBorrowerLocates!: () => Promise<null>;
  protected submitBorrowerLocate!: (payload: {
    locate: BorrowerLocate;
    crudAction: RestOperation;
  }) => Promise<BorrowerLocate>;

  protected isLoaded!: boolean;
  protected eqtySelected!: AuctionSecurity | null;

  protected borrowerLocateBuffer = BorrowerLocate.create();
  protected borrowLocateIntent!: BorrowIntent | null;
  protected quantityError!: string;

  protected processing = false;

  protected clErrors: { [index: string]: string } = {};

  protected borrowerLocates!: BorrowerLocate[];
  protected clientConfig!: ClientConfig;

  // selected security from dropdown
  @Watch('eqtySelected')
  protected onChangedSecurity(selected: AuctionSecurity): void {
    if (selected) {
      this.borrowerLocateBuffer.security = selected;
      this.clErrors.security = '';
    }
  }

  protected created(): void {
    this.borrowerLocateBuffer = BorrowerLocate.create();
  }

  protected async mounted(): Promise<void> {
    this.borrowerLocateBuffer = BorrowerLocate.create();

    try {
      await Promise.all([this.fetchBorrowerLocates()]);

      this.isLoaded = true;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected onVolumeChange(volume: number): void {
    this.borrowerLocateBuffer.quantity = volume;
    this.quantityError = '';
  }

  protected onVolumeError(errorStr: string): void {
    this.quantityError = errorStr;
  }

  protected async requestLocates(): Promise<void> {
    await this.createLocate();
  }

  protected async createLocate(): Promise<void> {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      this.clErrors = new FormValidator(this.borrowerLocateBuffer, ['security']).check();

      if (!isEmpty(this.clErrors)) {
        // jump to the first error and abort
        const focusRef = this.$refs[Object.keys(this.clErrors)[0]];
        if (focusRef) {
          setInputFocus(focusRef);
        }
        return;
      }

      if (this.borrowerLocateBuffer.quantity < 1) {
        this.quantityError = 'enter quantity to locate';
        setInputFocus(this.$refs.volume);
        return;
      }

      await this.submitLocate();

      this.eqtySelected = null;
      this.borrowerLocateBuffer = BorrowerLocate.create();
    } finally {
      this.processing = false;
    }
  }

  protected async submitLocate(): Promise<void> {
    try {
      await this.submitBorrowerLocate({
        crudAction: RestOperation.Create,
        locate: this.borrowerLocateBuffer,
      });
      await this.fetchBorrowerLocates();
      this.$snackbar.show({ message: 'Locate Requested', timeout: 2000 });
    } catch (e) {
      alert(e);
    }
  }

  protected onBorrowLocateIntent(intent: BorrowIntent): void {
    if (intent.quantity == null) {
      return;
    }

    this.borrowerLocateBuffer.security = intent.security;
    this.borrowerLocateBuffer.quantity = intent.quantity;

    this.eqtySelected = intent.security;
  }

  protected isMarketClosed(): boolean {
    return false;
  }

  protected onHandleWindowEvent(evtName: string): void {
    this.$emit(`${evtName}-widget`);
  }
}
</script>

<style lang="scss" scoped>
.aurora-drag-handle {
  font-weight: bold;
  background-color: #607d8b !important;
}
</style>
