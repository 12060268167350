<template>
  <div>
    <dashboard-panel :title="panelTitle">
      <pre-established-info />
      <manual-loans-list is-pre-established />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import ManualLoansList from '@/modules/manual-loan/components/ManualLoansList.vue';
import PreEstablishedInfo from '@/modules/manual-loan/components/PreEstablishedInfo.vue';
import i18n from '@/localisation/i18n';

@Component({
  components: {
    DashboardPanel,
    ManualLoansList,
    PreEstablishedInfo,
  },
})
export default class PreEstablishedLoansView extends Vue {
  protected get panelTitle(): string {
    return i18n.t('sidebar.items.preEstablished') as string;
  }
}
</script>

<style lang="scss"></style>
