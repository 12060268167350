// The auction window (market open - market close) is described in the config file and is based
// on the timezone of the broker. Currently we assume the broker is located in Europe/Amsterdam.
// The client displays dates in the local timezone. To validate user input we must map between
// the timezone of the broker and the client.
// NOTE: mapping timezone is client only. The API always uses UTC!
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  getHours,
  getMinutes,
  getSeconds,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import logger from '@/modules/common/services/logger.service';
import { AppState } from '@/store/store';
import { Store } from 'vuex';
import { GenericError } from '@/utils/errors';
import { Time } from '@/modules/market-closed/types/market-closed';

export class AuctionTimeManager {
  private readonly store: Store<AppState>;
  private brokerOffsetInMinutes!: number;
  private readonly brokerTimeZone: string;
  private auctionMarketTimeIncrements: string;
  private auctionMarketTimeMinOpen: string;
  private readonly auctionMarketDaysMaxOpen: number;

  public constructor(st: Store<AppState>) {
    this.store = st;
    if (!this.store.state.clientConfig) {
      // sticking with client default for a while...
      logger.debug('Warning: ClientConfig not loaded from server yet');
    }

    // @TODO should we throw an error above when there's not clientConfig? or if it's fine, then should we set sane defaults below?
    this.brokerTimeZone = this.store.state.clientConfig?.timezone ?? '';
    this.auctionMarketTimeIncrements =
      this.store.state.clientConfig?.auctionMarketTimeIncrements ?? '';
    this.auctionMarketTimeMinOpen = this.store.state.clientConfig?.auctionMarketTimeMinOpen ?? '';
    this.auctionMarketDaysMaxOpen = this.store.state.clientConfig?.auctionMarketDaysMaxOpen ?? 0;
    this.setBrokerOffsetInMinutes(new Date());
  }

  public getFirstAuctionMoment(): Date {
    // minimum time the auction should be open
    const [minHour, minMinute] = this.auctionMarketTimeMinOpen.split(':');

    // always in local time
    return setSeconds(addHours(addMinutes(new Date(), +minMinute), +minHour), 0);
  }

  public getBrokerTimeZone(): string {
    return this.brokerTimeZone;
  }

  public getBrokerMarketOpens(): Time {
    if (this.store.state.clientConfig) {
      return this.store.state.clientConfig.marketTimeframes.executeAuctions[0];
    } else {
      throw new GenericError('missing timeframes in client config');
    }
  }

  public getBrokerMarketCloses(): Time {
    if (this.store.state.clientConfig) {
      return this.store.state.clientConfig.marketTimeframes.executeAuctions[1];
    } else {
      throw new GenericError('missing timeframes in client config');
    }
  }

  public getAuctionMarketTimeIncrements(): [incHours: string, incMinutes: string] {
    return this.auctionMarketTimeIncrements.split(':') as [string, string];
  }

  public getAuctionMarketDaysMaxOpen(): number {
    return this.auctionMarketDaysMaxOpen;
  }

  public getMarketOpens(localWhen: Date): Date {
    const [openHour, openMinute] = this.getBrokerMarketOpens().split(':');
    return this.getMarketMoment(localWhen, +openHour, +openMinute);
  }

  public getMarketCloses(localWhen: Date): Date {
    const [closeHour, closeMinute] = this.getBrokerMarketCloses().split(':');
    return this.getMarketMoment(localWhen, +closeHour, +closeMinute);
  }

  public getBrokerOffsetMinutes(localWhen: Date): number {
    this.setBrokerOffsetInMinutes(localWhen);

    return this.brokerOffsetInMinutes;
  }

  private getMarketMoment(localWhen: Date, marketHour: number, marketMinute: number): Date {
    this.setBrokerOffsetInMinutes(localWhen);

    // sanity check
    if (getHours(localWhen) + getMinutes(localWhen) + getSeconds(localWhen) === 0) {
      logger.debug('Warning: AuctionTimeManager needs full date+time, not just a date!', localWhen);
    }

    // convert to broker time
    const brokerWhen = addMinutes(localWhen, -1 * this.brokerOffsetInMinutes);

    // do the math
    let marketMoment = setSeconds(setHours(setMinutes(brokerWhen, marketMinute), marketHour), 0);

    // convert back to local time
    marketMoment = addMinutes(marketMoment, this.brokerOffsetInMinutes);
    return marketMoment;
  }

  private setBrokerOffsetInMinutes(refDate: Date) {
    const brokerNow = utcToZonedTime(refDate, this.brokerTimeZone);
    this.brokerOffsetInMinutes = differenceInMinutes(refDate, brokerNow);
  }
}
