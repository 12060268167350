<template>
  <v-card class="flex d-flex flex-column">
    <uptime-service-dialog
      v-if="uptimeDialogOpen"
      :service="uptimeService"
      @close-modal="uptimeDialogOpen = false"
    ></uptime-service-dialog>

    <v-card-title class="mb-4">
      <span class="headline">{{ $t('adminUptimeStatistics.title') }}</span>
    </v-card-title>
    <v-card-text v-if="showError">
      <div
        class="v-alert v-alert--dense text--primary text-body-2 text-center"
        :class="{ error: showError }"
      >
        <div>
          {{ apiErrors.join('\n') }}
        </div>
      </div>
    </v-card-text>
    <v-card-text v-if="isLoading" class="text--primary">
      <v-container class="flex-column justify-center fill-height">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
        <h3 class="mt-4">Loading Services ...</h3>
      </v-container>
    </v-card-text>
    <v-card-text v-else class="text--primary">
      <v-container
        v-if="uptimeServices.length === 0"
        class="flex-column justify-center fill-height"
      >
        <v-alert icon="mdi-alert" outlined type="warning"
          >Uptime Statistics currently not available
        </v-alert>
      </v-container>
      <v-container v-else fluid>
        <v-row v-for="service in uptimeServices" :key="service.name" class="pa-4">
          <v-col>
            <v-card :key="service.name" class="mt-1 mx-auto" elevation="10">
              <v-card-text class="pt-0">
                <v-row no-gutters>
                  <v-col col="3">
                    <div class="text-h6 font-weight-light mb-2">{{ service.name }}</div>
                    <div class="subheading font-weight-light grey--text">
                      Status: {{ $t(`uptime.status.${service.status}`) }}
                    </div>
                    <v-col>
                      <v-btn
                        color="secondary"
                        small
                        @click="
                          uptimeService = service;
                          uptimeDialogOpen = true;
                        "
                        >historical uptime
                      </v-btn>
                    </v-col>
                  </v-col>
                  <v-col v-if="!isCreatingMaps" class="mt-5 scroll-horizontally" cols="9">
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-menu bottom>
                          <template #activator="{ on, attrs }">
                            <svg
                              class="uptime-graph"
                              height="52"
                              width="1100"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-for="(ix, i) in 90">
                                <rect
                                  :key="ix"
                                  :class="getServiceOutageClass(service.id, i)"
                                  height="34"
                                  rx="2"
                                  ry="2"
                                  width="10"
                                  :x="i * 12 + 10"
                                  :y="0"
                                  @click="showOutageDetails(service.id, i)"
                                ></rect>
                                <rect
                                  v-if="i % 10 === 0"
                                  :key="'line' + ix"
                                  class="uptime-line"
                                  height="5"
                                  rx="2"
                                  ry="2"
                                  width="1"
                                  :x="i * 12 + 14"
                                  :y="34"
                                ></rect>
                                <text
                                  v-if="i % 10 === 0"
                                  :key="'date' + ix"
                                  class="uptime-label"
                                  :x="i * 12"
                                  :y="52"
                                >
                                  {{ getServiceOutageDate(i) }}
                                </text>
                              </template>
                            </svg>
                          </template>
                          <v-container v-if="showTooltip" class="mt-16" fluid>
                            <v-card light max-width="500">
                              <v-card-title>{{ tooltipTitle }}</v-card-title>
                              <v-card-subtitle>{{ tooltipCaption }}</v-card-subtitle>
                              <v-card-text>
                                <div v-for="(o, i) in tooltipLines" :key="i">
                                  <v-icon :color="getStatusColor(o.status)" left small
                                    >{{ getStatusIcon(o.status) }}
                                  </v-icon>
                                  {{ getOutageText(o) }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-container>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="auto">Today</v-col>
                      <v-spacer>
                        <v-divider class="ma-3"></v-divider>
                      </v-spacer>
                      <v-col align-self="end" cols="auto">90 days ago</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-divider class="my-2"></v-divider>
                <v-icon class="mr-2" :color="getStatusColor(service.status)" small>
                  mdi-clock
                </v-icon>
                <span class="text-caption grey--text font-weight-light"
                  >{{
                    $tc('uptime.daysUp', service.days, {
                      percentage: service.percentage.eq(100.0)
                        ? service.percentage.toFixed(0)
                        : service.percentage.toFixed(3),
                      dayCount: service.days,
                    })
                  }}
                </span></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { UptimeOutage, UptimeService } from '@/utils/api/uptime';
import {
  getOutageDuration,
  getUptimeStatusColor,
  getUptimeStatusIcon,
  getUptimeStatusText,
} from '@/modules/uptime/helpers/uptime';
import { eachDayOfInterval, getDayOfYear, isBefore, subDays } from 'date-fns';
import { formatDate } from '@/utils/helpers/dates';
import i18n from '@/localisation/i18n';
import UptimeServiceDialog from '@/modules/uptime/components/UptimeServiceDialog.vue';

@Component({
  components: { UptimeServiceDialog },
  methods: {
    ...mapActions(['fetchAdminUptimeServices']),
  },
  computed: {
    ...mapState(['uptimeServices']),
  },
})
export default class UptimeStatistics extends Vue {
  // store actions
  protected fetchAdminUptimeServices!: () => void;
  protected uptimeServices!: UptimeService[];
  protected showError = false;
  protected apiErrors: string[] = [];

  protected isLoading = true;
  protected isCreatingMaps = false;

  protected showTooltip = false;
  protected tooltipTitle = '';
  protected tooltipCaption = '';
  protected tooltipLines: UptimeOutage[] | null = null;

  protected uptimeDialogOpen = false;
  protected uptimeService: UptimeService | null = null;

  protected get uptimeCache(): {
    services: Map<string, UptimeService>;
    outages: Map<string, UptimeOutage[]>;
  } {
    const now = new Date();
    const services = new Map<string, UptimeService>();
    const outages = new Map<string, UptimeOutage[]>();

    // split outages per service into a list per day
    this.uptimeServices.forEach((service) => {
      services.set(service.id, service);
      service.outages.forEach((outage) => {
        eachDayOfInterval({ start: outage.from, end: outage.until }).forEach((dt) => {
          const outageKey = `${service.id}:${getDayOfYear(now) - getDayOfYear(dt)}`;
          const outageList = outages.get(outageKey) || [];
          outageList.push(outage);
          outages.set(outageKey, outageList);
        });
      });
    });

    return { services: services, outages: outages };
  }

  protected getStatusColor(status: string): string {
    return getUptimeStatusColor(status);
  }

  protected getStatusIcon(status: string): string {
    return getUptimeStatusIcon(status);
  }

  protected getServiceOutageClass(serviceId: string, dayNumber: number): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const service = this.uptimeCache.services.get(serviceId)!;
    const date = subDays(new Date(), dayNumber);
    if (isBefore(date, service.rangeStart)) {
      return ''; // block remains `grey` for days the service did not exist yet
    }

    const outageKey = `${serviceId}:${dayNumber}`;
    const outageList = this.uptimeCache.outages.get(outageKey) || [];
    const majorOutageCount = outageList.reduce((count, o) => {
      return o.status === 'MAJOR_OUTAGE' ? count + 1 : count;
    }, 0);

    if (majorOutageCount > 0) {
      return 'error';
    }

    // any outage should be visible by its color
    return outageList.length > 0 ? 'warning' : 'success';
  }

  protected getServiceOutageDate(dayNumber: number): string {
    return formatDate(subDays(new Date(), dayNumber), 'MM/dd');
  }

  protected showOutageDetails(serviceId: string, dayNumber: number): void {
    this.showTooltip = false;
    this.tooltipTitle = '';
    this.tooltipCaption = '';

    const outageKey = `${serviceId}:${dayNumber}`;
    const outageList = this.uptimeCache.outages.get(outageKey);

    if (!outageList) {
      return; // 100% uptime, not need to popup outages
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const service = this.uptimeCache.services.get(serviceId)!; // every service is in the map!
    this.tooltipTitle = service.name;
    this.tooltipCaption = this.getServiceOutageDate(dayNumber);
    this.tooltipLines = outageList;
    this.showTooltip = true;
  }

  protected getOutageText(o: UptimeOutage): string {
    return i18n.t('uptime.outagePeriod', {
      status: getUptimeStatusText(o.status),
      from: formatDate(o.from, 'HH:mm'),
      duration: getOutageDuration(o),
    }) as string;
  }

  protected async mounted(): Promise<void> {
    this.isLoading = true;
    this.showError = false;
    try {
      await this.fetchAdminUptimeServices();
    } catch (err) {
      this.apiErrors = [`${err}`];
      this.showError = true;
      this.$log.warn(err);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.uptime-label {
  fill: #777777;
  font-size: 12px;
}

.scroll-horizontally {
  overflow-x: scroll;
}

.uptime-graph {
  cursor: default;

  rect {
    fill: #777777;
  }

  .success {
    fill: green;
  }

  .warning {
    fill: #10bb10;
    cursor: pointer;
  }

  .error {
    fill: red;
    cursor: pointer;
  }
}
</style>
