<script lang="ts">
import Component from 'vue-class-component';
import EnforceTimeframe from '@/modules/market-closed/components/EnforceTimeframe.vue';
import { VListItem } from 'vuetify/lib';

@Component({
  components: {
    VListItem,
  },
})
export default class AuroraListItem extends EnforceTimeframe {
  protected componentName = 'VListItem';
}
</script>
