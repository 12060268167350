<template>
  <div class="wrapper d-flex flex-column fill-height">
    <div class="gap-1 d-flex align-center">
      <simple-security-search
        class="security ml-2 my-2"
        clear-after-select
        label="Security"
        :value="selectedSecurity"
        @change="handleSecurityChange"
      />
    </div>

    <div class="d-flex flex-column flex">
      <dashboard-panel title="Depth of Book">
        <marketplace-depth-of-book as-broker :cusip="$route.params.cusip" />
      </dashboard-panel>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import MarketplaceDepthOfBook from '@/modules/marketplace/components/MarketplaceDepthOfBook.vue';
import SimpleSecuritySearch from '@/modules/manual-loan/components/SimpleSecuritySearch.vue';
import { Security } from '@/modules/common/models';
import { mapMutations } from 'vuex';
import { Watch } from 'vue-property-decorator';
import { DepthOfBookResponse } from '@/modules/marketplace/models';

@Component({
  components: {
    DashboardPanel,
    MarketplaceDepthOfBook,
    SimpleSecuritySearch,
  },
  methods: {
    ...mapMutations(['updateLastVisitedSymbolOverview']),
  },
})
export default class BrokerUserMarketplaceDepthOfBook extends Vue {
  // store methods
  protected updateLastVisitedSymbolOverview!: (security: Security) => void;

  protected selectedSecurity: Security | null = null;
  protected depthOfBook: DepthOfBookResponse | null = null;

  protected get depthOfBookItems(): DepthOfBookResponse['data'] {
    return this.depthOfBook ? this.depthOfBook.data : [];
  }

  @Watch('$route.params.cusip')
  protected updateSecurity(): void {
    // fix until we move from "hash" router to "history" router
    // in case user manually edits URL
    if (this.$route.params.cusip === undefined) {
      window.location.reload();
      return;
    }
    void this.fetch();
  }

  protected mounted(): void {
    void this.fetch();
  }

  protected async fetch(): Promise<void> {
    await this.fetchSecurity();
    this.updateLastVisitedSymbolOverview(this.selectedSecurity as Security);
  }

  protected async fetchSecurity(): Promise<void> {
    if (
      this.selectedSecurity === null ||
      this.selectedSecurity.cusip !== this.$route.params.cusip
    ) {
      this.selectedSecurity = await this.$api.manualLoans.fetchSecurityByCusip(
        this.$route.params.cusip
      );
    }
  }

  protected handleSecurityChange(security: Security | null): void {
    // Only change the page if user has selected a new security
    // (i.e. do nothing if user unselects security)
    if (security) {
      void this.$router.push({
        name: 'broker-user.client-monitor.marketplace-depth-of-book',
        params: { cusip: security.cusip },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}

.security {
  max-width: 300px;
}

.gap-1 {
  gap: 1rem;
}
</style>
