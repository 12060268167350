import { ConnectError } from '@connectrpc/connect';
import { ErrDetail } from './gen/common/protos/types_pb';
import { PlainMessage } from './message';
import { Message } from '@bufbuild/protobuf';

const knownErrors = new Set([
  // login
  'LoginIncorrect',
  'LoginTooManyAttempts',
  'Login2FARequired',
  'LoginConfirmIdentityRequired',
  'LoginConfirmIPAddrRequired',
  'InvalidActivationCode',
  'ExpiredActivationCode',
  'LoginInvalidPin',
  'LoginInvalidPinAlreadyUsed',

  // JWT
  'MissingAuthToken',
  'InvalidAuthToken',
  'RefreshTokenFailed',
  'ExpiredSession',

  // SSO
  'MissingSSOMetadata',
  'MissingSSOEntityDescriptor',
  'MissingSSOEntityId',
  'MissingSSOCertificate',
  'PermissionDenied',
  'RemovingSSOWasUnsuccessful',
  'InvalidXML',
  'FetchingMetadataFailed',
  'UserNotApproved',
] as const);

type KnownError = typeof knownErrors extends Set<infer T> ? T : never;

export type Data<T> = T extends Message<infer M> ? PlainMessage<M> : T;

export type ConnectResult<T, E extends `rest.${KnownError}` = `rest.${KnownError}`> =
  | {
      readonly success: false;
      readonly error: E;
      readonly details?: undefined;
    }
  | {
      readonly success: false;
      readonly error: 'rest.ReportToEngineering';
      readonly details: { uuid: string };
    }
  | {
      readonly success: true;
      readonly data: Data<T>;
    };

export async function getConnectResult<T>(action: () => Promise<T>): Promise<ConnectResult<T>> {
  try {
    const data = (await action()) as Data<T>;
    return { success: true, data };
  } catch (e: unknown) {
    let data: ConnectResult<T> = {
      success: false,
      error: 'rest.ReportToEngineering',
      details: { uuid: e instanceof Error ? e.name : typeof e === 'string' ? e : 'unknown' },
    };
    if (e instanceof ConnectError) {
      const details = e.findDetails(ErrDetail);
      if (details.length > 0) {
        const error = details[0].reason as KnownError;
        if (knownErrors.has(error)) {
          data = { success: false, error: `rest.${error}` };
        }
      } else {
        data = {
          success: false,
          error: `rest.ReportToEngineering`,
          details: { uuid: e.message },
        };
      }
    }
    return data;
  }
}
