<template>
  <div class="actions d-flex justify-end">
    <btn-dropdown
      color="secondary"
      data-test="actions-dropdown"
      :disabled="!hasOpsUserRole"
      main-text="Actions"
      x-small
    >
      <v-list v-if="item.side === 'LENDER'" ref="dropdown-actions" dense>
        <aurora-list-item
          data-test="agg-recall-menu-item"
          timeframe="recallLoans"
          @click="$emit('open-dialog', 'recall', item)"
        >
          <v-list-item-title> Recall</v-list-item-title>
        </aurora-list-item>
      </v-list>
      <v-list v-if="item.side === 'BORROWER'" ref="dropdown-actions" dense>
        <aurora-list-item
          data-test="agg-return-menu-item"
          timeframe="settleLoans"
          @click="$emit('open-dialog', 'return', item)"
        >
          <v-list-item-title> Return</v-list-item-title>
        </aurora-list-item>
      </v-list>
    </btn-dropdown>
  </div>
</template>

<script lang="ts">
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { AggregatedLoanByCounterpartyItem, AggregatedLoanBySecurityItem } from '@/utils/api/loans';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

@Component({
  components: {
    BtnDropdown,
  },
  props: {
    item: {
      type: Object as PropType<AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['hasOpsUserRole']),
  },
})
export default class OpenLoansTableAggregateActions extends Vue {
  // props
  protected item!: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem;

  // store state
  protected readonly hasOpsUserRole!: boolean;

  protected openDialog(
    action: MultipleLoanAction,
    item: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem
  ): void {
    if ('ticker' in item) {
      this.$emit('open-dialog', {
        action,
        equity: { ticker: item.ticker, cusip: item.cusip },
      });
    } else {
      this.$emit('open-dialog', {
        action,
        counterparty: item.counterparty,
      });
    }
  }
}
</script>

<style lang="scss" scoped></style>
