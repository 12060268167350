<template>
  <v-select
    :autofocus="autofocus"
    :disabled="disabled"
    item-text="text"
    item-value="value"
    :items="settlementTypeOptions"
    :label="label"
    :placeholder="placeholder"
    :value="value"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { SettlementType } from '@/modules/marketplace/types/marketplace';
import { mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';

@Component({
  props: {
    value: String as PropType<SettlementType>,
    disabled: Boolean,
    autofocus: Boolean,
    label: String,
    placeholder: String,
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class SettlementTypeSelect extends Vue {
  protected readonly clientConfig!: ClientConfig;
  protected readonly value!: SettlementType;
  protected readonly disabled?: boolean;
  protected readonly autofocus?: boolean;
  protected readonly label?: string;
  protected readonly placeholder?: string;

  protected get settlementTypeOptions(): Array<{ text: string; value: SettlementType }> {
    const options: Array<{ text: string; value: SettlementType }> = [
      { value: 'NSCC', text: 'NSCC' },
    ];

    if (this.clientConfig.bilateralLoansEnabled) {
      options.push({ value: 'BILATERAL', text: 'Bilateral' });
    }
    if (this.clientConfig.occBilateralLoansEnabled) {
      options.push({ value: 'OCC', text: 'OCC' });
    }

    return options;
  }
}
</script>
