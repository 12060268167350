<template>
  <div class="d-flex flex aurora-desktop-widget" :class="{ 'is-collapsed': localIsCollapsed }">
    <v-card class="flex d-flex flex-column">
      <system-bar
        :is-collapsed="localIsCollapsed"
        :no-collapse="noCollapse"
        :show-close-icon="showCloseIcon"
        :title="title"
        @close="$emit('close', true)"
        @update:isCollapsed="handleSystemBarEvent($event)"
      />
      <!-- Default slot for content of the panel -->
      <transition name="fade">
        <div v-show="!localIsCollapsed" class="d-flex flex-column flex-fill panel-content">
          <slot></slot>
        </div>
      </transition>
    </v-card>
  </div>
</template>

<script lang="ts">
import SystemBar from '@/modules/common/components/SystemBar.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

/**
 * Dashboard Panel wrapper component
 * Used for dashboard panels that have a title, flex to fill available space and are collapsible.
 *
 * The collapse feature can be disabled via the "no-collapse" prop.
 */
@Component({
  components: {
    SystemBar,
  },
  props: {
    /**
     * Title to display in the header section
     */
    title: String,
    /**
     * Disable the collapse feature and hide the icon
     */
    'no-collapse': Boolean,
    /**
     * Indicates if component is collapsed
     */
    isCollapsed: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Show a close icon which will emit a "close" event
     */
    showCloseIcon: Boolean,
  },
})
export default class DashboardPanel extends Vue {
  // props
  protected readonly title!: string;
  protected readonly noCollapse!: boolean;
  protected readonly isCollapsed!: boolean;
  protected readonly showCloseIcon!: boolean;

  protected localIsCollapsed = false;

  @Watch('isCollapsed')
  protected handleSystemBarEvent(isCollapsed: boolean): void {
    this.localIsCollapsed = isCollapsed;
    this.$emit('update:isCollapsed', isCollapsed);
  }

  protected mounted(): void {
    this.localIsCollapsed = this.isCollapsed;
  }
}
</script>

<style lang="scss" scoped>
// fix: vuetify uses !important on display which overrides v-show directive
*[style*='display: none'] {
  display: none !important;
}
.aurora-desktop-widget {
  // set a default height; allowed to grow with flex
  height: 15rem;
  max-height: 100%;
  padding: 0.25rem;

  transition: max-height 350ms ease-in-out;

  .v-system-bar {
    user-select: none;
    min-height: 2rem;
  }
  .panel-content {
    overflow-y: auto;
  }

  // collapse the panel down to just the header bar
  &.is-collapsed {
    max-height: 2.7rem;

    .panel-content {
      overflow: hidden;
      height: 0;
    }
  }

  > div {
    border: solid #4a4a4a 1px !important;
    border-radius: 4px !important;
  }

  // General Transitions and Animations
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
