<template>
  <div>
    <dashboard-panel title="Your Account">
      <div class="pa-6">
        <p>{{ $t('tradingDisabled') }}</p>
        <p>
          Please contact
          <a :href="`mailto:${clientConfig.systemBrokerContactEmail}`">
            {{ clientConfig.systemBrokerContactEmail }}</a
          >
          for more information.
        </p>
      </div>
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import { ClientConfig } from '@/utils/helpers/rest';
import { mapState } from 'vuex';

@Component({
  components: {
    DashboardPanel,
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class NoTrading extends Vue {
  private clientConfig!: ClientConfig;
}
</script>

<style lang="scss"></style>
