<template>
  <v-text-field
    ref="volume"
    v-model="volumeStr"
    :autofocus="autofocus"
    :class="['auction-number-input', { 'text-danger': volumeErr }]"
    color="form-field"
    :disabled="disabled"
    :label="label"
    type="text"
    @blur="handleBlur"
    @focus="handleFocus"
    @keyup.down="handleStep(-1)"
    @keyup.exact="handleKeyup"
    @keyup.m="handleHotkey()"
    @keyup.t="handleHotkey()"
    @keyup.up="handleStep(+1)"
  ></v-text-field>
</template>

<script lang="ts">
/**
 * VolumeInput is a small component for editing and formatting volumes
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getVolumeAsString, parseVolume } from '@/utils/helpers/auction-numbers';
import i18n from '@/localisation/i18n';

@Component({
  props: {
    disabled: Boolean,
    label: String,
    ticket: Object,
    volume: Number,
    autofocus: Boolean,
    step: {
      type: Number,
      default: 1,
    },
  },
})
export default class VolumeInput extends Vue {
  // props
  protected readonly ticket!: Record<string, unknown>;
  protected readonly volume!: number;
  protected readonly step!: number;

  private reUnformattedVolume = /,/gi;
  private reBeautifiedVolume = /[^0-9,]/gi;

  private volumeStr!: string;
  private volumeErr!: string;

  // volume changed by parent component; reformat display string
  @Watch('volume')
  protected onVolume(newVolume: number): void {
    this.volumeStr = getVolumeAsString(Number(newVolume));
  }

  @Watch('volumeErr')
  protected onError(newError: string): void {
    this.$emit('changed-error', newError, this.ticket);
  }

  protected data(): { volumeStr: string; volumeErr: string } {
    return {
      volumeStr: getVolumeAsString(Number(this.volume)),
      volumeErr: '',
    };
  }

  protected handleFocus(): void {
    // remove thousand separators from the input
    this.volumeStr = this.volumeStr.replace(this.reUnformattedVolume, '');
  }

  protected handleBlur(): void {
    this.formatInput(0);
  }

  protected handleStep(direction: number): void {
    this.formatInput(direction * this.step);
  }

  protected handleHotkey(): void {
    const reT = /t/gi;
    const reM = /m/gi;
    this.volumeStr = this.volumeStr.replace(reT, '000').replace(reM, '000000');
    if (parseInt(this.volumeStr, 10) === 0) {
      this.volumeStr = '0';
    }
    this.formatInput(0);
  }

  protected handleKeyup(): void {
    this.volumeErr = '';
    const tmpVolume = this.volumeStr.replace(this.reBeautifiedVolume, '');
    if (tmpVolume !== this.volumeStr) {
      this.volumeErr = i18n.tc('invalid.volume');
      return;
    }
    if (this.volumeStr === '') {
      this.volumeErr = i18n.tc('empty.volume');
      return;
    }

    const curVolume = parseVolume(this.volumeStr);
    if (curVolume !== undefined) {
      this.$emit('changed-volume', curVolume, this.ticket);
    }
  }

  protected formatInput(change: number): void {
    // don't format the volume if there is an error, giving the user the change to correct
    if (this.volumeErr !== '') {
      return;
    }

    const curVolume = parseVolume(this.volumeStr);
    if (curVolume === undefined) {
      this.volumeErr = i18n.tc('invalid.volume');
      return;
    }
    let newVolume = curVolume;
    if (change !== 0) {
      newVolume = change < this.step ? curVolume + this.step : curVolume + change;
    }

    this.volumeStr = getVolumeAsString(newVolume);
    this.$emit('changed-volume', newVolume, this.ticket);
  }
}
</script>
