<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        content-class="au-popup-dialog"
        max-width="800px"
        no-click-animation
        not-persistent
        overlay-color="secondary"
        overlay-opacity="0.80"
        :value="true"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
      >
        <v-form novalidate @submit.prevent>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('borrowerLocate.title') }}</span>
              <v-spacer></v-spacer>
              <span class="text--primary headline-2">{{ securityTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <security-selector
                      v-model="securitySelected"
                      color="form-field"
                      :error-messages="clErrors.security"
                      :label="$t('security')"
                      :placeholder="$t('securityBorrowerLocate.tip')"
                    ></security-selector>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="py-1 pl-12">
                <v-row>
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col cols="4">
                        {{ $t('Quantity') }}
                      </v-col>
                      <v-col class="py-0">
                        <volume-input
                          :ref="`volume-${locate.id}`"
                          :step="1"
                          tabindex
                          :volume="locate.quantity"
                          @changed-error="onVolumeError"
                          @changed-volume="onVolumeChange"
                        ></volume-input>
                      </v-col>
                    </v-row>
                    <v-row v-if="clErrors.volume" align="center">
                      <v-col class="pa-0 px-1" offset="4">
                        <v-alert class="py-1" color="error" dense text>
                          {{ clErrors.volume }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeDialog">
                {{ $t('cancelButton') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" min-width="150" type="submit" @click="createLocate">
                <v-icon left>mdi-table-search</v-icon>
                {{ $t('locateButton') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { isEmpty } from 'lodash';

import SecuritySelector from '@/modules/orders/components/SecuritySelector.vue';
import VolumeInput from '@/modules/common/components/format-volume/VolumeInput.vue';
import PriceInput from '@/modules/common/components/format-price/PriceInput.vue';

import { ClientConfig, Direction, RestOperation } from '@/utils/helpers/rest';
import { FormValidator, setInputFocus } from '@/utils/helpers/input-form';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import RateInput from '@/modules/common/components/format-rate/RateInput.vue';
import { AuctionSecurity } from '@/modules/auction/models';
import { BorrowerLocate } from '@/modules/borrower/models';

@Component({
  components: {
    RateInput,
    SecuritySelector,
    VolumeInput,
    PriceInput,
  },
  props: {},
  data: () => ({
    RATE_PRECISION: RATE_PRECISION,
    BORROW: Direction.Borrow,
    LEND: Direction.Lend,

    locate: {},

    securitySelected: null,
  }),
  methods: {
    ...mapActions(['submitBorrowerLocate']),
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class BorrowerLocateDialog extends Vue {
  public $refs!: {
    security: HTMLFormElement;
    volume: HTMLFormElement;
    priceLimit: HTMLFormElement;
  };

  // region store methods
  protected submitBorrowerLocate!: (payload: {
    locate: BorrowerLocate;
    crudAction: RestOperation;
  }) => Promise<BorrowerLocate>;

  // endregion

  // region store refs
  protected clientConfig!: ClientConfig;

  // endregion

  // region data
  protected locate: BorrowerLocate = BorrowerLocate.create();

  // endregion

  // region search & security derived properties
  protected processing = false;
  protected securityQuery = '';
  protected eqDescriptionLimit = 60;
  protected securityName = '';
  protected securityTitle = '';
  // endregion

  protected clErrors: { [index: string]: string } = {};

  @Watch('securitySelected')
  protected onChangedSecurity(selected: AuctionSecurity): void {
    if (selected) {
      this.locate.security = selected;
      this.renderSelectedSecurity(selected);
      this.clErrors.security = '';
    }
  }

  protected mounted(): void {
    setInputFocus(this.$refs.security);
  }

  protected onVolumeChange(volume: number): void {
    this.locate.quantity = volume;
  }

  protected onVolumeError(_errorStr: string): void {
    // @TODO
  }

  protected renderSelectedSecurity(security: AuctionSecurity): void {
    this.securityName = security.name;
    this.securityTitle = `${security.name}`;
    this.securityQuery = this.formatSecurityQuery(security);
  }

  protected formatSecurityQuery(security: AuctionSecurity): string {
    const description = `${security.name} / ${security.cusip}`;
    return description.length > this.eqDescriptionLimit
      ? description.slice(0, this.eqDescriptionLimit) + '...'
      : description;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async closeDialog(): Promise<void> {
    this.$emit('close-modal');
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async createLocate(): Promise<void> {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      this.clErrors = new FormValidator(this.locate, ['security']).check();

      if (!isEmpty(this.clErrors)) {
        // jump to the first error and abort
        const focusRef = this.$refs[Object.keys(this.clErrors)[0]];
        if (focusRef) {
          setInputFocus(focusRef);
        }
        return;
      }

      this.confirmCreateLocate();
    } finally {
      this.processing = false;
    }
  }

  protected confirmCreateLocate(): void {
    this.$dialog.ask({
      title: 'Confirm Locate',
      message: 'Are you sure you want to submit this locate?',
      positionAt: 'bottom',
      acceptText: 'Yes, Submit Locate',
      onAccept: () => this.submitLocate(),
      rejectText: 'No, Review Locate',
    });
  }

  protected async submitLocate(): Promise<void> {
    try {
      await this.submitBorrowerLocate({
        crudAction: RestOperation.Create,
        locate: this.locate,
      });
      await this.closeDialog();
    } catch (e) {
      alert(e);
    }
  }
}
</script>

<style lang="scss">
.test {
  border: solid green 2px;
}
</style>

<style lang="scss" scoped>
// make the dropdown of <v-autocomplete> stick out
.v-autocomplete__content.v-menu__content .v-select-list {
  background-color: #485c66;
}
</style>
