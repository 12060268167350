<script lang="ts">
import Component from 'vue-class-component';
import EnforceTimeframe from '@/modules/market-closed/components/EnforceTimeframe.vue';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';

@Component({
  components: {
    BtnDropdown,
  },
})
export default class AuroraBtnDropdown extends EnforceTimeframe {
  protected componentName = 'BtnDropdown';
}
</script>
