import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors/api-error';

/**
 * Bad Request Error (400)
 *
 * The request could not be fulfilled with the provided input
 */
export class BadRequestError<T = Api.ErrorResponse> extends ApiError<T> {
  public constructor(
    public message: string,
    public responseData?: T
  ) {
    super(message);
    this.name = 'BadRequestError';
  }
}
