import { ApiService } from '@/modules/common/services/api.service';
import { Api } from '@/modules/common/types/api';
import Vue from 'vue';

export class MarketdataApiService extends ApiService {
  private featureUrl = '/marketdata';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.marketdata = singleton;
    } else {
      v.prototype.$api = {
        marketdata: singleton,
      };
    }
  }

  /**
   * Get benchmarks and prices
   */
  public async getBenchmarkPrices(): Promise<Api.MarketData.BenchmarkPriceListResponse> {
    const url = this.baseUrl + this.featureUrl + '/benchmarks';
    const { data } = await this.axios.get<Api.MarketData.BenchmarkPriceListResponse>(url);
    return data;
  }
}
