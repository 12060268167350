import { AuctionSecurity } from '@/modules/auction/models';

const DEFAULT_EQUITY_DESCRIPTION_LIMIT = 60;

export function formatSecurityDescription(
  security: AuctionSecurity,
  securityDescriptionLimit?: number
): string {
  securityDescriptionLimit = securityDescriptionLimit ?? DEFAULT_EQUITY_DESCRIPTION_LIMIT;
  const description = security.name
    ? `${security.name} ${security.ticker} [${security.cusip}]`
    : `${security.ticker} [${security.cusip}]`;
  const nTooLong = description.length - securityDescriptionLimit;
  return nTooLong > 0
    ? `${security.name.slice(0, security.name.length - nTooLong)}... ${security.ticker} [${security.cusip}]`
    : description;
}
