import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, RawCompanyInfo, RawSecurity, Security } from '@/modules/common/models';
import { Benchmark } from '@/utils/api/loans';
import Decimal from 'decimal.js';

export type RawLoanRequestUploadResponse = Raw<
  LoanRequestUploadResponse,
  { counterparty: RawCompanyInfo; requestItems: RawLoanRequestUploadResponseItem[] }
>;

export class LoanRequestUploadResponse {
  public counterparty: CompanyInfo | null;
  public benchmark: Benchmark | null;
  public requestItems: LoanRequestUploadResponseItem[];
  public parseStatus: string;

  protected constructor(data: RawLoanRequestUploadResponse) {
    this.counterparty = CompanyInfo.fromData(data.counterparty);
    this.benchmark = data.benchmark;
    this.requestItems = data.requestItems.map(LoanRequestUploadResponseItem.fromData);
    this.parseStatus = data.parseStatus;
  }

  public static fromData(data: RawLoanRequestUploadResponse): LoanRequestUploadResponse {
    return new LoanRequestUploadResponse(data);
  }

  public static mock(data?: DeepPartial<RawLoanRequestUploadResponse>): LoanRequestUploadResponse {
    return LoanRequestUploadResponse.fromData(LoanRequestUploadResponse.mockData(data));
  }

  public static mockData(
    data?: DeepPartial<RawLoanRequestUploadResponse>
  ): RawLoanRequestUploadResponse {
    const { counterparty, requestItems, ...rest } = data ?? {};

    return {
      counterparty: CompanyInfo.mockData(counterparty),
      benchmark: null,
      requestItems: requestItems?.map(LoanRequestUploadResponseItem.mockData) ?? [],
      parseStatus: 'success',

      ...rest,
    };
  }
}

export type RawLoanRequestUploadResponseItem = Raw<
  LoanRequestUploadResponseItem,
  { equity: RawSecurity | null },
  'security'
>;

export class LoanRequestUploadResponseItem {
  public id: number;
  public security: Security | null;
  public quantity: number | null;
  public rate: Decimal | null;
  public notional: Decimal | null;

  protected constructor(data: RawLoanRequestUploadResponseItem) {
    this.id = data.id;
    this.security = Security.fromData(data.equity);
    this.quantity = data.quantity;
    this.rate = data.rate === null ? null : new Decimal(data.rate);
    this.notional = data.notional === null ? null : new Decimal(data.notional);
  }

  public static fromData(data: RawLoanRequestUploadResponseItem): LoanRequestUploadResponseItem {
    return new LoanRequestUploadResponseItem(data);
  }

  public static mockData(
    data?: DeepPartial<RawLoanRequestUploadResponseItem>
  ): RawLoanRequestUploadResponseItem {
    const { equity, ...rest } = data ?? {};

    return {
      id: 0,
      equity: Security.mockData(equity),
      quantity: null,
      rate: null,
      notional: null,

      ...rest,
    };
  }
}
