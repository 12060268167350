import Decimal from 'decimal.js';

export function getItemPosition(
  lastClosePrice: Decimal,
  quantity: number,
  contractValue: Decimal
): Decimal {
  if (quantity === 0) {
    return new Decimal(0);
  }
  return lastClosePrice.times(quantity).dividedBy(contractValue).times(100);
}
