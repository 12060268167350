import { Pbkdf2HmacSha256 } from '@lib/asmcrypto';
import { CryptoSubtle } from '@/modules/common/services/crypto/crypto-subtle';
import { Pbkdf2Service } from '@/modules/common/services/crypto/pbkdf2-service';

export class Pbkdf2AsmCrypto implements Pbkdf2Service {
  private static isSupportedValue = false;
  private static isSupportedChecked = false;
  private static isSupportedPromise: Promise<boolean>;
  private readonly subtle: CryptoSubtle;

  public constructor(subtle: CryptoSubtle) {
    this.subtle = subtle;
  }

  public pbkdf2(
    pw: Buffer,
    salt: Buffer,
    iterations: number,
    keySizeBytes: number
  ): Promise<Buffer> {
    const key: Buffer = Buffer.from(Pbkdf2HmacSha256(pw, salt, iterations, keySizeBytes));
    return Promise.resolve(key);
  }

  public isSupported(): Promise<boolean> {
    if (Pbkdf2AsmCrypto.isSupportedChecked) {
      return Promise.resolve(Pbkdf2AsmCrypto.isSupportedValue);
    } else {
      if (Pbkdf2AsmCrypto.isSupportedPromise === undefined) {
        Pbkdf2AsmCrypto.isSupportedPromise = this.isSupportedCheck();
      }
      return Promise.resolve(Pbkdf2AsmCrypto.isSupportedPromise);
    }
  }

  private isSupportedCheck(): Promise<boolean> {
    const hasCryptoMethods = typeof Pbkdf2HmacSha256 === 'function';

    if (!hasCryptoMethods) {
      return Promise.resolve(false);
    }

    return Promise.resolve(
      this.pbkdf2(Buffer.from('password', 'utf8'), Buffer.from('salt', 'utf8'), 1, 8)
        .then(
          (r: Buffer) => {
            Pbkdf2AsmCrypto.isSupportedValue = r.toString('hex') === '120fb6cffcf8b32c';
            Pbkdf2AsmCrypto.isSupportedChecked = true;
          },
          () => {
            Pbkdf2AsmCrypto.isSupportedValue = false;
            Pbkdf2AsmCrypto.isSupportedChecked = true;
          }
        )
        .then(() => {
          return Pbkdf2AsmCrypto.isSupportedValue;
        })
    );
  }
}
