import { type ShallowRef, computed, shallowRef, watch } from 'vue';

export function getRefName(name: string): string {
  return `StoredTabSyncRef_${name}`;
}

export function useStoredTabSyncRef<T>(name: string, defaultValue: T): ShallowRef<T> {
  const deafultValueStr = JSON.stringify(defaultValue);
  const refName = getRefName(name);
  const valueStr = shallowRef(localStorage.getItem(refName) ?? deafultValueStr);
  const valueRef = computed<T>({
    get() {
      return JSON.parse(valueStr.value);
    },
    set(value) {
      valueStr.value = JSON.stringify(value);
      localStorage.setItem(refName, valueStr.value);
    },
  });
  const channel = new BroadcastChannel(refName);
  channel.addEventListener('message', (event) => {
    const valueStrRecieved = event.data;
    // we already have it, no action needed
    if (valueStrRecieved === valueStr.value) return;
    valueStr.value = event.data ?? deafultValueStr;
  });
  watch(valueStr, (valueNew) => channel.postMessage(valueNew));
  return valueRef;
}
