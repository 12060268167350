import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import { BrowserTracing } from '@sentry/tracing';
import router from '@/router/router';
import { ClientConfig } from '@/utils/helpers/rest';

export function initSentry(cfg: ClientConfig): void {
  Sentry.init({
    Vue,
    dsn: cfg.sentryServerDSN,
    environment: cfg.sentryEnvironment,
    release: cfg.systemRelease,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    ignoreErrors: [
      'NotAuthorisedError',
      'NotAuthorizedError',
      'Not logged in',
      'Authentication failed',
      'Permission denied',
      /Request failed with status code (40\d|50\d)/,
      /Redirected when going from ".+?" to ".+?" via a navigation guard\./,
    ],
    tracesSampleRate: 0,
  });
}

if (process.env.NODE_ENV !== 'test') {
  Vue.config.errorHandler = (error, _vm, _info) => {
    /* eslint-disable no-console */
    console.error(error); // log error to browser console as well as to sentry
  };
}
