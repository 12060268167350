<template>
  <div class="home">
    <section class="hero is-halfheight is-bold">
      <div class="hero-body">
        <div class="container">
          <h1>{{ $t('404-title') }}</h1>
          <h2 class="subtitle">{{ $t('404-subtitle') }}</h2>
          <div>
            <router-link v-if="loginState.user" class="button is-primary is-medium" to="/dashboard"
              >{{ $t('Continue to Your Dashboard') }}
            </router-link>
            <router-link v-else class="button is-primary is-medium" to="/login"
              >{{ $t('getStarted') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { LoginState } from '@/store/store';

@Component({
  props: {
    redirectedFrom: {
      type: String,
    },
  },
  computed: {
    ...mapState(['loginState']),
  },
})
export default class PageNotFound extends Vue {
  // store state refs
  protected loginState!: LoginState;
  protected redirectedFrom!: string | undefined;

  protected mounted(): Promise<void> {
    let requestedPage = '?';
    if (this.redirectedFrom !== undefined) {
      requestedPage = this.redirectedFrom;
    }
    throw new Error('Page Not Found: [' + requestedPage + ']');
  }
}
</script>

<style lang="scss" scoped>
.home {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
