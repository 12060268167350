import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';
import Decimal from 'decimal.js';
import { Benchmark } from '@/utils/api/loans';
import { Api } from '@/modules/common/types/api';

export class SecLendingApiService extends ApiService {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.secLending = singleton;
    } else {
      v.prototype.$api = {
        secLending: singleton,
      };
    }
  }

  /**
   * Batch renegotiate multiple loans
   */
  public async batchCancelPendingLoans(
    ids: string[]
  ): Promise<Api.SecLendingLoans.BatchActionResponse> {
    const url = this.baseUrl + `/pending-loans/cancel`;
    // HTTP delete should not have request bodies (enforced by axios types)
    const { data } = await this.axios.put<Api.SecLendingLoans.BatchActionResponse>(url, ids);
    return data;
  }

  public async batchRejectRenegotiateAndCounterLoans(
    side: string,
    ids: string[],
    newRate: Decimal,
    newRateModifier: Benchmark
  ): Promise<Api.SecLendingLoans.BatchActionResponse> {
    const resReject = await this.batchRespondToRenegotiateLoans(side, ids, false);
    const failedIds = resReject.failedItems.map((i) => i.requestId);
    // it doesn't make sense to try to "rerate" if "reject" already failed
    const remainingIds = ids.filter((id) => !failedIds.includes(id));

    const resRenegotiate = await this.batchRenegotiateLoans(
      side,
      remainingIds,
      newRate,
      newRateModifier
    );

    return {
      failedItems: [...resReject.failedItems, ...resRenegotiate.failedItems],
    };
  }

  /**
   * Batch renegotiate multiple loans
   */
  public async batchRenegotiateLoans(
    side: string,
    ids: string[],
    newRate: Decimal,
    newRateModifier: Benchmark
  ): Promise<Api.SecLendingLoans.BatchActionResponse> {
    const url = this.baseUrl + `/renegotiate/${side}/loans`;
    const requests = ids.map((id) => {
      return {
        id: id,
        newRate: newRate,
        newRateModifier: newRateModifier,
      };
    });

    const { data } = await this.axios.post<Api.SecLendingLoans.BatchActionResponse>(url, requests);
    return data;
  }

  /**
   * Batch accept/reject renegotiate multiple loans
   */
  public async batchRespondToRenegotiateLoans(
    side: string,
    ids: string[],
    accepted: boolean
  ): Promise<Api.SecLendingLoans.BatchActionResponse> {
    const url = this.baseUrl + `/renegotiate/${side}/loans/respond`;
    const request: Api.SecLendingLoans.LoanRespondToRenegotiateRequest = { accepted, ids };

    const { data } = await this.axios.post<Api.SecLendingLoans.BatchActionResponse>(url, request);
    return data;
  }

  /**
   * Batch cancel renegotiate multiple loans
   */
  public async batchCancelRenegotiateLoans(
    side: string,
    ids: string[]
  ): Promise<Api.SecLendingLoans.BatchActionResponse> {
    const url = this.baseUrl + `/renegotiate/${side}/loans/cancel`;

    // HTTP delete should not have request bodies (enforced by axios types)
    const { data } = await this.axios.put<Api.SecLendingLoans.BatchActionResponse>(url, ids);
    return data;
  }
}
