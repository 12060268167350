import { AuroraError } from './aurora-error';

/**
 * API Error
 *
 * Generic error thrown when something goes wrong with the API
 * Use when a more specific error, like PermissionDenied (403) or NotFound (404), is not appropriate
 */
export class ApiError<T = Record<string, unknown>> extends AuroraError {
  public constructor(
    public message: string,
    public responseData?: T
  ) {
    super(message);
    this.name = 'ApiError';
  }

  public toString(): string {
    if (this.message !== '') {
      return this.message;
    }

    // when there is no error message we can only tell it is an error
    return `${this.name}: Unexpected error`;
  }
}
