import { CryptoSubtle } from '@/modules/common/services/crypto/crypto-subtle';
import { Pbkdf2Service } from '@/modules/common/services/crypto/pbkdf2-service';

export class Pbkdf2WebCrypto implements Pbkdf2Service {
  private static isSupportedValue = false;
  private static isSupportedChecked = false;
  private static isSupportedPromise: Promise<boolean>;
  private readonly subtle: CryptoSubtle;

  public constructor(subtle: CryptoSubtle) {
    this.subtle = subtle;
  }

  public pbkdf2(
    pw: Buffer,
    salt: Buffer,
    iterations: number,
    keySizeBytes: number
  ): Promise<Buffer> {
    return this.importKey(pw).then((key: CryptoKey) => {
      const bits = this.deriveBits(key, salt, iterations, keySizeBytes * 8);
      return bits;
    });
  }

  public isSupported(): Promise<boolean> {
    if (Pbkdf2WebCrypto.isSupportedChecked) {
      return Promise.resolve(Pbkdf2WebCrypto.isSupportedValue);
    } else {
      if (Pbkdf2WebCrypto.isSupportedPromise === undefined) {
        Pbkdf2WebCrypto.isSupportedPromise = this.isSupportedCheck();
      }
      return Promise.resolve(Pbkdf2WebCrypto.isSupportedPromise);
    }
  }

  private async importKey(pw: Buffer): Promise<CryptoKey> {
    return this.subtle.importKey('raw', pw, { name: 'PBKDF2', hash: 'SHA-256' }, false, [
      'deriveBits',
    ]);
  }

  private async deriveBits(
    key: CryptoKey,
    salt: Buffer,
    iterations: number,
    bits: number
  ): Promise<Buffer> {
    const arr: ArrayBuffer = await this.subtle.deriveBits(
      {
        name: 'PBKDF2',
        salt: salt,
        iterations: iterations,
        hash: { name: 'SHA-256' },
      },
      key,
      bits || 256 /* AES key data must be 128 or 256! */
    );

    return Buffer.from(new Uint8Array(arr));
  }

  private isSupportedCheck(): Promise<boolean> {
    const hasCryptoMethods =
      typeof this.subtle !== 'undefined' &&
      typeof this.subtle.importKey !== 'undefined' &&
      typeof this.subtle.deriveBits !== 'undefined';

    if (!hasCryptoMethods) {
      return Promise.resolve(false);
    }

    return Promise.resolve(
      this.pbkdf2(Buffer.from('password', 'utf8'), Buffer.from('salt', 'utf8'), 1, 8)
        .then(
          (r: Buffer) => {
            Pbkdf2WebCrypto.isSupportedValue = r.toString('hex') === '120fb6cffcf8b32c';
            Pbkdf2WebCrypto.isSupportedChecked = true;
          },
          () => {
            Pbkdf2WebCrypto.isSupportedValue = false;
            Pbkdf2WebCrypto.isSupportedChecked = true;
          }
        )
        .then(() => {
          return Pbkdf2WebCrypto.isSupportedValue;
        })
    );
  }
}
