// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/venue/venue.proto (package aurora.core.apiengine.venuev1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { QueryMarketplaceInstrumentsRequest, QueryMarketplaceInstrumentsResponse, QueryMarketplaceRequest, QueryMarketplaceResponse, QueryOrderBookRequest, QueryOrderBookResponse } from "./venue_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service aurora.core.apiengine.venuev1.VenueService
 */
export const VenueService = {
  typeName: "aurora.core.apiengine.venuev1.VenueService",
  methods: {
    /**
     * @generated from rpc aurora.core.apiengine.venuev1.VenueService.QueryOrderBook
     */
    queryOrderBook: {
      name: "QueryOrderBook",
      I: QueryOrderBookRequest,
      O: QueryOrderBookResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.venuev1.VenueService.QueryMarketplace
     */
    queryMarketplace: {
      name: "QueryMarketplace",
      I: QueryMarketplaceRequest,
      O: QueryMarketplaceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.venuev1.VenueService.QueryMarketplaceInstruments
     */
    queryMarketplaceInstruments: {
      name: "QueryMarketplaceInstruments",
      I: QueryMarketplaceInstrumentsRequest,
      O: QueryMarketplaceInstrumentsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

