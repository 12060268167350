<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="1200px"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistents
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-card>
      <v-card-title>{{ service.name }}</v-card-title>
      <v-card-subtitle>Historical Overview</v-card-subtitle>
      <v-card-text>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  class="mr-4"
                  color="secondary"
                  :disabled="isCurrentMonth"
                  outlined
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn color="secondary" :disabled="isFirstMonth" fab small text @click="prevMonth">
                  <v-icon small> mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn color="secondary" :disabled="isLastMonth" fab small text @click="nextMonth">
                  <v-icon small> mdi-chevron-right</v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar" class="pl-4">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="360">
              <v-calendar
                ref="calendar"
                v-model="currDay"
                color="primary"
                type="month"
                @change="filterMonthOutages"
                @click:date="viewDay"
                @click:more="viewDay"
              >
                <template #day="{ past, date }">
                  <v-row justify="center">
                    <template v-if="past">
                      <v-sheet
                        v-for="(calendarEvent, i) in getCalendarEvents(date)"
                        :key="i"
                        class="mt-2 mb-1"
                        :color="getEventColor(calendarEvent)"
                        height="8"
                        rounded
                        :title="calendarEvent.name"
                        :width="8"
                      ></v-sheet>
                    </template>
                  </v-row>
                </template>
              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-subtitle>Past Incidents</v-card-subtitle>
      <v-card-text>
        <v-container class="scrollable-container">
          <template v-if="serviceOutages.length === 0">
            <span><strong>No incidents this month.</strong></span>
          </template>
          <template v-for="(reportDate, i) in eachDayOfService()" v-else>
            <template v-if="eachOutageInDay(reportDate).length > 0">
              <v-list :key="i" subheader two-line>
                <v-subheader>{{ getDayTitle(reportDate) }}</v-subheader>
                <v-list-item v-for="(outage, j) in eachOutageInDay(reportDate)" :key="j">
                  <v-timeline align-top class="outage-report pt-3" dense>
                    <v-timeline-item class="pt-0 pb-2" color="info" small>
                      <v-row no-gutters>
                        <v-col cols="2">
                          <strong>{{ getOutageTime(reportDate, outage.until) }}</strong>
                        </v-col>
                        <v-col>
                          <strong>Resolved</strong>
                          <div>
                            The incident status has changed to resolved after
                            {{ getOutageDuration(outage) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                    <v-timeline-item :color="getOutageStatusColor(outage.status)" small>
                      <v-row no-gutters>
                        <v-col cols="2">
                          <strong>{{ getOutageTime(reportDate, outage.from) }}</strong>
                        </v-col>
                        <v-col>
                          <strong>{{ getOutageStatusText(outage.status) }}</strong>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-list-item>
              </v-list>
            </template>
          </template>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import {
  UptimeCalendarBoundary,
  UptimeCalendarRange,
  UptimeOutage,
  UptimeService,
} from '@/utils/api/uptime';
import {
  getOutageDuration,
  getUptimeStatusColor,
  getUptimeStatusText,
} from '@/modules/uptime/helpers/uptime';
import {
  eachDayOfInterval,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  parse as parseDate,
  startOfMonth,
} from 'date-fns';
import { formatDate } from '@/utils/helpers/dates';
import { VCalendar as Calendar } from 'vuetify/lib';

interface CalendarEvent {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
}

@Component({
  props: {
    service: Object as PropType<UptimeService>,
  },
})
export default class UptimeServiceDialog extends Vue {
  public $refs!: {
    calendar: InstanceType<typeof Calendar> & { prev: () => void; next: () => void; title: string };
  };

  // props
  protected readonly service!: UptimeService;

  protected currDay = '';
  protected currMonth = startOfMonth(new Date());
  protected serviceOutages: UptimeOutage[] = [];

  protected get isFirstMonth(): boolean {
    return isSameMonth(this.currMonth, this.service.rangeStart);
  }

  protected get isLastMonth(): boolean {
    return isSameMonth(this.currMonth, this.service.rangeEnd);
  }

  protected get isCurrentMonth(): boolean {
    return isSameMonth(this.currMonth, new Date());
  }

  protected getCalendarEvents(day: string): CalendarEvent[] {
    const firstDay = parseDate(day as string, 'yyyy-MM-dd', new Date());
    return this.serviceOutages
      .filter(
        (o) => o.from.getDate() === firstDay.getDate() && o.from.getMonth() === firstDay.getMonth()
      )
      .map((o) => {
        return {
          name: getUptimeStatusText(o.status),
          start: o.from,
          end: o.until,
          color: getUptimeStatusColor(o.status),
          timed: false,
        };
      });
  }

  protected eachDayOfService(): Date[] {
    return eachDayOfInterval({
      start: this.service.rangeStart,
      end: this.service.rangeEnd,
    }).reverse();
  }

  protected eachOutageInDay(dt: Date): UptimeOutage[] {
    return this.serviceOutages.filter((o) => isSameDay(o.from, dt) || isSameDay(o.until, dt));
  }

  protected getDayTitle(dt: Date): string {
    return formatDate(dt, 'MMMM d yyyy');
  }

  protected getOutageTime(reportDate: Date, outageDate: Date): string {
    let timeFormat = 'HH:mm';
    if (!isSameDay(reportDate, outageDate)) {
      timeFormat = 'HH:mm (MMM dd)';
    }
    return formatDate(outageDate, timeFormat);
  }

  protected getOutageStatusColor(status: string): string {
    return getUptimeStatusColor(status);
  }

  protected getOutageStatusText(status: string): string {
    return getUptimeStatusText(status);
  }

  protected getOutageDuration(o: UptimeOutage): string {
    return getOutageDuration(o);
  }

  protected filterMonthOutages({ start: sof, end: eom }: UptimeCalendarRange): void {
    const firstDay = parseDate(sof.date as string, 'yyyy-MM-dd', new Date());
    const lastDay = parseDate(eom.date as string, 'yyyy-MM-dd', new Date());
    this.currMonth = firstDay;
    this.serviceOutages = this.service.outages.filter(
      (o) => !(isAfter(o.from, lastDay) || isBefore(o.until, firstDay))
    );
  }

  protected viewDay({ date }: UptimeCalendarBoundary): void {
    this.currDay = date;
  }

  protected getEventColor(event: CalendarEvent): string {
    return event.color;
  }

  protected setToday(): void {
    this.currDay = '';
  }

  protected prevMonth(): void {
    this.$refs.calendar.prev();
  }

  protected nextMonth(): void {
    this.$refs.calendar.next();
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>
<style lang="scss" scoped>
.scrollable-container {
  height: calc(100vh - 800px);
  overflow-y: auto;
}

.outage-report {
  width: 900px;
}
</style>
