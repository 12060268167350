<template>
  <v-card>
    <template v-if="$slots.title">
      <v-card-title class="mb-0">
        <div class="d-flex align-center">
          <span class="headline"><slot name="title" /></span>
        </div>
        <v-spacer />
      </v-card-title>
    </template>

    <v-card-text>
      <v-alert class="mt-4" dense type="error">
        There were some errors while processing the file. Please review the affected rows before
        uploading again.
      </v-alert>

      <div v-if="errors.length > 0" class="table-container mt-4">
        <div>
          <v-data-table
            disable-filtering
            disable-pagination
            disable-sort
            fixed-header
            :headers="headers"
            hide-default-footer
            item-key="key"
            :items="normalizedErrors"
            width="100%"
          >
            <template #item="{ item }">
              <slot name="item" v-bind="{ item }" />
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex">
      <div class="d-flex flex-grow-1 justify-space-between align-end">
        <v-btn class="mt-6" color="secondary" @click="$emit('back')"> Back </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import type { UploadMethod } from '@/modules/common/types/upload';

interface UploadErrorItem {
  row: number;
  field: string;
  errMsg: string;
}

@Component({
  props: {
    errors: Array as PropType<UploadErrorItem[]>,
    headers: Array as PropType<DataTableHeader[]>,
    uploadMethod: String as PropType<UploadMethod>,
  },
})
export default class UploadParseErrors extends Vue {
  // props
  protected readonly errors!: UploadErrorItem[];
  protected readonly headers!: DataTableHeader[];
  protected readonly uploadMethod!: UploadMethod;

  protected get normalizedErrors(): UploadErrorItem[] {
    return this.errors.map((error) => ({
      ...error,
      row: this.uploadMethod === 'paste' ? error.row - 1 : error.row,
    }));
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
