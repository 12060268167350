// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/loanrecallconsts.proto (package aurora.core.consts.loanrecall, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * LoanRecallStatus : the state a Recall is in now
 *
 * @generated from enum aurora.core.consts.loanrecall.LoanRecallStatus
 */
export enum LoanRecallStatus {
  /**
   * @generated from enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: NEW = 1;
   */
  NEW = 1,

  /**
   * @generated from enum value: OPEN = 2;
   */
  OPEN = 2,

  /**
   * @generated from enum value: PARTIALLY_RETURNED = 3;
   */
  PARTIALLY_RETURNED = 3,

  /**
   * @generated from enum value: FULLY_RETURNED = 4;
   */
  FULLY_RETURNED = 4,

  /**
   * @generated from enum value: PENDING_CANCEL = 5;
   */
  PENDING_CANCEL = 5,

  /**
   * @generated from enum value: CANCELED = 6;
   */
  CANCELED = 6,

  /**
   * @generated from enum value: REJECTED = 7;
   */
  REJECTED = 7,

  /**
   * @generated from enum value: PENDING_AMEND = 8;
   */
  PENDING_AMEND = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanRecallStatus)
proto3.util.setEnumType(LoanRecallStatus, "aurora.core.consts.loanrecall.LoanRecallStatus", [
  { no: 0, name: "UNSPECIFIED" },
  { no: 1, name: "NEW" },
  { no: 2, name: "OPEN" },
  { no: 3, name: "PARTIALLY_RETURNED" },
  { no: 4, name: "FULLY_RETURNED" },
  { no: 5, name: "PENDING_CANCEL" },
  { no: 6, name: "CANCELED" },
  { no: 7, name: "REJECTED" },
  { no: 8, name: "PENDING_AMEND" },
]);

/**
 * @generated from enum aurora.core.consts.loanrecall.LoanRecallRejectReason
 */
export enum LoanRecallRejectReason {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: OUTSIDE_SUPPORTED_HOURS = 1;
   */
  OUTSIDE_SUPPORTED_HOURS = 1,

  /**
   * @generated from enum value: UNSUPPORTED_MESSAGE_DETAILS = 2;
   */
  UNSUPPORTED_MESSAGE_DETAILS = 2,

  /**
   * @generated from enum value: UNKNOWN_LOAN_RECALL = 3;
   */
  UNKNOWN_LOAN_RECALL = 3,

  /**
   * @generated from enum value: LOAN_INELIGIBLE = 4;
   */
  LOAN_INELIGIBLE = 4,

  /**
   * @generated from enum value: INVALID_LOAN_RECALL_QTY = 5;
   */
  INVALID_LOAN_RECALL_QTY = 5,

  /**
   * @generated from enum value: UNKNOWN_LOAN_ID = 6;
   */
  UNKNOWN_LOAN_ID = 6,

  /**
   * @generated from enum value: OTHER = 7;
   */
  OTHER = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(LoanRecallRejectReason)
proto3.util.setEnumType(LoanRecallRejectReason, "aurora.core.consts.loanrecall.LoanRecallRejectReason", [
  { no: 0, name: "NONE" },
  { no: 1, name: "OUTSIDE_SUPPORTED_HOURS" },
  { no: 2, name: "UNSUPPORTED_MESSAGE_DETAILS" },
  { no: 3, name: "UNKNOWN_LOAN_RECALL" },
  { no: 4, name: "LOAN_INELIGIBLE" },
  { no: 5, name: "INVALID_LOAN_RECALL_QTY" },
  { no: 6, name: "UNKNOWN_LOAN_ID" },
  { no: 7, name: "OTHER" },
]);

