import Decimal from 'decimal.js';
import { ensureDate, ensureDecimal } from '@/utils/api/helpers';
import { differenceInCalendarDays } from 'date-fns';

export interface UptimeService {
  id: string;
  name: string;
  status: string;
  rangeStart: Date;
  rangeEnd: Date;
  percentage: Decimal;
  outages: UptimeOutage[];
  days: number;
}

export interface UptimeOutage {
  status: string;
  from: Date;
  until: Date;
}

export interface UptimeCalendarBoundary {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  weekday: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}

export interface UptimeCalendarRange {
  start: UptimeCalendarBoundary;
  end: UptimeCalendarBoundary;
}

export function normalizeUptimeService(service: UptimeService): void {
  service.rangeStart = ensureDate(service.rangeStart);
  service.rangeEnd = ensureDate(service.rangeEnd);
  service.percentage = ensureDecimal(service.percentage);
  service.days = differenceInCalendarDays(service.rangeEnd, service.rangeStart);
  service.outages.forEach((o) => {
    normalizeUptimeOutage(o);
  });
}

export function normalizeUptimeOutage(o: UptimeOutage): void {
  o.from = ensureDate(o.from);
  o.until = ensureDate(o.until);
}
