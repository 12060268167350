<template>
  <v-container fluid>
    <v-row align="center">
      <v-col>
        <v-progress-linear
          v-if="secondsLeft <= 3600"
          :color="secondsLeft < 15 ? 'error' : secondsLeft < 60 ? 'warning' : infoColor"
          :height="height"
          rounded
          stream
          :value="100 - percentLeft"
        >
          {{ distanceStr }}
        </v-progress-linear>
        <div v-else class="counter">{{ distanceStr }}</div>
      </v-col>
      <v-col v-if="withActionButton" cols="3">
        <v-btn
          :color="secondsLeft < 15 ? 'error' : secondsLeft < 60 ? 'warning' : infoColor"
          x-large
          @click="expiredCountdown"
          >{{ $tc(actionText) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
/**
 * Countdown is a small component showing an expiration timer
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { differenceInSeconds, formatDistanceStrict } from 'date-fns';
import i18n from '@/localisation/i18n';
import VueI18n from 'vue-i18n';

@Component({
  props: {
    when: Date,
    infoColor: {
      type: String,
      default: 'secondary',
    },
    infoMessage: String,
    expiredMessage: String,
    height: {
      type: Number,
      default: 32,
    },
    actionText: {
      type: String,
      default: 'Okay',
    },
    withActionButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
})
export default class Countdown extends Vue {
  private when!: Date;
  private distanceStr = '';
  private timeoutHandle?: ReturnType<typeof setTimeout>;
  private timerHandle = 0;

  private secondsLeft = 60;
  private percentLeft = 100.0;

  private infoMessage!: string;
  private expiredMessage!: string;

  @Watch('when')
  protected onWhen(): void {
    // user changed the auction date
    this.destroyTimer();
    this.refreshDistance();
    this.installTimer();
  }

  protected mounted(): void {
    this.refreshDistance();
    this.installTimer();
  }

  protected destroyed(): void {
    this.destroyTimer();
  }

  private refreshDistance() {
    const now = new Date();

    this.secondsLeft = differenceInSeconds(this.when, now);
    this.percentLeft = (this.secondsLeft / 120) * 100.0;

    if (this.secondsLeft < 1) {
      this.destroyTimer();
      this.distanceStr = i18n.tc(this.expiredMessage);
      this.expiredCountdown();
      return;
    }

    const s = formatDistanceStrict(now, this.when, {
      roundingMethod: this.secondsLeft <= 3600 ? 'ceil' : 'floor',
      unit: this.secondsLeft <= 120 ? 'second' : this.secondsLeft <= 7200 ? 'minute' : 'hour',
    });
    const tr: VueI18n.TranslateResult = i18n.t(this.infoMessage, {
      distance: s,
    });

    if (typeof tr === 'string') {
      this.distanceStr = tr;
    } else {
      this.distanceStr = s;
    }
  }

  private installTimer() {
    const dist = differenceInSeconds(this.when, new Date());

    if (dist > 180) {
      this.timeoutHandle = setTimeout(() => {
        this.refreshDistance();
        this.installTimer();
      }, 60 * 1000);
    } else {
      this.timerHandle = window.setInterval(() => {
        this.refreshDistance();
      }, 1 * 1000);
    }
  }

  private destroyTimer() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = undefined;
    }
    if (this.timerHandle > 0) {
      clearInterval(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  private expiredCountdown(): void {
    this.$emit('expired-countdown');
  }
}
</script>

<style lang="scss" scoped>
.counter {
  margin-top: 20px;
  text-align: center;
}
</style>
