<template>
  <ag-table-server
    :column-defs="columnDefs"
    :get-row-id="getRowId"
    :is-row-selectable="isRowSelectable"
    :page="page"
    :page-size="pageSize"
    :query-data="queryData"
    :selected-items="selectedItems"
    :sort="sort"
    :summary="summary"
    table-id="OpenLoansTable"
    v-on="$listeners"
    @ready="(e) => $emit('ready', e)"
    @update:selected-items="
      (items) => {
        $emit('update:selected-items', items);
        updateSummary(items);
      }
    "
  />
</template>

<script lang="ts">
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';
import { OpenLoanItem } from '@/modules/common/models';
import { isTerminalLoanStatus } from '@/utils/api/loans';
import { ClientConfig } from '@/utils/helpers/rest';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { Agg, getAggLoans } from '@/modules/open-loans/helpers/getAggLoans';
import { ColDef, LoadSuccessParams, RowNode, SortModelItem } from 'ag-grid-enterprise';
import { AgTableServer } from '@/modules/common/components/ag-table';
import * as cols from '@/modules/common/components/ag-table/columns/open-loans';

const demModeOnlyCols = ['termContractDisplayId'];
const bilateralOnlyCols = ['settlementType'];

@Component({
  components: {
    AgGridVue,
    AgTableServer,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    sort: {
      type: Object as PropType<SortModelItem>,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showDropdownActions: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    selectedItems: {
      type: Array as PropType<OpenLoanItem[]>,
      default: () => [],
    },
    queryData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
    ...mapGetters(['hasOpsUserRole', 'hasTraderUserRole']),
  },
})
export default class OpenLoansTable extends Vue {
  // props
  protected readonly page!: number;
  protected readonly sort!: SortModelItem;
  protected readonly pageSize!: number;
  protected readonly omitHeaders!: string[];
  protected readonly showDropdownActions!: boolean;
  protected readonly showSelect!: boolean;
  protected readonly selectedItems!: OpenLoanItem[];
  protected readonly queryData!: (config: {
    page: number;
    pageSize: number;
    sort: string;
    signal: AbortSignal;
  }) => Promise<LoadSuccessParams | undefined>;

  // store state
  protected readonly hasOpsUserRole!: boolean;
  protected readonly hasTraderUserRole!: boolean;
  protected readonly clientConfig!: ClientConfig;

  protected agg: Agg | null = null;
  protected sumOrAvg: 'sum' | 'avg' = 'sum';
  protected readonly settlementTypeDisplayText = settlementTypeDisplayText;

  protected get summary(): [] | [Agg] {
    return this.agg === null ? [] : [this.agg];
  }

  protected get columnDefs(): ColDef[] {
    return [
      cols.checkbox(),
      cols.status({ viewLoan: this.viewLoan }),
      cols.updatedAt(),
      cols.side(),
      cols.counterparty(),
      cols.lenderDisplay(),
      cols.borrowerDisplay(),
      cols.settlementType(),
      cols.ticker({ viewLoan: this.viewLoan }),
      cols.cusip(),
      cols.startDate(),
      cols.openQuantity(),
      cols.returnedQuantityToday(),
      cols.rate(),
      cols.contractAmount(),
      cols.independentAmountRate(),
      cols.independentAmount(),
      cols.yesterdayIndependentAmount(),
      cols.settlementAmount(),
      cols.suggestedRate(),
      cols.recalledQuantity(),
      cols.dueDate(),
      cols.sponsorship(),
      cols.termContract(),
      cols.displayId(),
      cols.dtccRefId(),
      cols.actions({
        showDropdownActions: this.showDropdownActions,
        viewLoan: this.viewLoan,
        changeSumOrAvg: this.changeOrderSumOrAvg,
      }),
    ].filter(
      (h) =>
        (this.showSelect || h.colId !== 'checkbox') &&
        !this.omitHeaders.includes(h.field as string) &&
        (this.clientConfig.demoMode || !demModeOnlyCols.includes(h.field as string)) &&
        (this.clientConfig.bilateralLoansEnabled || !bilateralOnlyCols.includes(h.field as string))
    );
  }

  protected getRowId(openLoanItem: OpenLoanItem): string {
    return openLoanItem.id;
  }

  protected updateSummary(selectedItems: OpenLoanItem[]): void {
    this.agg = selectedItems.length === 0 ? null : getAggLoans(selectedItems, this.sumOrAvg);
  }

  protected changeOrderSumOrAvg(sumOrAvg: 'sum' | 'avg'): void {
    this.sumOrAvg = sumOrAvg;
    this.updateSummary(this.selectedItems);
  }

  protected viewLoan(
    loan: OpenLoanItem,
    activeTab: 'history' | 'recalls' | 'corporateActions' = 'history'
  ): void {
    this.$emit('view-loan', loan, activeTab);
  }

  protected isRowSelectable(rowNode: RowNode): boolean {
    return !isTerminalLoanStatus(rowNode.data.status);
  }
}
</script>
