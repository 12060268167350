// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/login/login.proto (package aurora.core.apiengine.loginv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ConfirmIdentityRequest, ConfirmIdentityResponse, LoginWithApiCredentialsRequest, LoginWithApiCredentialsResponse, LoginWithSAMLTicketRequest, LoginWithSAMLTicketResponse, LoginWithUserCredentialsRequest, LoginWithUserCredentialsResponse, RefreshTokenRequest, RefreshTokenResponse } from "./login_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * All methods of the Aurora Connect-API require an `access token`.
 * You can call `LoginWithApiCredentials` to get your `access token`.
 *
 * The TTL of an access token is 1 week.
 * Please make sure your application will request a new token every week.
 *
 * @generated from service aurora.core.apiengine.loginv1.LoginService
 */
export const LoginService = {
  typeName: "aurora.core.apiengine.loginv1.LoginService",
  methods: {
    /**
     * Request an access token.
     * To identify, please request a `client_id` and `secret` from your representative at Provable Markets.
     *
     * Please note the services expect a JWT ***Bearer*** Token.
     * For instance, when you pass the `access_token` to a `cURL` request you will have to pass it in the header like this:
     *
     * ```shell
     *    curl -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
     * ```
     * Unfortunately the keyword `Bearer` is currently missing from the examples.
     *
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.LoginWithApiCredentials
     */
    loginWithApiCredentials: {
      name: "LoginWithApiCredentials",
      I: LoginWithApiCredentialsRequest,
      O: LoginWithApiCredentialsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UNDOCUMENTED-RPC
     *
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.LoginWithUserCredentials
     */
    loginWithUserCredentials: {
      name: "LoginWithUserCredentials",
      I: LoginWithUserCredentialsRequest,
      O: LoginWithUserCredentialsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UNDOCUMENTED-RPC
     *
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.RefreshToken
     */
    refreshToken: {
      name: "RefreshToken",
      I: RefreshTokenRequest,
      O: RefreshTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UNDOCUMENTED-RPC
     *
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.ConfirmIdentity
     */
    confirmIdentity: {
      name: "ConfirmIdentity",
      I: ConfirmIdentityRequest,
      O: ConfirmIdentityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UNDOCUMENTED-RPC
     *
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.LoginWithSAMLTicket
     */
    loginWithSAMLTicket: {
      name: "LoginWithSAMLTicket",
      I: LoginWithSAMLTicketRequest,
      O: LoginWithSAMLTicketResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

