import { ApiService } from '@/modules/common/services/api.service';
import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors';
import axios from 'axios';
import Vue from 'vue';
import {
  DepthOfBookResponse,
  OmsCreateBasketRequest,
  OmsHistoryResponse,
  OmsOrdersResponse,
  ParseOmsBasketRequest,
  ParseOmsBasketResponse,
  RawDepthOfBookResponse,
  RawOmsHistoryResponse,
  RawOmsOrdersResponse,
  RawParseOmsBasketResponse,
  RawTopOfBookWatchlistContent,
  RawTopOfBookWatchlistSecuritiesUploadResponse,
  RawTopOfBookWatchlistWithInstrumentsResponse,
  RawTopOfBookWatchlistsResponse,
  RawVenueTopOfBookResponse,
  TopOfBookWatchlist,
  TopOfBookWatchlistContent,
  TopOfBookWatchlistSecuritiesUploadResponse,
  TopOfBookWatchlistWithInstrumentsResponse,
  TopOfBookWatchlistsResponse,
  VenueTopOfBookResponse,
  WatchlistItemCreateRequest,
  WatchlistItemUpdateRequest,
} from '@/modules/marketplace/models';

export class MarketplaceApiService extends ApiService {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.marketplace = singleton;
    } else {
      v.prototype.$api = {
        marketplace: singleton,
      };
    }
  }

  public async fetchTopOfBook(): Promise<VenueTopOfBookResponse['data']> {
    const url = this.baseUrl + '/orderbook/top-of-book';
    const res = await this.axios.get<RawVenueTopOfBookResponse>(url);
    return VenueTopOfBookResponse.fromData(res.data).data;
  }

  public async fetchDepthOfBook(cusip: string): Promise<DepthOfBookResponse> {
    const url = this.baseUrl + `/orderbook/depth-of-book/${encodeURI(cusip)}`;
    const res = await this.axios.get<RawDepthOfBookResponse>(url);
    return DepthOfBookResponse.fromData(res.data);
  }

  public async fetchAdminTopOfBook(): Promise<VenueTopOfBookResponse['data']> {
    const url = this.baseUrl + '/broker-user/monitor/top-of-book';
    const res = await this.axios.get<RawVenueTopOfBookResponse>(url);
    return VenueTopOfBookResponse.fromData(res.data).data;
  }

  public async fetchAdminDepthOfBook(cusip: string): Promise<DepthOfBookResponse> {
    const url = this.baseUrl + `/broker-user/monitor/depth-of-book/${encodeURI(cusip)}`;
    const res = await this.axios.get<RawDepthOfBookResponse>(url);
    return DepthOfBookResponse.fromData(res.data);
  }

  public async fetchOrdersList(params: {
    showAll: boolean;
    cusip?: string;
  }): Promise<OmsOrdersResponse['data']> {
    const url = this.baseUrl + '/oms/v2/orders';
    const res = await this.axios.get<RawOmsOrdersResponse>(url, { params });
    return OmsOrdersResponse.fromData(res.data).data;
  }

  public async fetchAdminOrdersList(params: {
    showAll: boolean;
    companyId: string | null;
  }): Promise<OmsOrdersResponse['data']> {
    const url = this.baseUrl + '/broker-user/monitor/orders';
    const res = await this.axios.get<RawOmsOrdersResponse>(url, { params });
    return OmsOrdersResponse.fromData(res.data).data;
  }

  public async fetchOrderDetails(
    orderRef: string,
    abortSignal: AbortSignal
  ): Promise<OmsHistoryResponse | void> {
    try {
      const url = this.baseUrl + '/oms/v2/orders/' + orderRef;
      const res = await this.axios.get<RawOmsHistoryResponse>(url, {
        signal: abortSignal,
      });
      return OmsHistoryResponse.fromData(res.data);
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async fetchAdminOrderDetails(
    orderRef: string,
    abortSignal: AbortSignal
  ): Promise<OmsHistoryResponse | void> {
    try {
      const url = this.baseUrl + '/broker-user/monitor/order/' + orderRef;
      const res = await this.axios.get<RawOmsHistoryResponse>(url, {
        signal: abortSignal,
      });
      return OmsHistoryResponse.fromData(res.data);
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }

  public async createOrder(
    payload: Api.Marketplace.OrderCreateRequest
  ): Promise<Api.Marketplace.OrderCreateResponse> {
    const url = this.baseUrl + '/oms/v2/orders';
    const res = await this.axios.post<Api.Marketplace.OrderCreateResponse>(url, payload);
    return res.data;
  }

  public async editOrder(payload: Api.Marketplace.OrderEditRequest): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + payload.orderRef;
    return this.axios.put(url, payload).then(() => undefined);
  }

  public async cancelOrder(orderRef: string): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + orderRef + '/cancel';
    return this.axios.post(url).then(() => undefined);
  }

  public async routeOrder(orderRef: string): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + orderRef + '/route';
    return this.axios.post(url).then(() => undefined);
  }

  public async unrouteOrder(orderRef: string): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/' + orderRef + '/unroute';
    return this.axios.post(url).then(() => undefined);
  }

  public async uploadOrders(request: ParseOmsBasketRequest): Promise<ParseOmsBasketResponse> {
    const url = this.baseUrl + '/oms/v2/orders/basket-parse';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = ParseOmsBasketRequest.toFormData(request);

    try {
      const { data } = await this.axios.post<RawParseOmsBasketResponse>(url, formData, {
        headers,
      });

      return ParseOmsBasketResponse.fromData(data);
    } catch (e) {
      const apiError = e as ApiError;
      try {
        // There's a possibility that the error has useful data
        apiError.responseData = ParseOmsBasketResponse.fromData(
          apiError.responseData as unknown as RawParseOmsBasketResponse
        ) as unknown as Record<string, unknown>;
      } catch {
        void 0;
      }
      throw e;
    }
  }

  public async batchCancelOrders(ids: string[]): Promise<Api.Marketplace.BatchActionResponse> {
    const payload = {
      orderRefs: ids,
    };
    const url = this.baseUrl + '/oms/v2/orders/cancel';
    const { data } = await this.axios.post<Api.Marketplace.BatchActionResponse>(url, payload);
    return data;
  }

  public async batchUpdate(
    payload: Api.Marketplace.BatchModifyPayload
  ): Promise<Api.Marketplace.BatchActionResponse> {
    const url = this.baseUrl + '/oms/v2/orders/modify';
    const { data } = await this.axios.put(url, payload);
    return data;
  }

  public async createOrders(request: OmsCreateBasketRequest): Promise<void> {
    const url = this.baseUrl + '/oms/v2/orders/basket';
    await this.axios.post(url, OmsCreateBasketRequest.toData(request));
  }

  public async fetchWatchlists(): Promise<TopOfBookWatchlist[]> {
    const url = this.baseUrl + '/orderbook/watchlists';
    const res = await this.axios.get<RawTopOfBookWatchlistsResponse>(url);
    return TopOfBookWatchlistsResponse.fromData(res.data).items;
  }

  public async fetchWatchlistWithSecurities(
    displayId: string
  ): Promise<TopOfBookWatchlistWithInstrumentsResponse> {
    const url = this.baseUrl + `/orderbook/watchlists/${displayId}/instruments`;
    const res = await this.axios.get<RawTopOfBookWatchlistWithInstrumentsResponse>(url);
    return TopOfBookWatchlistWithInstrumentsResponse.fromData(res.data);
  }

  public async fetchWatchlistContent(
    displayId: string
  ): Promise<TopOfBookWatchlistContent['data']> {
    const url = this.baseUrl + `/orderbook/watchlists/${displayId}`;
    const res = await this.axios.get<RawTopOfBookWatchlistContent>(url);
    return TopOfBookWatchlistContent.fromData(res.data).data;
  }

  public async deleteWatchlist(displayId: string): Promise<void> {
    const url = this.baseUrl + `/orderbook/watchlists/${displayId}`;
    await this.axios.delete(url);
  }

  public async createWatchlist(payload: WatchlistItemCreateRequest): Promise<void> {
    const url = this.baseUrl + '/orderbook/watchlists';
    return this.axios.post(url, WatchlistItemCreateRequest.toData(payload)).then(() => undefined);
  }

  public async updateWatchlist(params: {
    displayId: string;
    payload: WatchlistItemUpdateRequest;
  }): Promise<void> {
    const url = this.baseUrl + `/orderbook/watchlists/${params.displayId}`;
    return this.axios
      .put(url, WatchlistItemUpdateRequest.toData(params.payload))
      .then(() => undefined);
  }

  public async uploadWatchlistSecurities(
    file: File
  ): Promise<TopOfBookWatchlistSecuritiesUploadResponse> {
    const url = this.baseUrl + '/orderbook/watchlists/file-upload';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.axios.post<RawTopOfBookWatchlistSecuritiesUploadResponse>(
      url,
      formData,
      { headers }
    );

    return TopOfBookWatchlistSecuritiesUploadResponse.fromData(data);
  }
}
