import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors/api-error';

/**
 * Not Found Error (404)
 *
 * The requested resource was not found at the location provided
 */
export class NotFoundError<T = Api.ErrorResponse> extends ApiError<T> {
  public constructor(
    public message: string,
    public responseData?: T
  ) {
    super(message);
    this.name = 'NotFoundError';
  }
}
