import Decimal from 'decimal.js';
import { RenegotiateSide, Side } from '@/utils/api/loans';
import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { RawSecurity, Security } from './security';
import { EventInitiator, RawEventInitiator } from '@/modules/marketplace/models';
import logger from '@/modules/common/services/logger.service';

export type RawLoanEvent = Raw<
  LoanEventBase,
  {
    // always specify existing raw entry types explititly
    initiator: RawEventInitiator;
    loanState: RawLoanHistoryState;
  }
>;

export abstract class LoanEventBase {
  public eventType: LoanEventType;
  public eventTimestamp: string;
  public initiator: EventInitiator;
  public loanState: LoanHistoryState;

  protected constructor(data: RawLoanEvent) {
    this.eventType = data.eventType;
    this.eventTimestamp = data.eventTimestamp;
    this.initiator = data.initiator;
    this.loanState = LoanHistoryState.fromData(data.loanState);
  }

  public static fromData(data: RawLoanEvent): LoanEvent;
  public static fromData(data: RawLoanEvent | null): LoanEvent | null;
  public static fromData(data: RawLoanEvent | undefined): LoanEvent | undefined;
  public static fromData(data: null | undefined | RawLoanEvent): null | undefined | LoanEvent {
    if (data === null) return null;
    if (data === undefined) return undefined;

    switch (data.eventType) {
      case 'CREATED':
        return new LoanEventCreated(data);
      case 'DO_PENDING':
        return new LoanEventDOPending(data);
      case 'PENDING':
        return new LoanEventPending(data);
      case 'MADE':
        return new LoanEventMade(data);
      case 'DROPPED':
        return new LoanEventDropped(data);
      case 'REJECTED':
        return new LoanEventRejected(data);
      case 'CANCEL_PENDING':
        return new LoanEventDropped(data);
      case 'CANCEL_REJECTED':
        return new LoanEventCancelNewLoanRejected(data);
      case 'CANCELED':
        return new LoanEventCanceled(data);
      case 'RECALLED':
        return new LoanEventRecalled(data as Raw<LoanEventRecalled>);
      case 'RECALL_REJECTED':
        return new LoanEventRecallRejected(data as Raw<LoanEventRecallRejected>);
      case 'RECALL_CANCELED':
        return new LoanEventRecallCanceled(data as Raw<LoanEventRecallCanceled>);
      case 'ROLL_PENDING':
        return new LoanEventRollPending(data as Raw<LoanEventRollPending>);
      case 'ROLL_PD_PENDING':
        return new LoanEventRollPDPending(data as Raw<LoanEventRollPDPending>);
      case 'ROLL_PD_MADE':
        return new LoanEventRollPDMade(data as Raw<LoanEventRollPDMade>);
      case 'ROLLED':
        return new LoanEventRolled(data as Raw<LoanEventRolled>);
      case 'RETURNED':
        return new LoanEventReturned(data as Raw<LoanEventReturned>);
      case 'CANCEL_RETURN_PENDING':
        return new LoanEventCancelReturnPending(data);
      case 'RETURN_PENDING':
        return new LoanEventReturnPending(data as Raw<LoanEventReturnPending>);
      case 'RETURN_CANCELED':
        return new LoanEventReturnCanceled(data as Raw<LoanEventReturnCanceled>);
      case 'RETURN_FAILED':
        return new LoanEventReturnFailed(data as Raw<LoanEventReturnFailed>);
      case 'RETURN_REJECTED':
        return new LoanEventReturnRejected(data as Raw<LoanEventReturnRejected>);
      case 'TERMINATED':
        return new LoanEventTerminated(data);
      case 'PARTIALLY_RETURNED':
        return new LoanEventPartiallyReturned(data as Raw<LoanEventPartiallyReturned>);
      case 'BUY-IN':
        return new LoanEventBuyIn(data as Raw<LoanEventBuyIn>);
      case 'BUY-IN_PENDING':
        return new LoanEventBuyInPending(data as Raw<LoanEventBuyInPending>);
      case 'BUY-IN_REJECTED':
        return new LoanEventBuyInRejected(data as Raw<LoanEventBuyInRejected>);
      case 'BUY-IN_CANCELED':
        return new LoanEventBuyInCanceled(data as Raw<LoanEventBuyInCanceled>);
      case 'RENEGOTIATED':
        return new LoanEventRenegotiated(data as Raw<LoanEventRenegotiated>);
      case 'RENEGOTIATION_CANCELED':
        return new LoanEventRenegotiationCanceled(data as Raw<LoanEventRenegotiationCanceled>);
      case 'RENEGOTIATION_REJECTED':
        return new LoanEventRenegotiationRejected(data as Raw<LoanEventRenegotiationRejected>);
      case 'RENEGOTIATION_EXPIRED':
        return new LoanEventRenegotiationExpired(data as Raw<LoanEventRenegotiationExpired>);
      case 'FLOATING_RATE_RECALCULATED':
        return new LoanEventFloatingRateRecalculated(
          data as Raw<LoanEventFloatingRateRecalculated>
        );
      case 'CORP-ACTION':
        return new LoanEventCorpAction(data as RawLoanEventCorpAction);
      case 'RETURN_PROPOSED':
        return new LoanEventReturnProposed(data as Raw<LoanEventReturnProposed>);
      case 'RETURN_PROPOSAL_ACCEPTED':
        return new LoanEventReturnProposalAccepted(data as Raw<LoanEventReturnProposalAccepted>);
      case 'RETURN_PROPOSAL_REJECTED':
        return new LoanEventReturnProposalRejected(data);
      case 'RETURN_PROPOSAL_CANCELED':
        return new LoanEventReturnProposalCanceled(data);
      case 'RETURN_PROPOSAL_DROPPED':
        return new LoanEventReturnProposalDropped(data);
      case 'DAILY_INTEREST_AMOUNT':
        return new LoanEventDailyInterestAmount(data);
      case 'DAILY_INTEREST_PO_FAILED':
        return new LoanEventDailyInterestPOFailed(data as Raw<LoanEventDailyInterestPOFailed>);
      case 'DAILY_INTEREST_PO_MADE':
        return new LoanEventDailyInterestPOMade(data as Raw<LoanEventDailyInterestPOMade>);
      case 'MARKED_TO_MARKET':
        return new LoanEventMarkedToMarket(data as Raw<LoanEventMarkedToMarket>);
      case 'MARK_TO_MARKET_PO_MADE':
        return new LoanEventMarkedToMarketPOMade(data as Raw<LoanEventMarkedToMarketPOMade>);
      case 'MARK_TO_MARKET_PO_FAILED':
        return new LoanEventMarkedToMarketPOFailed(data as Raw<LoanEventMarkedToMarketPOFailed>);
      case 'BUY_IN_PO_MADE':
        return new LoanEventBuyInPOMade(data as Raw<LoanEventBuyInPOMade>);
      case 'BUY_IN_PO_FAILED':
        return new LoanEventBuyInPOFailed(data as Raw<LoanEventBuyInPOFailed>);
      default:
        logger.error(`${data.eventType} not supported`);
    }
  }

  public static mockData(data?: null | DeepPartial<RawLoanEvent>): RawLoanEvent {
    switch (data?.eventType) {
      case 'CREATED':
        return LoanEventCreated.mockData(data as RawLoanEventCreated);
      case 'RECALLED':
        return LoanEventRecalled.mockData(data as RawLoanEventRecalled);
      default:
        throw new Error(`Event type ${data?.eventType} is not handled, please implement`);
    }
  }

  public static mockBaseData(
    data?: null | DeepPartial<RawLoanEvent>
  ): Omit<RawLoanEvent, 'eventType'> {
    const { initiator, loanState, ...rest } = data ?? {};
    return {
      eventTimestamp: '2022-07-27T07:43:45.901631628-04:00',
      initiator: EventInitiator.mockData(initiator),
      loanState: LoanHistoryState.mockData(loanState),

      ...rest,
    };
  }
}

export type RawLoanHistoryState = Raw<LoanHistoryState>;

export class LoanHistoryState {
  public openQuantity: number;
  public unitPrice: Decimal;
  public rate: Decimal;
  public settlementValue: Decimal;
  public dailyInterestAmount: Decimal;
  public pendingDtccRefId: string;
  public activeDtccRefId: string;

  protected constructor(data: RawLoanHistoryState) {
    this.openQuantity = data.openQuantity;
    this.unitPrice = new Decimal(data.unitPrice);
    this.rate = new Decimal(data.rate);
    this.settlementValue = new Decimal(data.settlementValue);
    this.dailyInterestAmount = new Decimal(data.dailyInterestAmount);
    this.pendingDtccRefId = data.pendingDtccRefId;
    this.activeDtccRefId = data.activeDtccRefId;
  }

  public static fromData(data: RawLoanHistoryState): LoanHistoryState;
  public static fromData(data: RawLoanHistoryState | null): LoanHistoryState | null;
  public static fromData(data: RawLoanHistoryState | undefined): LoanHistoryState | undefined;
  public static fromData(
    data: null | undefined | RawLoanHistoryState
  ): null | undefined | LoanHistoryState {
    if (data === null) return null;
    if (data === undefined) return undefined;

    return new LoanHistoryState(data);
  }

  public static mockData(data?: null | DeepPartial<RawLoanHistoryState>): RawLoanHistoryState {
    return {
      openQuantity: 4444,
      unitPrice: '145.23',
      rate: '0.03',
      settlementValue: '645402.12',
      dailyInterestAmount: '53.78',
      activeDtccRefId: '',
      pendingDtccRefId: '',

      ...data,
    };
  }
}

type RawLoanEventCreated = Raw<
  LoanEventCreated,
  {
    // always specify existing raw entry types explititly
    initiator: RawEventInitiator;
    loanState: RawLoanHistoryState;
  }
>;

class LoanEventCreated extends LoanEventBase {
  public declare eventType: 'CREATED';

  public static mock(data?: null | DeepPartial<RawLoanEventCreated>): LoanEvent {
    return LoanEventCreated.fromData(LoanEventCreated.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawLoanEventCreated>): RawLoanEventCreated {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const { initiator: _initiator, loanState: _loanState, ...rest } = data ?? {};
    return {
      eventType: 'CREATED',
      ...LoanEventBase.mockBaseData(data),

      ...rest,
    };
  }
}

class LoanEventDOPending extends LoanEventBase {
  public declare eventType: 'DO_PENDING';
}

class LoanEventPending extends LoanEventBase {
  public declare eventType: 'PENDING';
}

class LoanEventMade extends LoanEventBase {
  public declare eventType: 'MADE';
}

class LoanEventDropped extends LoanEventBase {
  public declare eventType: 'DROPPED';
}

class LoanEventRejected extends LoanEventBase {
  public declare eventType: 'REJECTED';
}

class LoanEventCancelNewLoanPending extends LoanEventBase {
  public declare eventType: 'CANCEL_PENDING';
}

class LoanEventCancelNewLoanRejected extends LoanEventBase {
  public declare eventType: 'CANCEL_REJECTED';
}

class LoanEventCanceled extends LoanEventBase {
  public declare eventType: 'CANCELED';
}

type RawLoanEventRecalled = Raw<
  LoanEventRecalled,
  {
    // always specify existing raw entry types explititly
    initiator: RawEventInitiator;
    loanState: RawLoanHistoryState;
  }
>;

class LoanEventRecalled extends LoanEventBase {
  public declare eventType: 'RECALLED';
  public recalledQuantity: number;
  public totalRecalledQuantity: number;

  public constructor(data: Raw<LoanEventRecalled>) {
    super(data);

    this.recalledQuantity = data.recalledQuantity;
    this.totalRecalledQuantity = data.totalRecalledQuantity;
  }

  public static mock(data?: null | DeepPartial<RawLoanEventRecalled>): LoanEvent {
    return LoanEventRecalled.fromData(LoanEventRecalled.mockData(data));
  }

  public static mockData(data?: null | DeepPartial<RawLoanEventRecalled>): RawLoanEventRecalled {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const { initiator: _initiator, loanState: _loanState, ...rest } = data ?? {};
    return {
      eventType: 'RECALLED',
      ...LoanEventBase.mockBaseData(data),
      recalledQuantity: 100,
      totalRecalledQuantity: 100,

      ...rest,
    };
  }
}

class LoanEventRecallRejected extends LoanEventBase {
  public declare eventType: 'RECALL_REJECTED';
  public rejectedRecalledQuantity: number;
  public totalRecalledQuantity: number;

  public constructor(data: Raw<LoanEventRecallRejected>) {
    super(data);

    this.rejectedRecalledQuantity = data.rejectedRecalledQuantity;
    this.totalRecalledQuantity = data.totalRecalledQuantity;
  }
}

class LoanEventRecallCanceled extends LoanEventBase {
  public declare eventType: 'RECALL_CANCELED';
  public canceledRecalledQuantity: number;
  public totalRecalledQuantity: number;

  public constructor(data: Raw<LoanEventRecallCanceled>) {
    super(data);

    this.canceledRecalledQuantity = data.canceledRecalledQuantity;
    this.totalRecalledQuantity = data.totalRecalledQuantity;
  }
}

class LoanEventRollPending extends LoanEventBase {
  public declare eventType: 'ROLL_PENDING';
  public rolledQuantity: number;
  public unitPrice: Decimal;

  public constructor(data: Raw<LoanEventRollPending>) {
    super(data);

    this.rolledQuantity = data.rolledQuantity;
    this.unitPrice = new Decimal(data.unitPrice);
  }
}

class LoanEventRollPDPending extends LoanEventBase {
  public declare eventType: 'ROLL_PD_PENDING';
  public direction: Side;
  public amount: Decimal;

  public constructor(data: Raw<LoanEventRollPDPending>) {
    super(data);

    this.direction = data.direction;
    this.amount = new Decimal(data.amount);
  }
}

class LoanEventRollPDMade extends LoanEventBase {
  public declare eventType: 'ROLL_PD_MADE';
  public direction: Side;
  public amount: Decimal;

  public constructor(data: Raw<LoanEventRollPDMade>) {
    super(data);

    this.direction = data.direction;
    this.amount = new Decimal(data.amount);
  }
}

class LoanEventRolled extends LoanEventBase {
  public declare eventType: 'ROLLED';
  public rolledQuantity: number;
  public unitPrice: Decimal;

  public constructor(data: Raw<LoanEventRolled>) {
    super(data);

    this.rolledQuantity = data.rolledQuantity;
    this.unitPrice = new Decimal(data.unitPrice);
  }
}

class LoanEventReturned extends LoanEventBase {
  public declare eventType: 'RETURNED';
  public returnedQuantity: number;
  public remainingQuantity: number;

  public constructor(data: Raw<LoanEventReturned>) {
    super(data);

    this.returnedQuantity = data.returnedQuantity;
    this.remainingQuantity = data.remainingQuantity;
  }
}

class LoanEventCancelReturnPending extends LoanEventBase {
  public declare eventType: 'CANCEL_RETURN_PENDING';
}

class LoanEventReturnPending extends LoanEventBase {
  public declare eventType: 'RETURN_PENDING';
  public quantity: number;

  public constructor(data: Raw<LoanEventReturnPending>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventReturnCanceled extends LoanEventBase {
  public declare eventType: 'RETURN_CANCELED';
  public quantity: number;

  public constructor(data: Raw<LoanEventReturnCanceled>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventReturnFailed extends LoanEventBase {
  public declare eventType: 'RETURN_FAILED';
  public failedQuantity: number;

  public constructor(data: Raw<LoanEventReturnFailed>) {
    super(data);

    this.failedQuantity = data.failedQuantity;
  }
}

class LoanEventReturnRejected extends LoanEventBase {
  public declare eventType: 'RETURN_REJECTED';
  public quantity: number;

  public constructor(data: Raw<LoanEventReturnRejected>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventTerminated extends LoanEventBase {
  public declare eventType: 'TERMINATED';
}

class LoanEventPartiallyReturned extends LoanEventBase {
  public declare eventType: 'PARTIALLY_RETURNED';
  public returnedQuantity: number;
  public remainingQuantity: number;

  public constructor(data: Raw<LoanEventPartiallyReturned>) {
    super(data);

    this.returnedQuantity = data.returnedQuantity;
    this.remainingQuantity = data.remainingQuantity;
  }
}

class LoanEventBuyIn extends LoanEventBase {
  public declare eventType: 'BUY-IN';
  public avgPricePerShare: Decimal;
  public buyInQuantity: number;
  public remainingQuanity: number;

  public constructor(data: Raw<LoanEventBuyIn>) {
    super(data);

    this.avgPricePerShare = new Decimal(data.avgPricePerShare);
    this.buyInQuantity = data.buyInQuantity;
    this.remainingQuanity = data.remainingQuanity;
  }
}

class LoanEventBuyInPending extends LoanEventBase {
  public declare eventType: 'BUY-IN_PENDING';
  public quantity: number;

  public constructor(data: Raw<LoanEventBuyInPending>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventBuyInRejected extends LoanEventBase {
  public declare eventType: 'BUY-IN_REJECTED';
  public quantity: number;

  public constructor(data: Raw<LoanEventBuyInRejected>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventBuyInCanceled extends LoanEventBase {
  public declare eventType: 'BUY-IN_CANCELED';
  public quantity: number;

  public constructor(data: Raw<LoanEventBuyInCanceled>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventRenegotiated extends LoanEventBase {
  public declare eventType: 'RENEGOTIATED';
  public proposedBy: RenegotiateSide;
  public oldRate: Decimal;
  public newRate: Decimal;

  public constructor(data: Raw<LoanEventRenegotiated>) {
    super(data);

    this.proposedBy = data.proposedBy;
    this.oldRate = new Decimal(data.oldRate);
    this.newRate = new Decimal(data.newRate);
  }
}

class LoanEventRenegotiationCanceled extends LoanEventBase {
  public declare eventType: 'RENEGOTIATION_CANCELED';
  public proposedBy: RenegotiateSide;
  public oldRate: Decimal;
  public newRate: Decimal;

  public constructor(data: Raw<LoanEventRenegotiationCanceled>) {
    super(data);

    this.proposedBy = data.proposedBy;
    this.oldRate = new Decimal(data.oldRate);
    this.newRate = new Decimal(data.newRate);
  }
}

class LoanEventRenegotiationRejected extends LoanEventBase {
  public declare eventType: 'RENEGOTIATION_REJECTED';
  public proposedBy: RenegotiateSide;
  public oldRate: Decimal;
  public newRate: Decimal;

  public constructor(data: Raw<LoanEventRenegotiationRejected>) {
    super(data);

    this.proposedBy = data.proposedBy;
    this.oldRate = new Decimal(data.oldRate);
    this.newRate = new Decimal(data.newRate);
  }
}

class LoanEventRenegotiationExpired extends LoanEventBase {
  public declare eventType: 'RENEGOTIATION_EXPIRED';
  public proposedBy: RenegotiateSide;
  public oldRate: Decimal;
  public newRate: Decimal;

  public constructor(data: Raw<LoanEventRenegotiationExpired>) {
    super(data);

    this.proposedBy = data.proposedBy;
    this.oldRate = new Decimal(data.oldRate);
    this.newRate = new Decimal(data.newRate);
  }
}

class LoanEventFloatingRateRecalculated extends LoanEventBase {
  public declare eventType: 'FLOATING_RATE_RECALCULATED';
  public oldRate: Decimal;
  public newRate: Decimal;

  public constructor(data: Raw<LoanEventFloatingRateRecalculated>) {
    super(data);

    this.oldRate = new Decimal(data.oldRate);
    this.newRate = new Decimal(data.newRate);
  }
}

type RawLoanEventCorpAction = Raw<
  LoanEventCorpAction,
  {
    // always specify existing raw entry types explititly
    instrumentChange: {
      oldInstrument: RawSecurity;
      newInstrument: RawSecurity;
    } | null;
  },
  'securityChange'
>;

class LoanEventCorpAction extends LoanEventBase {
  public declare eventType: 'CORP-ACTION';
  public corpActionType: string;
  public securityChange: {
    oldSecurity: Security;
    newSecurity: Security;
  } | null;
  public oldQuantity: number;
  public newQuantity: number;

  public constructor(data: RawLoanEventCorpAction) {
    super(data);

    this.corpActionType = data.corpActionType;
    this.corpActionType = data.corpActionType;
    this.securityChange =
      data.instrumentChange === null
        ? null
        : {
            oldSecurity: Security.fromData(data.instrumentChange.oldInstrument),
            newSecurity: Security.fromData(data.instrumentChange.newInstrument),
          };
    this.oldQuantity = data.oldQuantity;
    this.newQuantity = data.newQuantity;
  }
}

class LoanEventReturnProposed extends LoanEventBase {
  public declare eventType: 'RETURN_PROPOSED';
  public quantity: number;

  public constructor(data: Raw<LoanEventReturnProposed>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventReturnProposalAccepted extends LoanEventBase {
  public declare eventType: 'RETURN_PROPOSAL_ACCEPTED';
  public quantity: number;

  public constructor(data: Raw<LoanEventReturnProposalAccepted>) {
    super(data);

    this.quantity = data.quantity;
  }
}

class LoanEventReturnProposalRejected extends LoanEventBase {
  public declare eventType: 'RETURN_PROPOSAL_REJECTED';
}

class LoanEventReturnProposalCanceled extends LoanEventBase {
  public declare eventType: 'RETURN_PROPOSAL_CANCELED';
}

class LoanEventReturnProposalDropped extends LoanEventBase {
  public declare eventType: 'RETURN_PROPOSAL_DROPPED';
}

class LoanEventDailyInterestAmount extends LoanEventBase {
  public declare eventType: 'DAILY_INTEREST_AMOUNT';
}

class LoanEventDailyInterestPOFailed extends LoanEventBase {
  public declare eventType: 'DAILY_INTEREST_PO_FAILED';
  public amount: Decimal;

  public constructor(data: Raw<LoanEventDailyInterestPOFailed>) {
    super(data);

    this.amount = new Decimal(data.amount);
  }
}

class LoanEventDailyInterestPOMade extends LoanEventBase {
  public declare eventType: 'DAILY_INTEREST_PO_MADE';
  public amount: Decimal;

  public constructor(data: Raw<LoanEventDailyInterestPOMade>) {
    super(data);

    this.amount = new Decimal(data.amount);
  }
}

class LoanEventMarkedToMarket extends LoanEventBase {
  public declare eventType: 'MARKED_TO_MARKET';
}

class LoanEventMarkedToMarketPOMade extends LoanEventBase {
  public declare eventType: 'MARK_TO_MARKET_PO_MADE';
  public amount: Decimal;

  public constructor(data: Raw<LoanEventMarkedToMarketPOMade>) {
    super(data);

    this.amount = new Decimal(data.amount);
  }
}

class LoanEventMarkedToMarketPOFailed extends LoanEventBase {
  public declare eventType: 'MARK_TO_MARKET_PO_FAILED';
  public amount: Decimal;

  public constructor(data: Raw<LoanEventMarkedToMarketPOFailed>) {
    super(data);

    this.amount = new Decimal(data.amount);
  }
}

class LoanEventBuyInPOMade extends LoanEventBase {
  public declare eventType: 'BUY_IN_PO_MADE';
  public amount: Decimal;

  public constructor(data: Raw<LoanEventBuyInPOMade>) {
    super(data);

    this.amount = new Decimal(data.amount);
  }
}

class LoanEventBuyInPOFailed extends LoanEventBase {
  public declare eventType: 'BUY_IN_PO_FAILED';
  public amount: Decimal;

  public constructor(data: Raw<LoanEventBuyInPOFailed>) {
    super(data);

    this.amount = new Decimal(data.amount);
  }
}

export type LoanEvent =
  | LoanEventCreated
  | LoanEventDOPending
  | LoanEventPending
  | LoanEventMade
  | LoanEventDropped
  | LoanEventRejected
  | LoanEventCancelNewLoanPending
  | LoanEventCancelNewLoanRejected
  | LoanEventCanceled
  | LoanEventRecalled
  | LoanEventRecallRejected
  | LoanEventRecallCanceled
  | LoanEventRollPending
  | LoanEventRollPDPending
  | LoanEventRollPDMade
  | LoanEventRolled
  | LoanEventReturned
  | LoanEventCancelReturnPending
  | LoanEventReturnFailed
  | LoanEventReturnPending
  | LoanEventReturnCanceled
  | LoanEventReturnRejected
  | LoanEventTerminated
  | LoanEventPartiallyReturned
  | LoanEventBuyIn
  | LoanEventBuyInPending
  | LoanEventBuyInRejected
  | LoanEventBuyInCanceled
  | LoanEventRenegotiated
  | LoanEventRenegotiationExpired
  | LoanEventRenegotiationRejected
  | LoanEventRenegotiationCanceled
  | LoanEventFloatingRateRecalculated
  | LoanEventCorpAction
  | LoanEventReturnProposed
  | LoanEventReturnProposalAccepted
  | LoanEventReturnProposalRejected
  | LoanEventReturnProposalCanceled
  | LoanEventReturnProposalDropped
  | LoanEventDailyInterestAmount
  | LoanEventDailyInterestPOFailed
  | LoanEventDailyInterestPOMade
  | LoanEventMarkedToMarket
  | LoanEventMarkedToMarketPOMade
  | LoanEventMarkedToMarketPOFailed
  | LoanEventBuyInPOMade
  | LoanEventBuyInPOFailed;

export type LoanEventType =
  | 'CREATED'
  | 'PENDING'
  | 'DO_PENDING'
  | 'MADE'
  | 'DROPPED'
  | 'REJECTED'
  | 'CANCEL_PENDING'
  | 'CANCEL_REJECTED'
  | 'CANCELED'
  | 'ROLL_PENDING'
  | 'ROLL_PD_PENDING'
  | 'ROLL_PD_MADE'
  | 'BUY_IN_PO_MADE'
  | 'BUY_IN_PO_FAILED'
  | 'ROLLED'
  | 'MARKED_TO_MARKET'
  | 'MARK_TO_MARKET_PO_MADE'
  | 'MARK_TO_MARKET_PO_FAILED'
  | 'DAILY_INTEREST_AMOUNT'
  | 'DAILY_INTEREST_PO_FAILED'
  | 'DAILY_INTEREST_PO_MADE'
  | 'RENEGOTIATED'
  | 'RENEGOTIATION_CANCELED'
  | 'RENEGOTIATION_REJECTED'
  | 'RENEGOTIATION_EXPIRED'
  | 'RETURNED'
  | 'CANCEL_RETURN_PENDING'
  | 'RETURN_CANCELED'
  | 'RETURN_PENDING'
  | 'RETURN_FAILED'
  | 'RETURN_REJECTED'
  | 'TERMINATED'
  | 'PARTIALLY_RETURNED'
  | 'RECALLED'
  | 'RECALL_REJECTED'
  | 'RECALL_CANCELED'
  | 'BUY-IN'
  | 'BUY-IN_PENDING'
  | 'BUY-IN_CANCELED'
  | 'BUY-IN_REJECTED'
  | 'BUY-IN_FAILED'
  | 'CORP-ACTION'
  | 'FLOATING_RATE_RECALCULATED'
  | 'RETURN_PROPOSED'
  | 'RETURN_PROPOSAL_ACCEPTED'
  | 'RETURN_PROPOSAL_REJECTED'
  | 'RETURN_PROPOSAL_CANCELED'
  | 'RETURN_PROPOSAL_DROPPED';
