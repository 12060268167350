import Decimal from 'decimal.js';
import { CustomRule, helpers } from 'vuelidate/lib/validators';

/**
 * Vuelidate custom validator:
 * validates that a decimal number does not exceed a given number of decimal places
 */
export const notGreaterThanPrecision =
  (precision: number): CustomRule =>
  (value: string | number | Decimal | null) => {
    if (typeof value === 'undefined' || value === null) {
      return false;
    }
    try {
      const decValue = new Decimal(value);
      const decimalPlaces = decValue.decimalPlaces();
      return decimalPlaces <= precision;
    } catch (err) {
      return false;
    }
  };

/**
 * Vuelidate custom validator:
 * validates that a decimal number has the same value as another decimal number
 */
export const sameDecimals = <T>(fieldName: string): CustomRule =>
  helpers.withParams(
    { type: 'sameDecimals', eq: fieldName },
    (value: Decimal | null, parentVm: T) => {
      const otherValue = parentVm[fieldName];
      if (value instanceof Decimal && otherValue instanceof Decimal) {
        return value.equals(otherValue);
      }
      return false;
    }
  );

/**
 * Vuelidate custom validator:
 * validates that two fields (potentially unrelated to the current field) have the same value
 */
export const sameValues = <T>(fieldNameA: string, fieldNameB: string): CustomRule =>
  helpers.withParams({ type: 'sameValues' }, (_: unknown, parentVm: T) => {
    const valueA = parentVm[fieldNameA];
    const valueB = parentVm[fieldNameB];
    return valueA === valueB;
  });
