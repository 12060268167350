import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';
import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors';
import {
  RawSponsoredLoanBasketResponse,
  SponsoredLoanBasketRequest,
  SponsoredLoanBasketResponse,
  SponsoredLoanCreateRequest,
} from '@/modules/sponsorship/models';

export class SponsorshipApiService extends ApiService {
  private featureUrl = '/sponsorship';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.sponsorship = singleton;
    } else {
      v.prototype.$api = {
        sponsorship: singleton,
      };
    }
  }

  public async createLoans(
    createRequest: SponsoredLoanCreateRequest
  ): Promise<Api.Sponsorship.NewLoanResponse> {
    const url = this.baseUrl + this.featureUrl;
    const { data } = await this.axios.post<Api.Sponsorship.NewLoanResponse>(url, createRequest);
    return data;
  }

  public async uploadLoans(
    request: SponsoredLoanBasketRequest
  ): Promise<SponsoredLoanBasketResponse> {
    const url = this.baseUrl + this.featureUrl + '/basket-parse';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = SponsoredLoanBasketRequest.toFormData(request);

    try {
      const { data } = await this.axios.post<RawSponsoredLoanBasketResponse>(url, formData, {
        headers,
      });
      return SponsoredLoanBasketResponse.fromData(data);
    } catch (e) {
      const apiError = e as ApiError;
      const data = apiError.responseData as unknown as RawSponsoredLoanBasketResponse;
      if (data) {
        try {
          apiError.responseData = SponsoredLoanBasketResponse.fromData(data) as unknown as Record<
            string,
            unknown
          >;
        } catch {
          void 0;
        }
      }
      throw e;
    }
  }
}
