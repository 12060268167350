<template>
  <items-batch-confirmation-dialog
    :description="`You are about to cancel the returns of ${items.length} loans:`"
    :execute="execute"
    item-id-key="displayId"
    :items="items"
    success-message="Return cancellations successfully initiated."
    :table-headers="[
      { text: 'Counterparty' },
      { text: 'Ticker' },
      { text: 'CUSIP' },
      { text: 'Open' },
      { text: 'Rebate', align: 'end' },
      { text: 'Recalled' },
    ]"
    title="Cancel Returns"
    v-on="$listeners"
  >
    <template #row="{ item }">
      <td>
        {{ item.counterpartyDisplay }}
      </td>
      <td>
        {{ item.security.ticker }}
      </td>
      <td>
        {{ item.security.cusip }}
      </td>
      <td>
        <pretty-number :value="item.openQuantity" />
      </td>
      <td class="text-end">
        <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
      </td>
      <td>
        <pretty-number :value="item.recalledQuantity" />
      </td>
    </template>
  </items-batch-confirmation-dialog>
</template>

<script lang="ts">
import ItemsBatchConfirmationDialog from '@/modules/common/components/ItemsBatchConfirmationDialog.vue';
import { BorrowerOpenLoan, LenderOpenLoan } from '@/modules/common/models';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

@Component({
  components: { ItemsBatchConfirmationDialog },
  props: {
    items: Array as PropType<LenderOpenLoan[] | BorrowerOpenLoan[]>,
  },
})
export default class BatchCancelReturnDialog extends Vue {
  // props
  protected readonly items!: LenderOpenLoan[] | BorrowerOpenLoan[];

  private async execute(): Promise<{ failedIds?: string[] }> {
    const displayIds = this.items.map((i: LenderOpenLoan | BorrowerOpenLoan) => i.displayId);
    const result = await this.$api.borrowerLoans.batchCancelReturnBorrowerLoan(displayIds);
    const failedIds = result.failedItems.map((i) => i.requestId);

    // failing ids only, we are losing the error messages here
    return { failedIds };
  }
}
</script>
<style lang="scss" scoped></style>
