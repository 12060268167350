<template>
  <v-chip :class="statusCssClass[item.status]" small>
    <slot name="prepend" />
    {{ capitalize(item.status) }}
  </v-chip>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { manualLoanStatusCssClass } from '@/modules/manual-loan/constants/manual-loans.const';
import { ManualLoanListItem } from '@/modules/manual-loan/types/manual-loans';
import { capitalize } from 'lodash';

@Component({
  props: {
    item: Object as PropType<ManualLoanListItem>,
  },
})
export default class ManualLoanStatusChip extends Vue {
  protected readonly item!: ManualLoanListItem;

  protected statusCssClass = manualLoanStatusCssClass;
  protected capitalize = capitalize;
}
</script>
<style lang="scss" scoped>
.v-chip {
  min-width: 6rem;
  justify-content: center;

  &.status-pending {
    color: #fff;
    background: #ffae00;
  }

  &.status-accepted {
    color: #fff;
    background: #4caf50;
  }

  &.status-automatched {
    color: #fff;
    background: #4caf50;
  }

  &.status-rejected {
    color: #fff;
    background: #df1616;
  }

  &.status-canceled {
    color: #fff;
    background: #7a7a7a;
  }

  &.status-expired {
    color: #fff;
    background: #8f8f8f;
  }
}
</style>
