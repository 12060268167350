import logger from '@/modules/common/services/logger.service';

export enum Context {
  None = '',
  Trader = 'trader',
  Broker = 'broker',
}

export type ApiContext = '' | 'broker' | 'trader';

export enum Role {
  BrokerComplianceRep = 491,
  BrokerAdmin = 490,
  BrokerUser = 410,
  TraderAdmin = 290,
  TraderUser = 210,
  OpsUser = 205,
  ConfigAdmin = 201,
  TraderViewer = 200,
  AnyUser = 1, // client only: page is accessible to ANY user (traders AND brokers), context will NOT be checked
  None = 0,
}

const userRoles = new Map<string, Role>([
  // broker context
  ['broker-compliance-rep', Role.BrokerComplianceRep],
  ['broker-admin', Role.BrokerAdmin],
  ['broker-user', Role.BrokerUser],
  // trader context
  ['trader-admin', Role.TraderAdmin],
  ['trader-user', Role.TraderUser],
  ['ops-user', Role.OpsUser],
  ['trader-viewer', Role.TraderViewer],
  ['config-admin', Role.ConfigAdmin],
]);

const roleContext = new Map<Role, ApiContext>([
  // broker context
  [Role.BrokerComplianceRep, Context.Broker],
  [Role.BrokerAdmin, Context.Broker],
  [Role.BrokerUser, Context.Broker],
  // trader context
  [Role.TraderAdmin, Context.Trader],
  [Role.TraderUser, Context.Trader],
  [Role.OpsUser, Context.Trader],
  [Role.TraderViewer, Context.Trader],
  [Role.ConfigAdmin, Context.Trader],
]);

export default class PermissionValidator {
  private readonly roles: Role[] = [];

  public constructor(roles: string[]) {
    if (roles.length === 0) {
      logger.warn('User has no permissions in any context!');
    }

    this.roles = roles.map((r) => userRoles.get(r) || Role.None);
  }

  // decide on the default (home) page of a user
  public defaultApiContext(): ApiContext {
    if (this.roles.length === 0) {
      return Context.None;
    }

    // lowest authorization level determines the default desktop
    //  e.g. a user with both trader- and broker roles is redirected to the trader dashboard
    const minUserRole = this.roles.reduce<Role>(
      (minRole, role) => (role < minRole ? role : minRole),
      Role.BrokerAdmin
    );
    return roleContext.get(minUserRole) || Context.None;
  }

  // hasPermission: is the user allowed to execute in a certain context
  public hasPermission(requiredRole: Role): boolean {
    // if a route requires `Role.AnyUser` role there is no need to check the context
    // Role.AnyUser is only used by the router to give all users access to a page (as long as they are logged on)
    if (this.roles.length > 0 && requiredRole === Role.AnyUser) {
      return true;
    }

    const requiredContext = roleContext.get(requiredRole);
    const maxRole = this.roles.reduce<Role>((max, role) => {
      const ctx = roleContext.get(role);
      return ctx == requiredContext && role > max ? role : max;
    }, Role.None);

    // exception to the `maxRole >= required` rule:
    //   `ConfigAdmin` is just above `TraderViewer` so normally it can only access `TraderViewer` pages
    //   (note that there are no `ConfigAdmin` pages)
    //   The exception is that `ConfigAdmin`s *can* access `TraderAdmin` pages!
    if (maxRole == Role.ConfigAdmin && requiredRole == Role.TraderAdmin) {
      return true;
    }

    return maxRole >= requiredRole;
  }
}

export function hasPermission(roles: string[], requiredRole: Role): boolean {
  return new PermissionValidator(roles).hasPermission(requiredRole);
}
