import { ColDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { alignLeft, alignRight, comparator, mergeClasses } from './utils';
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import * as cols from './common';
export { side, ticker, cusip } from './marketplace';
import { RoutingStatus } from '@/connect/gen/consts/omsconsts_pb';
import { Order } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import * as marketplaceCols from './marketplace';

export type OrdersWithEqualFieldsMap = Map<
  Order,
  { routingStatus: boolean; counterparties: boolean; minQuantity: boolean; rate: boolean }
>;

interface Config {
  ordersWithEqualFields: OrdersWithEqualFieldsMap;
}

export function routingStatus(config: Config): ColDef<Order, RoutingStatus> {
  return {
    field: 'routingStatus',
    colId: 'routingStatus',
    headerName: 'Status',
    valueFormatter: (params) => {
      return params.value === RoutingStatus.ROUTED
        ? 'Active'
        : params.value === RoutingStatus.PENDING
          ? 'Pending'
          : 'Inactive';
    },
    ...mergeClasses([
      alignLeft(),
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.routingStatus ? 'error--text' : undefined,
      },
    ]),
  };
}

export function counterparties(config: Config): ColDef<Order> {
  const baseColDef = marketplaceCols.counterparties();
  return {
    ...baseColDef,
    ...mergeClasses([
      baseColDef,
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.counterparties ? 'error--text' : undefined,
      },
    ]),
  };
}

export function executed(): ColDef<Order> {
  return {
    field: 'totalExecQty',
    colId: 'totalExecQty',
    headerName: 'Executed',
    valueFormatter: (params: ValueFormatterParams<Order, bigint>) =>
      !params.value ? '–' : formatPrettyNumber(params.value),
    comparator: comparator.bigint,
    ...alignRight(),
  };
}

export function quantity(): ColDef<Order> {
  return cols.quantity({
    field: 'quantity',
    headerName: 'Quantity',
  });
}

export function minQuantity(config: Config): ColDef<Order> {
  const baseColDef = marketplaceCols.minQuantity();
  return {
    ...baseColDef,
    ...mergeClasses([
      baseColDef,
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.minQuantity ? 'error--text' : undefined,
      },
    ]),
  };
}

export function rate(config: Config): ColDef<Order> {
  const colRate = cols.rate();
  return {
    ...colRate,
    ...mergeClasses([
      colRate,
      {
        cellClass: (params) =>
          config.ordersWithEqualFields.get(params.data)?.rate ? 'error--text' : undefined,
      },
    ]),
  } as ColDef<Order>;
}
