<template>
  <div
    v-if="!isOnline || !isConnected"
    class="mt-6 d-flex fill-height align-center justify-center flex-column online-indicator"
  >
    <v-alert data-test="offline-message" icon="mdi-wifi-off" type="warning">
      <b>Not connected {{ ' ' }} </b>
      <span v-if="isReconnecting" class="reconnect" data-test="reconnecting-message">
        reconnecting
      </span>
      <a
        class="white--text text-decoration-underline"
        data-test="reload-page-link"
        @click="reloadPage"
      >
        try now
      </a>
    </v-alert>
  </div>
</template>

<script lang="ts">
import { useIsOnline } from '@/composables/native';
import { StoreSocket, useStoreSocket } from '@/store/store-socket';
import { storeToRefs } from 'pinia';
import { type Ref } from 'vue';
import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  setup() {
    const { isConnected, isReconnecting } = storeToRefs(useStoreSocket());
    const isOnline = useIsOnline();
    return { isConnected, isReconnecting, isOnline };
  },
})
export default class OnlineIndicator extends Vue {
  protected isOnline!: boolean;

  // pinia
  protected readonly isConnected!: Ref<StoreSocket['isConnected']>;
  protected readonly isReconnecting!: Ref<StoreSocket['isReconnecting']>;

  protected reloadPage(): void {
    window.location.reload();
  }
}
</script>

<style lang="scss" scoped>
.online-indicator {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.reconnect {
  padding-right: 16px;
  &:after {
    content: ' .';
    font-size: 18px;
    animation: dots 1.5s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        0.25em 0 0 rgba(0, 0, 0, 0),
        0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow:
        0.25em 0 0 rgba(0, 0, 0, 0),
        0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow:
        0.25em 0 0 white,
        0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow:
        0.25em 0 0 white,
        0.5em 0 0 white;
    }
  }
}
</style>
