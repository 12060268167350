<template>
  <div>
    <dashboard-panel no-collapse :title="$t('sidebar.items.loansHistory')">
      <div class="filters-container">
        <div class="filters">
          <div>
            <simple-security-search
              v-model="selectedSecurity"
              class="simple-security-search"
              clearable
              data-test="simple-security-search"
              label="Security"
            />
          </div>
          <div>
            <counterparty-search
              v-model="counterparty"
              class="counterparties"
              clearable
              data-test="counterparty-search"
              include-sponsored
              placeholder="All counterparties"
              :side="counterpartySide"
            />
          </div>

          <div>
            <v-select
              v-model="selectedSide"
              class="side"
              clearable
              data-test="side"
              :items="sideItems"
              label="Side"
            />
          </div>
          <div style="margin-top: 18px">
            <text-date-picker
              v-model="selectedDate"
              clearable
              data-test="selected-date"
              label="Day"
            />
          </div>
          <div>
            <loan-event-select
              v-model="selectedEvents"
              clearable
              data-test="selected-events"
              multiple
              placeholder="All Events"
            />
          </div>
          <div>
            <v-text-field
              v-model="selectedDisplayId"
              clearable
              data-test="selected-display-id"
              label="Loan Display Id"
              prepend-inner-icon="mdi-magnify"
            />
          </div>
          <div>
            <v-text-field
              v-model="selectedInitiator"
              clearable
              data-test="selected-initiator"
              label="Initiator"
            />
          </div>
        </div>
      </div>
      <ag-table-server
        :column-defs="columnDefs"
        :get-row-id="getRowId"
        :page="page"
        :page-size="1000"
        :pagination-page-size="paginationPageSize"
        :query-data="queryData"
        :selected-items="selectedItems"
        :sort.sync="sort"
        :summary="summary"
        table-id="LoansHistory"
        @ready="onReady"
        @update:selected-items="updateSelectedItems"
      />
      <loan-details-dialog
        v-if="detailLoanId"
        :loan-id="detailLoanId"
        @close-modal="detailLoanId = null"
      />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import OpenLoansList from '@/modules/open-loans/components/OpenLoansList.vue';
import { mapGetters, mapState } from 'vuex';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import SimpleSecuritySearch from '@/modules/manual-loan/components/SimpleSecuritySearch.vue';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import TextDatePicker from '@/modules/common/components/TextDatePicker.vue';
import SettlementTypeSelect from '@/modules/common/components/SettlementTypeSelect.vue';
import LoanEventSelect from '@/modules/common/components/LoanEventSelect.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import { AgGridVue } from 'ag-grid-vue';
import { AgTableServer } from '@/modules/common/components/ag-table';
import { ColDef, LoadSuccessParams, SortModelItem } from 'ag-grid-enterprise';
import * as cols from '@/modules/common/components/ag-table/columns/loans-history';
import { SocketEvents } from '@/store/store';
import { Agg, getAggHistory } from './getAggOrHistory';
import {
  LoanHistoryEvent,
  LoanHistoryEventInitiator,
} from '@/connect/gen/modules/apiengine/services/loans/loans_pb';
import {
  LoanHistoryEventInitiatorKind,
  LoanHistoryEventType,
} from '@/connect/gen/consts/loanhistoryconsts_pb';
import { LoanStatus } from '@/connect/gen/consts/loanconsts_pb';
import { serviceLoans } from '@/connect/services/loans';
import { LoansHistoryQueryFilter } from '@/connect/gen/modules/apiengine/services/loans/loans_pb';
import { Watch } from 'vue-property-decorator';
import { addDays } from 'date-fns';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { proto3 } from '@bufbuild/protobuf';
import { CompanyInfo, Security } from '@/modules/common/models';

function stubFn() {
  return;
}

const MAX_ITEMS_PER_PAGE = 1000;

@Component({
  provide() {
    return {
      gridStateKey: 'LoansHistory',
    };
  },
  components: {
    DashboardPanel,
    OpenLoansList,
    LoanDetailsDialog,
    AgGridVue,
    AgTableServer,
    SimpleSecuritySearch,
    CounterpartySearch,
    TextDatePicker,
    SettlementTypeSelect,
    LoanEventSelect,
  },
  computed: {
    ...mapState(['clientConfig', 'uxConfig', 'socketEvents']),
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canBorrowAndLend']),
  },
})
export default class LoansHistory extends Vue {
  protected isRecalled = false;
  protected isRenegotiating = false;
  protected counterparty: null | CompanyInfo = null;
  protected security: Security | null = null;
  protected side: 'lender' | 'borrower' | null = null;
  protected sort: SortModelItem = { colId: 'eventTime', sort: 'desc' };
  protected page = 1;
  protected paginationPageSize = MAX_ITEMS_PER_PAGE;
  protected showAll = false;
  protected detailLoanId: string | null = null;
  protected selectedSecurity: Security | null = null;
  protected statuses: LoanStatus[] = [];
  protected sideItems = [
    { text: 'Lender', value: Side.LENDER },
    { text: 'Borrower', value: Side.BORROWER },
  ];
  protected selectedSide: Side | null = null;
  protected selectedDate: Date | null = null;
  protected selectedSettlementType: 'NSCC' | 'Bilateral' | 'OCC' | null = null;
  protected selectedEvents: LoanHistoryEventType[] = [];
  protected selectedDisplayId: string | null = null;
  protected selectedInitiator: string | null = null;

  protected selectedItems: LoanHistoryEvent[] = [];
  protected agg: Agg | null = null;
  protected sumOrAvg: 'sum' | 'avg' = 'sum';

  // @TODO: should probably be a prop
  protected showSelect = true;

  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;
  protected resetSelection: () => void = stubFn;

  // store state
  protected hasTraderUserRole!: boolean;
  protected canBorrow!: boolean;
  protected canBorrowAndLend!: boolean;
  protected clientConfig!: ClientConfig;
  protected uxConfig!: UXConfig;
  protected socketEvents!: SocketEvents;

  protected get columnDefs(): ColDef[] {
    return this.allColumnDefs().filter((h) => this.showSelect || h.colId !== 'checkbox');
  }

  protected get summary(): [] | [Agg] {
    return this.agg === null ? [] : [this.agg];
  }

  protected get counterpartySide(): 'ALL' | 'LENDER' | 'BORROWER' {
    return this.canBorrowAndLend ? 'ALL' : this.canBorrow ? 'LENDER' : 'BORROWER';
  }

  @Watch('selectedSecurity')
  @Watch('counterparty')
  @Watch('selectedEvents')
  @Watch('selectedDate')
  @Watch('selectedDisplayId')
  @Watch('selectedSide')
  @Watch('selectedInitiator')
  protected onChangeFilters(): void {
    this.tableRefresh({ purge: true });
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  protected allColumnDefs(): ColDef[] {
    return [
      cols.checkbox(),
      cols.displayId(),
      cols.eventTime(),
      cols.eventInitiator(),
      cols.side(),
      cols.eventType(),
      cols.counterparty(),
      cols.settlementType(),
      cols.ticker(),
      cols.cusip(),
      cols.openQuantity(),
      cols.openQuantityChange(),
      cols.rate(),
      cols.unitPrice(),
      cols.settlementAmount(),
      cols.settlementAmountChange(),
      cols.rateAmount(),
      cols.actions({ changeSumOrAvg: this.changeOrderSumOrAvg, viewLoan: this.viewLoan }),
    ];
  }

  protected onReady(config: {
    refresh: (config: { purge: boolean }) => void;
    resetSelection: () => void;
  }): void {
    this.tableRefresh = config.refresh;
    this.resetSelection = config.resetSelection;
    this.$emit('ready');
  }

  protected async queryData(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    signal: AbortSignal;
  }): Promise<LoadSuccessParams | undefined> {
    const result = await serviceLoans.queryLoansHistory({
      ...config,
      filter: this.getQueryFilter(),
    });

    if (!result.success) {
      this.$snackbar.show({ message: this.$i18n.t(result.error, result.details) as string });
      return;
    }

    // @TODO: remove number conversion once BE changes it
    return { rowData: result.data.events, rowCount: Number(result.data.totalEntries) };
  }

  protected getQueryFilter(): LoansHistoryQueryFilter {
    const filter = new LoansHistoryQueryFilter({
      instruments: this.selectedSecurity ? [this.selectedSecurity.cusip] : [],
      counterparties: this.counterparty ? [this.counterparty.companyId] : [],
      eventTypes: this.selectedEvents,
      eventTimeStart: this.selectedDate ? this.selectedDate.toISOString() : undefined,
      eventTimeEnd: this.selectedDate ? addDays(this.selectedDate, 1).toISOString() : undefined,
      loans: this.selectedDisplayId ? [this.selectedDisplayId] : [],
      side: this.selectedSide ?? undefined,
    });

    // try to guess enum value from input text field value
    const initiator = new LoanHistoryEventInitiator({
      kind: this.selectedInitiator
        ? proto3
            .getEnumType(LoanHistoryEventInitiatorKind)
            .values.find((v) => v.name === this.selectedInitiator?.toLocaleUpperCase().trim())?.no
        : undefined,
    });

    if (
      initiator.kind !== LoanHistoryEventInitiatorKind.UNSPECIFIED_EVENT_INITIATOR_KIND &&
      initiator.kind !== LoanHistoryEventInitiatorKind.TRADER
    ) {
      filter.initiatorKind = initiator.kind;
    } else if (this.selectedInitiator) {
      filter.initiatorId = this.selectedInitiator?.trim();
    }

    return filter;
  }

  protected viewLoan(loanId: string): void {
    this.detailLoanId = loanId;
  }

  protected getRowId(item: LoanHistoryEvent): string {
    return item.eventId;
  }

  protected updateSelectedItems(selectedItems: LoanHistoryEvent[]): void {
    const agg = selectedItems.length === 0 ? null : getAggHistory(selectedItems, this.sumOrAvg);
    this.agg = agg;
    this.selectedItems = selectedItems;
  }

  protected changeOrderSumOrAvg(sumOrAvg: 'sum' | 'avg'): void {
    this.sumOrAvg = sumOrAvg;
    // @TODO: all a bit confusing still
    this.updateSelectedItems(this.selectedItems);
  }
}
</script>

<style lang="scss">
.filters-container {
  container-type: inline-size;
  padding: 0 1rem;
}

.filters {
  display: grid;
  gap: 1rem;
}

.filters > div {
  min-width: 150px;
  max-width: 400px;
}

@container (min-width: 400px) {
  .filters {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@container (min-width: 600px) {
  .filters {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@container (min-width: 800px) {
  .filters {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@container (min-width: 1000px) {
  .filters {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@container (min-width: 1200px) {
  .filters {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@container (min-width: 1600px) {
  .filters {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@container (min-width: 2000px) {
  .filters {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
