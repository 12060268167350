<template>
  <div style="display: contents">
    <ag-table-client
      :column-defs="columnDefs"
      data-test="top-of-book-table"
      fit-full-width
      :get-row-id="getRowId"
      :page-size="1000"
      :row-data="items"
      :sort="{ colId: 'securityTicker', sort: 'asc' }"
      @click:cell="handleRowClick"
    />
  </div>
</template>

<script lang="ts">
import {
  customSortTopOfBook,
  formatCounterparties,
} from '@/modules/marketplace/helpers/marketplace';
import { CellClickedEvent, ColDef } from 'ag-grid-enterprise';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import * as cols from '@/modules/common/components/ag-table/columns/marketplace-top-of-book';
import { AgTableClient } from '@/modules/common/components/ag-table';
import { VenueTopOfBookItem, VenueTopOfBookResponse } from '@/modules/marketplace/models';

@Component({
  props: {
    items: Array as PropType<VenueTopOfBookResponse['data']>,
    asBroker: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AgTableClient,
  },
})
export default class MarketplaceTopOfBookTable extends Vue {
  // props
  protected items!: VenueTopOfBookResponse['data'];
  protected readonly asBroker!: boolean;
  protected sortBy = ['security'];
  protected formatCounterparties = formatCounterparties;
  protected customSortTopOfBook = customSortTopOfBook;

  protected get columnDefs(): Array<ColDef<VenueTopOfBookItem>> {
    return [
      cols.ticker(),
      cols.cusip(),

      cols.borrowEligibleTotalQuantity(),
      cols.borrowCounterparty(),
      cols.borrowQuantity(),
      cols.borrowRate(),

      cols.lendRate(),
      cols.lendQuantity(),
      cols.lendCounterparty(),
      cols.lendEligibleTotalQuantity(),
    ];
  }

  protected getRowId(item: VenueTopOfBookItem): string {
    return item.security.cusip;
  }

  // @TODO: make a proper link so users can open in a new tab, for example
  protected handleRowClick({ data: item }: CellClickedEvent<VenueTopOfBookItem>): void {
    if (item === undefined) return;
    if (this.asBroker) {
      void this.$router.push({
        name: 'broker-user.client-monitor.marketplace-depth-of-book',
        params: { cusip: item.security.cusip },
      });
    } else {
      void this.$router.push({
        name: 'marketplace.symbol-overview',
        params: { cusip: item.security.cusip },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ag-cell {
    cursor: pointer;
  }

  .highlighted-border-right {
    border-right: 1px solid #ccc !important; /* @TODO: fix */
  }
}
</style>
